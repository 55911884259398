package support

import js.objects.jso
import react.ElementType
import react.Props
import react.ReactElement
import react.createElement

sealed class Mode {
    object Search: Mode()
    object Edit: Mode()
    object Create: Mode()
    object Activate: Mode()
}

external interface ModeProps : Props {
    var mode: Mode
}

fun <P : ModeProps> ElementType<P>.create(mode: Mode): ReactElement<P> =
    createElement(this, props = jso { this.mode = mode })