package components

import mui.material.TablePagination
import react.FC
import react.Props
import react.create
import support.DesignSystem

external interface RPaginationProps : Props {
    var design: DesignSystem.Pagination
    var onPageChange: ((Int) -> Unit)
    var onRowsPerPage: ((Int) -> Unit)
}

val RPagination = FC<RPaginationProps> { props ->
    if (props.design.visible) {
        TablePagination {
            count = props.design.content?.items?.total ?: 0
            page = (props.design.content?.index?.page ?: 1) - 1
            rowsPerPage = props.design.content?.index?.size ?: 0
            onPageChange = { _, page -> props.onPageChange(page.toInt() + 1) }
            onRowsPerPageChange = { props.onRowsPerPage(it.target.asDynamic().value as Int) }
            labelDisplayedRows = {
                val count = if (it.count != -1) "${it.count}" else "mer än ${it.to}"
                RText.create { design = props.design.label.copy(text = "${it.from}–${it.to} av $count") }
            }
            labelRowsPerPage = RText.create { design = props.design.label.copy(text = "Rader per sida:") }
        }
    }
}