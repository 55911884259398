package views

import StoreContext
import components.*
import emotion.react.css
import js.objects.jso
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useRequiredContext
import react.useState
import screens.GalleryScreen
import support.DesignSystem
import support.RScaffold
import support.sceneInputOf
import support.useAsyncEffect
import techla.base.Identifier
import techla.storage.Asset
import web.html.HTMLElement
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Gallery = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(GalleryScreen.ViewModel.None as GalleryScreen.ViewModel)

    var asset by useState<Asset.Create?>(null)
    var currentAsset by useState<Identifier<Asset>?>(null)
    var anchorElement by useState<HTMLElement?>(null)
    var showUploadedToast by useState(false)
    var showDeletedToast by useState(false)

    suspend fun handleUpload() {
        GalleryScreen.upload(sceneInputOf(store, viewModel), asset = asset).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
            showUploadedToast = true
        }
    }

    fun handleDelete() {
        MainScope().launch {
            GalleryScreen.delete(sceneInputOf(store, viewModel), assetId = currentAsset).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                showDeletedToast = true
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is GalleryScreen.ViewModel.None -> if (coroutineScope.isActive) {
                GalleryScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is GalleryScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                GalleryScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is GalleryScreen.ViewModel.Ready -> {}
            is GalleryScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is GalleryScreen.ViewModel.Ready) {
            RToast {
                open = showDeletedToast
                onClose = { showDeletedToast = false }
                design = viewModel.deleted
            }
            RToast {
                open = showUploadedToast
                onClose = { showUploadedToast = false }
                design = viewModel.uploaded
            }

            Stack {
                spacing = responsive(2)

                RText { design = viewModel.title }

                Stack {
                    RForm {
                        css {
                            justifyContent = JustifyContent.center
                            alignItems = AlignItems.center
                            display = Display.flex
                            flexDirection = FlexDirection.row
                        }
                        onSubmit = ::handleUpload
                        Stack {
                            spacing = responsive(2)
                            sx {
                                width = 35.pct
                            }

                            Stack {
                                spacing = responsive(0.5)
                                RFileInput {
                                    onChange = { value -> asset = value }
                                    design = viewModel.asset
                                }
                            }

                            RButton { design = viewModel.upload }
                        }
                    }
                }

                RTable {
                    design = viewModel.assets

                    viewModel.assets.data?.map { (asset, row) ->
                        TableRow {
                            TableCell {
                                variant = TableCellVariant.body
                                sx {
                                    cursor = Cursor.pointer
                                }

                                Stack {
                                    direction = responsive(StackDirection.row)
                                    sx {
                                        justifyContent = JustifyContent.spaceBetween
                                        alignItems = AlignItems.center
                                    }
                                    Stack {
                                        direction = responsive(StackDirection.row)
                                        spacing = responsive(2)
                                        sx {
                                            alignItems = AlignItems.center
                                        }

                                        row.map { item ->
                                            when (item) {
                                                is DesignSystem.ImageView -> RImage { design = item; height = 50.0; width = 50.0 }
                                                is DesignSystem.Text -> RText { design = item }
                                                else -> {}
                                            }
                                        }
                                    }

                                    img {
                                        height = 26.0
                                        src = "img/ico_action_dots.svg"

                                        onClick = { event ->
                                            currentAsset = asset.id
                                            anchorElement = event.currentTarget
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }

            if (anchorElement != null) {
                Popover {
                    anchorEl = anchorElement
                    open = anchorElement != null
                    onClose = { _, _ -> anchorElement = null }
                    anchorOrigin = jso {
                        vertical = "bottom"
                        horizontal = "right"
                    }
                    transformOrigin = jso {
                        vertical = "top"
                        horizontal = "right"
                    }

                    div {
                        onClick = {
                            handleDelete()
                            anchorElement = null
                        }
                        css {
                            padding = Padding(4.px, 16.px)
                            cursor = Cursor.pointer
                        }
                        +viewModel.texts.delete
                    }
                }
            }
        }

        if (viewModel is GalleryScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is GalleryScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}
