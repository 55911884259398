package support

import techla.base.Key
import techla.conversation.Feed


data class FeedInfo(
    val id: String,
    val key: String,
    val read: Boolean,
    val write: Boolean,
    val delete: Boolean,
    val standard: Boolean,
)

fun List<FeedInfo>.readableFeeds(feeds: List<Feed>): List<Feed> {
    val keys = this.mapNotNull { if (it.read) Key<Feed>(it.key) else null }
    return feeds.filter { keys.contains(it.key) }
}

fun List<FeedInfo>.writeableFeeds(feeds: List<Feed>): List<Feed> {
    val keys = this.mapNotNull { if (it.write) Key<Feed>(it.key) else null }
    return feeds.filter { keys.contains(it.key) }
}

fun List<FeedInfo>.deletableFeeds(feeds: List<Feed>): List<Feed> {
    val keys = this.mapNotNull { if (it.delete) Key<Feed>(it.key) else null }
    return feeds.filter { keys.contains(it.key) }
}
