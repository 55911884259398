package views

import StoreContext
import components.*
import emotion.react.css
import js.objects.jso
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import screens.DashboardScreen
import support.*
import support.Statistics.MonthlyChange.ChangeType.*
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Dashboard = FC<Props> { props ->
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(DashboardScreen.ViewModel.None as DashboardScreen.ViewModel)
    val chartRef = useRef<RChartProps>(null)
    var ctx by useState<dynamic>(null)

    fun onTopListChange(value: DesignSystem.Option) {
        MainScope().launch {
            DashboardScreen.setValues(sceneInputOf(store, viewModel), selectedTopList = value).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    fun onStatisticsChange(value: DesignSystem.Option) {
        MainScope().launch {
            DashboardScreen.setValues(sceneInputOf(store, viewModel), selectedStatistic = value).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is DashboardScreen.ViewModel.None -> if (coroutineScope.isActive) {
                DashboardScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is DashboardScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                DashboardScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is DashboardScreen.ViewModel.Ready -> {
                ctx = chartRef.current?.asDynamic()?.ctx
            }

            else -> {}
        }
    }

    RScaffold {
        if (viewModel is DashboardScreen.ViewModel.Ready) {
            val stats = viewModel.state.statistics

            RText { design = viewModel.title; css { marginBottom = important(1.5.rem) } }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(3)

                Card {
                    sx {
                        width = 100.pct
                        backgroundColor = Color("blue")
                        padding = 1.rem
                        borderRadius = important(6.px)
                        backgroundImage = "linear-gradient(135deg, #32FBFC 0%, #3214F2 100%)".unsafeCast<BackgroundImage>()
                        boxShadow = important("0 8px 20px 0 rgb(0 0 0 / 10%)".unsafeCast<BoxShadow>())
                    }
                    RText { design = viewModel.users; css { marginBottom = important(0.5.rem) } }
                    RText {
                        design = DesignSystem.Text(
                            text = formatNumber(stats?.total?.users),
                            size = DesignSystem.SizeType.XL2,
                            style = DesignSystem.StyleType.BOLD,
                            align = DesignSystem.TextAlign.CENTER,
                            background = DesignSystem.Background.DARK
                        )
                        css {
                            marginBottom = important(0.5.rem)
                        }
                    }
                }

                Card {
                    sx {
                        width = 100.pct
                        backgroundColor = Color("blue")
                        padding = 1.rem
                        borderRadius = important(6.px)
                        backgroundImage = "linear-gradient(135deg, #F2C314 0%, #FC3832 100%)".unsafeCast<BackgroundImage>()
                        boxShadow = important("0 8px 20px 0 rgb(0 0 0 / 10%)".unsafeCast<BoxShadow>())
                    }
                    RText { design = viewModel.authentications; css { marginBottom = important(0.5.rem) } }
                    RText {
                        design = DesignSystem.Text(
                            text = formatNumber(stats?.total?.userAuthentications),
                            size = DesignSystem.SizeType.XL2,
                            style = DesignSystem.StyleType.BOLD,
                            align = DesignSystem.TextAlign.CENTER,
                            background = DesignSystem.Background.DARK
                        )
                        css {
                            marginBottom = important(0.5.rem)
                        }
                    }
                }

                Card {
                    sx {
                        width = 100.pct
                        backgroundColor = Color("blue")
                        padding = 1.rem
                        borderRadius = important(6.px)
                        backgroundImage = "linear-gradient(135deg, #9C09DB 0%, #1C0FD7 100%)".unsafeCast<BackgroundImage>()
                        boxShadow = important("0 8px 20px 0 rgb(0 0 0 / 10%)".unsafeCast<BoxShadow>())
                    }
                    RText { design = viewModel.messages; css { marginBottom = important(0.5.rem) } }
                    RText {
                        design = DesignSystem.Text(
                            text = formatNumber(stats?.total?.messages),
                            size = DesignSystem.SizeType.XL2,
                            style = DesignSystem.StyleType.BOLD,
                            align = DesignSystem.TextAlign.CENTER,
                            background = DesignSystem.Background.DARK
                        )
                        css {
                            marginBottom = important(0.5.rem)
                        }
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = AlignItems.start
                    marginTop = 2.5.rem
                    marginBottom = 1.5.rem
                }

                RText { design = viewModel.perMonth; css { fontWeight = important(FontWeight.bold) } }

                div {
                    css {
                        width = 25.pct
                    }
                    RSelectInput {
                        design = viewModel.selectStatistic
                        onChange = ::onStatisticsChange
                    }
                }
            }

            RChart {
                this.ref = chartRef
                this.labels = stats?.monthly?.labels?.toTypedArray() ?: emptyArray()
                this.data = run {
                    val statsType = StatisticType.valueOf(viewModel.state.selectedStatistic.value)

                    val gradientFill = ctx?.createLinearGradient(1000, 0, 0, 75)
                    when (statsType) {
                        StatisticType.MESSAGES -> {
                            gradientFill?.addColorStop(0, "rgba(28, 15, 215, 1)");
                            gradientFill?.addColorStop(1, "rgba(156, 9, 219, 1)");
                        }

                        StatisticType.AUTHENTICATIONS -> {
                            gradientFill?.addColorStop(0, "rgba(242, 195, 20, 1)");
                            gradientFill?.addColorStop(1, "rgba(252, 56, 50, 1)");
                        }

                        StatisticType.USERS -> {
                            gradientFill?.addColorStop(0, "rgba(50, 251, 252, 1)");
                            gradientFill?.addColorStop(1, "rgba(50, 20, 242, 1)");
                        }

                        StatisticType.BOOSTS -> {
                            gradientFill?.addColorStop(0, "rgba(255, 35, 202, 1)");
                            gradientFill?.addColorStop(1, "rgba(255, 0, 12, 1)");
                        }

                        StatisticType.EMPLOYEE_NPS -> {
                            gradientFill?.addColorStop(0, "rgba(169, 242, 39, 1)");
                            gradientFill?.addColorStop(1, "rgba(80, 228, 43, 1)");
                        }

                        StatisticType.MANAGER_NPS -> {
                            gradientFill?.addColorStop(0, "rgba(169, 242, 39, 1)");
                            gradientFill?.addColorStop(1, "rgba(80, 228, 43, 1)");
                        }

                        StatisticType.ENTERED -> {
                            gradientFill?.addColorStop(0, "rgba(169, 242, 39, 1)");
                            gradientFill?.addColorStop(1, "rgba(80, 228, 43, 1)");
                        }

                        StatisticType.EXITED -> {
                            gradientFill?.addColorStop(0, "rgba(169, 242, 39, 1)");
                            gradientFill?.addColorStop(1, "rgba(80, 228, 43, 1)");
                        }
                    }

                    arrayOf(jso {
                        fill = true
                        cubicInterpolationMode = "monotone"
                        backgroundColor = gradientFill
                        this.data = when (statsType) {
                            StatisticType.MESSAGES -> stats?.monthly?.messages
                            StatisticType.AUTHENTICATIONS -> stats?.monthly?.userAuthentications
                            StatisticType.USERS -> stats?.monthly?.users
                            StatisticType.EMPLOYEE_NPS -> stats?.monthly?.employeeNPS
                            StatisticType.MANAGER_NPS -> stats?.monthly?.managerNPS
                            StatisticType.BOOSTS -> stats?.monthly?.boosts
                            StatisticType.ENTERED -> stats?.monthly?.entered
                            StatisticType.EXITED -> stats?.monthly?.exited
                        }?.toTypedArray() ?: arrayOf()
                    })
                }
            }

            RText { design = viewModel.reportStatsTitle; css {marginTop = important(2.5.rem); marginBottom = important(1.5.rem); fontWeight = important(FontWeight.bold) } }
            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(3)
                sx {
                    width = 100.pct
                }

                listOfNotNull(
                    stats?.unique?.messages to viewModel.uniqueMessages,
                    stats?.unique?.likes to viewModel.uniqueLikes,
                    stats?.unique?.comments to viewModel.uniqueComments,
                    stats?.unique?.boostsCreated to viewModel.uniqueBoostsCreated,
                    stats?.unique?.boostsReceived to viewModel.uniqueBoostsReceived,
                ).chunked(4).map { chunk ->
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(3)

                        chunk.map { (monthlyChange, title) ->
                            Card {
                                sx {
                                    width = 100.pct
                                    maxWidth = 25.pct
                                    padding = 1.rem
                                    borderRadius = important(6.px)
                                    boxShadow = important("0 8px 20px 0 rgb(0 0 0 / 10%)".unsafeCast<BoxShadow>())
                                }
                                RText { design = title; css { marginBottom = important(0.5.rem) } }
                                RText {
                                    design = DesignSystem.Text(
                                        text = formatNumber(monthlyChange?.current),
                                        size = DesignSystem.SizeType.XL2,
                                        style = DesignSystem.StyleType.BOLD,
                                        align = DesignSystem.TextAlign.CENTER,
                                        background = DesignSystem.Background.LIGHT
                                    )
                                    css {
                                        marginBottom = important(0.5.rem)
                                    }
                                }

                                Stack {
                                    direction = responsive(StackDirection.row)
                                    css {
                                        alignItems = AlignItems.center
                                        justifyContent = JustifyContent.spaceBetween
                                    }

                                    RText {
                                        this.design = DesignSystem.Text(
                                            text = "${monthlyChange?.previous} ${viewModel.texts.lastMonth}",
                                        )
                                    }

                                    RChip {
                                        this.design = DesignSystem.Chip(
                                            message = DesignSystem.Text(
                                                text = "${monthlyChange?.changeSign}${monthlyChange?.change}",
                                                size = DesignSystem.SizeType.XS,
                                            ),
                                            style = when (monthlyChange?.changeType) {
                                                INCREASE -> DesignSystem.PaletteType.SUCCESS
                                                DECREASE -> DesignSystem.PaletteType.DANGER
                                                SAME -> DesignSystem.PaletteType.INFO
                                                null -> DesignSystem.PaletteType.INFO
                                            }
                                        )
                                    }
                                }
                            }
                        }

                        // Add empty cards to fill the row so that all cards are the same size
                        repeat(4 - chunk.size) {
                            div {
                                css {
                                    width = 100.pct
                                    maxWidth = 25.pct
                                    padding = 1.rem
                                }
                            }
                        }
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    justifyContent = JustifyContent.spaceBetween
                    alignItems = AlignItems.flexStart
                    marginTop = important(2.5.rem)
                }

                RText { design = viewModel.topListTitle }
                div {
                    css { maxWidth = 20.pct }
                    RSelectInput {
                        design = viewModel.selectTopList
                        onChange = ::onTopListChange
                    }
                }
            }

            Card {
                sx {
                    marginTop = important(1.rem)
                    marginBottom = important(5.rem)
                    paddingRight = important(1.rem)
                    paddingLeft = important(1.rem)
                }
                RTable {
                    design = viewModel.topList
                    striped = false

                    viewModel.topList.data?.map { (_, row) ->
                        TableRow {
                            row.map { cell ->
                                TableCell {
                                    variant = TableCellVariant.body
                                    when (cell) {
                                        is DesignSystem.Container -> {
                                            Stack {
                                                direction = responsive(StackDirection.row)
                                                spacing = responsive(1)
                                                sx {
                                                    alignItems = AlignItems.center
                                                }

                                                cell.children.forEachIndexed { idx, child ->
                                                    when (child) {
                                                        is DesignSystem.Text -> {
                                                            // First cell is profile circle
                                                            if (idx == 0) {
                                                                div {
                                                                    css {
                                                                        borderRadius = 9999.px
                                                                        width = 2.5.rem
                                                                        height = 2.5.rem
                                                                        backgroundImage = "linear-gradient(101.88deg, #32FBFC 0%, #3214F2 102.94%)".unsafeCast<BackgroundImage>()
                                                                        display = Display.flex
                                                                        alignItems = AlignItems.center
                                                                        justifyContent = JustifyContent.center
                                                                        boxShadow = "0px 3px 3px 0px #00000033".unsafeCast<BoxShadow>()

                                                                    }
                                                                    RText { design = child }
                                                                }
                                                            } else {
                                                                RText { design = child }
                                                            }
                                                        }
                                                        else -> {}
                                                    }
                                                }
                                            }
                                        }
                                        is DesignSystem.Text -> {
                                            RText { design = cell }
                                        }
                                        else -> {}
                                    }
                                }
                            }
                        }
                    }
                }

                if (viewModel.topList.data?.isEmpty() == true) {
                    div {
                        css {
                            display = Display.flex
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            height = 12.rem
                        }
                        Stack {
                            spacing = responsive(1)
                            direction = responsive(StackDirection.column)

                            RText { design = viewModel.noDataIcon }
                            RText { design = viewModel.noDataText }
                        }
                    }
                }
            }
        }

        if (viewModel is DashboardScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is DashboardScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}
