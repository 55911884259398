package screens

import screens.items.*
import services.*
import support.*
import techla.base.*
import techla.form.Field
import techla.form.Form
import techla.personal.Organization

object EmployeeSurveyScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
        val info = DesignSystem.Header(id = "info")
        val pageBreak = DesignSystem.Header(id = EmployeeSurvey.PAGE_BREAK)

        val cities = DesignSystem.Header(id = "cities")
        val departments = DesignSystem.Header(id = "departments")
        val positions = DesignSystem.Header(id = "positions")

        fun score0(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${EmployeeSurvey.stringFromStyle(Field.Style.Score(0, 10), "5")}${id.rawValue}")
        fun score0Max(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${EmployeeSurvey.stringFromStyle(Field.Style.Score(0, 10), "10")}${id.rawValue}")
        fun score1(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${EmployeeSurvey.stringFromStyle(Field.Style.Score(0, 10), "5")}${id.rawValue}")
        fun long(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${EmployeeSurvey.stringFromStyle(Field.Style.Long, "")}${id.rawValue}")
    }

    data class Texts(
        val employeeSurvey: String,
        val create: String,
        val done: String,
        val name: String,
        val info: String,

        val score0: String,
        val score0Max: String,
        val score1: String,
        val long: String,
        val pageBreak: String,

        val cities: String,
        val departments: String,
        val positions: String,

        val successTitle: String,
        val successBody: String,
        val deleteBody: String,
        val disableBody: String,
        val enableBody: String,
        val duplicateBody: String,

        override val fieldRequired: String,
    ) : ActivityTexts

    enum class Success { DELETE, ENABLE, DISABLE, DUPLICATE, SUCCESS }

    data class State(
        val mode: Mode = Mode.Search,
        val formId: Identifier<Form>? = null,
        val organization: Organization? = null,
    )

    data class SearchParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    data class ReadyParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    data class ActivateParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Search(
            override var texts: Texts,
            override var state: State,
            val params: SearchParams,
            val title: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val create: DesignSystem.Button,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val params: ReadyParams,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Activate(
            override var texts: Texts,
            override var state: State,
            val params: ActivateParams,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Waiting(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Success(
            override var texts: Texts,
            override var state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun search(state: State, params: SearchParams): ViewModel =
            Search(
                texts = texts,
                state = state,
                params = params,
                title = DesignSystem.Text(text = texts.employeeSurvey, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                image = DesignSystem.ImageView(image = DesignSystem.Image.SURVEY),
                create = DesignSystem.Button(text = texts.create, action = DesignSystem.Action.ADD),
                items = params.items
            )

        fun ready(state: State? = null, params: ReadyParams): ViewModel =
            Ready(
                texts = texts,
                state = (state ?: this.state),
                params = params,
                items = params.items
            )

        fun activate(state: State? = null, params: ActivateParams): ViewModel =
            Activate(
                texts = texts,
                state = (state ?: this.state),
                params = params,
                items = params.items
            )

        fun waiting(state: State): ViewModel =
            ViewModel.Waiting(texts = texts, state = state)

        fun success(success: EmployeeSurveyScreen.Success): ViewModel = ViewModel.Success(
            texts = texts,
            state = state,
            confirmation = DesignSystem.Confirmation(
                icon = "",
                title = texts.successTitle,
                body = when (success) {
                    EmployeeSurveyScreen.Success.DELETE -> texts.deleteBody
                    EmployeeSurveyScreen.Success.DISABLE -> texts.disableBody
                    EmployeeSurveyScreen.Success.ENABLE -> texts.enableBody
                    EmployeeSurveyScreen.Success.DUPLICATE -> texts.duplicateBody
                    EmployeeSurveyScreen.Success.SUCCESS -> texts.successBody
                },
                next = texts.done,
            )
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        fun asLoading() = this as? Loading
        fun asWaiting() = this as? Waiting
        fun asSearch() = this as? Search
        fun asReady() = this as? Ready
        fun asActivate() = this as? Activate
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    private fun options(text: Texts) = listOf<DesignSystem.Option>(
        DesignSystem.Option(title = text.score0, value = "SCORE_0", action = DesignSystem.Action.SCORE_0),
        DesignSystem.Option(title = text.score0Max, value = "SCORE_0_MAX", action = DesignSystem.Action.SCORE_0_MAX),
        DesignSystem.Option(title = text.score1, value = "SCORE_1", action = DesignSystem.Action.SCORE_1),
        DesignSystem.Option(title = text.long, value = "LONG", action = DesignSystem.Action.TEXT),
    )

    private fun readyTitles(text: Texts) = listOf(
        "Beskrivning",
        "Frågor",
    )

    private fun activateTitles(text: Texts) = listOf(
        "Målgrupp",
    )

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            employeeSurvey = "Medarbetarundersökning",
            create = "Skapa en medarbetarundersökning",
            done = "Fortsätt",
            name = "Namn på medarbetarundersökningen",
            info = "Beskrivning av medarbetarundersökningen",

            score0 = "1-10, med vet ej (förvalt 5)",
            score0Max = "1-10, med vet ej (förvalt 10)",
            score1 = "1-10, utan vet ej",
            long = "Fritext",
            pageBreak = "\uD83D\uDCC3 Sidbrytning",

            cities = "Städer",
            departments = "Avdelningar",
            positions = "Roller",

            successTitle = "Allt är nu klart \uD83C\uDF8A",
            successBody = "Då kör vi igen! Lycka till. Glöm inte att aktivera din undersökning.",
            deleteBody = "Puts väck. Men du kan alltid skapa en ny såklart!",
            disableBody = "Sådärja. Nu syns den inte längre i apparna, men resultatet hittar du under Resultat",
            enableBody = "Tuta och kör! Nu syns undersökningen i medarbetarnas app.",
            duplicateBody = "Sådärja. Nu finns en kopia på undersökningen",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isPro) return sceneOf(viewModel.getPro())
        return store.listEmployeeSurveys()
            .map { (actions, employeeSurveys) ->
                val state = viewModel.state
                val params = SearchParams(page = 1, items = buildSearch(employeeSurveys))
                sceneOf(viewModel.search(state = state, params = params), actions)
            }.failed { scene.failed(result = it) }
    }

    private suspend fun show(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.getForm(Identifier(id.rawValue))
            .map { (actions, form) ->
                val state = viewModel.state.copy(formId = form.id)
                val params = ReadyParams(
                    page = 1,
                    items = buildReady(form, viewModel.texts)
                        .decorate(mode = Mode.Edit, titles = readyTitles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(Unit).noActions()
            .map { (actions, _) ->
                val state = viewModel.state.copy(formId = null)
                val params = ReadyParams(
                    page = 1,
                    items = buildReady(null, viewModel.texts)
                        .decorate(mode = Mode.Create, titles = readyTitles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun activate(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.organization()
                    .accumulate(actions)
            }
            .map { (actions, organization) ->
                val state = viewModel.state.copy(
                    formId = Identifier(id.rawValue),
                    organization = organization,
                )
                val params = ActivateParams(
                    page = 1,
                    items = buildActivate(organization, viewModel.texts)
                        .decorate(mode = Mode.Activate, titles = activateTitles(viewModel.texts))
                )
                sceneOf(viewModel.activate(state, params))
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun disable(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.editForm(Identifier(id.rawValue), Form.Edit(status = modifiedOf(Form.Status.Inactive)))
                    .accumulate(actions)
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.DISABLE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun enable(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Activate

        val formId = viewModel.state.formId!!
        val activityValues = viewModel.params.items.activityValues()
        val cities = activityValues.firstOrNull { it.first == Header.cities }?.second?.stringValues
        val departments = activityValues.firstOrNull { it.first == Header.departments }?.second?.stringValues
        val positions = activityValues.firstOrNull { it.first == Header.positions }?.second?.stringValues

        val status = if (cities.isNullOrEmpty() && departments.isNullOrEmpty() && positions.isNullOrEmpty())
            Form.Status.Active
        else
            Form.Status.ActiveNarrative(
                cities = cities ?: emptyList(),
                departments = departments ?: emptyList(),
                positions = positions ?: emptyList(),
            )

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.listEmployeeSurveys()
                    .accumulate(actions)
            }
            .flatMap { (actions, employeeSurveys) ->
                store.editForm(formId, Form.Edit(status = modifiedOf(status)))
                    .accumulate(actions)
                    .map {
                        tupleOf(it.first, employeeSurveys, it.second)
                    }
            }
            .flatMap { (actions, employeeSurveys, form) ->
                // Finds any previously active forms and inactivate them
                val previous = employeeSurveys.firstOrNull { it.status.isActive }
                if (form.status.isActive && previous != null)
                    store.editForm(previous.id, Form.Edit(status = modifiedOf(Form.Status.Inactive)))
                        .accumulate(actions)
                else
                    successfulOf(tupleOf(actions, form))
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.ENABLE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.deleteForm(id = Identifier(id.rawValue))
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.DELETE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun action(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, action: DesignSystem.Action): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        techla_log("EMPLOYEE SURVEY: action='$action'")

        return when (viewModel) {
            is ViewModel.Search ->
                when (action) {
                    DesignSystem.Action.EDIT -> show(scene, id)
                    DesignSystem.Action.ENABLE -> activate(scene, id)
                    DesignSystem.Action.DISABLE -> disable(scene, id)
                    DesignSystem.Action.DELETE -> delete(scene, id)
                    // DesignSystem.Action.DUPLICATE -> sceneOf(viewModel.waiting(viewModel.state.copy(formId = Identifier(id.rawValue))))
                    else -> sceneOf(viewModel)
                }

            is ViewModel.Ready ->
                when (action) {
                    DesignSystem.Action.SEARCH -> load(scene)
                    DesignSystem.Action.DELETE -> sceneOf(viewModel.replace(viewModel.params.items.delete(id)))
                    DesignSystem.Action.UP -> sceneOf(viewModel.replace(viewModel.params.items.up(id)))
                    DesignSystem.Action.DOWN -> sceneOf(viewModel.replace(viewModel.params.items.down(id)))
                    DesignSystem.Action.PREV -> sceneOf(viewModel.replace(viewModel.params.items.prev()))
                    DesignSystem.Action.NEXT -> validate(scene)
                    DesignSystem.Action.SCORE_0 -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.score0(), label = viewModel.texts.score0))))
                    DesignSystem.Action.SCORE_0_MAX -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.score0Max(), label = viewModel.texts.score0Max))))
                    DesignSystem.Action.SCORE_1 -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.score1(), label = viewModel.texts.score1))))
                    DesignSystem.Action.TEXT -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.long(), label = viewModel.texts.long))))
                    DesignSystem.Action.PAGE_BREAK -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.pageBreak(header = Header.pageBreak, label = viewModel.texts.pageBreak))))
                    DesignSystem.Action.CREATE -> save(scene)
                    DesignSystem.Action.EDIT -> save(scene)
                    else -> sceneOf(viewModel)
                }

            is ViewModel.Activate ->
                when (action) {
                    DesignSystem.Action.NEXT -> enable(scene)
                    else -> sceneOf(viewModel)
                }

            else -> sceneOf(viewModel)
        }
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, value: ActivityValue): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return when (viewModel) {
            is ViewModel.Ready -> sceneOf(viewModel.replace(viewModel.params.items.setValue(id = id, value = value)))
            is ViewModel.Activate -> sceneOf(viewModel.replace(viewModel.params.items.setValue(id = id, value = value)))
            else -> sceneOf(viewModel)
        }
    }

    private fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, viewModel.params.page)
        return if (status.overallStatus() !is Status.Valid) {
            sceneOf(viewModel.status(status))
        } else
            sceneOf(viewModel.replace(viewModel.params.items.next()))
    }

    private suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, null)
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.status(status))
        }

        val formId = viewModel.state.formId
        val activityValues = viewModel.params.items.activityValues()
        val name = activityValues.firstOrNull { it.first == Header.name }?.second?.stringValue
        val info = activityValues.firstOrNull { it.first == Header.info }?.second?.stringValue
        val userActivities = activityValues.filter { it.first != Header.name && it.first != Header.info }

        val create = Form.Create(
            key = Key.random(length = 10),
            name = name ?: "",
            info = info ?: "",
            kind = Form.Kind.EmployeeSurvey,
            feedback = Form.Feedback.None,
            status = Form.Status.Inactive,
            policy = Form.Policy.Replace,
        )
        val edit = Form.Edit(
            name = name?.let { modifiedOf(it) } ?: Modification.Unmodified,
            info = info?.let { modifiedOf(it) } ?: Modification.Unmodified,
        )

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (formId == null)
                    store.reduce(actions).createForm(create = create)
                        .accumulate(actions)
                else
                    store.reduce(actions).editForm(formId, edit = edit)
                        .accumulate(actions)
            }
            .flatMap { (actions, form) ->
                form.fields
                    .zipAll(EmployeeSurvey.fields(userActivities), null, null)
                    .map { (existing, modified) ->
                        when {
                            existing != null && modified != null -> store.reduce(actions).editField(existing.id, modified.toEdit()).map { tupleOf(it.first, Unit) }
                            existing == null && modified != null -> store.reduce(actions).createField(modified.toCreate(form.id)).map { tupleOf(it.first, Unit) }
                            existing != null && modified == null -> store.reduce(actions).deleteField(existing.id)
                            else -> successfulOf(Unit).noActions()
                        }.accumulate(actions)
                    }
                    .all()
                    .map { tupleOf(it.first, form, it.second) }
            }
            .map { (actions, _, _) ->
                sceneOf(viewModel.success(Success.SUCCESS), actions)
            }.failed { scene.failed(result = it) }
    }

    private fun buildSearch(forms: List<Form>): List<ActivityItem.ViewModel> =
        forms.map { item ->
            ActivityItem.search(id = Identifier(item.id.rawValue), label = item.name, info = "", active = item.status is Form.Status.Active || item.status is Form.Status.ActiveNarrative)
        }

    private fun buildReady(form: Form?, texts: Texts): List<ActivityItem.ViewModel> {
        val page1 = listOf(
            ActivityItem.basicName(header = Header.name, value = ActivityValue.StringValue(form?.name ?: ""), label = texts.name, displayPage = 1),
            ActivityItem.basicInfo(header = Header.info, value = ActivityValue.StringValue(form?.info ?: ""), label = texts.info, displayPage = 1),
        )
        val page2 = form?.fields
            ?.groupBy { it.page }
            ?.map { entry ->
                entry.value
                    .sortedBy { it.order }
                    .mapNotNull { field ->
                        when (val style = field.style) {
                            is Field.Style.Score ->
                                when {
                                    field.predefined == "10" && style.min == 0 ->
                                        ActivityItem.title(header = Header.score0Max(field.id), label = texts.score0Max, value = ActivityValue.StringValue(value = field.label ?: ""), displayPage = 2)
                                    style.min == 0 ->
                                        ActivityItem.title(header = Header.score0(field.id), label = texts.score0, value = ActivityValue.StringValue(value = field.label ?: ""), displayPage = 2)
                                    else ->
                                        ActivityItem.title(header = Header.score1(field.id), label = texts.score1, value = ActivityValue.StringValue(value = field.label ?: ""), displayPage = 2)
                                }
                            is Field.Style.Long -> ActivityItem.title(header = Header.long(field.id), label = texts.long, value = ActivityValue.StringValue(value = field.label ?: ""), displayPage = 2)
                            else -> null
                        }
                    }
            }
            ?.filter { it.isNotEmpty() }
            ?.join(ActivityItem.pageBreak(header = Header.pageBreak, label = texts.pageBreak))
            ?: emptyList()

        return page1 + page2
    }

    private fun buildActivate(organization: Organization?, texts: Texts): List<ActivityItem.ViewModel> =
        listOf(
            ActivityItem.basicMultiple(header = Header.cities, label = texts.cities, values = organization?.cities ?: emptyList()),
            ActivityItem.basicMultiple(header = Header.departments, label = texts.departments, values = organization?.departments ?: emptyList()),
            ActivityItem.basicMultiple(header = Header.positions, label = texts.positions, values = organization?.positions ?: emptyList()),
        )
}

private fun EmployeeSurveyScreen.ViewModel.Ready.replace(items: List<ActivityItem.ViewModel>): EmployeeSurveyScreen.ViewModel {
    return ready(params = params.copy(items = items))
}

private fun EmployeeSurveyScreen.ViewModel.Activate.replace(items: List<ActivityItem.ViewModel>): EmployeeSurveyScreen.ViewModel {
    return activate(params = params.copy(items = items.map { it.resetStatus() }))
}

private fun EmployeeSurveyScreen.ViewModel.Ready.status(status: List<ValidationStatus>): EmployeeSurveyScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.setStatus(status) }))
}
