package views

import StoreContext
import components.*
import react.*
import screens.BoostScreen
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.*
import techla.base.Identifier
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val BoostView = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: BoostScreen.ViewModel by useState(BoostScreen.ViewModel.None)
    var showingModal by useState(false)
    val executor by useExecutor<BoostScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    useEffect(viewModel) {
        techla_log("BOOST: status='${viewModel.status}'")
        if (viewModel is BoostScreen.ViewModel.None)
            executor.call { BoostScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is BoostScreen.ViewModel.Loading)
            executor.call { BoostScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        if (action == DesignSystem.Action.DELETE && !showingModal) {
            showingModal = true
            return
        }
        executor.call({ BoostScreen.action(sceneInputOf(store, viewModel), id, action) }) {
            if (action == DesignSystem.Action.DELETE) {
                showingModal = false
            }
        }
    }

    fun onResume(action: DesignSystem.Action) {
        executor.call { BoostScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onChange(id: Identifier<ActivityItem>, value: ActivityValue) {
        executor.call { BoostScreen.setValue(sceneInputOf(store, viewModel), id, value) }
    }

    fun onCreate() {
        executor.call { BoostScreen.create(sceneInputOf(store, viewModel)) }
    }

    RScaffold {
        isFullScreen = false

        viewModel.asSearch()?.run {
            RActivityItemSearch {
                searchImage = image
                searchTitle = title
                searchBody = body
                activityItems = items
                firstButton = create
                onFirst = ::onCreate
                onAction = ::onAction
            }

            RModal {
                design = deleteModal
                show = showingModal
                hide = { showingModal = false }
                info = texts.deleteModalBody
                firstOnClick = { showingModal = false }
                secondOnClick = { onAction(items.first().id, DesignSystem.Action.DELETE) }
            }
        }

        viewModel.asReady()?.run {
            RActivityItemList {
                activityItems = items
                onChange = ::onChange
                onAction = ::onAction
            }
        }

        viewModel.asLoading()?.run {
            RLoader {}
        }

        viewModel.asSuccess()?.run {
            RConfirmation { design = confirmation; onClick = ::onResume }
        }

        viewModel.asGetPro()?.run {
            RGetPro {}
        }

        viewModel.asFailed()?.run {
            RError { message = message }
        }
    }
}
