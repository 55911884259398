import web.dom.document
import react.create
import react.dom.client.createRoot
import kotlin.time.ExperimentalTime

@ExperimentalTime
fun main() {
    val container = document.getElementById("app")!!

    val app = App.create {}
    createRoot(container).render(app)
}