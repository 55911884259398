package components

import emotion.react.css
import mui.material.Stack
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import support.Design
import support.DesignSystem
import web.cssom.*


external interface RLoaderProps : Props {
    var size: Length?
}

val RLoader = FC<RLoaderProps> { props ->
    Stack {
        sx {
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            minHeight = 100.vh
        }

        div {
            css {
                height = props.size ?: 128.px
                width = props.size ?: 128.px
            }
            RLottie {
                this.autoplay = true
                this.loop = true
                this.animationData = Design.animation(DesignSystem.Animation.SPINNER)
            }
        }
    }
}