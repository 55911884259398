package support

import io.ktor.http.*
import screens.items.ActivityValue
import techla.base.Identifier
import techla.base.Key
import techla.base.TechlaError
import techla.form.Field
import techla.form.Form

object Recruitment {
    val TEXT = "TEXT"
    val PHONE = "PHONE"
    val EMAIL = "EMAIL"
    val CHOICES = "CHOICES"

    val GOVID = "GOVID"
    val FIRST_NAME = "FIRST_NAME"
    val LAST_NAME = "LAST_NAME"
    val CITY = "CITY"

    fun stringFromStyle(s: Field.Style) =
        when (s) {
            is Field.Style.Short -> "$TEXT-"
            is Field.Style.Phone -> "$PHONE-"
            is Field.Style.Email -> "$EMAIL-"
            is Field.Style.Choice -> "$CHOICES-"
            is Field.Style.GovId -> "$GOVID-"
            is Field.Style.FirstName -> "$FIRST_NAME-"
            is Field.Style.LastName -> "$LAST_NAME-"
            is Field.Style.City -> "$CITY-"
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    private fun styleFromString(s: String, value: ActivityValue) =
        when {
            s.startsWith("$TEXT-") -> Field.Style.Short
            s.startsWith("$PHONE-") -> Field.Style.Phone
            s.startsWith("$EMAIL-") -> Field.Style.Email
            s.startsWith("$CHOICES-") -> Field.Style.Choice(value.listValue ?: emptyList())

            s.startsWith("$GOVID-") -> Field.Style.GovId
            s.startsWith("$FIRST_NAME-") -> Field.Style.FirstName
            s.startsWith("$LAST_NAME-") -> Field.Style.LastName
            s.startsWith("$CITY-") -> Field.Style.City
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    private fun predefinedFromString(s: String, value: ActivityValue) =
        when {
            s.startsWith("$CHOICES-") -> value.listValue?.firstOrNull()
            else -> null
        }

    private fun requiredFromString(s: String) =
        when {
            s.startsWith("$TEXT-") -> true
            s.startsWith("$PHONE-") -> true
            s.startsWith("$EMAIL-") -> true
            s.startsWith("$CHOICES-") -> true
            else -> false
        }

    fun userFields(values: List<Pair<DesignSystem.Header, ActivityValue>>) =
        values.mapIndexed { index, (header, value) ->
            val label = value.stringValue ?: ""
            val answer = value.answerValue
            val hint = value.hintValue
            val url = value.urlValue?.let { Url(it) }
            val assetId = value.assetValue
            val style = styleFromString(s = header.id, value = value)
            val predefined = predefinedFromString(s = header.id, value = value)
            val required = requiredFromString(s = header.id)
            Field(id = Identifier(), key = Key(label), style = style, order = index, page = 1, required = required, hidden = false, autofill = false, label = label, predefined = predefined, min = null, max = null, placeholder = null, hint = hint, answer = answer, url = url, assetId = assetId, attributes = emptyList())
        }

    fun hiddenFields(start: Int) =
        listOf(
            Field(id = Identifier(), key = Key(GOVID), style = Field.Style.GovId, order = start, page = 1, required = false, hidden = true, autofill = true, label = "Personnummer", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(FIRST_NAME), style = Field.Style.FirstName, order = start + 1, page = 1, required = false, hidden = true, autofill = true, label = "Förnamn", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(LAST_NAME), style = Field.Style.LastName, order = start + 2, page = 1, required = false, hidden = true, autofill = true, label = "Efternamn", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(CITY), style = Field.Style.City, order = start + 3, page = 1, required = false, hidden = true, autofill = true, label = "Stad", predefined = null, placeholder = null, min = null, max = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
        )

    fun fields(values: List<Pair<DesignSystem.Header, ActivityValue>>): List<Field> {
        val userFields = userFields(values)
        val hiddenFields = hiddenFields(userFields.size)
        return userFields + hiddenFields
    }
}