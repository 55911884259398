package components.lottie_react

import react.ComponentType
import react.Props

@JsModule("lottie-react")
@JsNonModule
external val lottieReact: dynamic

@Suppress("UnsafeCastFromDynamic")
val Lottie: ComponentType<LottieReactProps> = lottieReact.default

external interface LottieReactProps : Props {
    var animationData: dynamic
    var loop: dynamic
    var autoplay: Boolean
}