package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import react.FC
import react.dom.html.ReactHTML.hr
import react.router.useNavigate
import react.router.useParams
import react.useRequiredContext
import react.useState
import screens.ShortLinkScreen
import support.*
import techla.base.Identifier
import techla.content.ShortLink
import kotlin.time.ExperimentalTime
import web.cssom.*

@ExperimentalTime
val ShortLink = FC<CreateOrDetailViewProps> { props ->
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(ShortLinkScreen.ViewModel.None as ShortLinkScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val shortLinkId = params["id"]?.let { Identifier<ShortLink>(it) }

    val (showModal, setShowModal) = useState(false)
    var showUpdatedToast by useState(false)

    var name by useState("")
    var href by useState("")


    suspend fun handleSubmit() {
        ShortLinkScreen.validate(sceneInputOf(store, viewModel), name, href).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun handleDelete(action: DesignSystem.Action) {
        MainScope().launch {
            if (shortLinkId != null) {
                ShortLinkScreen.delete(sceneInputOf(store, viewModel), shortLinkId).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                    setShowModal(false)
                }
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ShortLinkScreen.ViewModel.None -> if (coroutineScope.isActive) {
                ShortLinkScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is ShortLinkScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                ShortLinkScreen.load(sceneInputOf(store, viewModel), shortLinkId = shortLinkId).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is ShortLinkScreen.ViewModel.Ready -> {
                if (coroutineScope.isActive && viewModel.state.isSaving) {
                    if (props.createView) {
                        ShortLinkScreen.create(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                        }
                    } else {
                        if (shortLinkId != null) {
                            ShortLinkScreen.save(sceneInputOf(store, viewModel), shortLinkId)
                                .also { (viewModel, actions) ->
                                    setViewModel(viewModel)
                                    dispatch(actions)
                                    showUpdatedToast = true
                                }
                        }
                    }
                }
            }
            is ShortLinkScreen.ViewModel.Created -> {}
            is ShortLinkScreen.ViewModel.Deleted -> {}
            is ShortLinkScreen.ViewModel.GetPro -> {}
            is ShortLinkScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is ShortLinkScreen.ViewModel.Ready) {
            RToast {
                open = showUpdatedToast
                onClose = { showUpdatedToast = false }
                design = viewModel.toast
            }

            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(2)
                css {
                    alignItems = AlignItems.start
                    justifyContent = JustifyContent.spaceBetween
                    marginBottom = 1.5.rem
                }
                RButton {
                    design = viewModel.back
                    onClick = { navigate(-1) }
                }
                RText { design = viewModel.title }
            }

            RForm {
                onSubmit = ::handleSubmit

                RFormLayout {
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.name; onChange = { value -> name = value } }
                        }
                        RFormInputWrapper {
                            RTextInput { design = viewModel.href; onChange = { value -> href = value } }
                        }
                    }
                }

                if (props.createView) {
                    RButton { design = viewModel.create }
                } else {
                    RButton { design = viewModel.save }
                }
            }

            hr {
                css {
                    marginTop = 1.5.rem
                    marginBottom = 1.rem
                }
            }

            if (!props.createView) RButton {
                design = viewModel.remove
                onClick = { setShowModal(true) }
            }

            RModal {
                design = viewModel.modalRemove
                show = showModal
                hide = { setShowModal(false) }
                info = viewModel.modalBody
                firstOnClick = { setShowModal(false) }
                secondOnClick = ::handleDelete
            }
        }

        if (viewModel is ShortLinkScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is ShortLinkScreen.ViewModel.Created) {
            RConfirmation { design = viewModel.added }
        }

        if (viewModel is ShortLinkScreen.ViewModel.Deleted) {
            RConfirmation { design = viewModel.removed }
        }

        if (viewModel is ShortLinkScreen.ViewModel.GetPro) {
            RGetPro {}
        }

        if (viewModel is ShortLinkScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}