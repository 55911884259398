package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.FormLabel
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.hr
import react.dom.html.ReactHTML.img
import react.useRequiredContext
import react.useState
import screens.AccountScreen
import support.RScaffold
import support.sceneInputOf
import support.useAsyncEffect
import techla.storage.Asset
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Account = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(AccountScreen.ViewModel.None as AccountScreen.ViewModel)

    var name by useState("")
    var govCode by useState("")
    var street by useState("")
    var postalCode by useState("")
    var city by useState("")
    var asset by useState<Asset.Create?>(null)

    var showToast by useState(false)
    var showUpgradeModal by useState(false)
    var showCloseAccountModal by useState(false)

    suspend fun handleSubmit() {
        AccountScreen.validate(sceneInputOf(store, viewModel), name = name, govCode = govCode, street = street, postalCode = postalCode, city = city)
            .also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
    }

    suspend fun handleUpload() {
        AccountScreen.upload(sceneInputOf(store, viewModel), asset = asset).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
            showToast = true
        }
    }

    fun handleUpgrade() {
        MainScope().launch {
            AccountScreen.upgrade(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                showUpgradeModal = false
            }
        }
    }


    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is AccountScreen.ViewModel.None -> if (coroutineScope.isActive) {
                AccountScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is AccountScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                AccountScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is AccountScreen.ViewModel.Ready ->
                if (coroutineScope.isActive && viewModel.state.isSaving) {
                    AccountScreen.save(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                        setViewModel(viewModel)
                        dispatch(actions)
                        showToast = true
                    }
                }

            is AccountScreen.ViewModel.Failed -> {}
            is AccountScreen.ViewModel.Upgraded -> {}
        }
    }

    RScaffold {
        if (viewModel is AccountScreen.ViewModel.Ready) {
            RToast {
                open = showToast
                onClose = { showToast = false }
                design = viewModel.saved
            }

            Stack {
                spacing = responsive(4)
                RText { design = viewModel.title }

                RForm {
                    onSubmit = ::handleSubmit

                    RFormLayout {
                        RFormRow {
                            RFormInputWrapper {
                                RTextInput { design = viewModel.name; onChange = { value -> name = value } }
                            }
                            RFormInputWrapper {
                                RTextInput { design = viewModel.govCode; onChange = { value -> govCode = value } }
                            }
                        }
                        RFormRow {
                            RFormInputWrapper {
                                RTextInput { design = viewModel.street; onChange = { value -> street = value } }
                            }
                            RFormInputWrapper {
                                RTextInput { design = viewModel.postalCode; onChange = { value -> postalCode = value } }
                            }
                        }
                        RFormRow {
                            RFormInputWrapper {
                                RTextInput { design = viewModel.city; onChange = { value -> city = value } }
                            }
                        }
                    }

                    RButton { design = viewModel.save }
                }

                hr {}

                RForm {
                    onSubmit = ::handleUpload
                    css {
                        marginTop = important(1.rem)
                    }
                    RFormLayout {
                        RFormRow {
                            RFormInputWrapper {
                                Stack {
                                    direction = responsive(StackDirection.row)
                                    spacing = responsive(1)
                                    sx {
                                        justifyContent = JustifyContent.flexStart
                                        alignItems = AlignItems.center
                                        marginBottom = 1.rem
                                    }

                                    FormLabel {
                                        sx {
                                            fontWeight = 600.unsafeCast<FontWeight>()
                                        }
                                        +viewModel.texts.logo
                                    }

                                    img {
                                        css {
                                            borderRadius = 0.25.rem
                                        }
                                        src = store.buyer?.logo?.url?.toString()
                                        height = 50.0
                                        width = 50.0
                                    }
                                }

                                RFileInput {
                                    onChange = { value -> asset = value }
                                    design = viewModel.logo
                                }
                            }
                        }
                    }

                    RButton { design = viewModel.upload }
                }

                hr {}

                RForm {
                    onSubmit = { showUpgradeModal = true }
                    RFormLayout {
                        RFormRow {
                            RFormInputWrapper {
                                RTextInput { design = viewModel.tier }
                            }
                        }
                    }
                    RButton { design = viewModel.upgrade }
                }

                hr {}

                RForm {
                    RButton {
                        design = viewModel.closeAccount
                        onClick = { showCloseAccountModal = true }
                    }
                }
            }

            RModal {
                design = viewModel.upgradeModel
                info = viewModel.texts.upgradeBody
                show = showUpgradeModal
                hide = { showUpgradeModal = false }
                firstOnClick = { showUpgradeModal = false }
                secondOnClick = { handleUpgrade() }
            }

            RModal {
                design = viewModel.closeAccountModal
                info = viewModel.texts.closeAccountInfo
                show = showCloseAccountModal
                hide = { showCloseAccountModal = false }
                firstOnClick = { showCloseAccountModal = false }
                secondOnClick = {
                    window.location.href = "mailto:hey@moodi.rocks?subject=${viewModel.texts.closeAccountSubject}&body=${viewModel.texts.closeAccountBody}"
                    showCloseAccountModal = false
                }
            }
        }

        if (viewModel is AccountScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is AccountScreen.ViewModel.Upgraded) {
            RConfirmation { design = viewModel.upgrade }
        }

        if (viewModel is AccountScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}