package support

import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.LocalDate
import kotlinx.datetime.minus
import screens.fullName
import techla.base.Date
import techla.base.techla_log
import techla.control.Event
import techla.conversation.Message
import techla.guard.Profile


enum class StatisticType {
    MESSAGES, AUTHENTICATIONS, USERS, EMPLOYEE_NPS, MANAGER_NPS, BOOSTS, ENTERED, EXITED
}

enum class TopListType {
    BOOSTS_CREATED, BOOSTS_RECEIVED, USER_AUTHENTICATIONS, USERS, MESSAGES, LIKES, COMMENTS, MOST_REACTIONS
}

data class TopLists(
    val boostsCreated: List<Entry>,
    val boostsReceived: List<Entry>,
    val userAuthentications: List<Entry>,
    val messages: List<Entry>,
    val likes: List<Entry>,
    val comments: List<Entry>,
    val mostReactions: List<Entry>,
) {
    data class Entry(
        val initials: String,
        val name: String,
        val count: Int,
    )
}

val Event.Statistic.month get() = (interval as? Event.Interval.Monthly)?.monthNumber ?: -1
fun List<Event.Statistic>.merge(): Event.Statistic? =
    fold(null) { r: Event.Statistic?, e -> if (r == null) e else e.copy(count = e.count + r.count) }
fun List<Event.Statistic>.groupByMonth(): List<Event.Statistic> =
    groupBy { it.month }.mapNotNull { (_, all) -> all.merge() }

fun List<Event.Statistic>.sortedByMonth(): List<Event.Statistic> =
    sortedBy { statistic -> (statistic.interval as Event.Interval.Monthly).let { "${it.year} - ${it.monthNumber}" } }

data class Statistics(
    val total: Total,
    val monthly: Monthly,
    val unique: Unique,
) {
    data class Total(
        val users: Int,
        val userAuthentications: Int,
        val messages: Int,
        val boosts: Int,
    )

    data class Monthly(
        val labels: List<String>,
        val userAuthentications: List<Int>,
        val users: List<Int>,
        val messages: List<Int>,
        val boosts: List<Int>,
        val employeeNPS: List<Int>,
        val managerNPS: List<Int>,
        val entered: List<Int>,
        val exited: List<Int>,
    )

    data class Unique(
        val messages: MonthlyChange,
        val likes: MonthlyChange,
        val comments: MonthlyChange,
        val boostsCreated: MonthlyChange,
        val boostsReceived: MonthlyChange,
    )

    data class MonthlyChange(
        val previous: Int,
        val current: Int,
    ) {
        enum class ChangeType {
            INCREASE, DECREASE, SAME
        }

        val change: Int = current - previous
        val percentage: Double = (change.toDouble() / previous.toDouble()) * 100

        val changeType: ChangeType = when {
            change > 0 -> ChangeType.INCREASE
            change < 0 -> ChangeType.DECREASE
            else -> ChangeType.SAME
        }

        val changeSign = when (changeType) {
            ChangeType.INCREASE -> "+"
            ChangeType.DECREASE -> "" // "-" comes from the change value itself
            ChangeType.SAME -> "±"
        }
    }
}

fun createStatistics(statistics: List<Event.Statistic>, monthLabelList: List<String>): Statistics {
    val unique = statistics.filter { it.uniqueOnly }
    val nonUnique = statistics.filterNot { it.uniqueOnly }

    val profiles = nonUnique.filter { it.category is Event.Category.Profile }
    val userAuthentications = nonUnique.filter { it.category is Event.Category.UserAuthentication }
    val messages = nonUnique.filter { it.category is Event.Category.Message }
    val employeeNPS = nonUnique.filter { it.category is Event.Category.EmployeeNPS }
    val managerNPS = nonUnique.filter { it.category is Event.Category.ManagerNPS }
    val boosts = nonUnique.filter { it.category is Event.Category.Boost }

    val uniqueUsers = unique.filter { it.category is Event.Category.UserAuthentication }
    val uniqueMessages = unique.filter { it.category is Event.Category.Message }
    val uniqueLikes = unique.filter { it.category is Event.Category.Reaction && it.subcategory is Event.Subcategory.Like }
    val uniqueComments = unique.filter { it.category is Event.Category.Reaction && it.subcategory is Event.Subcategory.Comment }
    val uniqueBoostsCreated = unique.filter { it.category is Event.Category.Boost && it.action is Event.Action.Created }
    val uniqueBoostsReceived = unique.filter { it.category is Event.Category.Boost && it.action is Event.Action.Received }

    // Total
    val totalUsers = profiles.filter { it.interval is Event.Interval.Total }.sumOf { it.count }
    val totalUserAuthentications = userAuthentications.filter { it.interval is Event.Interval.Total }.sumOf { it.count }
    val totalMessages = messages.filter { it.interval is Event.Interval.Total }.sumOf { it.count }
    val totalBoosts = boosts.filter { it.interval is Event.Interval.Total }.sumOf { it.count }

    // Monthly
    val monthlyUserAuthentications = userAuthentications.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyUniqueUsers = uniqueUsers.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyMessages = messages.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyBoosts = boosts.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyEmployeeNPS = employeeNPS.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyManagerNPS = managerNPS.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyEntered = profiles.filter { it.action is Event.Action.Created && it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyExited = profiles.filter { it.action !is Event.Action.Created && it.interval is Event.Interval.Monthly }.groupByMonth()

    val monthlyUniqueMessages = uniqueMessages.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyUniqueLikes = uniqueLikes.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyUniqueComments = uniqueComments.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyUniqueBoostsCreated = uniqueBoostsCreated.filter { it.interval is Event.Interval.Monthly }.groupByMonth()
    val monthlyUniqueBoostsReceived = uniqueBoostsReceived.filter { it.interval is Event.Interval.Monthly }.groupByMonth()

    // Sorted
    val sortedMonthlyUserAuthentications = monthlyUserAuthentications.sortedByMonth().map { it.count }
    val sortedMonthlyUsers = monthlyUniqueUsers.sortedByMonth().map { it.count }
    val sortedMonthlyMessages = monthlyMessages.sortedByMonth().map { it.count }
    val sortedMonthlyBoosts = monthlyBoosts.sortedByMonth().map { it.count }
    val sortedMonthlyEmployeeNPS = monthlyEmployeeNPS.sortedByMonth().map { it.count }
    val sortedMonthlyManagerNPS = monthlyManagerNPS.sortedByMonth().map { it.count }
    val sortedMonthlyEntered = monthlyEntered.sortedByMonth().map { it.count }
    val sortedMonthlyExited = monthlyExited.sortedByMonth().map { it.count }

    val sortedMonthlyUniqueMessages =
        monthlyUniqueMessages.sortedBy { monthlyIntervalOrdering(it.interval as Event.Interval.Monthly) }.map { it.count }.takeLast(2)
    val sortedMonthlyUniqueLikes =
        monthlyUniqueLikes.sortedBy { monthlyIntervalOrdering(it.interval as Event.Interval.Monthly) }.map { it.count }.takeLast(2)
    val sortedMonthlyUniqueComments =
        monthlyUniqueComments.sortedBy { monthlyIntervalOrdering(it.interval as Event.Interval.Monthly) }.map { it.count }.takeLast(2)
    val sortedMonthlyUniqueBoostsCreated =
        monthlyUniqueBoostsCreated.sortedBy { monthlyIntervalOrdering(it.interval as Event.Interval.Monthly) }.map { it.count }.takeLast(2)
    val sortedMonthlyUniqueBoostsReceived =
        monthlyUniqueBoostsReceived.sortedBy { monthlyIntervalOrdering(it.interval as Event.Interval.Monthly) }.map { it.count }.takeLast(2)

    techla_log("sortedMonthlyUsers: '$sortedMonthlyUsers'")

    return Statistics(
        total = Statistics.Total(
            users = totalUsers,
            userAuthentications = totalUserAuthentications,
            messages = totalMessages,
            boosts = totalBoosts
        ),
        monthly = Statistics.Monthly(
            labels = monthLabelList,
            userAuthentications = sortedMonthlyUserAuthentications,
            users = sortedMonthlyUsers,
            messages = sortedMonthlyMessages,
            boosts = sortedMonthlyBoosts,
            employeeNPS = sortedMonthlyEmployeeNPS,
            managerNPS = sortedMonthlyManagerNPS,
            entered = sortedMonthlyEntered,
            exited = sortedMonthlyExited,
        ),
        unique = Statistics.Unique(
            messages = Statistics.MonthlyChange(
                previous = sortedMonthlyUniqueMessages.first(),
                current = sortedMonthlyUniqueMessages.last()
            ),
            likes = Statistics.MonthlyChange(
                previous = sortedMonthlyUniqueLikes.first(),
                current = sortedMonthlyUniqueLikes.last()
            ),
            comments = Statistics.MonthlyChange(
                previous = sortedMonthlyUniqueComments.first(),
                current = sortedMonthlyUniqueComments.last()
            ),
            boostsCreated = Statistics.MonthlyChange(
                previous = sortedMonthlyUniqueBoostsCreated.first(),
                current = sortedMonthlyUniqueBoostsCreated.last()
            ),
            boostsReceived = Statistics.MonthlyChange(
                previous = sortedMonthlyUniqueBoostsReceived.first(),
                current = sortedMonthlyUniqueBoostsReceived.last()
            )
        )
    )
}

/**
 * Used to sort monthly intervals where we take the current and previous month.
 * @returns the month number, but if the current month is January, we return 13 to make sure it's the last month.
 */
private fun monthlyIntervalOrdering(interval: Event.Interval.Monthly): Int {
    val currentMonth = Date.now().dateTime.monthNumber

    return when (val month = interval.monthNumber) {
        1 -> if (currentMonth == 1) 13 else month
        else -> month
    }
}

fun createTopLists(type: TopListType, statistics: List<Event.Statistic>, profiles: List<Profile>, messages: List<Message>): TopLists {
    val posts = statistics.filter {
        it.category is Event.Category.Message && it.interval is Event.Interval.Monthly && it.profileId != null
    }
    val userAuthentications = statistics.filter {
        it.category is Event.Category.UserAuthentication && it.interval is Event.Interval.Monthly && it.profileId != null
    }
    val boostsCreated = statistics.filter {
        it.category is Event.Category.Boost && it.action is Event.Action.Created && it.interval is Event.Interval.Monthly && it.profileId != null
    }
    val boostsReceived = statistics.filter {
        it.category is Event.Category.Boost && it.action is Event.Action.Received && it.interval is Event.Interval.Monthly && it.profileId != null
    }
    val reactions = statistics.filter {
        it.category is Event.Category.Reaction && it.interval is Event.Interval.Monthly
    }
    val likes = statistics.filter {
        it.category is Event.Category.Reaction && it.subcategory is Event.Subcategory.Like && it.interval is Event.Interval.Monthly && it.profileId != null
    }
    val comments = statistics.filter {
        it.category is Event.Category.Reaction && it.subcategory is Event.Subcategory.Comment && it.interval is Event.Interval.Monthly && it.profileId != null
    }

    val sortedPosts = posts.sortedBy { it.count }.reversed()
    val sortedUserAuthentications = userAuthentications.sortedBy { it.count }.reversed()
    val sortedBoostsCreated = boostsCreated.sortedBy { it.count }.reversed()
    val sortedBoostsReceived = boostsReceived.sortedBy { it.count }.reversed()
    val sortedReactions = reactions.sortedBy { it.count }.reversed()
    val sortedLikes = likes.sortedBy { it.count }.reversed()
    val sortedComments = comments.sortedBy { it.count }.reversed()

    val topPosts = sortedPosts.map { toEntry(type, profiles, messages, it) }
    val topUserAuthentications = sortedUserAuthentications.map { toEntry(type, profiles, messages, it) }
    val topBoostsCreated = sortedBoostsCreated.map { toEntry(type, profiles, messages, it) }
    val topBoostsReceived = sortedBoostsReceived.map { toEntry(type, profiles, messages, it) }
    val mostReactions = sortedReactions.map { toEntry(type, profiles, messages, it) }
    val topLikes = sortedLikes.map { toEntry(type, profiles, messages, it) }
    val topComments = sortedComments.map { toEntry(type, profiles, messages, it) }

    return TopLists(
        boostsCreated = topBoostsCreated,
        boostsReceived = topBoostsReceived,
        userAuthentications = topUserAuthentications,
        messages = topPosts,
        likes = topLikes,
        comments = topComments,
        mostReactions = mostReactions,
    )
}

private fun toEntry(
    type: TopListType,
    profiles: List<Profile>,
    messages: List<Message>,
    statistic: Event.Statistic,
): TopLists.Entry {
    val message = if (type == TopListType.MOST_REACTIONS)
        messages
            .firstOrNull { it.id == statistic.secondary }
    else
        null

    val profile = if (type == TopListType.MOST_REACTIONS)
        profiles
            .firstOrNull { it.index.id == message?.author }
    else
        profiles
            .firstOrNull { it.index.id == statistic.profileId }

    val initials = "${profile?.firstName?.first() ?: ""}${profile?.lastName?.first() ?: ""}"

    val text = if (type == TopListType.MOST_REACTIONS)
        "${profile?.fullName ?: ""} (${message?.inlineTitles?.firstOrNull()?.title})"
    else
        profile?.fullName ?: ""

    return TopLists.Entry(initials, text, statistic.count)
}

fun getIntervals(): Pair<List<Event.Interval>, List<String>> {
    val intervals = mutableListOf<Event.Interval>()
    val today = Date.now().dateTime.date
    val yearMonthList = mutableListOf<LocalDate>()

    // The current month and the last five months
    (0..5).forEach { yearMonthList.add(today.minus(it, DateTimeUnit.MONTH)) }

    yearMonthList.forEach { intervals.add(Event.Interval.Monthly(year = it.year, monthNumber = it.monthNumber)) }

    val monthName = mapOf(
        1 to "JAN",
        2 to "FEB",
        3 to "MAR",
        4 to "APR",
        5 to "MAJ",
        6 to "JUN",
        7 to "JUL",
        8 to "AUG",
        9 to "SEP",
        10 to "OKT",
        11 to "NOV",
        12 to "DEC"
    )
    return Pair(intervals, yearMonthList.map { monthName.getValue(it.monthNumber) }.reversed())
}
