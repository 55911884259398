package screens

import ROUTES
import js.objects.jso
import kotlinx.browser.document
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.files.Blob
import services.listForms
import services.listSubmissions
import services.report
import support.*
import techla.base.*
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import kotlin.time.ExperimentalTime

val Field.isInternalField get() = style is Field.Style.MessageId

val Form.toOption
    get() =
        DesignSystem.Option(name, key.rawValue)

val Submission.displayState
    get() = when (state) {
        is Submission.State.Unknown -> "Okänd"
        is Submission.State.Open -> "Pågående"
        is Submission.State.Closed -> "Inskickad"
        is Submission.State.Archived -> "Arkiverad"
        is Submission.State.Rejected -> "Nekad"
    }

val Submission.displayActivity
    get() =
        form?.name ?: ""


@ExperimentalTime
object SubmissionsScreen {
    object Header {
        val activity = DesignSystem.Header(id = "activity")
        val report = DesignSystem.Header(id = "report")
        val year = DesignSystem.Header(id = "year")
    }

    data class Texts(
        val title: String,
        val export: String,
        val date: String,
        val activity: String,
        val status: String,
    )

    data class State(
        val submissions: PageContent<Submission>? = null,
        val results: List<Submission.Report.Result>? = null,
        val forms: List<Form>? = null,
        val selectedForm: Form? = null,
        val selectedYear: String = Date.now().dateTime.year.toString(),
        val reports: List<Report>? = null,
        val selectedReport: Report? = null,
        val page: Int = 1,
        val rowsPerPage: Int = 25,
    ) {
        val submissionReport
            get() =
                if (selectedForm != null && selectedReport != null)
                    Submission.Report(form = selectedForm.key, operations = selectedReport.operations ?: emptyList())
                else
                    null
    }

    data class Report(
        val name: String,
        val value: String,
        val operations: List<Submission.Operation>?,
    ) {
        val toOption = DesignSystem.Option(name, value)
    }

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val activity: DesignSystem.SelectInput,
            val report: DesignSystem.SelectInput,
            val year: DesignSystem.SelectInput,
            val export: DesignSystem.Button,
            val submissions: DesignSystem.Table<Submission>,
            val results: DesignSystem.Table<Submission.Report.Result>,
            val pagination: DesignSystem.Pagination,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
                submissions = DesignSystem.Table(
                    titles = listOf(
                        DesignSystem.Text(text = texts.date),
                        DesignSystem.Text(text = texts.activity),
                        DesignSystem.Text(text = texts.status),
                    ),
                    data = state.submissions?.contents?.map { submission ->
                        submission to listOf(
                            DesignSystem.Link(text = localize(submission.timestamp), to = ROUTES.DASHBOARD.SUBMISSIONS.show(submission.index.id)),
                            DesignSystem.Text(text = submission.displayActivity, size = DesignSystem.SizeType.MD),
                            DesignSystem.Text(text = submission.displayState, size = DesignSystem.SizeType.MD),
                        )
                    },
                    visible = !state.submissions?.contents.isNullOrEmpty()
                ),
                results = DesignSystem.Table(
                    titles = state.results?.firstOrNull()?.let { result ->
                        result.entries.map {
                            DesignSystem.Text(text = it.fieldKey.rawValue)
                        }
                    } ?: emptyList(),
                    data = state.results?.map { result ->
                        result to result.entries.map {
                            DesignSystem.Text(text = it.text, size = DesignSystem.SizeType.MD)
                        }
                    },
                    visible = !state.results.isNullOrEmpty()
                ),
                pagination = DesignSystem.Pagination(state.submissions, visible = !state.submissions?.contents.isNullOrEmpty()),
                activity = DesignSystem.SelectInput(
                    header = Header.activity,
                    label = null,
                    options = listOf(
                        DesignSystem.Option("Välj aktivitet...", "null"),
                        *(state.forms?.map { it.toOption }?.toTypedArray() ?: emptyArray())
                    ),
                    selected = state.selectedForm?.toOption ?: DesignSystem.Option("Välj aktivitet...", "null"),
                ),
                report = DesignSystem.SelectInput(
                    header = Header.report,
                    label = null,
                    options = listOf(
                        DesignSystem.Option("Välj rapport...", "null"),
                        *(state.reports?.map { it.toOption }?.toTypedArray() ?: emptyArray())
                    ),
                    selected = state.selectedReport?.toOption ?: DesignSystem.Option("Välj rapport...", "null"),
                    visible = state.reports?.let { it.isNotEmpty() } ?: false,
                ),
                year = DesignSystem.SelectInput(
                    header = Header.year,
                    label = null,
                    options = (2021..Date.now().dateTime.year).reversed()
                        .map { DesignSystem.Option(it.toString(), it.toString()) },
                    selected = state.selectedYear.let { DesignSystem.Option(it, it) },
                    visible = state.selectedForm?.key?.rawValue == "PULSE" && state.selectedReport != null,
                ),
                export = DesignSystem.Button(
                    text = texts.export,
                    disabled = state.selectedForm == null,
                )
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val texts = Texts(
            title = "Resultat",
            export = "Exportera till Excel",
            date = "Datum",
            activity = "Aktivitet",
            status = "Status",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        return filter(scene, form = null, report = null, year = scene.viewModel.state.selectedYear)
    }

    suspend fun filter(scene: Scene.Input<ViewModel>, form: String?, report: String?, year: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.listForms()
            .map { (actions, forms) ->
                val selectedForm = forms.find { it.key.rawValue == form }
                val reports = predefinedReports(selectedForm, viewModel.state.copy(selectedYear = year))
                val selectedReport = reports.find { it.value == report }
                val state = viewModel.state.copy(
                    page = 1,
                    forms = forms,
                    selectedForm = selectedForm,
                    reports = reports,
                    selectedReport = selectedReport,
                    selectedYear = year,
                )
                showPage(store.reduce(actions), viewModel, state)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun setPage(scene: Scene.Input<ViewModel>, page: Int): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state.copy(
            page = page,
        )
        return showPage(store, viewModel, state)
    }

    suspend fun setRowsPerPage(scene: Scene.Input<ViewModel>, rowsPerPage: Int): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state.copy(
            rowsPerPage = rowsPerPage,
        )
        return showPage(store, viewModel, state)
    }

    private suspend fun showPage(store: Store, viewModel: ViewModel, state: State): Scene.Output<ViewModel> {
        val index = PageIndex(page = state.page, size = state.rowsPerPage)

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                val x: ActionOutcome<Either<List<Submission.Report.Result>, PageContent<Submission>>> = when {
                    state.submissionReport != null -> {
                        store.reduce(actions).report(state.submissionReport!!)
                            .accumulate(actions)
                            .map { tupleOf(it.first, leftOf(it.second)) }
                    }

                    state.selectedForm != null -> {
                        store.reduce(actions).listSubmissions(index, listOf(state.selectedForm.key), listOf(Submission.State.Closed))
                            .accumulate(actions)
                            .map { tupleOf(it.first, rightOf(it.second)) }
                    }

                    else -> {
                        successfulOf(PageContent.empty<Submission>()).noActions()
                            .map { tupleOf(it.first, rightOf(it.second)) }
                    }
                }
                x
            }
            .map { (actions, submissions) ->
                val updated = state.copy(
                    results = submissions.leftOrNull(),
                    submissions = submissions.rightOrNull(),
                )
                sceneOf(viewModel.ready(state = updated), actions)
            }
            .failed { sceneOf(viewModel.failed(message = it.message)) }
    }

    suspend fun export(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val form = state.selectedForm ?: return sceneOf(viewModel.failed("No form selected"))
        val report = state.submissionReport

        val emptyReport = successfulOf(emptyList<Submission.Report.Result>()).noActions()
        val emptySubmission = successfulOf(
            PageContent<Submission>(
                contents = emptyList(),
                index = PageIndex(1, 0, null),
                items = PageSummary(0, 0, 0),
                pages = PageSummary(0, 0, 0),
            )
        ).noActions()

        val index = PageIndex(page = 1, size = 50_000, search = null)

        return (if (report == null) store.listSubmissions(index = index, forms = listOf(form.key), listOf(Submission.State.Closed)) else emptySubmission).zip(
            (if (report != null) store.report(report = report) else emptyReport)
        ).fold(
            { (submissions, results) ->
                val actions = submissions.first + results.first

                val builder = StringBuilder()

                when {
                    results.second.isNotEmpty() -> {
                        results.second.firstOrNull()?.let { result ->
                            result.entries.forEachIndexed { index, entry ->
                                if (index > 0) builder.append(";")
                                builder.append("\"${entry.fieldKey.rawValue}\"")
                            }
                            builder.append("\r\n")
                            results.second.map { result ->
                                result.entries.forEachIndexed { index, entry ->
                                    if (index > 0) builder.append(";")
                                    builder.append("\"${entry.text.replace(".", ",")}\"")
                                }
                                builder.append("\r\n")
                            }
                        }
                    }

                    submissions.second.contents.isNotEmpty() -> {
                        val columns = state.forms?.firstOrNull { it.key == form.key }?.fields?.filter { !it.isInternalField }?.sortedBy { it.order } ?: emptyList()

                        columns.forEachIndexed { index, field ->
                            if (index > 0) builder.append(";")
                            builder.append("\"${field.label ?: field.key.rawValue}\"")
                        }
                        builder.append("\r\n")

                        submissions.second.contents.forEach { submission ->
                            columns.forEachIndexed { index, field ->
                                if (index > 0) builder.append(";")
                                val results = submission.results.map {
                                    when (val result = it.first) {
                                        is Either.Left ->
                                            when (result.value.style) {
                                                is Field.Style.GovId ->
                                                    result.value.key to it.second.take(8) + "-" + it.second.takeLast(4)

                                                else ->
                                                    result.value.key to it.second
                                            }

                                        is Either.Right ->
                                            result.value to it.second
                                    }
                                }
                                val entry = results.firstOrNull { it.first == field.key }?.second ?: "-"
                                builder.append("\"$entry\"")
                            }
                            builder.append("\r\n")
                        }
                    }
                }

                val blob = Blob(arrayOf(builder.toString()), jso { type = "text/csv;charset=utf-8;" })
                val url = URL.createObjectURL(blob)
                val a = document.createElement("a") as HTMLAnchorElement
                a.href = url
                a.download = "export.csv"
                a.click()
                URL.revokeObjectURL(url)

                sceneOf(viewModel.ready(state = state), actions)
            },
            {
                scene.failed(result = it)
            }
        )
    }

    private fun predefinedReports(form: Form?, state: State): List<Report> {
        return when (form?.kind) {
            is Form.Kind.Pulse -> listOf(
                Report(
                    "Min, max, medel samt antal, uppdelat per vecka", "P1",
                    listOf(
                        Submission.Operation.Min(Key("PULSE"), Key("Min")),
                        Submission.Operation.Max(Key("PULSE"), Key("Max")),
                        Submission.Operation.Average(Key("PULSE"), Key("Medel")),
                        Submission.Operation.Count(Key("Antal")),
                        Submission.Operation.IntValue(Key("WEEK"), Key("Vecka")),
                        Submission.Operation.IntValue(Key("YEAR"), Key("År")),

                        Submission.Operation.GroupBy(Key("WEEK")),
                        Submission.Operation.OrderBy(Key("WEEK"), ascending = false),

                        Submission.Operation.Include(Submission.State.Closed),

                        Submission.Operation.Filter(Key("YEAR"), listOf(state.selectedYear)),
                    ),
                ),
                Report(
                    "Min, max, medel samt antal, uppdelat per stad", "P2",
                    listOf(
                        Submission.Operation.Min(Key("PULSE"), Key("Min")),
                        Submission.Operation.Max(Key("PULSE"), Key("Max")),
                        Submission.Operation.Average(Key("PULSE"), Key("Medel")),
                        Submission.Operation.Count(Key("Antal")),
                        Submission.Operation.Value(Key("CITY"), Key("Stad")),
                        Submission.Operation.IntValue(Key("YEAR"), Key("År")),

                        Submission.Operation.GroupBy(Key("CITY")),
                        Submission.Operation.OrderBy(Key("CITY")),

                        Submission.Operation.Include(Submission.State.Closed),

                        Submission.Operation.Filter(Key("YEAR"), listOf(state.selectedYear)),
                    ),
                ),
                Report(
                    "Min, max, medel samt antal, uppdelat per vecka och stad", "P3",
                    listOf(
                        Submission.Operation.Min(Key("PULSE"), Key("Min")),
                        Submission.Operation.Max(Key("PULSE"), Key("Max")),
                        Submission.Operation.Average(Key("PULSE"), Key("Medel")),
                        Submission.Operation.Count(Key("Antal")),
                        Submission.Operation.IntValue(Key("WEEK"), Key("Vecka")),
                        Submission.Operation.Value(Key("CITY"), Key("Stad")),
                        Submission.Operation.IntValue(Key("YEAR"), Key("År")),

                        Submission.Operation.GroupBy(Key("WEEK")),
                        Submission.Operation.GroupBy(Key("CITY")),
                        Submission.Operation.OrderBy(Key("WEEK"), ascending = false),
                        Submission.Operation.OrderBy(Key("CITY")),

                        Submission.Operation.Include(Submission.State.Closed),

                        Submission.Operation.Filter(Key("YEAR"), listOf(state.selectedYear)),
                    ),
                ),
                Report(
                    "Min, max, medel samt antal, uppdelat på stad och per vecka", "P4",
                    listOf(
                        Submission.Operation.Min(Key("PULSE"), Key("Min")),
                        Submission.Operation.Max(Key("PULSE"), Key("Max")),
                        Submission.Operation.Average(Key("PULSE"), Key("Medel")),
                        Submission.Operation.Count(Key("Antal")),
                        Submission.Operation.Value(Key("CITY"), Key("Stad")),
                        Submission.Operation.IntValue(Key("WEEK"), Key("Vecka")),
                        Submission.Operation.IntValue(Key("YEAR"), Key("År")),

                        Submission.Operation.GroupBy(Key("CITY")),
                        Submission.Operation.GroupBy(Key("WEEK")),
                        Submission.Operation.OrderBy(Key("CITY")),
                        Submission.Operation.OrderBy(Key("WEEK"), ascending = false),

                        Submission.Operation.Include(Submission.State.Closed),

                        Submission.Operation.Filter(Key("YEAR"), listOf(state.selectedYear)),
                    ),
                ),
            )

            is Form.Kind.Course -> listOf(
                Report(
                    "Slutfört kurs", "C1",
                    listOf(
                        Submission.Operation.Value(Key("Förnamn"), Key("Förnamn")),
                        Submission.Operation.Value(Key("Efternamn"), Key("Efternamn")),

                        Submission.Operation.GroupBy(Key("Personnummer")),

                        Submission.Operation.OrderBy(Key("Efternamn")),
                        Submission.Operation.OrderBy(Key("Förnamn")),

                        Submission.Operation.Include(Submission.State.Closed),
                    ),
                ),
            )

            is Form.Kind.Quiz -> listOf(
                Report(
                    "Topplistan, lag", "Q1",
                    listOf(
                        Submission.Operation.IntValue(Key("Rätta svar"), Key("Resultat")),
                        Submission.Operation.Value(Key("Lag"), Key("Lag")),

                        Submission.Operation.GroupBy(Key("Lag")),

                        Submission.Operation.OrderBy(Key("Rätta svar"), ascending = false),

                        Submission.Operation.Include(Submission.State.Closed),
                    ),
                ),
                Report(
                    "Topplistan, personer", "Q2",
                    listOf(
                        Submission.Operation.IntValue(Key("Rätta svar"), Key("Resultat")),
                        Submission.Operation.Value(Key("Förnamn"), Key("Förnamn")),
                        Submission.Operation.Value(Key("Efternamn"), Key("Efternamn")),

                        Submission.Operation.GroupBy(Key("Personnummer")),

                        Submission.Operation.OrderBy(Key("Rätta svar"), ascending = false),

                        Submission.Operation.Include(Submission.State.Closed),
                    ),
                ),
            )

            is Form.Kind.Poll -> listOf(
                Report(
                    "Resultat", "O1",
                    listOf(
                        Submission.Operation.Value(Key("POLL"), named = Key("Resultat")),
                        Submission.Operation.Count(Key("POLL")),

                        Submission.Operation.GroupBy(Key("POLL")),

                        Submission.Operation.OrderBy(Key("POLL")),

                        Submission.Operation.Include(Submission.State.Closed),
                    ),
                ),
            )

            else -> emptyList()
        }
    }
}