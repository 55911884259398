package screens

import screens.items.*
import services.*
import support.*
import techla.base.*
import techla.form.Field
import techla.form.Form
import kotlin.time.ExperimentalTime

@ExperimentalTime
object RecruitmentScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
        val info = DesignSystem.Header(id = "info")

        fun short(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Recruitment.stringFromStyle(Field.Style.Short)}${id.rawValue}")
        fun phone(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Recruitment.stringFromStyle(Field.Style.Phone)}${id.rawValue}")
        fun email(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Recruitment.stringFromStyle(Field.Style.Email)}${id.rawValue}")
        fun list(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Recruitment.stringFromStyle(Field.Style.Choice(emptyList()))}${id.rawValue}")
    }

    enum class Success { DELETE, ENABLE, DISABLE, DUPLICATE, SUCCESS }

    data class Texts(
        val back: String,
        val next: String,
        val description: String,
        val content: String,
        val add: String,
        val delete: String,
        val successTitle: String,
        val successBody: String,
        val done: String,
        val name: String,
        val info: String,
        val short: String,
        val phone: String,
        val email: String,
        val list: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    data class State(
        val formId: Identifier<Form>? = null,
        val name: String = "",
        val choice: String = "",
        val correctAnswer: String = "",
    )

    data class SearchParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    data class ReadyParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        data class Waiting(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        data class Search(
            override val texts: Texts,
            override val state: State,
            val params: SearchParams,
            val title: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val create: DesignSystem.Button,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Ready(
            override val texts: Texts,
            override val state: State,
            val params: ReadyParams,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Success(
            override val texts: Texts,
            override val state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(
            override val texts: Texts,
            override val state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class GetPro(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts? = null): ViewModel =
            Loading(texts = texts ?: this.texts, state = state)

        fun waiting(state: State): ViewModel =
            ViewModel.Waiting(texts = texts, state = state)

        fun search(state: State, params: SearchParams): ViewModel =
            Search(
                texts = texts,
                state = state,
                params = params,
                title = DesignSystem.Text(text = "Future Star", size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                image = DesignSystem.ImageView(image = DesignSystem.Image.RECRUITMENT),
                create = DesignSystem.Button(text = "Skapa ett Future Star", action = DesignSystem.Action.ADD),
                items = params.items,
            )

        fun ready(state: State? = null, params: ReadyParams): ViewModel =
            tupleOf(texts, state ?: this.state).let { (texts, state) ->
                Ready(
                    texts = texts,
                    state = state,
                    params = params,
                    items = params.items,
                )
            }

        fun success(success: RecruitmentScreen.Success): ViewModel = Success(
            texts = texts,
            state = state,
            confirmation = DesignSystem.Confirmation(
                icon = "",
                title = texts.successTitle,
                body = texts.successBody,
                next = texts.done,
            )
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        val status
            get() = when (this) {
                is None -> "NONE"
                is Search -> "SEARCH"
                is Waiting -> "WAITING"
                is Loading -> "LOADING"
                is Ready -> "READY"
                is Failed -> "FAILED"
                is Success -> "SUCCESS"
                is GetPro -> "GET PRO"
            }

        fun asLoading() = this as? Loading
        fun asWaiting() = this as? Waiting
        fun asSearch() = this as? Search
        fun asReady() = this as? Ready
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    private fun options(text: Texts) = listOf(
        DesignSystem.Option(title = text.short, value = "TEXT", action = DesignSystem.Action.TEXT),
        DesignSystem.Option(title = text.phone, value = "PHONE", action = DesignSystem.Action.PHONE),
        DesignSystem.Option(title = text.email, value = "EMAIL", action = DesignSystem.Action.EMAIL),
        DesignSystem.Option(title = text.list, value = "CHOICE", action = DesignSystem.Action.CHOICE),
    )

    private fun titles(text: Texts) = listOf(
        "Beskrivning",
        "Innehåll",
    )

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            back = "Tillbaka",
            next = "Nästa",
            description = "Beskrivning",
            content = "Innehåll",
            add = "Lägg till",
            delete = "Radera",

            successTitle = "Allt är nu klart \uD83C\uDF8A",
            successBody = "Allt är nu klart!",
            done = "Fortsätt",

            name = "Namn på Future Star",
            info = "Information och regler",
            short = "\uD83E\uDEAA Text",
            phone = "☎️️ Telefon",
            email = "✉️ E-post",
            list = "\uD83C\uDFF7 Lista",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isPro) return sceneOf(viewModel.getPro())
        return store.listRecruitments()
            .map { (actions, recruitments) ->
                val state = viewModel.state
                val params = SearchParams(page = 1, items = buildSearch(recruitments))
                sceneOf(viewModel.search(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun show(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.getForm(Identifier(id.rawValue))
            .map { (actions, form) ->
                val state = viewModel.state.copy(formId = form.id)
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(form, viewModel.texts)
                        .decorate(mode = Mode.Edit, titles = titles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(Unit).noActions()
            .map { (actions, _) ->
                val state = viewModel.state.copy(formId = null)
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(null, viewModel.texts)
                        .decorate(mode = Mode.Create, titles = titles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun toggle(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, enabled: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status = if (enabled) Form.Status.Active else Form.Status.Inactive
        val success = if (enabled) Success.ENABLE else Success.DISABLE
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.listRecruitments()
                    .accumulate(actions)
            }
            .flatMap { (actions, recruitments) ->
                store.editForm(Identifier(id.rawValue), Form.Edit(status = modifiedOf(status)))
                    .accumulate(actions)
                    .map {
                        tupleOf(it.first, recruitments, it.second)
                    }
            }
            .flatMap { (actions, recruitments, form) ->
                // Finds any previously active forms and inactivate them
                val previous = recruitments.firstOrNull { it.status.isActive }
                if (form.status.isActive && previous != null)
                    store.editForm(previous.id, Form.Edit(status = modifiedOf(Form.Status.Inactive)))
                        .accumulate(actions)
                else
                    successfulOf(tupleOf(actions, form))
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(success), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.deleteForm(id = Identifier(id.rawValue))
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.DELETE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun action(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, action: DesignSystem.Action): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        techla_log("RECRUITMENT: action='$action'")

        return when (viewModel) {
            is ViewModel.Search ->
                when (action) {
                    DesignSystem.Action.EDIT -> show(scene, id)
                    DesignSystem.Action.ENABLE -> toggle(scene, id, enabled = true)
                    DesignSystem.Action.DISABLE -> toggle(scene, id, enabled = false)
                    DesignSystem.Action.DELETE -> delete(scene, id)
                    // DesignSystem.Action.DUPLICATE -> sceneOf(viewModel.waiting(viewModel.state.copy(formId = Identifier(id.rawValue))))
                    else -> sceneOf(viewModel)
                }

            is ViewModel.Ready ->
                when (action) {
                    DesignSystem.Action.SEARCH -> load(scene)
                    DesignSystem.Action.DELETE -> sceneOf(viewModel.replace(viewModel.params.items.delete(id)))
                    DesignSystem.Action.UP -> sceneOf(viewModel.replace(viewModel.params.items.up(id)))
                    DesignSystem.Action.DOWN -> sceneOf(viewModel.replace(viewModel.params.items.down(id)))
                    DesignSystem.Action.PREV -> sceneOf(viewModel.replace(viewModel.params.items.prev()))
                    DesignSystem.Action.NEXT -> validate(scene)
                    DesignSystem.Action.TEXT -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.short(), label = viewModel.texts.short))))
                    DesignSystem.Action.PHONE -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.phone(), label = viewModel.texts.phone))))
                    DesignSystem.Action.EMAIL -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.email(), label = viewModel.texts.email))))
                    DesignSystem.Action.CHOICE -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.choices(header = Header.list(), label = viewModel.texts.list))))
                    DesignSystem.Action.CREATE -> save(scene)
                    DesignSystem.Action.EDIT -> save(scene)
                    else -> sceneOf(viewModel)
                }

            else -> sceneOf(viewModel)
        }
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, value: ActivityValue): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready
        return sceneOf(viewModel.replace(viewModel.params.items.setValue(id = id, value = value)))
    }

    private fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, viewModel.params.page)
        return if (status.overallStatus() !is Status.Valid) {
            sceneOf(viewModel.status(status))
        } else
            sceneOf(viewModel.replace(viewModel.params.items.next()))
    }

    private suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, null)
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.status(status))
        }

        val formId = viewModel.state.formId
        val activityValues = viewModel.params.items.activityValues()
        val name = activityValues.firstOrNull { it.first == Header.name }?.second?.stringValue
        val info = activityValues.firstOrNull { it.first == Header.info }?.second?.stringValue
        val userActivities = activityValues.filter { it.first != Header.name && it.first != Header.info }

        val create = Form.Create(
            key = Key.random(length = 10),
            name = name ?: "",
            info = info ?: "",
            kind = Form.Kind.Recruitment,
            feedback = Form.Feedback.None,
            status = Form.Status.Inactive,
            policy = Form.Policy.Append,
        )
        val edit = Form.Edit(
            name = name?.let { modifiedOf(it) } ?: Modification.Unmodified,
            info = info?.let { modifiedOf(it) } ?: Modification.Unmodified,
        )

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (formId == null)
                    store.reduce(actions).createForm(create = create)
                        .accumulate(actions)
                else
                    store.reduce(actions).editForm(formId, edit = edit)
                        .accumulate(actions)
            }
            .flatMap { (actions, form) ->
                form.fields
                    .zipAll(Recruitment.fields(userActivities), null, null)
                    .map { (existing, modified) ->
                        when {
                            existing != null && modified != null -> store.reduce(actions).editField(existing.id, modified.toEdit()).map { tupleOf(it.first, Unit) }
                            existing == null && modified != null -> store.reduce(actions).createField(modified.toCreate(form.id)).map { tupleOf(it.first, Unit) }
                            existing != null && modified == null -> store.reduce(actions).deleteField(existing.id)
                            else -> successfulOf(Unit).noActions()
                        }.accumulate(actions)
                    }
                    .all()
                    .map { tupleOf(it.first, form, it.second) }
            }
            .map { (actions, _, _) ->
                sceneOf(viewModel.success(Success.SUCCESS), actions)
            }.failed { scene.failed(result = it) }
    }

    private fun buildSearch(forms: List<Form>): List<ActivityItem.ViewModel> =
        forms.map { item ->
            ActivityItem.search(id = Identifier(item.id.rawValue), label = item.name, info = "", active = item.status is Form.Status.Active)
        }

    private fun buildItems(form: Form?, texts: Texts): List<ActivityItem.ViewModel> {
        val page1 = listOf(
            ActivityItem.basicName(header = Header.name, value = ActivityValue.StringValue(form?.name ?: ""), label = texts.name, displayPage = 1),
            ActivityItem.basicInfo(header = Header.info, value = ActivityValue.StringValue(form?.info ?: ""), label = texts.info, displayPage = 1),
        )
        val page2 = form?.fields
            ?.sortedBy { it.order }
            ?.mapNotNull { field ->
                when (val style = field.style) {
                    is Field.Style.Short -> ActivityItem.title(header = Header.short(field.id), label = texts.short, value = ActivityValue.StringValue(value = field.label ?: ""))
                    is Field.Style.Phone -> ActivityItem.title(header = Header.phone(field.id), label = texts.phone, value = ActivityValue.StringValue(value = field.label ?: ""))
                    is Field.Style.Email -> ActivityItem.title(header = Header.email(field.id), label = texts.email, value = ActivityValue.StringValue(value = field.label ?: ""))
                    is Field.Style.Choice -> ActivityItem.choices(header = Header.list(field.id), label = texts.list, value = ActivityValue.ListValue(title = field.label ?: "", choices = style.choices), displayPage = 2)
                    else -> null
                }
            } ?: emptyList()

        return page1 + page2
    }
}

@ExperimentalTime
private fun RecruitmentScreen.ViewModel.Ready.replace(items: List<ActivityItem.ViewModel>): RecruitmentScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.resetStatus() }))
}

@ExperimentalTime
private fun RecruitmentScreen.ViewModel.Ready.status(status: List<ValidationStatus>): RecruitmentScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.setStatus(status) }))
}
