package components

import mui.material.CssBaseline
import mui.system.ThemeProvider
import muix.pickers.AdapterDateFns
import muix.pickers.LocalizationProvider
import react.FC
import react.PropsWithChildren
import support.rocksTheme

val RTheme = FC<PropsWithChildren> { props ->
    ThemeProvider {
        this.theme = rocksTheme
        CssBaseline {
            LocalizationProvider {
                this.dateAdapter = AdapterDateFns

                +props.children
            }
        }
    }
}