package services

import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.async
import kotlinx.coroutines.coroutineScope
import kotlinx.datetime.number
import support.*
import techla.base.*
import techla.control.ControlAPI
import techla.control.Event
import techla.conversation.Message
import techla.guard.Application
import techla.guard.Profile
import techla.guard.UserAuthentication

typealias Lookup = Triple<List<Event.Action>, List<Event.Category>, List<Event.Subcategory>?>

fun ActionOutcome<PageContent<Profile>>.toEventStatistic() =
    map { (actions, page) ->
        tupleOf(
            actions, listOf(
                Event.Statistic(
                    count = page.items.total,
                    action = Event.Action.Created,
                    category = Event.Category.Profile,
                    interval = Event.Interval.Total,
                    uniqueOnly = false,
                    subcategory = null,
                    secondary = null,
                    profileId = null,
                )
            )
        )
    }

fun ActionOutcome<PageContent<UserAuthentication>>.toEventStatistic() =
    map { (actions, page) ->
        tupleOf(
            actions, listOf(
                Event.Statistic(
                    count = page.items.total,
                    action = Event.Action.Created,
                    category = Event.Category.UserAuthentication,
                    interval = Event.Interval.Total,
                    uniqueOnly = false,
                    subcategory = null,
                    secondary = null,
                    profileId = null,
                )
            )
        )
    }

fun ActionOutcome<PageContent<Message>>.toEventStatistic() =
    map { (actions, page) ->
        tupleOf(
            actions, listOf(
                Event.Statistic(
                    count = page.items.total,
                    action = Event.Action.Created,
                    category = Event.Category.Message,
                    interval = Event.Interval.Total,
                    uniqueOnly = false,
                    subcategory = null,
                    secondary = null,
                    profileId = null,
                )
            )
        )
    }

suspend fun Store.findStatistics(): Statistics {
    val (months, monthLabelList) = getIntervals()
    val userAuthentications: Lookup =
        tupleOf(listOf(Event.Action.Created), listOf(Event.Category.UserAuthentication), listOf(Event.Subcategory.Manual, Event.Subcategory.Import))
    val messages: Lookup =
        tupleOf(listOf(Event.Action.Created), listOf(Event.Category.Message), null)
    val others: Lookup =
        tupleOf(listOf(Event.Action.Created), listOf(Event.Category.Boost, Event.Category.EmployeeNPS, Event.Category.ManagerNPS), null)
    val reactions: Lookup =
        tupleOf(listOf(Event.Action.Created), listOf(Event.Category.Reaction), listOf(Event.Subcategory.Comment, Event.Subcategory.Like))
    val boosts: Lookup =
        tupleOf(listOf(Event.Action.Created, Event.Action.Received), listOf(Event.Category.Boost), null)
    val profiles: Lookup =
        tupleOf(listOf(Event.Action.Created, Event.Action.Deleted, Event.Action.Inactivated), listOf(Event.Category.Profile), null)

    val statistics: List<Event.Statistic> = coroutineScope {
        listOf(
            // The 3 top values are directly from their sources
            async { listProfiles(PageIndex.countOnly(), sources = listOf(Profile.Source.Manual, Profile.Source.Import)).toEventStatistic() },
            async { listUserAuthentications(PageIndex.countOnly(), statuses = listOf(UserAuthentication.Status.Complete)).toEventStatistic() },
            async { listMessages(standard, PageIndex.countOnly()).toEventStatistic() },

            // The rest are from TechControl
            async { findStatistics(userAuthentications, intervals = months) },
            async { findStatistics(userAuthentications, intervals = months, uniqueOnly = true) },
            async { findStatistics(messages, intervals = months) },
            async { findStatistics(messages, intervals = months, uniqueOnly = true) },
            async { findStatistics(others, intervals = months) },
            async { findStatistics(reactions, intervals = months, uniqueOnly = true) },
            async { findStatistics(boosts, intervals = months, uniqueOnly = true) },
            async { findStatistics(profiles, intervals = months) },
            // Need to use `.distinct()` here since the demo data is the same for every call to `findStatistics`
        ).awaitAll().all().outputOrNull()?.second?.flatten().orEmpty().distinct()
    }

    return createStatistics(statistics, monthLabelList)
}

suspend fun Store.findTopList(type: TopListType): TopLists {
    val today = Date.now().dateTime
    val intervals = listOf(Event.Interval.Monthly(year = today.year, monthNumber = today.month.number))

    var actions: List<Event.Action>? = null
    var categories: List<Event.Category>? = null
    var subcategories: List<Event.Subcategory>? = null
    var onSecondary: Boolean = false

    when (type) {
        TopListType.BOOSTS_CREATED -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.Boost)
        }

        TopListType.BOOSTS_RECEIVED -> {
            actions = listOf(Event.Action.Received)
            categories = listOf(Event.Category.Boost)
        }

        TopListType.USER_AUTHENTICATIONS -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.UserAuthentication)
            subcategories = listOf(Event.Subcategory.Manual)
        }

        TopListType.USERS -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.UserAuthentication)
            subcategories = listOf(Event.Subcategory.Manual)
        }

        TopListType.MESSAGES -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.Message)
        }

        TopListType.LIKES -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.Reaction)
            subcategories = listOf(Event.Subcategory.Like)
        }

        TopListType.COMMENTS -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.Reaction)
            subcategories = listOf(Event.Subcategory.Comment)
        }

        TopListType.MOST_REACTIONS -> {
            actions = listOf(Event.Action.Created)
            categories = listOf(Event.Category.Reaction)
            onSecondary = true
        }
    }

    var statistics = findTopLists(actions, categories, intervals, subcategories = subcategories, onSecondary = onSecondary).outputOrNull()?.second.orEmpty()

    // We need a specific case for demo mode, since we call findTopLists twice and the demo data is not unique, the same profile can appear multiple times
    if (demoMode) {
        statistics = statistics.distinctBy { listOf(it.action, it.category, it.subcategory, it.profileId) }
    }

    // If we are using secondary, we assume it's a message id
    val messageIds = if (onSecondary)
        statistics.mapNotNull { it.secondary }.distinct().map { Identifier<Message>(it.rawValue) }
    else
        emptyList()
    val messages: List<Message> = coroutineScope {
        messageIds.map { async { getMessageOrNull(it) } }.awaitAll().all().outputOrNull()?.second?.filterNotNull().orEmpty()
    }

    val profileIds = if (onSecondary)
        messages.mapNotNull { it.author }.distinct()
    else
        statistics.mapNotNull { it.profileId }.distinct()
    val profiles: List<Profile> = coroutineScope {
        profileIds.map { async { getProfileOrNull(it) } }.awaitAll().all().outputOrNull()?.second?.filterNotNull().orEmpty()
    }

    return createTopLists(type, statistics, profiles, messages)
}

private suspend fun Store.findStatistics(
    lookup: Lookup,
    intervals: List<Event.Interval>,
    timeZone: String = "Europe/Stockholm",
    locale: String = "sv-SE",
    application: Key<Application>? = null,
    uniqueOnly: Boolean = false
): ActionOutcome<List<Event.Statistic>> =
    findStatistics(
        actions = lookup.first,
        categories = lookup.second,
        subcategories = lookup.third,
        intervals = intervals,
        timeZone = timeZone,
        locale = locale,
        application = application,
        uniqueOnly = uniqueOnly,
    )

private suspend fun Store.findStatistics(
    actions: List<Event.Action>,
    categories: List<Event.Category>,
    subcategories: List<Event.Subcategory>? = null,
    intervals: List<Event.Interval>,
    timeZone: String = "Europe/Stockholm",
    locale: String = "sv-SE",
    application: Key<Application>? = null,
    uniqueOnly: Boolean = false
): ActionOutcome<List<Event.Statistic>> {
    if (demoMode) return Demo.exampleStatistics(intervals).noActions()
    return controlAPI { api ->
        api.findStatistics(actions, categories, intervals, timeZone, locale, application, subcategories, uniqueOnly)
            .onNotSuccess {
                techla_log("WARN: $it")
            }
    }
}

private suspend fun Store.findTopLists(
    actions: List<Event.Action>,
    categories: List<Event.Category>,
    intervals: List<Event.Interval>,
    timeZone: String = "Europe/Stockholm",
    locale: String = "sv-SE",
    application: Key<Application>? = null,
    subcategories: List<Event.Subcategory>? = null,
    onSecondary: Boolean = false,
): ActionOutcome<List<Event.Statistic>> {
    if (demoMode) return Demo.exampleTopLists(intervals).noActions()
    return controlAPI { api ->
        api.findTopLists(actions, categories, intervals, timeZone, locale, application, subcategories, onSecondary)
            .onNotSuccess {
                techla_log("WARN: $it")
            }
    }
}

suspend fun <T> Store.controlAPI(block: suspend (api: ControlAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = ControlAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ControlAPI.sandbox else ControlAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}
