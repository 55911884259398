package components

import emotion.react.css
import mui.material.*
import mui.system.sx
import react.FC
import react.PropsWithChildren
import support.DesignSystem
import support.Palette
import kotlin.time.ExperimentalTime
import web.cssom.*

external interface TableProps : PropsWithChildren {
    var design: DesignSystem.Table<*>
    var striped: Boolean?
}

@ExperimentalTime
val RTable = FC<TableProps> { props ->

    if (props.design.visible) {
        TableContainer {
            Table {
                sx {
                    "th, td" {
                        padding = 0.5.rem
                    }
                }
                TableHead {
                    TableRow {
                        props.design.titles.map { title ->
                            TableCell {
                                sx {
                                    borderBottom = Border(1.px, LineStyle.solid, Color(Palette.Grey.main))
                                }
                                variant = TableCellVariant.head
                                RText {
                                    css {
                                        fontWeight = important(FontWeight.bold)
                                    }
                                    design = title
                                }
                            }
                        }
                    }
                }
                TableBody {
                    if (props.striped != false) {
                        sx {
                            "tr:nth-of-type(odd)" {
                                backgroundColor = rgb(0, 0, 0, 0.05)
                            }
                        }
                    }
                    +props.children
                }
            }
        }
    }
}