package support

import kotlinx.serialization.Serializable
import techla.base.*
import techla.conversation.Message
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import techla.guard.Me
import techla.personal.Narrative

val WHISTLE_BLOWER_FIELD = Key<Field>("WHISTLE_BLOWER")
val SUGGESTION_BOX_FIELD = Key<Field>("SUGGESTION_BOX")


typealias Presentation = String

@Serializable
data class Format(
    val presentsLabel: Boolean = true,
    val presentsHint: Boolean = false,
    val presentsValue: Boolean = false,
    val presentsAnswer: Boolean = false,
    val presentsAssets: Boolean = false,
    val labelTitle: String = "",
    val hintTitle: String = "",
    val valueTitle: String = "",
    val answerTitle: String = "",
    val assetTitle: String = "",
    val isUrlValue: Boolean = false,
) {
    companion object {
        val zero = Format()
    }
}

fun Form.isValidFor(narrative: Narrative?) = when (val status = this.status) {
    is Form.Status.Unknown -> true
    is Form.Status.Active -> true
    is Form.Status.ActiveNarrative -> (status.cities.isEmpty() || status.cities.contains(narrative?.city)) && (status.departments.isEmpty() || status.departments.contains(
        narrative?.department
    )) && (status.positions.isEmpty() || status.positions.contains(narrative?.position))

    is Form.Status.Inactive -> false
}

fun Submission.Entry.Companion.toEntry(
    field: Field, me: Me, narrative: Narrative?, message: Message?, correctAnswers: Int?
): Submission.Entry = when (field.style) {
    is Field.Style.GovId -> Submission.Entry(fieldKey = field.key, text = me.govId ?: "-")

    is Field.Style.FirstName -> Submission.Entry(fieldKey = field.key, text = me.firstName ?: "-")

    is Field.Style.LastName -> Submission.Entry(fieldKey = field.key, text = me.lastName ?: "-")

    is Field.Style.Email -> Submission.Entry(fieldKey = field.key, text = narrative?.email ?: "-")

    is Field.Style.City -> Submission.Entry(fieldKey = field.key, text = narrative?.city ?: "-")

    is Field.Style.Department -> Submission.Entry(fieldKey = field.key, text = narrative?.department ?: "-")

    is Field.Style.Position -> Submission.Entry(fieldKey = field.key, text = narrative?.position ?: "-")

    is Field.Style.Role -> Submission.Entry(fieldKey = field.key, text = narrative?.role?.rawValue ?: "-")

    is Field.Style.WeekOfYear -> Submission.Entry(
        fieldKey = field.key, text = message?.timestamp?.weekOfYear?.toString() ?: Date.now().weekOfYear.toString()
    )

    is Field.Style.Month -> Submission.Entry(
        fieldKey = field.key,
        text = message?.timestamp?.dateTime?.monthNumber?.toString() ?: Date.now().dateTime.monthNumber.toString()
    )

    is Field.Style.Year -> Submission.Entry(
        fieldKey = field.key,
        text = message?.timestamp?.dateTime?.year?.toString() ?: Date.now().dateTime.year.toString()
    )

    is Field.Style.CorrectAnswers -> Submission.Entry(fieldKey = field.key, text = correctAnswers?.toString() ?: "0")

    is Field.Style.MessageId -> Submission.Entry(fieldKey = field.key, text = message?.id?.rawValue ?: "-")

    else -> Submission.Entry(fieldKey = field.key, text = field.predefined ?: "")
}

fun Field.toCreate(formId: Identifier<Form>) =
    Field.Create(
        formId = formId,
        key = key,
        style = style,
        order = order,
        page = page,
        required = required,
        hidden = hidden,
        autofill = autofill,
        label = label,
        predefined = predefined,
        placeholder = placeholder,
        hint = hint,
        answer = answer,
        url = url,
        assetId = assetId,
    )

fun Field.toEdit() =
    Field.Edit(
        key = modifiedOf(key),
        style = modifiedOf(style),
        order = modifiedOf(order),
        page = modifiedOf(page),
        required = modifiedOf(required),
        hidden = modifiedOf(hidden),
        autofill = modifiedOf(autofill),
        label = modifiedOf(label),
        predefined = modifiedOf(predefined),
        placeholder = modifiedOf(placeholder),
        hint = modifiedOf(hint),
        answer = modifiedOf(answer),
        url = modifiedOf(url),
        assetId = modifiedOf(assetId),
    )
