package services

import support.*
import techla.base.*
import techla.form.*

suspend fun Store.findForms(keys: List<Key<Form>>): ActionOutcome<List<Form>> {
    if (demoMode) return Demo.allForms.noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.FindForms(keys, null), api) {
            api.findForms(keys).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listForms(): ActionOutcome<List<Form>> {
    if (demoMode) return Demo.allForms.noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.ListForms, api) {
            api.listForms().onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listEmployeeSurveys(): ActionOutcome<List<Form>> =
    listForms()
        .map { (actions, forms) ->
            tupleOf(actions, forms.filter { it.kind is Form.Kind.EmployeeSurvey })
        }

suspend fun Store.listRecruitments(): ActionOutcome<List<Form>> =
    listForms()
        .map { (actions, forms) ->
            tupleOf(actions, forms.filter { it.kind is Form.Kind.Recruitment })
        }

suspend fun Store.listQuizzes(): ActionOutcome<List<Form>> =
    listForms()
        .map { (actions, forms) ->
            tupleOf(actions, forms.filter { it.kind is Form.Kind.Quiz })
        }

suspend fun Store.getForm(id: Identifier<Form>): ActionOutcome<Form> {
    if (demoMode) return Demo.matchForm(id).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.GetForm(id), api) {
            api.getForm(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editForm(id: Identifier<Form>, edit: Form.Edit): ActionOutcome<Form> {
    if (demoMode) return Demo.matchForm(id).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.EditForm(id, edit), api) {
            api.editForm(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createForm(create: Form.Create): ActionOutcome<Form> {
    if (demoMode) return Demo.createForm(create).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.CreateForm(create), api) {
            api.createForm(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteForm(id: Identifier<Form>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.DeleteForm(id), api) {
            api.deleteForm(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editField(id: Identifier<Field>, edit: Field.Edit): ActionOutcome<Field> {
    if (demoMode) return Demo.matchField(id).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.EditField(id, edit), api) {
            api.editField(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createField(create: Field.Create): ActionOutcome<Field> {
    if (demoMode) return Demo.createField(create).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.CreateField(create), api) {
            api.createField(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteField(id: Identifier<Field>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.DeleteField(id), api) {
            api.deleteField(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listSubmissions(
    index: PageIndex, forms: List<Key<Form>>, states: List<Submission.State>?
): ActionOutcome<PageContent<Submission>> {
    if (demoMode) return Demo.allSubmissionsPage(index).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.ListSubmissionsWithPaging(index, forms, states, null), api) {
            api.listSubmissions(index, forms, states).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.getSubmission(id: Identifier<Submission>): ActionOutcome<Submission> {
    if (demoMode) return Demo.matchSubmission(id).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.GetSubmission(id), api) {
            api.getSubmission(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteSubmission(id: Identifier<Submission>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.DeleteSubmission(id), api) {
            api.deleteSubmission(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.report(report: Submission.Report): ActionOutcome<List<Submission.Report.Result>> {
    if (demoMode) return successfulOf(emptyList<Submission.Report.Result>()).noActions()
    return formAPI { api ->
        measureAPI(FormAPIResource.Report(report), api) {
            api.report(report).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun <T> Store.formAPI(block: suspend (api: FormAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = FormAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) FormAPI.sandbox else FormAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}