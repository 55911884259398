package views

import StoreContext
import components.*
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.*
import screens.WhistleBlowerScreen
import support.*
import kotlin.time.ExperimentalTime
import web.cssom.*

@ExperimentalTime
val WhistleBlower = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: WhistleBlowerScreen.ViewModel by useState(WhistleBlowerScreen.ViewModel.None)
    val executor by useExecutor<WhistleBlowerScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    var text by useState("")

    useEffect(viewModel) {
        if (viewModel is WhistleBlowerScreen.ViewModel.None)
            executor.call { WhistleBlowerScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is WhistleBlowerScreen.ViewModel.Loading)
            executor.call { WhistleBlowerScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onDisable() {
        executor.call { WhistleBlowerScreen.disable(sceneInputOf(store, viewModel)) }
    }

    fun onEnable() {
        executor.call { WhistleBlowerScreen.enable(sceneInputOf(store, viewModel), value = text) }
    }

    fun onCreate() {
        executor.call { WhistleBlowerScreen.create(sceneInputOf(store, viewModel)) }
    }

    fun onBack(action: DesignSystem.Action) {
        executor.call { WhistleBlowerScreen.start(sceneInputOf(store, viewModel)) }
    }

    RScaffold {
        isFullScreen = false

        viewModel.asReady()?.run {
            RActivityItemTitle {
                imageView = image
                titleText = title
                firstButton = enable
                secondButton = disable
                onFirst = ::onCreate
                onSecond = ::onDisable
            }
        }

        viewModel.asCreate()?.run {
            Stack {
                sx {
                    width = 100.pct
                }

                RForm {
                    onSubmit = { onEnable() }
                    RTextInput { design = this@run.text; onChange = { value -> text = value } }
                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            justifyContent = JustifyContent.spaceBetween
                            paddingTop = 16.px
                        }
                        RButton { design = back; onClick = ::onBack }
                        RButton { design = next; }
                    }
                }
            }
        }

        viewModel.asLoading()?.run {
            RLoader {}
        }

        viewModel.asSuccess()?.run {
            RBackgroundImage {}
            RConfirmation { design = confirmation; onClick = ::onBack }
        }

        viewModel.asGetPro()?.run {
            RGetPro {}
        }

        viewModel.asFailed()?.run {
            RError { message = message }
        }
    }
}