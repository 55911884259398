package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useRequiredContext
import react.useState
import screens.SubmissionsScreen
import support.DesignSystem
import support.RScaffold
import support.sceneInputOf
import support.useAsyncEffect
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Submissions = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(SubmissionsScreen.ViewModel.None as SubmissionsScreen.ViewModel)
    val (loadingReportData, setLoadingReportData) = useState(false)
    val (isExporting, setIsExporting) = useState(false)

    fun onFilter(form: String?, report: String?, year: String) {
        MainScope().launch {
            setLoadingReportData(true)
            SubmissionsScreen.filter(sceneInputOf(store, viewModel), form = form, report = report, year = year).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
            setLoadingReportData(false)
        }
    }

    fun onExport() {
        MainScope().launch {
            setIsExporting(true)
            SubmissionsScreen.export(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
            setIsExporting(false)
        }
    }

    fun onPageChange(page: Int) {
        MainScope().launch {
            SubmissionsScreen.setPage(sceneInputOf(store, viewModel), page).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    fun onRowsPerPage(rowsPerPage: Int) {
        MainScope().launch {
            SubmissionsScreen.setRowsPerPage(sceneInputOf(store, viewModel), rowsPerPage).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is SubmissionsScreen.ViewModel.None -> if (coroutineScope.isActive) {
                SubmissionsScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is SubmissionsScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                SubmissionsScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is SubmissionsScreen.ViewModel.Ready -> {}
            is SubmissionsScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (loadingReportData) {
            RLoader {}
        }
        if (viewModel is SubmissionsScreen.ViewModel.Ready && !loadingReportData) {
            Stack {
                spacing = responsive(2)
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                    }
                    RText { design = viewModel.title }
                }
                RForm {
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(2)
                        sx {
                            justifyContent = JustifyContent.flexStart
                            margin = Margin(1.rem, 0.rem)
                        }

                        div {
                            css {
                                width = 33.pct
                            }
                            RSelectInput {
                                design = viewModel.activity
                                onChange = { selected -> onFilter(form = selected.value, report = null, year = viewModel.state.selectedYear.toString()) }
                            }
                        }

                        if (viewModel.report.visible) {
                            div {
                                css {
                                    width = 33.pct
                                }
                                RSelectInput {
                                    design = viewModel.report
                                    onChange = { selected -> onFilter(form = viewModel.state.selectedForm?.key?.rawValue, report = selected.value, year = viewModel.state.selectedYear.toString()) }
                                }
                            }
                        }

                        if (viewModel.year.visible) {
                            div {
                                css {
                                    width = 33.pct
                                }
                                RSelectInput {
                                    design = viewModel.year
                                    onChange = { selected -> onFilter(form = viewModel.state.selectedForm?.key?.rawValue, report = viewModel.state.selectedReport?.value, year = selected.value) }
                                }
                            }
                        }

                        div {
                            css {
                                width = 33.pct
                            }
                            RButton {
                                design = viewModel.export.copy(disabled = viewModel.export.disabled || isExporting)
                                onClick = { onExport() }
                            }
                        }
                    }
                }

                RTable {
                    design = viewModel.submissions

                    viewModel.submissions.data?.map { (_, row) ->
                        TableRow {
                            row.map { cell ->
                                TableCell {
                                    variant = TableCellVariant.body
                                    when (cell) {
                                        is DesignSystem.Link -> RLink { design = cell }
                                        is DesignSystem.Text -> RText { design = cell }
                                        else -> {}
                                    }
                                }
                            }
                        }
                    }
                }
                RTable {
                    design = viewModel.results

                    viewModel.results.data?.map { (_, row) ->
                        TableRow {
                            row.map { cell ->
                                TableCell {
                                    variant = TableCellVariant.body
                                    when (cell) {
                                        is DesignSystem.Text -> RText { design = cell }
                                        else -> {}
                                    }
                                }
                            }
                        }
                    }
                }
                RPagination {
                    design = viewModel.pagination
                    onPageChange = ::onPageChange
                    onRowsPerPage = ::onRowsPerPage
                }
            }
        }

        if (viewModel is SubmissionsScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is SubmissionsScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}
