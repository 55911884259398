package components

import emotion.react.css
import io.ktor.client.request.forms.*
import io.ktor.utils.io.core.*
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import org.khronos.webgl.ArrayBuffer
import org.khronos.webgl.Int8Array
import react.*
import react.dom.events.ChangeEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import support.*
import techla.storage.Asset
import web.file.FileReader
import web.cssom.*
import web.html.HTMLElement
import web.html.HTMLInputElement
import web.html.HTMLTextAreaElement
import web.html.InputType

fun ArrayBuffer.toByteArray(): ByteArray = Int8Array(this).unsafeCast<ByteArray>()

external interface RTextInputProps : Props {
    var design: DesignSystem.TextInput
    var onChange: ((String) -> Unit)?
}

val RTextInput = FC<RTextInputProps> { props ->
    var value: String by useState("")

    useEffectOnce {
        props.onChange?.let { it(props.design.value ?: "") }
    }

    useEffect(props.design.value) {
        value = props.design.value ?: ""
    }

    fun onChange(event: ChangeEvent<HTMLElement>) {
        (event.target as? HTMLInputElement)?.let { target ->
            value = target.value
            props.onChange?.let { it(target.value) }
        }
        (event.target as? HTMLTextAreaElement)?.let { target ->
            value = target.value
            props.onChange?.let { it(target.value) }
        }
    }

    if (props.design.visible) {
        FormControl {
            this.fullWidth = true
            if (props.design.label != null) {
                FormLabel {
                    sx {
                        marginBottom = 0.5.rem
                        fontWeight = 600.unsafeCast<FontWeight>()
                    }
                    htmlFor = props.design.header.id
                    +props.design.label!!
                }
            }

            props.design.hint?.let { hint ->
                Typography {
                    variant = TypographyVariant.body1
                    +hint
                }
            }

            if (props.design.insetText?.isNotEmpty() == true) {
                Stack {
                    sx {
                        justifyContent = JustifyContent.flexStart
                        alignItems = AlignItems.center
                        flexDirection = FlexDirection.row
                        flexWrap = FlexWrap.nowrap
                        height = 100.pct
                    }

                    div {
                        css {
                            backgroundColor = Color("#CFD0D2")
                            padding = Padding(0.375.rem, 0.75.rem)
                            color = Color("#495057")
                            borderBottomLeftRadius = Shape.borderRadius
                            borderTopLeftRadius = Shape.borderRadius
                            fontSize = themeFontSize(DesignSystem.SizeType.MD).rem
                        }
                        +props.design.insetText!!
                    }
                    OutlinedInput {
                        sx {
                            width = 100.pct
                            borderBottomLeftRadius = 0.px
                            borderTopLeftRadius = 0.px
                            ".MuiOutlinedInput-input" {
                                padding = Padding(0.375.rem, 0.75.rem)
                            }
                            ".MuiOutlinedInput-notchedOutline" {
                                border = None.none
                            }
                            padding = Padding(0.5.px, 0.px)
                        }
                        this.key = props.design.header.id
                        this.value = value
                        this.name = props.design.header.id
                        this.disabled = props.design.disabled
                        this.placeholder = props.design.placeholder
                        this.onChange = ::onChange
                        this.type = when (props.design.type) {
                            DesignSystem.TextInputType.TEXT, DesignSystem.TextInputType.GOV_ID, DesignSystem.TextInputType.CREW -> "text"
                            DesignSystem.TextInputType.EMAIL -> "email"
                            DesignSystem.TextInputType.NUMBER -> "number"
                            DesignSystem.TextInputType.PHONE -> "tel"
                            else -> ""
                        }
                    }
                }
            } else if (props.design.type == DesignSystem.TextInputType.NOVEL) {
                OutlinedInput {
                    sx {
                        border = None.none
                        outline = None.none
                        ".MuiOutlinedInput-input" {
                            padding = Padding(0.375.rem, 0.75.rem)
                        }
                        ".MuiOutlinedInput-notchedOutline" {
                            border = None.none
                        }
                    }
                    this.key = props.design.header.id
                    this.fullWidth = true
                    this.multiline = true
                    this.minRows = 5
                    this.value = value
                    this.name = props.design.header.id
                    this.disabled = props.design.disabled
                    this.placeholder = props.design.placeholder
                    this.onChange = ::onChange
                    this.type = when (props.design.type) {
                        DesignSystem.TextInputType.TEXT, DesignSystem.TextInputType.GOV_ID, DesignSystem.TextInputType.CREW -> "text"
                        DesignSystem.TextInputType.EMAIL -> "email"
                        DesignSystem.TextInputType.NUMBER -> "number"
                        DesignSystem.TextInputType.PHONE -> "tel"
                        else -> ""
                    }
                }
            } else {
                OutlinedInput {
                    sx {
                        border = None.none
                        outline = None.none
                        ".MuiOutlinedInput-input" {
                            padding = Padding(0.375.rem, 0.75.rem)
                        }
                        ".MuiOutlinedInput-notchedOutline" {
                            border = None.none
                        }
                    }
                    this.key = props.design.header.id
                    this.fullWidth = true
                    this.value = value
                    this.name = props.design.header.id
                    this.disabled = props.design.disabled
                    this.placeholder = props.design.placeholder
                    this.onChange = ::onChange
                    this.type = when (props.design.type) {
                        DesignSystem.TextInputType.TEXT, DesignSystem.TextInputType.GOV_ID, DesignSystem.TextInputType.CREW -> "text"
                        DesignSystem.TextInputType.EMAIL -> "email"
                        DesignSystem.TextInputType.NUMBER -> "number"
                        DesignSystem.TextInputType.PHONE -> "tel"
                        else -> ""
                    }
                }
            }
            if (props.design.status.message?.isNotEmpty() == true)
                props.design.status.message?.let {
                    FormLabel {
                        sx {
                            fontSize = 14.px
                            marginTop = 0.25.rem
                            color = important(Color(Palette.Danger.dark))
                        }
                        error = true
                        htmlFor = props.design.header.id
                        +it
                    }
                }
        }
    }
}

external interface RMultiLineInputProps : Props {
    var design: DesignSystem.MultiLineInput
    var onChange: (String) -> Unit
}

val RMultiLineInput = FC<RMultiLineInputProps> { props ->
    fun onChange(event: ChangeEvent<HTMLElement>) {
        props.onChange((event.target as HTMLTextAreaElement).value)
    }

    FormControl {
        if (props.design.label != null) {
            FormLabel {
                sx {
                    marginBottom = 0.5.rem
                    fontWeight = 600.unsafeCast<FontWeight>()
                }
                htmlFor = props.design.header.id
                +props.design.label!!
            }
        }

        OutlinedInput {
            this.defaultValue = props.design.value
            this.name = props.design.header.id
            this.disabled = props.design.disabled
            this.placeholder = props.design.placeholder
            this.onChange = ::onChange
            this.multiline = true
        }
    }
}

external interface RBooleanInputProps : Props {
    var design: DesignSystem.BooleanInput
    var onChange: ((Boolean) -> Unit)?
}

val RBooleanInput = FC<RBooleanInputProps> { props ->
    var value: Boolean by useState(false)

    useEffectOnce {
        props.onChange?.let { it(props.design.value ?: false) }
    }

    useEffect(props.design.header) {
        value = props.design.value ?: false
    }

    fun onChange(event: ChangeEvent<HTMLElement>, checked: Boolean) {
        value = checked
        props.onChange?.let { it(checked) }
    }

    if (props.design.visible) {
        FormControl {
            sx {
                justifyContent = JustifyContent.flexStart
                alignItems = AlignItems.center
                flexDirection = FlexDirection.row
                flexWrap = FlexWrap.nowrap
                ".MuiFormLabel-root" {
                    color = important(Color(Palette.Grey.dark))
                }
            }

            when (props.design.style) {
                DesignSystem.BooleanStyle.CHECKBOX ->
                    Checkbox {
                        sx {
                            backgroundColor = Color(Palette.Grey.main)
                            height = important(1.25.rem)
                            width = 1.25.rem

                            padding = 0.px
                            height = important(1.25.rem)
                            width = important(1.25.rem)
                            marginRight = 8.px
                        }
                        this.checked = value
                        this.name = props.design.header.id
                        this.disabled = props.design.disabled
                        this.onChange = ::onChange
                    }
                DesignSystem.BooleanStyle.SWITCH ->
                    Switch {
                        this.checked = value
                        this.name = props.design.header.id
                        this.disabled = props.design.disabled
                        this.onChange = ::onChange
                    }
            }

            FormLabel {
                htmlFor = props.design.header.id
                Typography {
                    variant = Support.typographyVariant(DesignSystem.SizeType.MD, DesignSystem.StyleType.REGULAR)
                    sx {
                        color = Color(Palette.Grey.dark)
                    }
                    +(props.design.label ?: "")
                }
            }
        }
    }
}

external interface RSelectInputProps : PropsWithClassName {
    var design: DesignSystem.SelectInput
    var onChange: (DesignSystem.Option) -> Unit
    var overrideValue: DesignSystem.Option?
}

val RSelectInput = FC<RSelectInputProps> { props ->
    fun onChange(event: ChangeEvent<HTMLElement>, node: ReactNode) {
        val value = event.target.asDynamic().value
        val selected = props.design.options.first { it.value == value }
        props.onChange(selected)
    }

    if (props.design.visible) {
        FormControl {
            fullWidth = true
            if (props.design.label != null) {
                FormLabel {
                    sx {
                        marginBottom = 0.5.rem
                        fontWeight = 600.unsafeCast<FontWeight>()
                    }
                    htmlFor = props.design.header.id
                    +props.design.label!!
                }
            }

            val value = props.overrideValue?.value ?: props.design.selected?.value ?: ""

            Select {
                this.name = props.design.header.id
                this.disabled = props.design.disabled
                this.onChange = ::onChange
                this.value = value
                this.fullWidth = true
                this.className = props.className
                sx {
                    ".MuiSelect-outlined" {
                        padding = Padding(0.375.rem, 0.75.rem)
                        backgroundColor = Color(Palette.Grey.light)
                    }
                    ".MuiOutlinedInput-notchedOutline" {
                        border = important(Border(1.px, LineStyle.solid, rgb(0, 0, 0, 0.23)))
                    }
                }

                props.design.options.map { option ->
                    MenuItem {
                        this.value = option.value

                        Typography {
                            variant = Support.typographyVariant(DesignSystem.SizeType.MD, DesignSystem.StyleType.REGULAR)
                            +option.title
                        }
                    }
                }
            }

            if (props.design.status.message?.isNotEmpty() == true)
                props.design.status.message?.let {
                    FormLabel {
                        sx {
                            fontSize = 14.px
                            marginTop = 0.25.rem
                            color = important(Color(Palette.Danger.dark))
                        }
                        error = true
                        htmlFor = props.design.header.id
                        +it
                    }
                }
        }
    }
}

external interface RMultipleSelectInputProps : Props {
    var design: DesignSystem.MultipleSelectInput
    var onChange: (List<DesignSystem.Option>) -> Unit
}

val RMultipleSelectInput = FC<RMultipleSelectInputProps> { props ->
    fun onChange(event: ChangeEvent<HTMLElement>, node: ReactNode) {
        val values = event.target.asDynamic().value as Array<String>
        val selected = props.design.options.filter { values.contains(it.value) }
        props.onChange(selected)
    }

    if (props.design.visible) {
        FormControl {
            fullWidth = true
            if (props.design.label != null) {
                FormLabel {
                    sx {
                        marginBottom = 0.5.rem
                        fontWeight = 600.unsafeCast<FontWeight>()
                    }
                    htmlFor = props.design.header.id
                    +props.design.label!!
                }
            }

            val value = props.design.selected?.map { it.value }?.toTypedArray() ?: arrayOf()

            Select {
                this.name = props.design.header.id
                this.disabled = props.design.disabled
                this.onChange = ::onChange
                this.value = value
                this.fullWidth = true
                this.multiple = true
                sx {
                    ".MuiSelect-outlined" {
                        padding = Padding(0.375.rem, 0.75.rem)
                        backgroundColor = Color(Palette.Grey.light)
                    }
                    ".MuiOutlinedInput-notchedOutline" {
                        border = important(Border(1.px, LineStyle.solid, rgb(0, 0, 0, 0.23)))
                    }
                }

                props.design.options.map { option ->
                    MenuItem {
                        this.value = option.value

                        +option.title
                    }
                }
            }

            if (props.design.status.message?.isNotEmpty() == true)
                props.design.status.message?.let {
                    FormLabel {
                        sx {
                            fontSize = 14.px
                            marginTop = 0.25.rem
                            color = important(Color(Palette.Danger.dark))
                        }
                        error = true
                        htmlFor = props.design.header.id
                        +it
                    }
                }
        }
    }
}

external interface RFileInputProps : Props {
    var design: DesignSystem.FileInput
    var onChange: (Asset.Create) -> Unit
}

val RFileInput = FC<RFileInputProps> { props ->
    val inputRef = useRef<HTMLInputElement>(null)
    var fileName by useState("Välj bildfil...")

    fun onChange(event: ChangeEvent<HTMLInputElement>) {
        val file = event.target.files?.item(0)
        if (file != null) {
            val reader = FileReader()
            reader.readAsArrayBuffer(file)
            reader.onload = {
                val data = InputProvider { ByteReadPacket(reader.result.unsafeCast<ArrayBuffer>().toByteArray()) }
                val asset = Asset.Create(name = file.name, data = data, contentType = file.type)
                fileName = file.name
                props.onChange(asset)
            }
        }
    }

    input {
        type = InputType.file
        hidden = true
        onChange = ::onChange
        ref = inputRef
    }

    FormControl {
        fullWidth = true
        if (props.design.label != null) {
            FormLabel {
                sx {
                    marginBottom = 0.5.rem
                    fontWeight = 600.unsafeCast<FontWeight>()
                }
                htmlFor = props.design.header.id
                +props.design.label!!
            }
        }

        Stack {
            sx {
                justifyContent = JustifyContent.flexStart
                alignItems = AlignItems.center
                flexDirection = FlexDirection.row
                flexWrap = FlexWrap.nowrap
                width = 100.pct
                cursor = Cursor.default
                borderRadius = Shape.borderRadius
                border = Border(1.px, LineStyle.solid, Color("#CED4DA"))
            }
            onClick = {
                inputRef.current?.click()
            }

            OutlinedInput {
                sx {
                    width = 100.pct
                    backgroundColor = Color(Palette.Grey.light)
                    borderBottomRightRadius = 0.px
                    borderTopRightRadius = 0.px
                    ".MuiOutlinedInput-input" {
                        padding = Padding(0.375.rem, 0.75.rem)
                    }
                    ".MuiOutlinedInput-notchedOutline" {
                        border = None.none
                    }
                    padding = Padding(0.5.px, 0.px)
                }
                this.fullWidth = true
                this.value = fileName
                this.name = props.design.header.id
                this.readOnly = true
                this.type = "text"
            }

            div {
                css {
                    backgroundColor = Color("#E9ECEF")
                    padding = Padding(0.375.rem, 0.75.rem)
                    color = Color("#495057")
                    borderBottomRightRadius = Shape.borderRadius
                    borderTopRightRadius = Shape.borderRadius
                    borderLeft = Border(1.px, LineStyle.solid, Color("#CED4DA"))
                }
                +"Bläddra"
            }
        }
    }

    RText {
        design = props.design.helpText
        css {
            marginTop = 0.25.rem
            fontSize = important(14.px)
        }
    }
}
