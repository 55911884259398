package components

import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import web.cssom.Overflow
import web.cssom.Position
import web.cssom.ZIndex
import web.cssom.px
import web.cssom.url

val RBackgroundImage = FC<Props> { props ->
    div {
        css {
            overflowX = Overflow.hidden
        }
        div {
            css {
                position = Position.absolute
                overflowX = Overflow.hidden
                right = 0.px
                top = 0.px
                zIndex = (-1).unsafeCast<ZIndex>()
                height = 678.px
                width = 464.px
                background = url("img/org_shape2.svg")
            }
        }
        div {
            css {
                position = Position.absolute
                overflowX = Overflow.hidden
                left = (-20).px
                bottom = 0.px
                zIndex = (-3).unsafeCast<ZIndex>()
                height = 488.px
                width = 182.px
                background = url("img/org_shape3.svg")
            }
        }
    }
}
