package support

val DesignSystem.Header.Companion.preview
    get() =
        DesignSystem.Header(id = "Header1")

val DesignSystem.Option.Companion.previewOption
    get() =
        DesignSystem.Option(title = "Option 1", value = "Value 1")

val DesignSystem.Option.Companion.previewOptions
    get() =
        listOf(
            DesignSystem.Option(title = "Option 1", value = "Value 1"),
            DesignSystem.Option(title = "Option 2", value = "Value 2"),
            DesignSystem.Option(title = "Option 3", value = "Value 3"),
        )

val DesignSystem.Text.Companion.previewXL2Bold
    get() =
        DesignSystem.Text(text = "XL2, Bold", size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.BOLD)

val DesignSystem.Text.Companion.previewXLBold
    get() =
        DesignSystem.Text(text = "XL, Bold", size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD)

val DesignSystem.Text.Companion.previewLGBold
    get() =
        DesignSystem.Text(text = "LG, Bold", size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD)

val DesignSystem.Text.Companion.previewMDRegular
    get() =
        DesignSystem.Text(text = "MD, Regular´", size = DesignSystem.SizeType.MD)

val DesignSystem.Text.Companion.previewMDBold
    get() =
        DesignSystem.Text(text = "MD, Bold", size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD)

val DesignSystem.Text.Companion.previewSMRegular
    get() =
        DesignSystem.Text(text = "SM, Regular", size = DesignSystem.SizeType.SM)

val DesignSystem.Text.Companion.previewSMBold
    get() =
        DesignSystem.Text(text = "SM, Bold", size = DesignSystem.SizeType.SM, style = DesignSystem.StyleType.BOLD)

val DesignSystem.Text.Companion.previewXSRegular
    get() =
        DesignSystem.Text(text = "XS, Regular", size = DesignSystem.SizeType.XS)

val DesignSystem.Text.Companion.previewXSBold
    get() =
        DesignSystem.Text(text = "XS, Bold", size = DesignSystem.SizeType.XS, style = DesignSystem.StyleType.BOLD)

val DesignSystem.Text.Companion.previewXS2Regular
    get() =
        DesignSystem.Text(text = "XS2, Regular", size = DesignSystem.SizeType.XS2)

val DesignSystem.Text.Companion.previewXS2Bold
    get() =
        DesignSystem.Text(text = "XS2, Bold", size = DesignSystem.SizeType.XS2, style = DesignSystem.StyleType.BOLD)

val DesignSystem.PalettePreview.Companion.previewPrimary
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.PRIMARY)

val DesignSystem.PalettePreview.Companion.previewSecondary
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.SECONDARY)

val DesignSystem.PalettePreview.Companion.previewSuccess
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.SUCCESS)

val DesignSystem.PalettePreview.Companion.previewDanger
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.DANGER)

val DesignSystem.PalettePreview.Companion.previewWarning
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.WARNING)

val DesignSystem.PalettePreview.Companion.previewInfo
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.INFO)

val DesignSystem.PalettePreview.Companion.previewGrey
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.GREY)

val DesignSystem.PalettePreview.Companion.previewTransparent
    get() =
        DesignSystem.PalettePreview(palette = DesignSystem.PaletteType.TRANSPARENT)

val DesignSystem.Button.Companion.previewPrimary
    get() =
        DesignSystem.Button(text = "OK", style = DesignSystem.PaletteType.PRIMARY)

val DesignSystem.Button.Companion.previewPrimaryImage
    get() =
        DesignSystem.Button(text = "Logga in", image = DesignSystem.Image.BANKID, style = DesignSystem.PaletteType.PRIMARY)

val DesignSystem.Button.Companion.previewSecondary
    get() =
        DesignSystem.Button(text = "Secondary", style = DesignSystem.PaletteType.SECONDARY)

val DesignSystem.Button.Companion.previewPrimaryDanger
    get() =
        DesignSystem.Button(text = "Caution", danger = true, style = DesignSystem.PaletteType.PRIMARY)

val DesignSystem.Button.Companion.previewTransparent
    get() =
        DesignSystem.Button(text = "Transparent", style = DesignSystem.PaletteType.TRANSPARENT)

val DesignSystem.Button.Companion.previewTransparentDanger
    get() =
        DesignSystem.Button(text = "Transparent Danger", danger = true, style = DesignSystem.PaletteType.TRANSPARENT)

val DesignSystem.Button.Companion.previewGrey
    get() =
        DesignSystem.Button(text = "Grey", image = DesignSystem.Image.EDIT, style = DesignSystem.PaletteType.GREY)

val DesignSystem.Button.Companion.previewPrimaryMenu
    get() =
        DesignSystem.Button(text = "Primary Menu", style = DesignSystem.PaletteType.PRIMARY, menu = DesignSystem.Option.previewOptions)

val DesignSystem.Button.Companion.previewGreyMenu
    get() =
        DesignSystem.Button(text = "Grey Menu", image = DesignSystem.Image.EDIT, style = DesignSystem.PaletteType.GREY, menu = DesignSystem.Option.previewOptions)

val DesignSystem.TextInput.Companion.previewNoLabel
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = null, type = DesignSystem.TextInputType.TEXT)

val DesignSystem.TextInput.Companion.previewLabel
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "Name", type = DesignSystem.TextInputType.TEXT)

val DesignSystem.TextInput.Companion.previewPlaceholder
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "Name", placeholder = "Enter text here", type = DesignSystem.TextInputType.TEXT)

val DesignSystem.TextInput.Companion.previewInvalid
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "Name", type = DesignSystem.TextInputType.TEXT, status = Status.Invalid("Bad name"))

val DesignSystem.TextInput.Companion.previewValid
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "Name", type = DesignSystem.TextInputType.TEXT, status = Status.Valid)

val DesignSystem.TextInput.Companion.previewValue
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "color", value = "Blue", type = DesignSystem.TextInputType.TEXT)

val DesignSystem.TextInput.Companion.previewInsetText
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "color", insetText = "Blue", type = DesignSystem.TextInputType.TEXT)

val DesignSystem.TextInput.Companion.previewDisabled
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "Not available", disabled = true, type = DesignSystem.TextInputType.TEXT)

val DesignSystem.TextInput.Companion.previewNovel
    get() =
        DesignSystem.TextInput(header = DesignSystem.Header.preview, label = "Description", type = DesignSystem.TextInputType.NOVEL)

val DesignSystem.MultiLineInput.Companion.preview
    get() =
        DesignSystem.MultiLineInput(header = DesignSystem.Header.preview, label = "Description", placeholder = "Enter text here")

val DesignSystem.BooleanInput.Companion.previewNoLabel
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = null)

val DesignSystem.BooleanInput.Companion.previewOff
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active")

val DesignSystem.BooleanInput.Companion.previewOn
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active", value = true)

val DesignSystem.BooleanInput.Companion.previewDisabled
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active", value = true, disabled = true)

val DesignSystem.BooleanInput.Companion.previewInvalid
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active", status = Status.Invalid("Must be checked"))

val DesignSystem.BooleanInput.Companion.previewValid
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active", status = Status.Valid)

val DesignSystem.BooleanInput.Companion.switchOff
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active", style = DesignSystem.BooleanStyle.SWITCH)

val DesignSystem.BooleanInput.Companion.switchOn
    get() =
        DesignSystem.BooleanInput(header = DesignSystem.Header.preview, label = "Active", value = true, style = DesignSystem.BooleanStyle.SWITCH)

val DesignSystem.SelectInput.Companion.previewNoLabel
    get() =
        DesignSystem.SelectInput(header = DesignSystem.Header.preview, label = null, options = DesignSystem.Option.previewOptions)

val DesignSystem.SelectInput.Companion.previewOff
    get() =
        DesignSystem.SelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions)

val DesignSystem.SelectInput.Companion.previewOn
    get() =
        DesignSystem.SelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, selected = DesignSystem.Option.previewOption)

val DesignSystem.SelectInput.Companion.previewDisabled
    get() =
        DesignSystem.SelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, selected = DesignSystem.Option.previewOption, disabled = true)

val DesignSystem.SelectInput.Companion.previewInvalid
    get() =
        DesignSystem.SelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, status = Status.Invalid("Must be selected"))

val DesignSystem.SelectInput.Companion.previewValid
    get() =
        DesignSystem.SelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, status = Status.Valid)

val DesignSystem.MultipleSelectInput.Companion.previewNoLabel
    get() =
        DesignSystem.MultipleSelectInput(header = DesignSystem.Header.preview, label = null, options = DesignSystem.Option.previewOptions)

val DesignSystem.MultipleSelectInput.Companion.previewOff
    get() =
        DesignSystem.MultipleSelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions)

val DesignSystem.MultipleSelectInput.Companion.previewOn
    get() =
        DesignSystem.MultipleSelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, selected = DesignSystem.Option.previewOptions)

val DesignSystem.MultipleSelectInput.Companion.previewDisabled
    get() =
        DesignSystem.MultipleSelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, selected = DesignSystem.Option.previewOptions, disabled = true)

val DesignSystem.MultipleSelectInput.Companion.previewInvalid
    get() =
        DesignSystem.MultipleSelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, status = Status.Invalid("Must be selected"))

val DesignSystem.MultipleSelectInput.Companion.previewValid
    get() =
        DesignSystem.MultipleSelectInput(header = DesignSystem.Header.preview, label = "Active", options = DesignSystem.Option.previewOptions, status = Status.Valid)

val DesignSystem.FileInput.Companion.previewNoLabel
    get() =
        DesignSystem.FileInput(header = DesignSystem.Header.preview, label = null, helpText = DesignSystem.Text.previewSMRegular)

val DesignSystem.FileInput.Companion.previewOff
    get() =
        DesignSystem.FileInput(header = DesignSystem.Header.preview, label = "Välj en fil", helpText = DesignSystem.Text.previewSMRegular)

val DesignSystem.FileInput.Companion.previewOn
    get() =
        DesignSystem.FileInput(header = DesignSystem.Header.preview, label = "Välj en fil", helpText = DesignSystem.Text.previewSMRegular)

val DesignSystem.FileInput.Companion.previewDisabled
    get() =
        DesignSystem.FileInput(header = DesignSystem.Header.preview, label = "Välj en fil", helpText = DesignSystem.Text.previewSMRegular, disabled = true)

val DesignSystem.FileInput.Companion.previewInvalid
    get() =
        DesignSystem.FileInput(header = DesignSystem.Header.preview, label = "Välj en fil", helpText = DesignSystem.Text.previewSMRegular, status = Status.Invalid("File needs to be provided"))

val DesignSystem.FileInput.Companion.previewValid
    get() =
        DesignSystem.FileInput(header = DesignSystem.Header.preview, label = "Välj en fil", helpText = DesignSystem.Text.previewSMRegular, status = Status.Valid)

val DesignSystem.Toast.Companion.previewSuccess
    get() =
        DesignSystem.Toast(message = "Yo, waz up", style = DesignSystem.ToastStyle.SUCCESS)

val DesignSystem.Toast.Companion.previewError
    get() =
        DesignSystem.Toast(message = "Yo, waz up", style = DesignSystem.ToastStyle.ERROR)

val DesignSystem.Chip.Companion.preview
    get() =
        DesignSystem.Chip(message = DesignSystem.Text("Yo, waz up"))

val DesignSystem.Chip.Companion.previewWarning
    get() =
        DesignSystem.Chip(message = DesignSystem.Text("Lookout!"), style = DesignSystem.PaletteType.WARNING)

val DesignSystem.Chip.Companion.previewSuccess
    get() =
        DesignSystem.Chip(message = DesignSystem.Text("Yes!", size = DesignSystem.SizeType.MD), style = DesignSystem.PaletteType.SUCCESS)

val DesignSystem.Chip.Companion.previewInfo
    get() =
        DesignSystem.Chip(message = DesignSystem.Text("By the way", size = DesignSystem.SizeType.XS), style = DesignSystem.PaletteType.INFO)

val DesignSystem.Link.Companion.preview
    get() =
        DesignSystem.Link(text = "Linking to Noboto", href = "https://noboto.io")

val DesignSystem.SideBarItem.Companion.preview
    get() =
        DesignSystem.SideBarItem(href = "", iconSrc = "/img/ico_hub_b.svg", text = "Hubben")

val DesignSystem.SideBarItem.Companion.previewPro
    get() =
        DesignSystem.SideBarItem(href = "", iconSrc = "/img/ico_hub_b.svg", text = "Hubben", pro = true)

val DesignSystem.Confirmation.Companion.preview
    get() =
        DesignSystem.Confirmation(animation = DesignSystem.Animation.CONFETTI, title = "Title", body = "Body", next = "Next")

val DesignSystem.ImageView.Companion.previews
    get() =
        DesignSystem.Image.values().map {
            DesignSystem.ImageView(image = it)
        }
