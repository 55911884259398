package components.items

import components.RButton
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import support.DesignSystem
import techla.base.Identifier
import web.cssom.*


external interface ActivityItemButtonsProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.Buttons
    var onClick: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemButtons = FC<ActivityItemButtonsProps> { props ->
    fun onClick(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onClick(id, action)
    }

    if (props.viewModel.navigation.isVisible) {
        Stack {
            direction = responsive(StackDirection.column)
            sx {
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
            }
            RButton { design = props.viewModel.add; onClick = { onClick(props.viewModel.id, it) } }
        }

        Stack {
            direction = responsive(StackDirection.row)
            spacing = responsive(1.rem)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.flexStart
            }
            RButton { design = props.viewModel.back; onClick = { onClick(props.viewModel.id, it) } }
            RButton { design = props.viewModel.prev; onClick = { onClick(props.viewModel.id, it) } }
            RButton { design = props.viewModel.next; onClick = { onClick(props.viewModel.id, it) } }
            RButton { design = props.viewModel.submit; onClick = { onClick(props.viewModel.id, it) } }
            RButton { design = props.viewModel.edit; onClick = { onClick(props.viewModel.id, it) } }
        }
    }
}