package support

import js.objects.jso
import kotlinx.browser.localStorage
import kotlinx.coroutines.*
import react.*
import techla.base.techla_log
import kotlin.reflect.KProperty

data class JobDelegate(
    private val requestCoroutineScopeFromState: () -> CoroutineScope
) {
    operator fun getValue(nothing: Nothing?, property: KProperty<*>): CoroutineScope =
        requestCoroutineScopeFromState()
}

fun useReactScope(): JobDelegate {
    var reactJob: Job? by useState(null)

    useEffectOnce {
        techla_log("REACT SCOPE: CREATE")
        reactJob = SupervisorJob()
        cleanup {
            techla_log("REACT SCOPE: CANCEL")
            reactJob?.cancel()
            reactJob = null
        }
    }

    return JobDelegate {
        if (reactJob != null)
            CoroutineScope(reactJob!! + Dispatchers.Main.immediate)
        else {
            techla_log("REACT SCOPE: Job is null, falling back on MainScope...")
            MainScope()
        }
    }
}

class Executor<T>(
    val coroutineScope: CoroutineScope,
    val dispatch: (Scene.Output<T>) -> Unit,
) {
    fun call(call: suspend () -> Scene.Output<T>) {
        coroutineScope.launch {
            dispatch(call())
        }
    }

    fun call(call: suspend () -> Scene.Output<T>, effect: (T) -> Unit) {
        coroutineScope.launch {
            val scene = call()
            dispatch(scene)
            effect(scene.viewModel)
        }
    }
}

data class ExecutorDelegate<T>(
    private val requestExecutor: () -> Executor<T>
) {
    operator fun getValue(nothing: Nothing?, property: KProperty<*>): Executor<T> =
        requestExecutor()
}

fun <T> useExecutor(dispatch: (Scene.Output<T>) -> Unit): ExecutorDelegate<T> {
    val reactScope by useReactScope()
    return ExecutorDelegate { Executor(coroutineScope = reactScope, dispatch = dispatch) }
}

external interface CreateOrDetailViewProps : Props {
    var createView: Boolean
}

fun <P : CreateOrDetailViewProps> ElementType<P>.create(createView: Boolean): ReactElement<P> =
    createElement(this, props = jso { this.createView = createView })

fun useAsyncEffect(vararg dependencies: dynamic, effect: suspend (coroutineScope: CoroutineScope) -> Unit) {
    useEffect(dependencies) {
        val job = MainScope().launch {
            effect(this)
        }
        cleanup {
            job.cancel()
        }
    }
}

/**
 * A simple wrapper around localStorage and useState that allows you to store and retrieve values
 * from localStorage as Kotlin properties.
 *
 * @param T The type of the property.
 * @param key The key of the property.
 * @param initialValue The default value of the property.
 */
fun <T> useLocalStorage(key: String, initialValue: T): LocalStorageStateInstance<T> {
    val readValue = useCallback(key, initialValue) {
        val item = localStorage.getItem(key)
        if (item == null) {
            initialValue
        } else {
            JSON.parse(item)
        }
    }

    var storedValue by useState(readValue)

    fun setValue(newValue: T) {
        localStorage.setItem(key, JSON.stringify(newValue))
        storedValue = newValue
    }

    useEffectOnce {
        storedValue = readValue()
    }

    return LocalStorageStateInstance(storedValue to { newValue -> setValue(newValue) })
}

/**
 * A simple wrapper around localStorage and useState that allows you to store and retrieve values
 * from localStorage as Kotlin properties.
 *
 * @param T The type of the property.
 * @param key The key of the property.
 * @param initialValue A function that returns the default value of the property.
 */
fun <T> useLocalStorage(key: String, initialValue: () -> T): LocalStorageStateInstance<T> {
    return useLocalStorage(key, initialValue())
}

class LocalStorageStateInstance<T>(val state: Pair<T, (T) -> Unit>) {
    inline operator fun component1(): T = state.first
    inline operator fun component2(): (T) -> Unit = state.second

    inline operator fun getValue(
        thisRef: Nothing?,
        property: KProperty<*>,
    ): T = state.first

    inline operator fun setValue(
        thisRef: Nothing?,
        property: KProperty<*>,
        value: T,
    ) {
        state.second(value)
    }
}