package services

import support.*
import techla.base.*
import techla.conversation.*
import techla.guard.Profile


// Feeds
suspend fun Store.listFeeds(
    styles: List<Feed.Style>? = null, keys: List<Key<Feed>>? = null
): ActionOutcome<List<Feed>> {
    if (demoMode) return Demo.allFeeds(keys).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListFeeds(styles, keys), api) {
            api.listFeeds(styles, keys).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listFeeds(
    index: PageIndex, styles: List<Feed.Style>?
): ActionOutcome<PageContent<Feed>> {
    if (demoMode) return Demo.allFeedsPage(index).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListFeedsWithPaging(index, styles), api) {
            api.listFeeds(index, styles).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.getFeed(id: Identifier<Feed>): ActionOutcome<Feed> {
    if (demoMode) return Demo.matchFeed(id).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.GetFeed(id), api) {
            api.getFeed(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editFeed(id: Identifier<Feed>, edit: Feed.Edit): ActionOutcome<Feed> {
    if (demoMode) return Demo.matchFeed(id).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.EditFeed(id, edit), api) {
            api.editFeed(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createFeed(create: Feed.Create): ActionOutcome<Feed> {
    if (demoMode) return Demo.currentFeed.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.CreateFeed(create), api) {
            api.createFeed(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteFeed(id: Identifier<Feed>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.DeleteFeed(id), api) {
            api.deleteFeed(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createFeedInfoList(
    feedList: List<Feed>?, readFeedsList: List<Feed>? = null, crossList: List<Cross>? = null
): List<FeedInfo>? {
    return feedList?.map {
        val standard = it.style is Feed.Style.Standard
        val read = when {
            readFeedsList != null -> readFeedsList.firstOrNull { feed -> feed.id.rawValue == it.id.rawValue } != null
            standard -> true
            else -> false
        }
        val write = when {
            standard -> true
            else -> crossList?.firstOrNull { cross -> cross.role == Cross.Role.Contributor }?.feeds?.firstOrNull { feed -> feed.id.rawValue == it.id.rawValue } != null
        }
        FeedInfo(id = it.id.rawValue,
            key = it.key.rawValue,
            read = read,
            write = write,
            delete = crossList?.firstOrNull { cross -> cross.role == Cross.Role.Moderator }?.feeds?.firstOrNull { feed -> feed.id.rawValue == it.id.rawValue } != null,
            standard = standard)
    }
}


// Messages
suspend fun Store.listMessages(feed: Key<Feed>): ActionOutcome<List<Message>> {
    if (demoMode) return Demo.allMessages.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListMessages(feed = feed, feedId = null), api) {
            api.listMessages(Either.Right(feed)).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listMessages(feed: Either<Identifier<Feed>, Key<Feed>>, index: PageIndex): ActionOutcome<PageContent<Message>> {
    if (demoMode) return Demo.allMessagesPage(index).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListMessagesWithPaging(feedId = feed.leftOrNull(), feed = feed.rightOrNull(), index = index, null, null, null, true), api) {
            api.listMessages(feed, index).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listMessages(feed: Feed?, index: PageIndex): ActionOutcome<PageContent<Message>> =
    if (feed == null)
        successfulOf(PageContent.empty<Message>()).noActions()
    else
        listMessages(leftOf(feed.id), index)


suspend fun Store.getMessageOrNull(id: Identifier<Message>): ActionOutcome<Message?> {
    if (demoMode) return Demo.matchMessage(id).noActions().map { it }
    val (actions, message) = getMessage(id).outputOrNull() ?: (emptyList<Store.Action>() to null)
    return successfulOf(actions to message)
}

suspend fun Store.getMessage(id: Identifier<Message>): ActionOutcome<Message> {
    if (demoMode) return Demo.matchMessage(id).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.GetMessage(id), api) {
            api.getMessage(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editMessage(id: Identifier<Message>, edit: Message.Edit): ActionOutcome<Message> {
    if (demoMode) return Demo.matchMessage(id).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.EditMessage(id, edit), api) {
            api.editMessage(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.swapMessageOrder(id1: Identifier<Message>, id2: Identifier<Message>, order1: Int, order2: Int): ActionOutcome<Pair<Message, Message>> {
    if (demoMode) return Demo.matchMessage(id1).flatMap { m1 -> Demo.matchMessage(id2).map { m2 -> m1 to m2 } }.noActions()
    return editMessage(
        id = id1,
        edit = Message.Edit(display = modifiedOf(Message.Display.Fixed(order1)))
    ).flatMap { (actions, message1) ->
        reduce(actions).editMessage(
            id = id2,
            edit = Message.Edit(display = modifiedOf(Message.Display.Fixed(order2)))
        )
            .accumulate(actions)
            .map { tupleOf(it.first, message1 to it.second) }
    }
}

suspend fun Store.createMessage(create: Message.Create): ActionOutcome<Message> {
    if (demoMode) return Demo.allMessages.map { it.first() }.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.CreateMessage(create), api) {
            api.createMessage(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteMessage(id: Identifier<Message>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.DeleteMessage(id), api) {
            api.deleteMessage(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.flagMessage(id: Identifier<Message>, flags: Message.Flags): ActionOutcome<Message> {
    if (demoMode) return Demo.matchMessage(id).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.Flag(id, flags), api) {
            api.flag(id, flags).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

// Mixes
suspend fun Store.listMixes(profileId: Identifier<Profile>): ActionOutcome<List<Mix>> {
    if (demoMode) return Demo.matchMixes(profileId).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListMixesWithProfile(profileId), api) {
            api.listMixes(profileId).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editMix(id: Identifier<Mix>, edit: Mix.Edit): ActionOutcome<Mix> {
    if (demoMode) return Demo.currentMix.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.EditMix(id, edit), api) {
            api.editMix(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createMix(create: Mix.Create): ActionOutcome<Mix> {
    if (demoMode) return Demo.currentMix.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.CreateMix(create), api) {
            api.createMix(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteMix(id: Identifier<Mix>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.DeleteMix(id), api) {
            api.deleteMix(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.addEditDeleteMix(
    profileId: Identifier<Profile>, mixId: Identifier<Mix>?, edit: Mix.Edit
): ActionOutcome<Unit> {
    return when {
        mixId != null && edit.feeds.getOrNull().isNullOrEmpty() -> deleteMix(mixId)
        mixId == null && !edit.feeds.getOrNull().isNullOrEmpty() -> {
            val create = Mix.Create(
                destination = Mix.Destination.TargetProfile(profileId), feeds = edit.feeds.getOrNull() ?: emptyList()
            )
            createMix(create).map {}.noActions()
        }

        mixId != null -> editMix(id = mixId, edit = edit).map {}.noActions()
        else -> successfulOf(Unit).noActions()
    }
}


// Crosses
suspend fun Store.listCrosses(profileId: Identifier<Profile>): ActionOutcome<List<Cross>> {
    if (demoMode) return Demo.allCrosses.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListCrossesWithProfile(profileId), api) {
            api.listCrosses(profileId).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editCross(id: Identifier<Cross>, edit: Cross.Edit): ActionOutcome<Cross> {
    if (demoMode) return Demo.matchCross(id).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.EditCross(id, edit), api) {
            api.editCross(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createCross(create: Cross.Create): ActionOutcome<Cross> {
    if (demoMode) return Demo.currentCross.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.CreateCross(create), api) {
            api.createCross(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteCross(id: Identifier<Cross>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.DeleteCross(id), api) {
            api.deleteCross(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.addEditDeleteCross(
    profileId: Identifier<Profile>, crossId: Identifier<Cross>?, edit: Cross.Edit
): ActionOutcome<Unit> {
    return when {
        crossId != null && edit.feeds.getOrNull().isNullOrEmpty() -> deleteCross(crossId)
        crossId == null && !edit.feeds.getOrNull().isNullOrEmpty() -> {
            val create = Cross.Create(
                profileId = profileId,
                role = edit.role.getOrNull() ?: Cross.Role.Contributor,
                feeds = edit.feeds.getOrNull() ?: emptyList()
            )
            createCross(create).map {}.noActions()
        }

        crossId != null -> editCross(id = crossId, edit = edit).map {}.noActions()
        else -> successfulOf(Unit).noActions()
    }
}

// Emitters
suspend fun Store.listEmitters(): ActionOutcome<List<Emitter>> {
    if (demoMode) return Demo.allEmitters.noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.ListEmitters, api) {
            api.listEmitters().onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createEmitter(create: Emitter.Create): ActionOutcome<Emitter> {
    if (demoMode) return Demo.createEmitter(create).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.CreateEmitter(create), api) {
            api.createEmitter(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteEmitter(id: Identifier<Emitter>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return conversationAPI { api ->
        measureAPI(ConversationAPIResource.DeleteEmitter(id), api) {
            api.deleteEmitter(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun <T> Store.conversationAPI(block: suspend (api: ConversationAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = ConversationAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ConversationAPI.sandbox else ConversationAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}