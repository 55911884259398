package screens

import services.*
import support.*
import techla.base.*
import techla.billing.Buyer
import techla.conversation.Feed
import techla.guard.Token
import techla.guard.UserAuthentication
import techla.guard.Workspace
import kotlin.time.ExperimentalTime

@ExperimentalTime
object LoginScreen {
    object Header {
        val crew = DesignSystem.Header(id = "crew")
        val phone = DesignSystem.Header(id = "phone")
        val code = DesignSystem.Header(id = "code")
    }

    data class Texts(
        val loginTitle: String,
        val prefix: String,
        val loginSubtitle: String,
        val crew: String,
        val phone: String,
        val code: String,
        val login: String,
        val verifyTitle: String,
        val verifySubtitle: String,
        val verify: String,
        val crewRequired: String,
        val phoneFormat: String,
        val phoneRequired: String,
        val codeLength: String,
        val codeFormat: String,
        val codeRequired: String,
        val expired: String,
        val bankId: String,
        val cancel: String,
        val crewInsetText: String,
        val demoTitle: String,
        val demoBody: String,
        val demoBase: String,
        val demoPro: String,
        val bankIdTitle: String,
        val expiredTitle: String,
        val expiredBody: String,
        val expiredReload: String,
        val expireOut: String,
    )

    data class State(
        val crew: String? = null,
        val phone: String? = null,
        val code: String? = null,
        val version: String = "",
        val workspaces: List<Workspace> = emptyList(),
    )

    data class ReadyParams(
        val isBankID: Boolean,
        val isSMS: Boolean,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val params: ReadyParams,
            val logo: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val crew: DesignSystem.TextInput,
            val phone: DesignSystem.TextInput,
            val loginBankID: DesignSystem.Button,
            val loginSMS: DesignSystem.Button,
            val demoBase: DesignSystem.Button,
            val demoPro: DesignSystem.Button,
            val demoTitle: DesignSystem.Text,
            val demoBody: DesignSystem.Text,
            val version: DesignSystem.Text,
            val status: Status,
        ) : ViewModel(texts, state)

        data class Verify(
            override var texts: Texts,
            override var state: State,
            val readyParams: ReadyParams,
            val logo: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val code: DesignSystem.TextInput,
            val verify: DesignSystem.Button,
            val status: Status,
        ) : ViewModel(texts, state)

        data class QRCode(
            override var texts: Texts,
            override var state: State,
            val logo: DesignSystem.ImageView,
            val title: DesignSystem.Text,
            val cancel: DesignSystem.Button,
            val status: Status,
        ) : ViewModel(texts, state)

        data class Waiting(
            override var texts: Texts,
            override var state: State,
            val readyParams: ReadyParams,
            val bankId: DesignSystem.Text,
            val cancel: DesignSystem.Button,
        ) : ViewModel(texts, state)

        data class Finished(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Expired(
            override var texts: Texts,
            override var state: State,
            val resume: DesignSystem.Button = DesignSystem.Button(),
            val expireTitle: DesignSystem.Text,
            val expireBody: DesignSystem.Text,
            val expireOut: DesignSystem.Text,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State, params: ReadyParams, status: List<ValidationStatus> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                params = params,
                logo = DesignSystem.ImageView(image = DesignSystem.Image.MOODI),
                title = DesignSystem.Text(text = texts.loginTitle, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                body = DesignSystem.Text(text = texts.loginSubtitle, align = DesignSystem.TextAlign.CENTER, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.REGULAR),
                crew = DesignSystem.TextInput(
                    header = Header.crew,
                    label = null,
                    placeholder = texts.crew,
                    type = DesignSystem.TextInputType.CREW,
                    insetText = texts.crewInsetText,
                    value = state.crew ?: "",
                    status = status.statusOf(Header.crew),
                ),
                phone = DesignSystem.TextInput(
                    header = Header.phone,
                    label = null,
                    placeholder = texts.phone,
                    type = DesignSystem.TextInputType.PHONE,
                    value = state.phone ?: "",
                    visible = params.isSMS,
                    status = status.statusOf(Header.phone),
                ),
                loginBankID = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.login,
                    style = DesignSystem.PaletteType.PRIMARY,
                    image = DesignSystem.Image.BANKID,
                    fullWidth = true,
                    visible = params.isBankID,
                ),
                loginSMS = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.login,
                    style = DesignSystem.PaletteType.PRIMARY,
                    fullWidth = true,
                    visible = params.isSMS,
                ),
                demoBase = DesignSystem.Button(
                    type = DesignSystem.ButtonType.BUTTON,
                    text = texts.demoBase,
                    style = DesignSystem.PaletteType.PRIMARY,
                ),
                demoPro = DesignSystem.Button(
                    type = DesignSystem.ButtonType.BUTTON,
                    text = texts.demoPro,
                    style = DesignSystem.PaletteType.PRIMARY,
                ),
                demoTitle = DesignSystem.Text(
                    text = texts.demoTitle,
                    size = DesignSystem.SizeType.LG,
                    style = DesignSystem.StyleType.BOLD,
                    align = DesignSystem.TextAlign.CENTER,
                    background = DesignSystem.Background.DARK,
                ),
                demoBody = DesignSystem.Text(
                    text = texts.demoBody,
                    size = DesignSystem.SizeType.SM,
                    align = DesignSystem.TextAlign.CENTER,
                    background = DesignSystem.Background.DARK,
                ),
                version = DesignSystem.Text(
                    text = state.version,
                    size = DesignSystem.SizeType.XS,
                    align = DesignSystem.TextAlign.CENTER,
                ),
                status = status.overallStatus()
            )

        fun verify(state: State, readyParams: ReadyParams, status: List<ValidationStatus> = emptyList()): ViewModel =
            Verify(
                texts = texts,
                state = state,
                readyParams = readyParams,
                logo = DesignSystem.ImageView(image = DesignSystem.Image.MOODI),
                title = DesignSystem.Text(text = texts.verifyTitle, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                body = DesignSystem.Text(text = texts.verifySubtitle, align = DesignSystem.TextAlign.CENTER),
                code = DesignSystem.TextInput(
                    header = Header.code,
                    label = null,
                    placeholder = texts.code,
                    type = DesignSystem.TextInputType.OTP,
                    value = state.code ?: "",
                    status = status.statusOf(Header.code),
                ),
                verify = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.verify,
                    style = DesignSystem.PaletteType.PRIMARY,
                    fullWidth = true,
                ),
                status = status.overallStatus()
            )

        fun qRCode(state: State, status: List<ValidationStatus> = emptyList()): ViewModel =
            QRCode(
                texts = texts,
                state = state,
                logo = DesignSystem.ImageView(image = DesignSystem.Image.MOODI),
                title = DesignSystem.Text(text = texts.bankIdTitle, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                cancel = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.cancel,
                    style = DesignSystem.PaletteType.PRIMARY,
                    fullWidth = true,
                ),
                status = status.overallStatus()
            )

        fun waiting(readyParams: ReadyParams): ViewModel =
            Waiting(
                texts = texts,
                state = state,
                readyParams = readyParams,
                bankId = DesignSystem.Text(texts.bankId, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER, visible = readyParams.isBankID),
                cancel = DesignSystem.Button(type = DesignSystem.ButtonType.BUTTON, text = texts.cancel, style = DesignSystem.PaletteType.TRANSPARENT, fullWidth = true)
            )

        fun finished(): ViewModel = Finished(
            texts = texts,
            state = state,
        )

        fun expired(texts: Texts): ViewModel =
            Expired(
                texts = texts,
                state = state,
                expireTitle = DesignSystem.Text(text = texts.expiredTitle, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                expireBody = DesignSystem.Text(text = texts.expiredBody, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                expireOut = DesignSystem.Text(text = texts.expireOut, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD),
                resume = DesignSystem.Button(text = texts.expiredReload, type = DesignSystem.ButtonType.SUBMIT),
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        val asReady get() = this as? Ready
        val asVerify get() = this as? Verify
        val asQRCode get() = this as? QRCode
        val asWaiting get() = this as? Waiting
        val asExpired get() = this as? Expired
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            loginTitle = "Logga in",
            prefix = "moodi-",
            loginSubtitle = "Ange ditt crew-namn som du angav när du blev medlem",
            crew = "crew",
            phone = "Telefonnummer",
            code = "Kod",
            login = "Logga in",
            verifyTitle = "Ange kod",
            verifySubtitle = "Vi har skickat ett SMS med en verifieringskod",
            verify = "Nästa",
            crewRequired = "\uD83D\uDC6E Crew måste anges",
            phoneFormat = "\uD83D\uDC6E Telefonnummer får bara vara siffror och plus tecken",
            phoneRequired = "\uD83D\uDC6E Telefonnummer får inte vara tomt",
            codeFormat = "\uD83D\uDC6E Kod får bara vara siffror",
            codeRequired = "\uD83D\uDC6E Kod får inte vara tomt",
            codeLength = "\uD83D\uDC6E Kod måste vara 6 siffror",
            expired = "Din inloggning har gått ut och du behöver logga in på nytt.",
            bankId = "Öppna BankID",
            cancel = "Avbryt",
            crewInsetText = "moodi-",
            demoTitle = "De hemliga knapparna... \uD83D\uDD75️",
            demoBody = "Du har lyckats komma åt de hemliga knapparna för att se hur baksidan av Moodi kontrollcenter ser ut \uD83C\uDF89. Det du kommer att se är en demokund så var inte orolig, du kan klicka på för fullt.",
            demoBase = "Demo Bas \uD83D\uDD11",
            demoPro = "Demo Pro \uD83D\uDD11",
            bankIdTitle = "Scanna och signera med bankId",
            expiredTitle = "Tiden för inloggning har gått ut",
            expiredBody = "Ladda om sidan eller tryck på knappen nedan för att påbörja igen.",
            expiredReload = "Försök igen",
            expireOut = "\uD83D\uDCA9",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, savedCrew: String?, savedPhone: String?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.createApplicationAuthentication(deployment.primary)
            .map { auth ->
                Store.Action.ApplicationAuthenticationCompleted(
                    applicationToken = auth.tokens.filterIsInstance<Token.Application>().first().token,
                    workspace = deployment.primary,
                    workspaces = viewModel.state.workspaces,
                )
            }
            .flatMap { action ->
                store.reduce(action).listWorkspaces()
                    .map { tupleOf(action, it) }
            }
            .map { (action, workspaces) ->
                val state = viewModel.state.copy(
                    crew = savedCrew,
                    phone = savedPhone,
                    version = store.version,
                    workspaces = workspaces,
                )
                val readyParams = ReadyParams(isBankID = false, isSMS = false)
                sceneOf(viewModel.ready(state = state, params = readyParams), action)
            }.failed { scene.failed(result = it) }
    }

    fun setValues(scene: Scene.Input<ViewModel>, crew: String? = null, phone: String? = null, code: String? = null): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val state = viewModel.state.copy(
            crew = crew ?: viewModel.state.crew,
            phone = phone ?: viewModel.state.phone,
            code = code ?: viewModel.state.code,
        )
        return when (viewModel) {
            is ViewModel.Ready -> {
                val provider = state.crew?.let { crew ->
                    state.workspaces
                        .firstOrNull { it.name == deployment.workspaceName(crew) }
                        ?.providers
                        ?.firstOrNull()
                }
                val params = viewModel.params.copy(
                    isBankID = provider is UserAuthentication.Provider.BankID || provider is UserAuthentication.Provider.None,
                    isSMS = provider is UserAuthentication.Provider.SMSCode
                )
                sceneOf(viewModel.ready(state = state, params = params))
            }

            is ViewModel.Verify -> sceneOf(viewModel.verify(state = state, readyParams = viewModel.readyParams))
            else -> sceneOf(viewModel.failed("Unsupported ViewModel in setValues"))
        }
    }

    suspend fun checkQrCode(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.getUserAuthentication().map { auth ->
            when (auth.status) {
                is UserAuthentication.Status.Complete -> {
                    val completed =
                        Store.Action.UserAuthenticationCompleted(
                            tokens = auth.tokens,
                            profileId = auth.profileId!!,
                            demoMode = false
                        )
                    return successfulOf(Unit).noActions()
                        .accumulate(completed)
                        .flatMap { (actions, _) ->
                            store.reduce(actions).listBuyers()
                                .accumulate(actions)
                        }
                        .flatMap { (actions, buyers) ->
                            val buyer = buyers.firstOrNull()
                            if (buyer != null)
                                successfulOf(buyer).noActions()
                                    .accumulate(actions)
                            else
                                failedOf(TechlaError.PreconditionFailed("Workspace must contain one buyer"))
                        }
                        .flatMap { (actions, buyer) ->
                            store.reduce(actions).listFeeds()
                                .accumulate(actions)
                                .map { tupleOf(it.first, it.second, buyer) }
                        }
                        .flatMap { (actions, feeds, buyer) ->
                            val standard = feeds.firstOrNull { it.style is Feed.Style.Standard }
                            if (standard != null)
                                successfulOf(standard).noActions()
                                    .accumulate(actions)
                                    .map { tupleOf(it.first, it.second, buyer) }
                            else
                                failedOf(TechlaError.PreconditionFailed("Workspace must contain a standard feed"))
                        }
                        .map { (actions, standard, buyer) ->
                            val loadBuyer = Store.Action.LoadBuyer(buyer = buyer)
                            val loadStandard = Store.Action.LoadStandard(feed = standard)
                            sceneOf(viewModel.finished(), actions + loadBuyer + loadStandard)
                        }
                        .failed { scene.failed(result = it) }
                }

                is UserAuthentication.Status.Outstanding -> {
                    val action = Store.Action.UserAuthenticationStarted(
                        userAuthenticationId = auth.index.id,
                        tokens = auth.tokens
                    )
                    return sceneOf(viewModel.qRCode(viewModel.state), action)
                }

                is UserAuthentication.Status.Cancelled ->
                    sceneOf(viewModel.loading(viewModel.texts))

                is UserAuthentication.Status.Expired ->
                    return sceneOf(viewModel.expired(viewModel.texts))

                else ->
                    sceneOf(viewModel.failed(message = auth.reason ?: "Unknown Error"))
            }

        }.failed {
            if (it.rightOrNull() is TechlaError.ServiceUnavailable) {
                sceneOf(viewModel.expired(viewModel.texts))
            } else
                scene.failed(result = it)
        }
    }


    suspend fun qrCode(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val workspaceName = deployment.workspaceName(state.crew ?: "")
        val match = state.workspaces.firstOrNull { it.name == workspaceName }

        return if (match != null) {
            store.createApplicationAuthentication(workspace = match.key)
                .flatMap {
                    val action = Store.Action.ApplicationAuthenticationCompleted(
                        applicationToken = it.tokens.filterIsInstance<Token.Application>().first().token,
                        workspace = match.key,
                        workspaces = state.workspaces,
                    )
                    store.reduce(action).createUserAuthentication()
                        .noActions()
                        .accumulate(action)
                }.flatMap { (actions, auth) ->
                    val action = Store.Action.UserAuthenticationStarted(
                        userAuthenticationId = auth.index.id,
                        tokens = auth.tokens
                    )
                    successfulOf(tupleOf(actions + action, auth))
                }
                .map { (actions, userAuthentication) ->
                    val actions2 = actions + Store.Action.UserAuthenticationStarted(
                        userAuthenticationId = userAuthentication.index.id,
                        tokens = userAuthentication.tokens
                    )
                    val (auth2, error, warnings) = store.reduce(actions2)
                        .getUserAuthentication().onNotSuccess {
                            return sceneOf(viewModel.expired(viewModel.texts))
                        }
                    when (auth2?.status) {
                        is UserAuthentication.Status.Complete -> {
                            val updatedActions = actions2 +
                                    Store.Action.UserAuthenticationCompleted(
                                        tokens = userAuthentication.tokens,
                                        profileId = userAuthentication.profileId!!,
                                        demoMode = false
                                    )
                            val updatedStore = store.reduce(updatedActions)
                            return check(scene.copy(store = updatedStore))
                        }

                        is UserAuthentication.Status.Outstanding -> {
                            val action = Store.Action.UserAuthenticationStarted(
                                userAuthenticationId = auth2.index.id,
                                tokens = auth2.tokens
                            )
                            sceneOf(viewModel.qRCode(viewModel.state), actions2 + listOf(action))
                        }

                        is UserAuthentication.Status.Cancelled ->
                            sceneOf(viewModel.loading(viewModel.texts))

                        is UserAuthentication.Status.Expired ->
                            return sceneOf(viewModel.expired(viewModel.texts))

                        is UserAuthentication.Status.Failed ->
                            sceneOf(viewModel.failed(message = auth2.reason ?: "Unknown Error"))

                        else -> {
                            if (error is TechlaError.ServiceUnavailable)
                                sceneOf(viewModel.expired(viewModel.texts))
                            else if (warnings != null)
                                scene.failed(leftOf(warnings))
                            else if (error != null)
                                scene.failed(rightOf(error))
                            else
                                sceneOf(viewModel.failed(message = "Unknown Error"))
                        }
                    }
                    sceneOf(viewModel.qRCode(viewModel.state), actions)
                }.failed {
                    if (it.rightOrNull() is TechlaError.ServiceUnavailable) {
                        sceneOf(viewModel.expired(viewModel.texts))
                    } else
                        scene.failed(result = it)
                }
        } else {
            sceneOf(viewModel.failed("${state.crew} is not a valid Workspace"))
        }
    }


    fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status = mutableListOf<ValidationStatus>()
        var state = viewModel.state

        when (viewModel) {
            is ViewModel.Ready -> {
                Validator.text(
                    text = state.crew,
                    isEmpty = { status.add(Header.crew to Status.Invalid(warning = viewModel.texts.crewRequired)) },
                    passed = { status.add(Header.crew to Status.Valid) },
                    formatted = { state = state.copy(crew = it) },
                )
                if (viewModel.params.isSMS) {
                    Validator.phone(
                        phone = state.phone,
                        illegalCharacters = { status.add(Header.phone to Status.Invalid(warning = viewModel.texts.phoneFormat)) },
                        isEmpty = { status.add(Header.phone to Status.Invalid(warning = viewModel.texts.phoneRequired)) },
                        passed = { status.add(Header.phone to Status.Valid) },
                        formatted = { state = state.copy(phone = it) },
                    )
                }
            }

            is ViewModel.Verify ->
                Validator.verificationCode(
                    code = state.code,
                    illegalCharacters = { status.add(Header.code to Status.Invalid(viewModel.texts.codeFormat)) },
                    isEmpty = { status.add(Header.code to Status.Invalid(warning = viewModel.texts.codeRequired)) },
                    isWrongLength = { status.add(Header.code to Status.Invalid(viewModel.texts.codeLength)) },
                    passed = { status.add(Header.code to Status.Valid) },
                    formatted = { state = state.copy(code = it) },
                )

            else -> {}
        }
        return when (viewModel) {
            is ViewModel.Ready -> {
                if (viewModel.params.isBankID)
                    sceneOf(viewModel.qRCode(state = state, status = status))
                else
                    sceneOf(viewModel.ready(state = state, params = viewModel.params, status = status))
            }

            is ViewModel.Verify -> sceneOf(viewModel.verify(state = state, readyParams = viewModel.readyParams, status = status))
            else -> sceneOf(viewModel.failed("Unsupported ViewModel in validate"))
        }
    }

    suspend fun login(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state
        val phone = viewModel.state.phone?.filter { "+0123456789".contains(it) }

        viewModel as ViewModel.Ready

        val workspaceName = deployment.workspaceName(state.crew ?: "")
        val match = state.workspaces.firstOrNull { it.name == workspaceName }

        val provider = state.crew?.let { crew ->
            state.workspaces
                .firstOrNull { it.name == deployment.workspaceName(crew) }
                ?.providers
                ?.firstOrNull()
        }

        return if (provider is UserAuthentication.Provider.BankID && match != null)
            qrCode(scene)
        else
            if (match != null) {
                store.createApplicationAuthentication(workspace = match.key)
                    .flatMap {
                        val action = Store.Action.ApplicationAuthenticationCompleted(
                            applicationToken = it.tokens.filterIsInstance<Token.Application>().first().token,
                            workspace = match.key,
                            workspaces = state.workspaces,
                        )
                        store.reduce(action).createUserAuthentication(phone = phone)
                            .noActions()
                            .accumulate(action)
                    }.map { (actions, userAuthentication) ->
                        val action = Store.Action.UserAuthenticationStarted(userAuthenticationId = userAuthentication.index.id, tokens = userAuthentication.tokens)
                        sceneOf(viewModel.verify(state, viewModel.params), actions + action)
                    }.failed { scene.failed(result = it) }
            } else {
                sceneOf(viewModel.failed("${state.crew} is not a valid Workspace"))
            }
    }

    suspend fun verify(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val code = viewModel.state.code?.filter { "0123456789".contains(it) }

        viewModel as ViewModel.Verify

        return store.verifyUserAuthentication(code = code)
            .map { updated ->
                sceneOf(viewModel.waiting(viewModel.readyParams))
            }
            .failed { scene.failed(result = it) }
    }


    suspend fun check(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Waiting

        return store.getUserAuthentication().map { userAuthentication ->
            when (userAuthentication.status) {
                is UserAuthentication.Status.Complete -> {
                    val completed = Store.Action.UserAuthenticationCompleted(
                        tokens = userAuthentication.tokens, profileId = userAuthentication.profileId, demoMode = false
                    )
                    successfulOf(Unit).noActions()
                        .accumulate(completed)
                        .flatMap { (actions, _) ->
                            store.reduce(actions).listBuyers()
                                .accumulate(actions)
                        }
                        .flatMap { (actions, buyers) ->
                            val buyer = buyers.firstOrNull()
                            if (buyer != null)
                                successfulOf(buyer).noActions()
                                    .accumulate(actions)
                            else
                                failedOf(TechlaError.PreconditionFailed("Workspace must contain one buyer"))
                        }
                        .flatMap { (actions, buyer) ->
                            store.reduce(actions).listFeeds()
                                .accumulate(actions)
                                .map { tupleOf(it.first, it.second, buyer) }
                        }
                        .flatMap { (actions, feeds, buyer) ->
                            val standard = feeds.firstOrNull { it.style is Feed.Style.Standard }
                            if (standard != null)
                                successfulOf(standard).noActions()
                                    .accumulate(actions)
                                    .map { tupleOf(it.first, it.second, buyer) }
                            else
                                failedOf(TechlaError.PreconditionFailed("Workspace must contain a standard feed"))
                        }
                        .map { (actions, standard, buyer) ->
                            val loadBuyer = Store.Action.LoadBuyer(buyer = buyer)
                            val loadStandard = Store.Action.LoadStandard(feed = standard)
                            sceneOf(viewModel.finished(), actions + loadBuyer + loadStandard)
                        }
                        .failed { scene.failed(result = it) }
                }

                is UserAuthentication.Status.Outstanding -> sceneOf(viewModel.waiting(viewModel.readyParams))
                is UserAuthentication.Status.Cancelled -> sceneOf(viewModel.ready(state = viewModel.state, params = viewModel.readyParams))
                is UserAuthentication.Status.Expired -> sceneOf(viewModel.failed(viewModel.texts.expired))
                is UserAuthentication.Status.Failed, is UserAuthentication.Status.Verified -> sceneOf(
                    viewModel.failed(userAuthentication.reason ?: "Unknown reason")
                )
            }
        }.failed { scene.failed(result = it) }
    }

    suspend fun demo(scene: Scene.Input<ViewModel>, isPro: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val applicationAuthentication = Store.Action.ApplicationAuthenticationCompleted(
            applicationToken = "demo_token",
            workspace = Key(if (isPro) Demo.WORKSPACE_PRO else Demo.WORKSPACE_STANDARD),
            workspaces = viewModel.state.workspaces,
        )
        val userAuthentication = Store.Action.UserAuthenticationCompleted(
            tokens = Demo.demoCompletedUserAuthentication.outputOrNull()?.tokens ?: emptyList(),
            profileId = Demo.demoCompletedUserAuthentication.outputOrNull()?.profileId,
            demoMode = true
        )
        val actions = listOf(applicationAuthentication, userAuthentication)

        return store.reduce(actions).listBuyers().accumulate(actions).map { (actions, buyers) ->
            val tier = if (isPro) Buyer.Tier.Pro else Buyer.Tier.Standard
            val buyer = buyers.first().copy(tier = tier)
            val buyerAction = Store.Action.LoadBuyer(buyer = buyer)
            sceneOf(viewModel.finished(), actions + buyerAction)
        }.failed { scene.failed(result = it) }
    }

    suspend fun cancel(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return when (viewModel) {
            is ViewModel.Waiting -> store.deleteUserAuthentication().map {
                sceneOf(viewModel.ready(state = viewModel.state, params = viewModel.readyParams))
            }.failed { scene.failed(result = it) }

            else -> store.deleteUserAuthentication().map {
                sceneOf(viewModel.ready(state = viewModel.state, params = ReadyParams(isBankID = false, isSMS = false)))
            }.failed { scene.failed(result = it) }
        }
    }
}