package components

import emotion.react.css
import mui.material.Container
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.nav
import web.cssom.*


val RNavbar = FC<Props> { props ->
    Container {
        maxWidth = "lg"
        nav {
            css {
                display = Display.flex
                alignItems = AlignItems.center
                padding = Padding(0.5.rem, 0.rem)
            }
            a {
                href = "https://moodi.rocks"
                css {
                    padding = Padding(0.3125.rem, 0.rem)
                }

                img {
                    src = "/img/moodi_logo.svg"
                }
            }
        }
    }
}