package screens

import io.ktor.http.*
import services.createShortLink
import services.deleteShortLink
import services.editShortLink
import services.getShortLink
import support.*
import support.overallStatus
import support.statusOf
import techla.base.*
import techla.content.ShortLink
import kotlin.time.ExperimentalTime

val ShortLink.href
    get() = when (val a = access) {
        is ShortLink.Access.Shortener -> a.base
        else -> null
    }

@ExperimentalTime
object ShortLinkScreen {

    object Header {
        val name = DesignSystem.Header(id = "name")
        val href = DesignSystem.Header(id = "href")
    }

    data class Texts(
        val title: String,
        val back: String,
        val name: String,
        val href: String,
        val save: String,
        val create: String,
        val remove: String,
        val toast: String,
        val yes: String,
        val no: String,
        val nameRequired: String,
        val hrefRequired: String,
        val modalTitle: String,
        val modalBody: String,
        val createdIcon: String,
        val createdTitle: String,
        val createdBody: String,
        val next: String,
        val removedIcon: String,
        val removedTitle: String,
        val removedBody: String,
    )

    data class State(
        val shortLink: ShortLink?,
        val name: String,
        val href: String,
        val isSaving: Boolean,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {

        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, "", "", false)
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val back: DesignSystem.Button,
            val name: DesignSystem.TextInput,
            val href: DesignSystem.TextInput,
            val remove: DesignSystem.Button,
            val create: DesignSystem.Button,
            val save: DesignSystem.Button,
            val modalRemove: DesignSystem.Modal,
            val modalBody: String,
            val toast: DesignSystem.Toast,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class Created(
            override var texts: Texts,
            override var state: State,
            val added: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Deleted(
            override var texts: Texts,
            override var state: State,
            val removed: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State, status: List<ValidationStatus> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
                back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.TRANSPARENT),
                create = DesignSystem.Button(text = texts.create, type = DesignSystem.ButtonType.SUBMIT),
                save = DesignSystem.Button(text = texts.save, type = DesignSystem.ButtonType.SUBMIT),
                remove = DesignSystem.Button(text = texts.remove, danger = true, style = DesignSystem.PaletteType.PRIMARY),
                name = DesignSystem.TextInput(
                    header = Header.name,
                    label = texts.name,
                    value = state.shortLink?.name ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.name)
                ),
                href = DesignSystem.TextInput(
                    header = Header.href,
                    label = texts.href,
                    value = state.shortLink?.href?.toString() ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.href)
                ),
                modalBody = texts.modalBody,
                modalRemove = DesignSystem.Modal(title = texts.modalTitle, firstButton = DesignSystem.Button(text = texts.no, style = DesignSystem.PaletteType.SECONDARY), secondButton = DesignSystem.Button(text = texts.yes)),
                toast = DesignSystem.Toast(message = texts.toast, style = DesignSystem.ToastStyle.SUCCESS)
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun created(): ViewModel = Created(
            texts = texts, state = state, added = DesignSystem.Confirmation(
                icon = texts.createdIcon,
                title = texts.createdTitle,
                body = texts.createdBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.SHORTLINKS.INDEX
            )
        )

        fun deleted(): ViewModel = Deleted(
            texts = texts, state = state, removed = DesignSystem.Confirmation(
                icon = texts.removedIcon,
                title = texts.removedTitle,
                body = texts.removedBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.SHORTLINKS.INDEX
            )
        )

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val texts = Texts(
            title = "Snabblänkar",
            back = "Tillbaka",
            name = "Namn",
            href = "Länk",
            save = "Spara ändringar",
            create = "Skapa snabblänk",
            remove = "Ta bort snabblänk",
            yes = "Japp!",
            no = "Nej",
            toast = "Snabblänk uppdaterad",
            nameRequired = "\uD83D\uDC6E Namn måste anges",
            hrefRequired = "\uD83D\uDC6E Länk måste anges",
            modalTitle = "Är du säker?",
            modalBody = "Vill du ta bort snabblänken?",


            createdIcon = "\uD83E\uDD70",
            createdTitle = "Snabblänk upplagd",
            createdBody = "Sådärja. Nu finns en ny genväg till cyberspace.",

            removedIcon = "\uD83D\uDC94",
            removedTitle = "Snabblänk borttagen",
            removedBody = "Borta! Skulle du ångra dig kan du lägga till länken på nytt!",
            next = "Fortsätt",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, shortLinkId: Identifier<ShortLink>?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        if (!store.isPro) return sceneOf(viewModel.getPro())

        if (shortLinkId == null) return sceneOf(viewModel.ready(state = viewModel.state))

        return store.getShortLink(shortLinkId).map { (action, shortLink) ->
            val state = viewModel.state.copy(shortLink = shortLink)
            sceneOf(viewModel.ready(state = state), action)
        }.failed { scene.failed(result = it) }
    }

    fun validate(scene: Scene.Input<ViewModel>, name: String, href: String): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status = mutableListOf<ValidationStatus>()
        var state = viewModel.state

        Validator.text(
            text = name,
            isEmpty = { status.add(Header.name to Status.Invalid(warning = viewModel.texts.nameRequired)) },
            passed = { status.add(Header.name to Status.Valid) },
            formatted = { state = state.copy(name = it) },
        )
        Validator.text(
            text = href,
            isEmpty = { status.add(Header.href to Status.Invalid(warning = viewModel.texts.hrefRequired)) },
            passed = { status.add(Header.href to Status.Valid) },
            formatted = { state = state.copy(href = it) },
        )

        if (status.overallStatus() !is Status.Valid)
            return sceneOf(viewModel.ready(state = viewModel.state, status = status))

        return sceneOf(viewModel.ready(state = state.copy(isSaving = true)))
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val create = ShortLink.Create(
            name = viewModel.state.name,
            access = ShortLink.Access.Shortener(Url(viewModel.state.href))
        )

        return store.createShortLink(create).map { (actions, _) ->
            sceneOf(viewModel.created(), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun save(scene: Scene.Input<ViewModel>, id: Identifier<ShortLink>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val edit = ShortLink.Edit(
            name = modifiedOf(viewModel.state.name),
            access = modifiedOf(ShortLink.Access.Shortener(Url(viewModel.state.href))),
        )

        return store.editShortLink(id, edit).map { (actions, shortLink) ->
            val newState = viewModel.state.copy(shortLink = shortLink, isSaving = false)
            sceneOf(viewModel.ready(state = newState), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<ShortLink>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.deleteShortLink(id).map { (actions, _) ->
            sceneOf(viewModel.deleted(), actions)
        }.failed { scene.failed(result = it) }
    }
}