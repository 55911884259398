package components

import mui.material.Typography
import mui.material.TypographyAlign
import mui.system.sx
import react.FC
import react.PropsWithClassName
import support.DesignSystem
import support.Palette
import support.Support
import support.typographyVariant
import web.cssom.*

external interface RTextProps : PropsWithClassName {
    var design: DesignSystem.Text?
    var onDoubleClick: (() -> Unit)?
}

val RText = FC<RTextProps> { props ->
    val textAlign = when (props.design?.align) {
        DesignSystem.TextAlign.CENTER -> TypographyAlign.center
        DesignSystem.TextAlign.RIGHT -> TypographyAlign.right
        else -> TypographyAlign.left
    }

    if (props.design?.visible == true) {
        val design = props.design!!

        Typography {
            variant = Support.typographyVariant(design.size, design.style)
            align = textAlign
            onDoubleClick = { props.onDoubleClick?.let { it() } }
            className = props.className
            if (design.background == DesignSystem.Background.DARK) {
                sx {
                    color = Color(Palette.Grey.light)
                }
            }
            +design.text
        }
    }
}
