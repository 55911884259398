package screens

import screens.items.ActivityTexts
import services.*
import support.*
import techla.base.*
import techla.form.Field
import techla.form.Form
import kotlin.time.ExperimentalTime

@ExperimentalTime
object WhistleBlowerScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
    }

    data class Texts(
        val whistleBlower: String,
        val disabledTitle: String,
        val disabledBody: String,
        val done: String,
        val enabledTitle: String,
        val enabledBody: String,
        val back: String,
        val next: String,
        val enable: String,
        val disable: String,
        val description: String,
        val placeholder: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    data class State(
        val formId: Identifier<Form>? = null,
        val enabled: Boolean = false,
        val name: String = "",
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            var image: DesignSystem.ImageView,
            var title: DesignSystem.Text,
            var enable: DesignSystem.Button,
            var disable: DesignSystem.Button,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class Success(
            override var texts: Texts,
            override var state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Create(
            override var texts: Texts,
            override var state: State,
            var back: DesignSystem.Button,
            var next: DesignSystem.Button,
            var text: DesignSystem.TextInput,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel =
            Ready(
                texts = texts,
                state = state,
                image = DesignSystem.ImageView(image = DesignSystem.Image.WHISTLEBLOWER),
                title = DesignSystem.Text(text = texts.whistleBlower, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                disable = DesignSystem.Button(text = texts.disable, visible = state.enabled),
                enable = DesignSystem.Button(text = texts.enable, visible = !state.enabled),
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)


        fun success(state: State): ViewModel =
            Success(
                texts = texts,
                state = state,
                confirmation = DesignSystem.Confirmation(
                    icon = "",
                    title = if (state.enabled) texts.enabledTitle else texts.disabledTitle,
                    body = if (state.enabled) texts.enabledBody else texts.disabledBody,
                    next = texts.done,
                )
            )

        fun create(state: State? = null, status: List<ValidationStatus> = emptyList()): ViewModel =
            (state ?: this.state).run {
                Create(
                    texts = texts,
                    state = this,
                    back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.SECONDARY),
                    next = DesignSystem.Button(text = texts.next, type = DesignSystem.ButtonType.SUBMIT),
                    text = DesignSystem.TextInput(
                        header = Header.name,
                        label = texts.description,
                        value = name,
                        type = DesignSystem.TextInputType.NOVEL,
                        placeholder = texts.placeholder,
                        status = status.statusOf(Header.name),
                    )
                )
            }

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        fun asLoading() = this as? Loading
        fun asReady() = this as? Ready
        fun asCreate() = this as? Create
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            whistleBlower = "Visselblåsaren",
            done = "Fortsätt",
            back = "Tillbaka",
            next = "Nästa",
            enable = "Jag vill ha en visselblåsare",
            disable = "Inaktivera visselblåsaren",
            enabledTitle = "Allt är nu klart \uD83C\uDF8A",
            enabledBody = "Visselblåsaren är nu igång och kommer att synas för anställda i appen under Hubben",
            disabledTitle = "Allt är nu klart \uD83C\uDF8A",
            disabledBody = "Visselblåsaren är nu inaktiv",

            description = "Beskrivning av visselblåsaren",
            placeholder = "Fyll på här tack :)",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state

        if (!store.isPro) return sceneOf(viewModel.getPro())

        return store.listForms().map { (actions, forms) ->
            val form = forms.firstOrNull { it.kind is Form.Kind.WhistleBlower }
            state = state.copy(formId = form?.id, enabled = form?.status is Form.Status.Active)
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        return sceneOf(viewModel.create())
    }

    suspend fun enable(scene: Scene.Input<ViewModel>, value: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val state = viewModel.state.copy(
            name = value
        )

        val status: MutableList<ValidationStatus> = mutableListOf()
        Validator.text(
            text = value,
            isEmpty = { status.add(Header.name to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.name to Status.Valid) },
            formatted = { }
        )
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.create(state, status))
        }

        val form = Form.Create(
            key = Key.random(length = 10),
            name = "Visselpipan",
            info = value,
            kind = Form.Kind.WhistleBlower,
            feedback = Form.Feedback.None,
            status = Form.Status.Active
        )

        return if (state.formId == null) {
            store.createForm(create = form).map { (actions, form) ->
                val field = Field.Create(
                    formId = form.id,
                    key = WHISTLE_BLOWER_FIELD,
                    style = Field.Style.Long,
                    order = 0,
                    page = 1,
                    required = true,
                    hidden = false,
                    label = "Meddelande",
                    predefined = null,
                    placeholder = null,
                    hint = null
                )
                store.reduce(actions).createField(create = field).accumulate(actions).map { (actions, _) ->
                    val newState = viewModel.state.copy(enabled = form.status is Form.Status.Active)
                    sceneOf(viewModel.success(state = newState), actions)
                }.failed { scene.failed(result = it) }
            }.failed { scene.failed(result = it) }
        } else {
            store.editForm(id = state.formId, edit = Form.Edit(status = modifiedOf(Form.Status.Active), info = modifiedOf(value))).map { (actions, form) ->
                val newState = viewModel.state.copy(enabled = form.status is Form.Status.Active)
                sceneOf(viewModel.success(state = newState), actions)
            }.failed { scene.failed(result = it) }
        }
    }

    suspend fun disable(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val id = viewModel.state.formId ?: return sceneOf(viewModel.failed("Missing formId"))

        val edit = Form.Edit(status = modifiedOf(Form.Status.Inactive))

        return store.editForm(id = id, edit = edit).map { (actions, form) ->
            val newState = viewModel.state.copy(enabled = form.status is Form.Status.Active)
            sceneOf(viewModel.success(state = newState), actions)
        }.failed { scene.failed(result = it) }
    }

}