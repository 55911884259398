package components.chartjs

import react.ComponentType
import react.Props
import react.Ref

@JsModule("chart.js")
@JsNonModule
external val chartJs: dynamic

@JsModule("react-chartjs-2")
@JsNonModule
private external val reactChartJs: dynamic

@Suppress("UnsafeCastFromDynamic")
val reactChart: ComponentType<ReactChartProps> = reactChartJs.Chart

external interface ReactChartProps : Props {
    var type: String // keyof ChartTypeRegistry
    var data: Data
    var options: Options
    var updateMode: String // "resize" | "reset" | "none" | "hide" | "show" | "normal" | "active"
    var ref: Ref<*>
}


external interface DataSet {
    var label: String
    var data: Array<Number>
    var fill: Boolean
    var cubicInterpolationMode: String
    var backgroundColor: dynamic
}

external interface AnimationOptions {
    var easing: String
}

external interface PointOptions {
    var borderColor: String
    var backgroundColor: String
    var hoverBackgroundColor: String
    var hoverBorderColor: String
    var borderWidth: Number
    var hoverRadius: Number
    var hoverBorderWidth: Number
    var radius: Number
}

external interface ScaleOptions {
    var min: Number
    var ticks: TicksOptions
    var grid: GridOptions
}

external interface TicksOptions {
    var font: FontOptions
    var padding: Number
    var maxTicksLimit: Number
}

external interface FontOptions {
    var color: String
    var weight: String
}

external interface GridOptions {
    var drawBorder: Boolean
    var drawTicks: Boolean
    var display: Boolean
}

external interface ScalesOptions {
    var x: ScaleOptions
    var y: ScaleOptions
}

external interface ElementsOptions {
    var line: LineOptions
    var point: PointOptions
}

external interface LineOptions {
    var borderWidth: Number
    var borderColor: String
}

external interface Data {
    var labels: Array<String>
    var datasets: Array<DataSet>
}

external interface PluginsOptions {
    var legend: LegendOptions
    var tooltip: TooltipOptions
}

external interface LegendOptions {
    var display: Boolean
}

external interface TooltipOptions {
    var displayColors: Boolean
}

external interface Options {
    var maintainAspectRatio: Boolean
    var animation: AnimationOptions
    var scales: ScalesOptions
    var elements: ElementsOptions
    var plugins: PluginsOptions
    var responsive: Boolean
}