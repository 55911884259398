package screens

import services.listShortLinks
import support.*
import techla.base.*
import techla.content.ShortLink
import kotlin.time.ExperimentalTime

@ExperimentalTime
object ShortLinksScreen {

    object Header {
        val name = DesignSystem.Header(id = "name")
        val href = DesignSystem.Header(id = "href")
    }
    data class Texts(
        val title: String,
        val name: String,
        val href: String,
        val create: String,

    )

    data class State(
        val shortLinks: List<ShortLink>? = null,
        val shortLink: ShortLink? = null
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {

        object None : ViewModel(
            texts = Texts("", "", "", ""),
            state = State()
        )

        data class Loading(
            override var texts: Texts,
            override var state: State
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val shortLinks: DesignSystem.Table<ShortLink>,
            val create: DesignSystem.Button,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Failed(override var texts: Texts, override var state: State, val message: String) :
            ViewModel(texts, state)


        fun loading(texts: Texts): ViewModel = Loading(texts = texts, state = state)

        fun ready(texts: Texts, state: State): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
                shortLinks = DesignSystem.Table(
                    titles = listOf(
                        DesignSystem.Text(text = texts.name),
                        DesignSystem.Text(text = texts.href),
                    ),
                    data = state.shortLinks?.map { shortLink ->
                        shortLink to listOf(
                            DesignSystem.Link(text = shortLink.name, to = ROUTES.DASHBOARD.SHORTLINKS.show(shortLink.id)),
                            DesignSystem.Link(text = shortLink.href.toString(), href = shortLink.href.toString())
                        )
                    }
                ),
                create = DesignSystem.Button(text = texts.create),
            )

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        fun failed(message: String): ViewModel = Failed(texts = texts, state = state, message = message)


        fun failed(result: Either<List<Warning>, Throwable>) = failed(message = result.message)
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val texts = Texts(
            title = "Snabblänkar",
            name = "Namn",
            href = "Länk",
            create = "Ny snabblänk",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }


    suspend fun load(scene: Scene.Input<ViewModel> ): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        if (!store.isPro) return sceneOf(viewModel.getPro())

        val index = PageIndex(page = 1, size = 50)

        return store.listShortLinks(index).map { (action, pageContent) ->
            val newState = viewModel.state.copy(shortLinks = pageContent)

            sceneOf(viewModel.ready(texts = viewModel.texts, state = newState), action)
        }.failed { scene.failed(result = it) }
    }


}