package services

import support.ActionOutcome
import support.Demo
import support.Store
import support.noActions
import techla.base.*
import techla.storage.Asset
import techla.storage.Catalog
import techla.storage.StorageAPI
import techla.storage.StorageAPIResource

suspend fun Store.findAssets(keys: List<Key<Catalog>>): ActionOutcome<List<Asset>> {
    if (demoMode) return Demo.allAssets.noActions()
    return storageAPI { api ->
        measureAPI(StorageAPIResource.FindAssets(keys), api) {
            api.findAssets(keys).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.getAsset(id: Identifier<Asset>): ActionOutcome<Asset> {
    if (demoMode) return Demo.matchAsset(id).noActions()
    return storageAPI { api ->
        measureAPI(StorageAPIResource.GetAsset(id), api) {
            api.getAsset(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createAsset(create: Asset.Create): ActionOutcome<Asset> {
    if (demoMode) return Demo.createAsset(create).noActions()
    return storageAPI { api ->
        measureAPI(StorageAPIResource.CreateAsset(create), api) {
            api.createAsset(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteAsset(id: Identifier<Asset>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return storageAPI { api ->
        measureAPI(StorageAPIResource.DeleteAsset(id), api) {
            api.deleteAsset(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.findCatalogs(keys: List<Key<Catalog>>): ActionOutcome<List<Catalog>> {
    return storageAPI { api ->
        measureAPI(StorageAPIResource.FindCatalogs(keys), api) {
            api.findCatalogs(keys).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createCatalog(create: Catalog.Create): ActionOutcome<Catalog> {
    return storageAPI { api ->
        measureAPI(StorageAPIResource.CreateCatalog(create), api) {
            api.createCatalog(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun <T> Store.storageAPI(block: suspend (api: StorageAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = StorageAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) StorageAPI.sandbox else StorageAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}