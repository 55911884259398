package services

import support.ActionOutcome
import support.Demo
import support.Store
import support.noActions
import techla.base.*
import techla.guard.Profile
import techla.loyalty.LoyaltyAPI
import techla.loyalty.LoyaltyAPIResource
import techla.loyalty.Member
import techla.loyalty.Pin

suspend fun Store.member(profileId: Identifier<Profile>): ActionOutcome<Member?> {
    if (demoMode) return Demo.matchMemberByProfile(profileId).noActions()
    return loyaltyAPI { api ->
        measureAPI(LoyaltyAPIResource.Membership, api) {
            api.member(profileId).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editMember(id: Identifier<Member>, edit: Member.Edit): ActionOutcome<Member> {
    if (demoMode) return Demo.matchMember(id).noActions()
    return loyaltyAPI { api ->
        measureAPI(LoyaltyAPIResource.EditMember(id, edit), api) {
            api.editMember(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createMember(create: Member.Create): ActionOutcome<Member> {
    if (demoMode) return Demo.currentMember.noActions()
    return loyaltyAPI { api ->
        measureAPI(LoyaltyAPIResource.CreateMember(create), api) {
            api.createMember(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.addEditMember(
    profileId: Identifier<Profile>, memberId: Identifier<Member>?, edit: Member.Edit
): ActionOutcome<Member> {
    return if (memberId == null) {
        val create = Member.Create(profileId = profileId, balance = 0, notifications = null)
        createMember(create)
    } else {
        editMember(memberId, edit = edit)
    }
}

suspend fun Store.listPins(categories: List<Pin.Category>? = null): ActionOutcome<List<Pin>> {
    if (demoMode) return Demo.allPins.noActions()
    return loyaltyAPI { api ->
        measureAPI(LoyaltyAPIResource.ListPins(categories), api) {
            api.listPins(categories).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createPin(create: Pin.Create): ActionOutcome<Pin> {
    if (demoMode) return Demo.createPin(create).noActions()
    return loyaltyAPI { api ->
        measureAPI(LoyaltyAPIResource.CreatePin(create), api) {
            api.createPin(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editPin(id: Identifier<Pin>, edit: Pin.Edit): ActionOutcome<Pin> {
    if (demoMode) return Demo.matchPin(id).noActions()
    return loyaltyAPI { api ->
        measureAPI(LoyaltyAPIResource.EditPin(id, edit), api) {
            api.editPin(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun <T> Store.loyaltyAPI(block: suspend (api: LoyaltyAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = LoyaltyAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) LoyaltyAPI.sandbox else LoyaltyAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}