package support

import components.items.*
import react.FC
import react.Props
import screens.items.ActivityItem
import screens.items.ActivityValue
import techla.base.Identifier

external interface ActivityItemListProps: Props {
    var activityItems: List<ActivityItem.ViewModel>?
    var onChange: ((id: Identifier<ActivityItem>, value: ActivityValue) -> Unit)?
    var onAction: ((id: Identifier<ActivityItem>, action: DesignSystem.Action) -> Unit)?
}

val RActivityItemList = FC<ActivityItemListProps> { props->
    fun onChange(id: Identifier<ActivityItem>, value: ActivityValue) {
        props.onChange?.let { it(id, value) }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction?.let { it(id, action) }
    }

    props.activityItems?.map { item ->
        when (item) {
            is ActivityItem.ViewModel.Header -> ActivityItemHeader { this.viewModel = item }
            is ActivityItem.ViewModel.BasicTextInput -> ActivityItemBasicTextInput { this.viewModel = item; onChange = ::onChange }
            is ActivityItem.ViewModel.BasicSelectInput -> ActivityItemBasicSelectInput { this.viewModel = item; onChange = ::onChange }
            is ActivityItem.ViewModel.BasicMultipleSelectInput -> ActivityItemBasicMultipleSelectInput { this.viewModel = item; onChange = ::onChange }
            is ActivityItem.ViewModel.PageBreak -> ActivityItemPageBreak { this.viewModel = item; onAction = ::onAction }
            is ActivityItem.ViewModel.TextInput -> ActivityItemTextInput { this.viewModel = item; onChange = ::onChange; onAction = ::onAction }
            is ActivityItem.ViewModel.Choices -> ActivityItemChoices { this.viewModel = item; onChange = ::onChange; onAction = ::onAction }
            is ActivityItem.ViewModel.Question -> ActivityItemQuestion { this.viewModel = item; onChange = ::onChange; onAction = ::onAction }
            is ActivityItem.ViewModel.Link -> ActivityItemLink { this.viewModel = item; onChange = ::onChange; onAction = ::onAction }
            is ActivityItem.ViewModel.Asset -> ActivityItemAsset { this.viewModel = item; onChange = ::onChange; onAction = ::onAction }
            is ActivityItem.ViewModel.Pins -> ActivityItemPins { this.viewModel = item; onChange = ::onChange; onAction = ::onAction }
            is ActivityItem.ViewModel.Buttons -> ActivityItemButtons { this.viewModel = item; onClick = ::onAction }

            else -> {}
        }
    }
}
