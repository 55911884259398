package support

object Validator {
    fun <T> notNull(
        value: T?,
        isEmpty: () -> Unit,
        formatted: (T) -> Unit,
        passed: () -> Unit,
    ) {
        // Validate
        when (value) {
            null -> isEmpty()
            else -> {
                formatted(value)
                passed()
            }
        }
    }

    fun text(
        text: String?,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter

        // Validate
        when {
            text.isNullOrEmpty() -> isEmpty()
            else -> {
                formatted(text)
                passed()
            }
        }
    }

    fun govId(
        govId: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        var filtered = govId?.filter { "0123456789".contains(it) } ?: ""
        val filtered2 = govId?.filter { "0123456789-".contains(it) } ?: ""

        // Validate
        when {
            filtered2 != govId -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            filtered.length != 10 && filtered.length != 12 -> isWrongLength()
            else -> passed()
        }

        // Format
        if (filtered.length == 10) {
            val year = filtered.dropLast(8).toIntOrNull() ?: 70
            filtered = if (year > 20) "19$filtered" else "20$filtered"
        }

        formatted(filtered)
    }

    fun govCode(
        govCode: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtered = govCode?.replace("-", "")?.filter { "0123456789".contains(it) } ?: ""

        // Validate
        when {
            filtered != govCode -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            filtered.length != 10 -> isWrongLength()
            else -> passed()
        }

        formatted(filtered)
    }

    fun email(
        email: String?,
        isWrongFormat: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Validate
        when {
            email.isNullOrEmpty() -> isEmpty()
            email.firstOrNull { it == '@' } == null -> isWrongFormat()
            else -> passed()
        }

        formatted(email?.lowercase() ?: "")
    }

    fun phone(
        phone: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
        countryPrefix: String = "46",
    ) {
        // Filter
        var filtered = phone?.filter { "0123456789+".contains(it) } ?: ""
        val filtered2 = phone?.filter { "0123456789+- ".contains(it) } ?: ""

        // Validate
        when {
            filtered2 != phone -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            else -> passed()
        }

        // Format
        if (!filtered.startsWith("+")) {
            if (filtered.startsWith("0")) filtered = filtered.drop(1)
            filtered = "+$countryPrefix$filtered"
        }

        formatted(filtered)
    }

    fun postalCode(
        postalCode: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
    ) {
        // Filter
        val filtered = postalCode?.filter { "0123456789".contains(it) } ?: ""

        // Validate
        when {
            filtered != postalCode -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            filtered.length != 5 -> isWrongLength()
            else -> passed()
        }

        formatted(filtered)
    }

    fun verificationCode(
        code: String?,
        illegalCharacters: () -> Unit,
        isEmpty: () -> Unit,
        isWrongLength: () -> Unit,
        formatted: (String) -> Unit,
        passed: () -> Unit,
        length: Int = 6,
    ) {
        // Filter
        val filtered = code?.filter { "0123456789".contains(it) } ?: ""

        // Validate
        when {
            filtered != code -> illegalCharacters()
            filtered.isEmpty() -> isEmpty()
            filtered.length != length -> isWrongLength()
            else -> passed()
        }

        formatted(filtered)
    }
}