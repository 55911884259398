package components

import emotion.react.css
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import support.DesignSystem
import support.Palette
import kotlin.time.ExperimentalTime
import web.cssom.*
import web.html.ButtonType
import web.html.HTMLButtonElement

external interface ModalProps : PropsWithChildren {
    var design: DesignSystem.Modal
    var firstOnClick: (() -> Unit)?
    var secondOnClick: ((DesignSystem.Action) -> Unit)?
    var show: Boolean
    var hide: (Boolean) -> Unit
    var info: String?
}


@ExperimentalTime
val RModal = FC<ModalProps> { props ->

    fun handleFirst(event: MouseEvent<HTMLButtonElement, *>) {
        props.firstOnClick?.let { it() }
    }

    fun handleSecond(action: DesignSystem.Action) {
        props.secondOnClick?.let { it(action) }
    }

    Dialog {
        open = props.show
        onClose = { _, _ -> props.hide(false) }
        fullWidth = true
        maxWidth = "xs"
        sx {
            ".MuiDialogContent-root" {
                paddingBottom = Padding(0.rem, 1.rem, 3.rem, 1.rem)
            }
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.flexEnd
                padding = Padding(0.rem, 0.5.rem)
                opacity = 0.75.unsafeCast<Opacity>()
                hover {
                    opacity = 0.5.unsafeCast<Opacity>()
                }
            }
            Button {
                onClick = { props.hide(false) }
                sx {
                    color = Color(Palette.Grey.dark)
                    fontSize = 1.8.rem
                    fontWeight = FontWeight.bold
                }
                +"×️"
            }
        }

        DialogContent {
            Stack {
                spacing = responsive(2)
                props.design.title?.let {
                    RText {
                        design = DesignSystem.Text(text = it, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER)
                    }
                }
                props.info?.let {
                    RText {
                        design = DesignSystem.Text(text = it, size = DesignSystem.SizeType.SM, align = DesignSystem.TextAlign.CENTER)
                    }
                }
            }

            Stack {
                direction = responsive(StackDirection.row)
                spacing = responsive(3)
                sx {
                    padding = Padding(1.rem, 0.rem)
                    justifyContent = JustifyContent.center
                    width = 75.pct
                    margin = Auto.auto
                }

                props.design.firstButton?.let {
                    div {
                        css {
                            width = 100.pct
                        }
                        Button {
                            sx {
                                boxShadow = BoxShadow(0.px, 2.px, 4.px, rgb(60, 9, 179, 0.5))
                            }
                            this.type = ButtonType.button
                            this.variant = ButtonVariant.text
                            this.onClick = ::handleFirst
                            this.disabled = false
                            this.color = ButtonColor.primary
                            this.fullWidth = true

                            +(it.text ?: "")
                        }
                    }
                }
                props.design.secondButton?.let {
                    RButton {
                        design = it.copy(fullWidth = true)
                        onClick = ::handleSecond
                    }
                }
            }
        }
    }
}