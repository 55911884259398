package components

import ROUTES
import StoreContext
import emotion.react.css
import js.objects.jso
import mui.icons.material.ExpandLess
import mui.icons.material.ExpandMore
import mui.material.*
import mui.material.Size
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.main
import react.router.Outlet
import react.router.useMatch
import react.router.useNavigate
import support.*
import web.cssom.*

const val DRAWER_WIDTH = 325

val sidebarItems = listOf(
    DesignSystem.SideBarItem(href = ROUTES.DASHBOARD.INDEX, iconSrc = "/img/ico_start_b.svg", text = "Start"),
    DesignSystem.SideBarItem(href = ROUTES.DASHBOARD.USERS.INDEX, iconSrc = "/img/ico_users_b.svg", text = "Anställda"),
    DesignSystem.SideBarItem(
        href = "",
        iconSrc = "/img/ico_hub_b.svg",
        text = "Hubben",
        children = listOf(
            DesignSystem.SideBarItem(
                text = "AKTIVITETER", href = "#", iconSrc = null,
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.SUGGESTION_BOX, iconSrc = "/img/ico_suggestion_box_b.svg", text = "Förslagslåda", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.PULSE, iconSrc = "/img/ico_pulse_b.svg", text = "Puls", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.EMPLOYEE_SURVEY, iconSrc = "/img/ico_survey_b.svg", text = "Medarbetarundersökning", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.SKILLS, iconSrc = "/img/ico_skills_b.svg", text = "Skills", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.WHISTLE_BLOWER, iconSrc = "/img/ico_whistleblower_b.svg", text = "Visselblåsaren", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.QUIZ.INDEX, iconSrc = "/img/ico_quiz_b.svg", text = "Quiz", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.RECRUITMENT.INDEX, iconSrc = "/img/ico_recruitment_b.svg", text = "Rekrytering", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.BOOST.INDEX, iconSrc = "/img/ico_boost_b.svg", text = "Boost", pro = true
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.SUBMISSIONS.INDEX, iconSrc = "/img/ico_form_answers_b.svg", text = "Resultat"
            ),
            DesignSystem.SideBarItem(
                text = "SNABBLÄNKAR", href = "#", iconSrc = null,
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.SHORTLINKS.INDEX, iconSrc = "/img/ico_shortlinks_b.svg", text = "Snabblänkar", pro = true
            ),
            DesignSystem.SideBarItem(
                text = "GALLERIET", href = "#", iconSrc = null,
            ),
            DesignSystem.SideBarItem(
                href = ROUTES.DASHBOARD.GALLERY, iconSrc = "/img/ico_gallery_b.svg", text = "Galleriet"
            ),
        )
    ),
    DesignSystem.SideBarItem(
        href = ROUTES.DASHBOARD.FEEDS.INDEX, iconSrc = "/img/ico_channels_b.svg", text = "Kanaler", pro = true
    ),
    DesignSystem.SideBarItem(href = ROUTES.DASHBOARD.ACCOUNT, iconSrc = "/img/ico_account_b.svg", text = "Abonnemang"),
)

val RLayout = FC<Props> { props ->
    val (store, dispatch) = useRequiredContext(StoreContext)
    var drawerOpen by useState(true)
    val navigate = useNavigate()

    useEffect(store) {
        if (!store.isSignedIn) {
            navigate(ROUTES.LOGIN)
        }
    }

    Box {
        component = div
        sx {
            height = 100.vh
        }

        IconButton {
            sx {
                if (drawerOpen) marginLeft = DRAWER_WIDTH.px
                zIndex = 5.unsafeCast<ZIndex>()
                position = Position.sticky
                top = 0.px
                left = 0.px
            }
            mui.icons.material.Menu {}
            onClick = { drawerOpen = !drawerOpen }
        }

        Drawer {
            variant = DrawerVariant.persistent
            anchor = DrawerAnchor.left
            open = drawerOpen

            Stack {
                sx {
                    justifyContent = JustifyContent.spaceBetween
                    height = 100.pct
                    padding = Padding(48.px, 0.px)
                    backgroundColor = Color(Palette.Grey.main)
                }

                Stack {
                    spacing = responsive(6)
                    sx {
                        overflow = Overflow.scroll
                        alignItems = AlignItems.center
                    }

                    react.router.dom.Link {
                        to = ROUTES.DASHBOARD.INDEX
                        RImage {
                            design = DesignSystem.ImageView(
                                image = DesignSystem.Image.MOODI
                            )
                        }
                    }

                    List {
                        dense = true
                        sx {
                            width = DRAWER_WIDTH.px
                        }

                        sidebarItems.map { RSideBarItem { design = it } }
                    }

                }

                Stack {
                    sx {
                        width = 100.pct
                        padding = 1.rem
                        alignItems = AlignItems.center
                    }

                    img {
                        css {
                            marginBottom = 0.5.rem
                            borderRadius = 0.25.rem
                        }
                        src = store.buyer?.logo?.url?.toString()
                        height = 50.0
                        width = 50.0
                    }

                    Typography {
                        sx {
                            marginBottom = 1.rem
                        }
                        variant = Support.typographyVariant(DesignSystem.SizeType.SM, DesignSystem.StyleType.REGULAR)
                        +(store.buyer?.identity?.name ?: "")
                    }

                    RButton {
                        design = DesignSystem.Button(text = "Logga ut", fullWidth = true)
                        onClick = { dispatch(listOf(Store.Action.Logout)) }
                    }
                }
            }
        }

        main {
            Box {
                id = "RLayout-Main"
                sx {
                    if (drawerOpen) marginLeft = DRAWER_WIDTH.px
                    position = Position.absolute
                    top = 0.px
                    minHeight = 100.vh
                    width = 100.pct - if (drawerOpen) DRAWER_WIDTH.px else 0.px
                }

                Outlet {}
            }
        }
    }
}

external interface RSideBarItemProps : Props {
    var design: DesignSystem.SideBarItem
}

val RSideBarItem = FC<RSideBarItemProps> { props ->
    val navigate = useNavigate()
    val active = useMatch("${props.design.href}/*") != null

    if (props.design.children == null) {
        ListItemButton {
            sx {
                marginBottom = 0.75.rem
            }
            onClick = { navigate(props.design.href) }

            props.design.iconSrc?.let {
                ListItemIcon {
                    img {
                        css {
                            if (active) filter =
                                "invert(10%) sepia(90%) saturate(5922%) hue-rotate(260deg) brightness(105%) contrast(114%)".unsafeCast<Filter>()
                        }
                        src = it
                    }
                }
            }
            ListItemText {
                sx {
                    marginLeft = (-1).rem
                }
                primaryTypographyProps = jso {
                    variant = Support.typographyVariant(DesignSystem.SizeType.SM, DesignSystem.StyleType.REGULAR)
                    sx {
                        color = if (active) Color(Palette.Primary.main) else Color(Palette.Grey.dark)
                        wordBreak = WordBreak.breakWord
                    }
                }
                +props.design.text
            }
            if (props.design.pro) {
                ListItemSecondaryAction {
                    Chip {
                        sx {
                            borderRadius = 0.25.rem
                            fontSize = important(0.65.rem)
                            fontWeight = 600.unsafeCast<FontWeight>()
                            height = important(18.px)
                        }
                        size = Size.small
                        color = ChipColor.primary
                        label = ReactNode("PRO")
                    }
                }
            }
        }
    } else {
        var open by useState(false)

        ListItemButton {
            sx {
                marginBottom = 0.75.rem
            }
            onClick = { open = !open }

            ListItemIcon { img { src = props.design.iconSrc } }
            ListItemText {
                sx {
                    marginLeft = (-1).rem
                }
                primaryTypographyProps = jso {
                    variant = Support.typographyVariant(DesignSystem.SizeType.SM, DesignSystem.StyleType.REGULAR)
                }
                +props.design.text
            }

            if (open) ExpandLess {} else ExpandMore {}
        }

        Collapse {
            `in` = open
            timeout = "auto"

            List {
                dense = true
                sx {
                    paddingLeft = 1.rem
                    ".Mui-disabled" {
                        opacity = important(1.unsafeCast<Opacity>())
                    }
                }

                props.design.children?.map { sideBarItem ->
                    val childActive = useMatch("${sideBarItem.href}/*") != null
                    ListItemButton {
                        sx {
                            marginBottom = 8.px
                        }
                        disabled = sideBarItem.iconSrc == null
                        onClick = { navigate(sideBarItem.href) }
                        sideBarItem.iconSrc?.let {
                            ListItemIcon {
                                img {
                                    css {
                                        if (childActive) filter =
                                            "invert(10%) sepia(90%) saturate(5922%) hue-rotate(260deg) brightness(105%) contrast(114%)".unsafeCast<Filter>()
                                    }
                                    src = it
                                }
                            }
                        }
                        ListItemText {
                            primaryTypographyProps = jso {
                                variant = Support.typographyVariant(DesignSystem.SizeType.SM, DesignSystem.StyleType.REGULAR)
                                sx {
                                    color =
                                        if (childActive) Color(Palette.Primary.main) else Color(Palette.Grey.dark)
                                    wordBreak = WordBreak.breakWord
                                    if (sideBarItem.iconSrc != null) marginLeft = (-1).rem
                                }
                            }
                            +sideBarItem.text
                        }
                        if (sideBarItem.pro) {
                            ListItemSecondaryAction {
                                Chip {
                                    sx {
                                        borderRadius = 0.25.rem
                                        fontSize = important(0.65.rem)
                                        fontWeight = 600.unsafeCast<FontWeight>()
                                        height = important(18.px)
                                    }
                                    size = Size.small
                                    color = ChipColor.primary
                                    label = ReactNode("PRO")
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}