package components.items

import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import support.DesignSystem
import support.RActivityItem
import techla.base.Identifier

external interface ActivityItemPageBreakProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.PageBreak
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemPageBreak = FC<ActivityItemPageBreakProps> { props ->

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    if (props.viewModel.navigation.isVisible) {
        RActivityItem {
            hasBorder = true
            viewModel = props.viewModel
            onAction = ::onAction
        }
    }
}