package components

import emotion.react.css
import mui.material.Typography
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.router.dom.Link
import support.DesignSystem
import support.Palette
import support.Support
import support.typographyVariant
import web.cssom.*
import web.window.WindowTarget


external interface RLinkProps : Props {
    var design: DesignSystem.Link
}

val RLink = FC<RLinkProps> { props ->
    if (props.design.to != null) {
        Link {
            css {
                textDecoration = None.none
            }
            to = props.design.to!!
            Typography {
                sx {
                    color = Color(Palette.Primary.main)
                }
                variant = Support.typographyVariant(DesignSystem.SizeType.MD, DesignSystem.StyleType.REGULAR)
                +props.design.text
            }
        }
    } else if (props.design.href != null) {
        a {
            css {
                textDecoration = None.none
            }
            href = props.design.href!!
            target = WindowTarget._blank
            rel = "noopener noreferrer"
            Typography {
                sx {
                    color = Color(Palette.Primary.main)
                }
                variant = Support.typographyVariant(DesignSystem.SizeType.MD, DesignSystem.StyleType.REGULAR)
                +props.design.text
            }
        }
    }
}