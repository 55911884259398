package screens

import ROUTES
import services.deleteSubmission
import services.getSubmission
import support.*
import techla.base.*
import techla.form.Field
import techla.form.Submission
import kotlin.time.ExperimentalTime

@ExperimentalTime
object SubmissionScreen {
    object Header {
        val timestamp = DesignSystem.Header(id = "timestamp")
        val status = DesignSystem.Header(id = "status")
        val activity = DesignSystem.Header(id = "activity")
    }

    data class Texts(
        val submission: String,
        val timestamp: String,
        val activity: String,
        val status: String,
        val name: String,
        val value: String,
        val remove: String,
        val next: String,
        val back: String,

        val removedIcon: String,
        val removedTitle: String,
        val removedBody: String,

        val modalTitle: String,
        val modalBody: String,
        val no: String,
        val yes: String,
    )

    data class State(
        val submission: Submission?,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null)
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val back: DesignSystem.Button,
            val timestamp: DesignSystem.TextInput,
            val activity: DesignSystem.TextInput,
            val status: DesignSystem.TextInput,
            val remove: DesignSystem.Button,
            val modalRemove: DesignSystem.Modal,
            val modalBody: String,
            val results: DesignSystem.Table<Pair<Either<Field, Key<Field>>, String>>
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class Deleted(
            override var texts: Texts,
            override var state: State,
            val removed: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.submission, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
                back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.TRANSPARENT),
                timestamp = DesignSystem.TextInput(
                    header = Header.timestamp,
                    label = texts.timestamp,
                    value = state.submission?.timestamp?.let { localize(it) } ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    disabled = true,
                ),
                status = DesignSystem.TextInput(
                    header = Header.status,
                    label = texts.status,
                    value = state.submission?.displayState ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    disabled = true,
                ),
                activity = DesignSystem.TextInput(
                    header = Header.activity,
                    label = texts.activity,
                    value = state.submission?.displayActivity ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    disabled = true,
                ),
                remove = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, danger = true, style = DesignSystem.PaletteType.PRIMARY, text = texts.remove),
                modalBody = texts.modalBody,
                modalRemove = DesignSystem.Modal(title = texts.modalTitle, firstButton = DesignSystem.Button(text = texts.no, style = DesignSystem.PaletteType.SECONDARY), secondButton = DesignSystem.Button(text = texts.yes)),
                results = DesignSystem.Table(
                    titles = listOf(
                        DesignSystem.Text(text = texts.name),
                        DesignSystem.Text(text = texts.value),
                    ),
                    data = state.submission?.results?.filter { it.first.leftOrNull()?.isInternalField == false }?.map { result ->
                        result to listOf(
                            DesignSystem.Text(
                                text = when (val field = result.first) {
                                    is Either.Left -> field.value.label ?: field.value.key.rawValue
                                    is Either.Right -> field.value.rawValue
                                },
                            ),
                            DesignSystem.Text(text = result.second),
                        )
                    }
                )
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun deleted(): ViewModel = Deleted(
            texts = texts,
            state = state,
            removed = DesignSystem.Confirmation(
                icon = texts.removedIcon,
                title = texts.removedTitle,
                body = texts.removedBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.SUBMISSIONS.INDEX,
            )
        )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            submission = "Resultat",
            name = "Namn",
            back = "Tillbaka",

            remove = "Ta bort resultat",
            modalTitle = "Är du säker?",
            modalBody = "Vill du ta bort resultatet?",
            no = "Nej",
            yes = "Ta bort",

            next = "Fortsätt",

            removedIcon = "\uD83D\uDC94",
            removedTitle = "Resultat borttaget",
            removedBody = "Puts väck!",

            activity = "Aktivitet",
            status = "Status",
            timestamp = "Tidpunkt",
            value = "Värde",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, submissionId: Identifier<Submission>?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state
        submissionId ?: return sceneOf(viewModel.failed(message = "Missing submissionId"))

        return store.getSubmission(submissionId).map { (actions, submission) ->
            state = state.copy(submission = submission)
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val submissionId = viewModel.state.submission?.index?.id ?: return sceneOf(viewModel.ready(state = viewModel.state))

        return store.deleteSubmission(submissionId).map { (actions, _) ->
            sceneOf(viewModel.deleted(), actions)
        }.failed { scene.failed(result = it) }
    }
}