package views

import ROUTES
import StoreContext
import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import react.router.useNavigate
import screens.UsersScreen
import support.*
import techla.guard.Profile
import kotlin.time.ExperimentalTime
import web.cssom.*


val Profile.fullName: String get() = "$firstName $lastName"

@ExperimentalTime
val Users = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: UsersScreen.ViewModel by useState(UsersScreen.ViewModel.None)
    val executor by useExecutor<UsersScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }
    val navigate = useNavigate()
    var search by useState("")

    useEffect(viewModel) {
        if (viewModel is UsersScreen.ViewModel.None)
            executor.call { UsersScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is UsersScreen.ViewModel.Loading)
            executor.call({ UsersScreen.load(sceneInputOf(store, viewModel)) }) { updated ->
                updated.asReady?.let {
                    search = it.search.value ?: ""
                }
            }
    }

    fun onDirectoryOn(value: Boolean) {
        executor.call { UsersScreen.setValues(sceneInputOf(store, viewModel), directoryOn = value) }
    }

    fun onDirectoryName(value: String) {
        executor.call { UsersScreen.setValues(sceneInputOf(store, viewModel), directoryName = value) }
    }

    fun onDirectoryPhone(value: Boolean) {
        executor.call { UsersScreen.setValues(sceneInputOf(store, viewModel), directoryPhone = value) }
    }

    fun onDirectoryEmail(value: Boolean) {
        executor.call { UsersScreen.setValues(sceneInputOf(store, viewModel), directoryEmail = value) }
    }

    fun onDirectoryAIBio(value: Boolean) {
        executor.call { UsersScreen.setValues(sceneInputOf(store, viewModel), directoryAIBio = value) }
    }

    fun onSave() {
        executor.call({ UsersScreen.validate(sceneInputOf(store, viewModel)) }) {
            if (it.state.isSaving) {
                executor.call { UsersScreen.save(sceneInputOf(store, viewModel)) }
            }
        }
    }

    fun onDone() {
        executor.call { UsersScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onSearch() {
        executor.call { UsersScreen.search(sceneInputOf(store, viewModel), search) }
    }

    fun onPageChange(page: Int) {
        executor.call { UsersScreen.setPage(sceneInputOf(store, viewModel), page) }
    }

    fun onRowsPerPage(rowsPerPage: Int) {
        executor.call { UsersScreen.setRowsPerPage(sceneInputOf(store, viewModel), rowsPerPage) }
    }

    RScaffold {
        viewModel.asReady?.let { ready ->
            Stack {
                spacing = responsive(2)
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                    }
                    RText { design = ready.title }
                    RButton {
                        design = ready.new
                        onClick = { navigate(ROUTES.DASHBOARD.USERS.ADD) }
                    }
                }

                RForm {
                    Box {
                        sx {
                            backgroundColor = Color(Palette.Grey.light)
                            border = Border(0.5.px, LineStyle.solid, Color(Palette.Grey.main))
                            borderRadius = Shape.borderRadius
                            padding = 1.rem
                        }
                        Stack {
                            direction = responsive(StackDirection.column)
                            spacing = responsive(2)
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(2)
                                    RBooleanInput {
                                        design = ready.directoryOn; onChange = ::onDirectoryOn
                                }
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(2)
                                RTextInput {
                                        design = ready.directoryName; onChange = ::onDirectoryName
                                }
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(2)
                                RBooleanInput {
                                    design = ready.directoryPhone; onChange = ::onDirectoryPhone
                                }
                                RBooleanInput {
                                    design = ready.directoryEmail; onChange = ::onDirectoryEmail
                                }
                                RBooleanInput {
                                    design = ready.directoryAIBio; onChange = ::onDirectoryAIBio
                                }
                            }
                            Stack {
                                direction = responsive(StackDirection.row)
                                spacing = responsive(2)
                                RButton { design = ready.save; onClick = { onSave() } }
                            }
                        }
                    }
                }

                RForm {
                    onSubmit = { onSearch() }
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(2)
                        sx {
                            justifyContent = JustifyContent.flexStart
                            alignItems = AlignItems.center
                            width = 40.pct
                            margin = Margin(1.rem, 0.rem)
                        }
                        RTextInput {
                            design = ready.search.copy(value = search); onChange = { search = it }
                        }
                        RButton { design = ready.searchButton; onClick = { onSearch() } }
                    }
                }

                RTable {
                    design = ready.profiles

                    ready.profiles.data?.map { (_, row) ->
                        TableRow {
                            row.map { cell ->
                                TableCell {
                                    variant = TableCellVariant.body
                                    when (cell) {
                                        is DesignSystem.Link -> RLink { design = cell }
                                        is DesignSystem.Text -> RText { design = cell }
                                        else -> {}
                                    }
                                }
                            }
                        }
                    }
                }
                RPagination {
                    design = ready.pagination
                    onPageChange = ::onPageChange
                    onRowsPerPage = ::onRowsPerPage
                }
            }
        }

        viewModel.asLoading?.let {
            RLoader {}
        }

        viewModel.asSaved?.let { saved ->
            RConfirmation { design = saved.confirmation; onClick = { onDone() } }
        }

        viewModel.asFailed?.let { failed ->
            RError { message = failed.message }
        }
    }
}