package views

import StoreContext
import components.*
import react.*
import screens.SkillsScreen
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.*
import techla.base.Identifier
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val SkillsView = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: SkillsScreen.ViewModel by useState(SkillsScreen.ViewModel.None)
    val executor by useExecutor<SkillsScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    useEffect(viewModel) {
        techla_log("SKILLS: status='${viewModel.status}'")
        if (viewModel is SkillsScreen.ViewModel.None)
            executor.call { SkillsScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is SkillsScreen.ViewModel.Loading)
            executor.call { SkillsScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        executor.call { SkillsScreen.action(sceneInputOf(store, viewModel), id, action) }
    }

    fun onResume(action: DesignSystem.Action) {
        executor.call { SkillsScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onChange(id: Identifier<ActivityItem>, value: ActivityValue) {
        executor.call { SkillsScreen.setValue(sceneInputOf(store, viewModel), id, value) }
    }

    fun onSave() {
        executor.call { SkillsScreen.saveSkills(sceneInputOf(store, viewModel)) }
    }

    fun onToggle() {
        executor.call { SkillsScreen.toggleSkills(sceneInputOf(store, viewModel)) }
    }

    fun onCreate() {
        executor.call { SkillsScreen.create(sceneInputOf(store, viewModel)) }
    }

    RScaffold {
        isFullScreen = false
        viewModel.asSearch()?.run {
            RActivityItemSearch {
                searchImage = image
                searchTitle = skillTitle
                activityItems = skillItems
                firstButton = save
                onFirst = ::onSave
                secondButton = toggle
                onSecond = ::onToggle
                onChange = ::onChange
                onAction = ::onAction
            }
            RActivityItemSearch {
                searchTitle = courseTitle
                activityItems = courseItems
                firstButton = create
                onFirst = ::onCreate
                onAction = ::onAction
            }
        }

        viewModel.asReady()?.run {
            RActivityItemList {
                activityItems = items
                onChange = ::onChange
                onAction = ::onAction
            }
        }

        viewModel.asLoading()?.run {
            RLoader {}
        }

        viewModel.asSuccess()?.run {
            RConfirmation { design = confirmation; onClick = ::onResume }
        }

        viewModel.asGetPro()?.run {
            RGetPro {}
        }

        viewModel.asFailed()?.run {
            RError { message = message }
        }
    }
}
