package components

import emotion.react.css
import mui.material.Stack
import mui.material.StackDirection
import mui.material.Typography
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.img
import react.router.useNavigate
import support.DesignSystem
import web.cssom.*


external interface RErrorProps : Props {
    var message: String
}

val RError = FC<RErrorProps> { props ->
    val navigate = useNavigate()
    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(2)
        sx {
            justifyContent = JustifyContent.center
            alignItems = AlignItems.center
            height = 75.pct
        }

        RText {
            design = DesignSystem.Text(
                text = "\uD83D\uDCA9", size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD
            )
        }

        RText {
            design = DesignSystem.Text(
                text = "Hoppsan", size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD
            )
        }

        RText {
            design = DesignSystem.Text(
                text = "Ett oplanerat fel har inträffat.", size = DesignSystem.SizeType.SM
            )
        }

        Stack {
            direction = responsive(StackDirection.row)
            sx {
                alignItems = AlignItems.center
            }
            Typography {
                variant = TypographyVariant.body1
                +"Vänligen"
            }
            RButton {
                design = DesignSystem.Button(text = "återgå", style = DesignSystem.PaletteType.TRANSPARENT)
                onClick = { navigate(-1) }
            }
            Typography {
                variant = TypographyVariant.body1
                +"och försök igen."
            }
        }

        RText {
            design = DesignSystem.Text(
                text = "Kvarstår felet så kontakta oss gärna på", size = DesignSystem.SizeType.SM
            )
        }

        Stack {
            direction = responsive(StackDirection.row)
            img {
                src = "/img/ico_mail.svg"
                height = 20.0
            }
            RLink {
                design = DesignSystem.Link(
                    text = " hey@moodi.rocks", href = "mailto:hey@moodi.rocks"
                )
            }
        }

        RText {
            css {
                fontSize = important(14.px)
            }
            design = DesignSystem.Text(text = props.message)
        }
    }
}