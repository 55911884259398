package components

import components.lottie_react.Lottie
import react.FC
import react.Props

external interface RLottieProps : Props {
    var loop: Boolean
    var autoplay: Boolean
    var animationData: dynamic
}

val RLottie = FC<RLottieProps> { props ->
    Lottie {
        this.loop = props.loop
        this.autoplay = props.autoplay
        this.animationData = props.animationData
    }
}