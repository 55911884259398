package screens.items


import support.*
import techla.base.Identifier
import techla.storage.Asset

val Asset.option
    get() =
        DesignSystem.Option(title = name, value = id.rawValue)
val Identifier<Asset>.option
    get() =
        DesignSystem.Option(title = "", value = rawValue)

val Identifier<ActivityItem>.header
    get() =
        DesignSystem.Header(id = rawValue)

fun Identifier<ActivityItem>.header(index: Int) =
    DesignSystem.Header(id = "$rawValue-$index")

interface ActivityTexts {
    val fieldRequired: String
}

sealed class ActivityValue {
    object None : ActivityValue()
    data class StringValue(val value: String) : ActivityValue()
    data class BooleanValue(val value: Boolean) : ActivityValue()
    data class OptionValue(val value: DesignSystem.Option) : ActivityValue()
    data class OptionValues(val values: List<DesignSystem.Option>) : ActivityValue()
    data class ChoicesValue(val value: EditChoicesValue) : ActivityValue()
    data class ListValue(val title: String, val choices: List<String>) : ActivityValue()
    data class QuestionValue(val title: String, val choices: List<String>, val answer: String) : ActivityValue()
    data class LinkValue(val title: String, val hint: String, val url: String) : ActivityValue()
    data class AssetValue(val title: String, val hint: String, val asset: Identifier<Asset>?) : ActivityValue()

    val stringValue: String?
        get() =
            when (this) {
                is None -> null
                is StringValue -> value
                is BooleanValue -> value.toString()
                is OptionValue -> value.value
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> title
                is QuestionValue -> title
                is LinkValue -> title
                is AssetValue -> title
            }

    val stringValues: List<String>?
        get() =
            when (this) {
                is None -> null
                is StringValue -> listOf(value)
                is BooleanValue -> listOf(value.toString())
                is OptionValue -> listOf(value.value)
                is OptionValues -> values.map { it.value }
                is ChoicesValue -> null
                is ListValue -> listOf(title)
                is QuestionValue -> listOf(title)
                is LinkValue -> listOf(title)
                is AssetValue -> listOf(title)
            }

    val booleanValue: Boolean?
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> value
                is OptionValue -> null
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> null
                is LinkValue -> null
                is AssetValue -> null
            }

    val optionValue
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> value
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> null
                is LinkValue -> null
                is AssetValue -> asset?.option
            }

    val optionValues
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> listOf(value)
                is OptionValues -> values
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> null
                is LinkValue -> null
                is AssetValue -> null
            }

    val listValue
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> null
                is OptionValues -> values.map { it.value }
                is ChoicesValue -> null
                is ListValue -> choices
                is QuestionValue -> choices
                is LinkValue -> null
                is AssetValue -> null
            }

    val answerValue
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> null
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> answer
                is LinkValue -> null
                is AssetValue -> null
            }

    val hintValue
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> null
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> null
                is LinkValue -> hint
                is AssetValue -> hint
            }

    val urlValue
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> null
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> null
                is LinkValue -> url
                is AssetValue -> null
            }

    val assetValue
        get() =
            when (this) {
                is None -> null
                is StringValue -> null
                is BooleanValue -> null
                is OptionValue -> Identifier(value.value)
                is OptionValues -> null
                is ChoicesValue -> null
                is ListValue -> null
                is QuestionValue -> null
                is LinkValue -> null
                is AssetValue -> asset
            }
}

data class EditChoicesValue(
    val title: String = "",
    val choices: String = "",
    val correctAnswer: String = "",
)

object ActivityItem {
    data class Navigation(
        val hasFixedPosition: Boolean = false,
        val prevHasFixedPosition: Boolean = false,
        val nextHasFixedPosition: Boolean = false,
        val index: Double = 0.0,
        val displayPage: Int = 0,
        val currentPage: Int = 0,
        val up: DesignSystem.Button = DesignSystem.Button(visible = false),
        val down: DesignSystem.Button = DesignSystem.Button(visible = false),
    ) {
        val isVisible
            get() =
                displayPage == 0 || displayPage == currentPage

        fun setIndex(index: Double, prevHasFixedPosition: Boolean, nextHasFixedPosition: Boolean) =
            copy(
                index = index,
                prevHasFixedPosition = prevHasFixedPosition,
                nextHasFixedPosition = nextHasFixedPosition,
                up = up.copy(visible = !hasFixedPosition && !prevHasFixedPosition),
                down = down.copy(visible = !hasFixedPosition && !nextHasFixedPosition),
            )
    }

    data class TitleBar(
        val style: DesignSystem.Text,
        val options: DesignSystem.Button,
        val booleanInput: DesignSystem.BooleanInput? = null,
    ) {
        companion object {
            val none
                get() = TitleBar(
                    style = DesignSystem.Text(visible = false),
                    options = DesignSystem.Button(visible = false),
                )

            fun normal(label: String) = TitleBar(
                style = DesignSystem.Text(text = label, size = DesignSystem.SizeType.MD),
                options = DesignSystem.Button(
                    text = "",
                    style = DesignSystem.PaletteType.GREY,
                    image = DesignSystem.Image.EDIT,
                    menu = listOf(
                        DesignSystem.Option(title = "Ta bort", value = DesignSystem.Action.DELETE.toString(), action = DesignSystem.Action.DELETE)
                    ),
                )
            )

            fun withBooleanInput(label: String, booleanInput: DesignSystem.BooleanInput) = TitleBar(
                style = DesignSystem.Text(text = label, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD),
                options = DesignSystem.Button(
                    text = "",
                    style = DesignSystem.PaletteType.GREY,
                    image = DesignSystem.Image.EDIT,
                    menu = listOf(
                        DesignSystem.Option(title = "Ta bort", value = DesignSystem.Action.DELETE.toString(), action = DesignSystem.Action.DELETE)
                    ),
                ),
                booleanInput = booleanInput,
            )
        }
    }

    data class ButtonsParams(
        val id: Identifier<ActivityItem> = Identifier(),
        val mode: Mode,
        val options: List<DesignSystem.Option> = emptyList(),
    )

    sealed class ViewModel(
        open val id: Identifier<ActivityItem>,
        open val navigation: Navigation,
        open val titleBar: TitleBar,
    ) {
        data class Header(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            val titles: kotlin.collections.List<String>,
        ) : ViewModel(id = id, navigation = navigation, titleBar = TitleBar.none) {
            companion object
        }

        data class Search(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            val title: DesignSystem.Text,
            val info: DesignSystem.Text,
            val status: DesignSystem.Chip,
            val more: DesignSystem.Button
        ) : ViewModel(id = id, navigation = navigation, titleBar = TitleBar.none)

        data class BasicTextInput(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.TextInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = TitleBar.none) {
            companion object
        }

        data class BasicSelectInput(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.SelectInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = TitleBar.none) {
            companion object
        }

        data class BasicMultipleSelectInput(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.MultipleSelectInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = TitleBar.none) {
            companion object
        }

        data class PageBreak(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class Choices(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.TextInput,
            val choices: DesignSystem.TextInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class TextInput(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.TextInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class Question(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.TextInput,
            val choices: DesignSystem.TextInput,
            val answer: DesignSystem.TextInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class Link(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.TextInput,
            val hint: DesignSystem.TextInput,
            val url: DesignSystem.TextInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class Asset(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val input: DesignSystem.TextInput,
            val hint: DesignSystem.TextInput,
            val asset: DesignSystem.SelectInput,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class Pins(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            override val titleBar: TitleBar,
            val header: DesignSystem.Header,
            val value: ActivityValue,
            val image: DesignSystem.ImageView,
        ) : ViewModel(id = id, navigation = navigation, titleBar = titleBar) {
            companion object
        }

        data class Buttons(
            override val id: Identifier<ActivityItem>,
            override val navigation: Navigation,
            val params: ButtonsParams,
            val back: DesignSystem.Button,
            val next: DesignSystem.Button,
            val submit: DesignSystem.Button,
            val edit: DesignSystem.Button,
            val prev: DesignSystem.Button,
            val add: DesignSystem.Button,
        ) : ViewModel(id = id, navigation = navigation, titleBar = TitleBar.none) {
            companion object
        }

        val hasActivityValue
            get() =
                when (this) {
                    is Header -> false
                    is Search -> false
                    is PageBreak -> false
                    is BasicTextInput -> true
                    is BasicSelectInput -> true
                    is BasicMultipleSelectInput -> true
                    is Choices -> true
                    is TextInput -> true
                    is Question -> true
                    is Link -> true
                    is Asset -> true
                    is Pins -> true
                    is Buttons -> false
                }

        val activityHeader
            get() =
                when (this) {
                    is Header -> null
                    is Search -> null
                    is PageBreak -> null
                    is BasicTextInput -> header
                    is BasicSelectInput -> header
                    is BasicMultipleSelectInput -> header
                    is Choices -> header
                    is TextInput -> header
                    is Question -> header
                    is Link -> header
                    is Asset -> header
                    is Pins -> header
                    is Buttons -> null
                }

        val activityValue
            get() = when (this) {
                is Header -> null
                is Search -> null
                is PageBreak -> null
                is BasicTextInput -> value
                is BasicSelectInput -> value
                is BasicMultipleSelectInput -> value
                is Choices -> value
                is TextInput -> value
                is Question -> value
                is Link -> value
                is Asset -> value
                is Pins -> value
                is Buttons -> null
            }


        fun setIndex(index: Double, prevHasFixedPosition: Boolean = false, nextHasFixedPosition: Boolean = false) =
            when (this) {
                is Header -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Search -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is PageBreak -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is BasicTextInput -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is BasicSelectInput -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is BasicMultipleSelectInput -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Choices -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is TextInput -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Question -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Link -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Asset -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Pins -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
                is Buttons -> copy(navigation = navigation.setIndex(index = index, prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition))
            }

        fun setCurrentPage(currentPage: Int) =
            when (this) {
                is Header -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Search -> copy(navigation = navigation.copy(currentPage = currentPage))
                is PageBreak -> copy(navigation = navigation.copy(currentPage = currentPage))
                is BasicTextInput -> copy(navigation = navigation.copy(currentPage = currentPage))
                is BasicSelectInput -> copy(navigation = navigation.copy(currentPage = currentPage))
                is BasicMultipleSelectInput -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Choices -> copy(navigation = navigation.copy(currentPage = currentPage))
                is TextInput -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Question -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Link -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Asset -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Pins -> copy(navigation = navigation.copy(currentPage = currentPage))
                is Buttons -> buttons(params = params, navigation = navigation.copy(currentPage = currentPage))
            }

        fun setValue(value: ActivityValue) =
            when (this) {
                is Header -> this
                is Search -> this
                is PageBreak -> this
                is BasicTextInput -> copy(value = value, input = input.copy(value = value.stringValue))
                is BasicSelectInput -> copy(value = value, input = input.copy(selected = value.optionValue))
                is BasicMultipleSelectInput -> copy(value = value, input = input.copy(selected = value.optionValues))
                is Choices -> copy(value = value, input = input.copy(value = value.stringValue), choices = choices.copy(value = value.listValue?.joinToString(", ")))
                is TextInput -> copy(value = value, input = input.copy(value = value.stringValue))
                is Question -> copy(value = value, input = input.copy(value = value.stringValue), choices = choices.copy(value = value.listValue?.joinToString(", ")), answer = answer.copy(value = value.answerValue))
                is Link -> copy(value = value, input = input.copy(value = value.stringValue), hint = hint.copy(value = value.hintValue), url = url.copy(value = value.urlValue?.toString()))
                is Asset -> copy(value = value, input = input.copy(value = value.stringValue), hint = hint.copy(value = value.hintValue), asset = asset.copy(selected = value.optionValue))
                is Pins -> copy(value = value, titleBar = titleBar.copy(booleanInput = titleBar.booleanInput?.copy(value = value.booleanValue)))
                is Buttons -> this
            }

        fun setStatus(status: List<ValidationStatus>): ViewModel =
            when (this) {
                is Header -> this
                is Search -> this
                is PageBreak -> this
                is BasicTextInput -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is BasicSelectInput -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is BasicMultipleSelectInput -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is Choices -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is TextInput -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is Question -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is Link -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is Asset -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is Pins -> setStatus(status.statusOf(header.append("-input")), status.statusOf(header.append("-choices")), status.statusOf(header.append("-answer")), status.statusOf(header.append("-hint")), status.statusOf(header.append("-url")), status.statusOf(header.append("-asset")))
                is Buttons -> this
            }

        fun resetStatus(): ViewModel =
            when (this) {
                is Header -> this
                is Search -> this
                is PageBreak -> this
                is BasicTextInput -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is BasicSelectInput -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is BasicMultipleSelectInput -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is Choices -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is TextInput -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is Question -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is Link -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is Asset -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is Pins -> setStatus(Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid, Status.Valid)
                is Buttons -> this
            }

        private fun setStatus(inputStatus: Status, choicesStatus: Status, answerStatus: Status, hintStatus: Status, urlStatus: Status, assetStatus: Status): ViewModel =
            when (this) {
                is Header -> this
                is Search -> this
                is PageBreak -> this
                is BasicTextInput -> copy(input = input.copy(status = inputStatus))
                is BasicSelectInput -> copy(input = input.copy(status = inputStatus))
                is BasicMultipleSelectInput -> copy(input = input.copy(status = inputStatus))
                is Choices -> copy(input = input.copy(status = inputStatus), choices = choices.copy(status = choicesStatus))
                is TextInput -> copy(input = input.copy(status = inputStatus))
                is Question -> copy(input = input.copy(status = inputStatus), choices = choices.copy(status = choicesStatus), answer = answer.copy(status = answerStatus))
                is Link -> copy(input = input.copy(status = inputStatus), hint = hint.copy(status = hintStatus), url = url.copy(status = urlStatus))
                is Asset -> copy(input = input.copy(status = inputStatus), hint = hint.copy(status = hintStatus), asset = asset.copy(status = assetStatus))
                is Pins -> copy(titleBar = titleBar.copy(booleanInput = titleBar.booleanInput?.copy(status = inputStatus)))
                is Buttons -> this
            }
    }

    fun header(id: Identifier<ActivityItem> = Identifier(), titles: List<String>): ViewModel =
        ViewModel.Header(
            id = id,
            navigation = Navigation(hasFixedPosition = true),
            titles = titles
        )

    fun search(id: Identifier<ActivityItem>, label: String, info: String, active: Boolean, hasFixedPosition: Boolean = true): ViewModel =
        ViewModel.Search(
            id = id,
            navigation = Navigation(
                hasFixedPosition = hasFixedPosition,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP, visible = !hasFixedPosition),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN, visible = !hasFixedPosition),
            ),
            title = DesignSystem.Text(text = label, size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD),
            info = DesignSystem.Text(text = info, size = DesignSystem.SizeType.MD, visible = info.isNotEmpty()),
            status = DesignSystem.Chip(
                message = DesignSystem.Text(text = if (active) "Aktiv - syns i appen" else "Inaktiv - syns ej i appen", size = DesignSystem.SizeType.SM),
                style = if (active) DesignSystem.PaletteType.SUCCESS else DesignSystem.PaletteType.WARNING
            ),
            more = moreButton(active),
        )

    fun basicName(header: DesignSystem.Header, label: String, displayPage: Int = 1, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.BasicTextInput(
            id = Identifier(header.id),
            navigation = Navigation(hasFixedPosition = true, displayPage = displayPage),
            header = header,
            value = value,
            input = DesignSystem.TextInput(
                header = header.append("-input"),
                label = label,
                placeholder = null,
                type = DesignSystem.TextInputType.TEXT,
                value = value.stringValue,
            ),
        )

    fun basicInfo(header: DesignSystem.Header, label: String, displayPage: Int = 1, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.BasicTextInput(
            id = Identifier(header.id),
            navigation = Navigation(hasFixedPosition = true, displayPage = displayPage),
            header = header,
            value = value,
            input = DesignSystem.TextInput(
                header = header.append("-input"),
                label = label,
                placeholder = null,
                type = DesignSystem.TextInputType.NOVEL,
                value = value.stringValue,
            ),
        )

    fun basicFeed(header: DesignSystem.Header, label: String, displayPage: Int = 0, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.BasicTextInput(
            id = Identifier(header.id),
            navigation = Navigation(hasFixedPosition = true, displayPage = displayPage),
            header = header,
            value = value,
            input = DesignSystem.TextInput(
                header = header.append("-input"),
                label = label,
                placeholder = null,
                type = DesignSystem.TextInputType.TEXT,
                value = value.stringValue,
            ),
        )

    fun basicAsset(header: DesignSystem.Header, label: String, assets: List<Asset>, displayPage: Int = 1, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.BasicSelectInput(
            id = Identifier(header.id),
            navigation = Navigation(hasFixedPosition = true, displayPage = displayPage),
            header = header,
            value = value,
            input = DesignSystem.SelectInput(
                header = header.append("-input"),
                label = label,
                selected = value.optionValue,
                options = assets.map { it.option }
            ),
        )

    fun basicMultiple(header: DesignSystem.Header, label: String, values: List<String>, displayPage: Int = 1, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.BasicMultipleSelectInput(
            id = Identifier(header.id),
            navigation = Navigation(hasFixedPosition = true, displayPage = displayPage),
            header = header,
            value = value,
            input = DesignSystem.MultipleSelectInput(
                header = header,
                label = label,
                selected = value.optionValues ?: emptyList(),
                options = values.map { DesignSystem.Option(title = it, value = it) }
            ),
        )

    fun pageBreak(header: DesignSystem.Header, label: String, displayPage: Int = 2): ViewModel =
        ViewModel.PageBreak(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN),
            ),
            titleBar = TitleBar.normal(label),
            header = header,
        )

    fun choices(header: DesignSystem.Header, label: String, displayPage: Int = 2, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.Choices(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN),
            ),
            titleBar = TitleBar.normal(label),
            header = header,
            value = value,
            input = DesignSystem.TextInput(header = header.append("-input"), label = "Rubrik", value = value.stringValue, type = DesignSystem.TextInputType.TEXT),
            choices = DesignSystem.TextInput(header = header.append("-choices"), label = "Svarsalternativ", value = value.listValue?.joinToString(", "), type = DesignSystem.TextInputType.TEXT),
        )

    fun title(header: DesignSystem.Header, label: String, displayPage: Int = 2, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.TextInput(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN),
            ),
            titleBar = TitleBar.normal(label),
            header = header,
            value = value,
            input = DesignSystem.TextInput(header = header.append("-input"), label = "Rubrik", value = value.stringValue, type = DesignSystem.TextInputType.TEXT),
        )

    fun question(header: DesignSystem.Header, label: String, displayPage: Int = 2, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.Question(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN),
            ),
            titleBar = TitleBar.normal(label),
            header = header,
            value = value,
            input = DesignSystem.TextInput(header = header.append("-input"), label = "Rubrik", value = value.stringValue, type = DesignSystem.TextInputType.TEXT),
            choices = DesignSystem.TextInput(header = header.append("-choices"), label = "Svarsalternativ", value = value.listValue?.joinToString(", "), type = DesignSystem.TextInputType.TEXT),
            answer = DesignSystem.TextInput(header = header.append("-answer"), label = "Rätt svar", value = value.answerValue, type = DesignSystem.TextInputType.TEXT),
        )

    fun link(header: DesignSystem.Header, label: String, displayPage: Int = 2, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.Link(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN),
            ),
            titleBar = TitleBar.normal(label),
            header = header,
            value = value,
            input = DesignSystem.TextInput(header = header.append("-input"), label = "Rubrik", value = value.stringValue, type = DesignSystem.TextInputType.TEXT),
            hint = DesignSystem.TextInput(header = header.append("-hint"), label = "Information", value = value.hintValue, type = DesignSystem.TextInputType.TEXT),
            url = DesignSystem.TextInput(header = header.append("-url"), label = "Länk", value = value.urlValue, type = DesignSystem.TextInputType.TEXT),
        )

    fun asset(header: DesignSystem.Header, label: String, assets: List<Asset>, displayPage: Int = 2, value: ActivityValue = ActivityValue.None): ViewModel =
        ViewModel.Asset(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                up = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.UP, action = DesignSystem.Action.UP),
                down = DesignSystem.Button(text = "", style = DesignSystem.PaletteType.GREY, image = DesignSystem.Image.DOWN, action = DesignSystem.Action.DOWN),
            ),
            titleBar = TitleBar.normal(label),
            header = header,
            value = value,
            input = DesignSystem.TextInput(header = header.append("-input"), label = "Rubrik", value = value.stringValue, type = DesignSystem.TextInputType.TEXT),
            hint = DesignSystem.TextInput(header = header.append("-hint"), label = "Information", value = value.hintValue, type = DesignSystem.TextInputType.TEXT),
            asset = DesignSystem.SelectInput(header = header.append("-asset"), label = "Välj material", selected = value.optionValue, options = assets.map { DesignSystem.Option(title = it.name, value = it.id.rawValue) }),
        )

    fun pins(header: DesignSystem.Header, label: String, displayPage: Int = 1, value: ActivityValue = ActivityValue.None, image: DesignSystem.ImageView = DesignSystem.ImageView(visible = false)): ViewModel =
        ViewModel.Pins(
            id = Identifier(header.id),
            navigation = Navigation(
                displayPage = displayPage,
                hasFixedPosition = true,
            ),
            titleBar = TitleBar.withBooleanInput(
                label = label,
                booleanInput = DesignSystem.BooleanInput(header = header.append("-input"), value = value.booleanValue, style = DesignSystem.BooleanStyle.SWITCH),
            ),
            header = header,
            value = value,
            image = image,
        )

    fun buttons(params: ButtonsParams, navigation: Navigation = Navigation(hasFixedPosition = true)): ViewModel =
        ViewModel.Buttons(
            id = params.id,
            navigation = navigation,
            params = params,
            back = DesignSystem.Button(text = "Tillbaka", style = DesignSystem.PaletteType.SECONDARY, visible = navigation.currentPage == 1, action = DesignSystem.Action.SEARCH),
            next = DesignSystem.Button(text = "Nästa", style = DesignSystem.PaletteType.PRIMARY, visible = navigation.currentPage == 1, action = DesignSystem.Action.NEXT),
            submit = DesignSystem.Button(text = "Spara", style = DesignSystem.PaletteType.PRIMARY, visible = navigation.currentPage == 2 && params.mode is Mode.Create, action = DesignSystem.Action.CREATE),
            edit = DesignSystem.Button(text = "Spara", style = DesignSystem.PaletteType.PRIMARY, visible = navigation.currentPage == 2 && params.mode is Mode.Edit, action = DesignSystem.Action.EDIT),
            prev = DesignSystem.Button(text = "Tillbaka", style = DesignSystem.PaletteType.PRIMARY, visible = navigation.currentPage == 2, action = DesignSystem.Action.PREV),
            add = DesignSystem.Button(text = "Lägg till", style = DesignSystem.PaletteType.PRIMARY, visible = navigation.currentPage == 2, action = DesignSystem.Action.ADD, menu = params.options),
        )

    private fun moreButton(active: Boolean) =
        DesignSystem.Button(
            text = "",
            style = DesignSystem.PaletteType.GREY,
            image = DesignSystem.Image.EDIT,
            menu = listOf(
                if (active)
                    DesignSystem.Option(title = "Inaktivera", value = DesignSystem.Action.DISABLE.toString(), action = DesignSystem.Action.DISABLE)
                else
                    DesignSystem.Option(title = "Aktivera", value = DesignSystem.Action.ENABLE.toString(), action = DesignSystem.Action.ENABLE),
                DesignSystem.Option(title = "Ändra", value = DesignSystem.Action.EDIT.toString(), action = DesignSystem.Action.EDIT),
                // DesignSystem.Option(title = "Duplicera", value = DesignSystem.Action.DUPLICATE.toString(), action = DesignSystem.Action.DUPLICATE),
                DesignSystem.Option(title = "Radera", value = DesignSystem.Action.DELETE.toString(), action = DesignSystem.Action.DELETE),
            ),
        )
}

fun List<ActivityItem.ViewModel>.decorate(mode: Mode, titles: List<String> = emptyList(), options: List<DesignSystem.Option> = emptyList()): List<ActivityItem.ViewModel> {
    val header = listOf(
        ActivityItem.header(
            titles = titles
        )
    )
    val footer = listOf(
        ActivityItem.buttons(params = ActivityItem.ButtonsParams(mode = mode, options = options))
    )
    val all = if (mode is Mode.Search)
        this
    else
        header + this + footer

    return all
        .order()
        .setCurrentPage(currentPage = 1)
}

fun List<ActivityItem.ViewModel>.order() =
    mapIndexed { index, item ->
        val prevHasFixedPosition = getOrNull(index - 1)?.navigation?.hasFixedPosition ?: true
        val nextHasFixedPosition = getOrNull(index + 1)?.navigation?.hasFixedPosition ?: true
        item.setIndex(index = index.toDouble(), prevHasFixedPosition = prevHasFixedPosition, nextHasFixedPosition = nextHasFixedPosition)
    }

fun List<ActivityItem.ViewModel>.setCurrentPage(currentPage: Int) =
    map { item -> item.setCurrentPage(currentPage = currentPage) }

fun List<ActivityItem.ViewModel>.setValue(id: Identifier<ActivityItem>, value: ActivityValue) =
    map { item -> if (item.id == id) item.setValue(value) else item }

fun List<ActivityItem.ViewModel>.prev() =
    setCurrentPage(currentPage = (firstOrNull()?.navigation?.currentPage ?: 0) - 1)

fun List<ActivityItem.ViewModel>.next() =
    setCurrentPage(currentPage = (firstOrNull()?.navigation?.currentPage ?: 0) + 1)

fun List<ActivityItem.ViewModel>.up(id: Identifier<ActivityItem>) =
    map { item ->
        if (item.id == id && !item.navigation.prevHasFixedPosition)
            item.setIndex(index = item.navigation.index.minus(1.5))
        else
            item
    }
        .sortedBy { it.navigation.index }
        .order()

fun List<ActivityItem.ViewModel>.down(id: Identifier<ActivityItem>) =
    map { item ->
        if (item.id == id && !item.navigation.nextHasFixedPosition)
            item.setIndex(index = item.navigation.index.plus(1.5))
        else
            item
    }
        .sortedBy { it.navigation.index }
        .order()

fun List<ActivityItem.ViewModel>.add(item: ActivityItem.ViewModel): List<ActivityItem.ViewModel> {
    val head = dropLast(1)
    val tail = takeLast(1)
    val new = item.setCurrentPage(head.firstOrNull()?.navigation?.currentPage ?: 0)
    return (head + new + tail)
        .order()
}

fun List<ActivityItem.ViewModel>.delete(id: Identifier<ActivityItem>) =
    filter { it.id != id }
        .order()


fun List<ActivityItem.ViewModel>.activityValues() =
    filter { it.hasActivityValue }.map { it.activityHeader!! to it.activityValue!! }

fun List<ActivityItem.ViewModel>.validate(texts: ActivityTexts, displayPage: Int?): List<ValidationStatus> =
    filter { if (displayPage != null) it.navigation.displayPage == displayPage else true }
        .let { list ->
            list.filterIsInstance<ActivityItem.ViewModel.BasicTextInput>().flatMap { it.validate(texts) } +
                    list.filterIsInstance<ActivityItem.ViewModel.BasicSelectInput>().flatMap { it.validate(texts) } +
                    list.filterIsInstance<ActivityItem.ViewModel.Choices>().flatMap { it.validate(texts) } +
                    list.filterIsInstance<ActivityItem.ViewModel.TextInput>().flatMap { it.validate(texts) } +
                    list.filterIsInstance<ActivityItem.ViewModel.Question>().flatMap { it.validate(texts) } +
                    list.filterIsInstance<ActivityItem.ViewModel.Link>().flatMap { it.validate(texts) } +
                    list.filterIsInstance<ActivityItem.ViewModel.Asset>().flatMap { it.validate(texts) }
        }

fun ActivityItem.ViewModel.BasicTextInput.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    return status
}

fun ActivityItem.ViewModel.BasicSelectInput.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.selected?.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    return status
}

fun ActivityItem.ViewModel.Choices.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    Validator.text(
        text = choices.value,
        isEmpty = { status.add(header.append("-choices") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-choices") to Status.Valid) },
        formatted = { }
    )
    return status
}

fun ActivityItem.ViewModel.TextInput.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    return status
}

fun ActivityItem.ViewModel.Question.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    Validator.text(
        text = choices.value,
        isEmpty = { status.add(header.append("-choices") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-choices") to Status.Valid) },
        formatted = { }
    )
    Validator.text(
        text = answer.value,
        isEmpty = { status.add(header.append("-answer") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-answer") to Status.Valid) },
        formatted = { }
    )
    return status
}

fun ActivityItem.ViewModel.Link.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    // Don't validate hint, allow it to be blank
/*    Validator.text(
        text = hint.value,
        isEmpty = { status.add(header.append("-hint") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-hint") to Status.Valid) },
        formatted = { }
    )*/
    Validator.text(
        text = url.value,
        isEmpty = { status.add(header.append("-url") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-url") to Status.Valid) },
        formatted = { }
    )
    return status
}

fun ActivityItem.ViewModel.Asset.validate(texts: ActivityTexts): List<ValidationStatus> {
    val status: MutableList<ValidationStatus> = mutableListOf()
    Validator.text(
        text = input.value,
        isEmpty = { status.add(header.append("-input") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-input") to Status.Valid) },
        formatted = { }
    )
    // Don't validate hint, allow it to be blank
/*    Validator.text(
        text = hint.value,
        isEmpty = { status.add(header.append("-hint") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-hint") to Status.Valid) },
        formatted = { }
    )*/
    Validator.text(
        text = asset.selected?.value,
        isEmpty = { status.add(header.append("-asset") to Status.Invalid(warning = texts.fieldRequired)) },
        passed = { status.add(header.append("-asset") to Status.Valid) },
        formatted = { }
    )
    return status
}
