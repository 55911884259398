package screens

import kotlinx.datetime.Month
import services.findStatistics
import services.findTopList
import support.*
import techla.base.*
import techla.guard.Profile

val Profile.fullName
    get() = "$firstName $lastName"

fun currentMonthName() =
    when (Date.now().dateTime.month) {
        Month.JANUARY -> "Januari"
        Month.FEBRUARY -> "Februari"
        Month.MARCH -> "Mars"
        Month.APRIL -> "April"
        Month.MAY -> "Maj"
        Month.JUNE -> "Juni"
        Month.JULY -> "Juli"
        Month.AUGUST -> "Augusti"
        Month.SEPTEMBER -> "September"
        Month.OCTOBER -> "Oktober"
        Month.NOVEMBER -> "November"
        Month.DECEMBER -> "December"
    }

object DashboardScreen {
    object Header {
        val showStatistics = DesignSystem.Header(id = "showStatistics")
        val topList = DesignSystem.Header(id = "topList")
    }

    data class Texts(
        val dashboard: String,
        val perMonth: String,
        val users: String,
        val authentications: String,
        val messages: String,
        val uniqueMessages: String,
        val uniqueLikes: String,
        val uniqueComments: String,
        val uniqueBoostsCreated: String,
        val uniqueBoostsReceived: String,
        val lastMonth: String,
        val reportStatsTitle: String,
        val topListTitle: String,
        val noDataIcon: String,
        val noDataText: String,
    )

    data class State(
        val statistics: Statistics?,
        val topLists: TopLists?,
        val selectedStatistic: DesignSystem.Option = DesignSystem.Option(title = "Inlägg", value = StatisticType.MESSAGES.name),
        val selectedTopList: DesignSystem.Option = DesignSystem.Option(title = "Fått Boosts", value = TopListType.BOOSTS_RECEIVED.name),
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        data object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, null),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val perMonth: DesignSystem.Text,
            val users: DesignSystem.Text,
            val authentications: DesignSystem.Text,
            val messages: DesignSystem.Text,
            val selectStatistic: DesignSystem.SelectInput,
            val selectTopList: DesignSystem.SelectInput,
            val uniqueMessages: DesignSystem.Text,
            val uniqueLikes: DesignSystem.Text,
            val uniqueComments: DesignSystem.Text,
            val uniqueBoostsCreated: DesignSystem.Text,
            val uniqueBoostsReceived: DesignSystem.Text,
            val reportStatsTitle: DesignSystem.Text,
            val topList: DesignSystem.Table<Unit>,
            val topListTitle: DesignSystem.Text,
            val noDataIcon: DesignSystem.Text,
            val noDataText: DesignSystem.Text,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel = Ready(
            texts = texts,
            state = state,
            title = DesignSystem.Text(text = texts.dashboard, size = DesignSystem.SizeType.XL2, style = DesignSystem.StyleType.BOLD),
            perMonth = DesignSystem.Text(text = texts.perMonth, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
            users = DesignSystem.Text(text = texts.users, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.DARK),
            authentications = DesignSystem.Text(text = texts.authentications, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.DARK),
            messages = DesignSystem.Text(text = texts.messages, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.DARK),
            selectStatistic = DesignSystem.SelectInput(
                header = Header.showStatistics,
                label = null,
                selected = state.selectedStatistic,
                options = listOf(
                    DesignSystem.Option(title = "Inloggningar", value = StatisticType.AUTHENTICATIONS.name),
                    DesignSystem.Option(title = "Unika inloggningar", value = StatisticType.USERS.name),
                    DesignSystem.Option(title = "Inlägg", value = StatisticType.MESSAGES.name),
                    DesignSystem.Option(title = "Boostningar", value = StatisticType.BOOSTS.name),
                    DesignSystem.Option(title = "eNPS", value = StatisticType.EMPLOYEE_NPS.name),
                    DesignSystem.Option(title = "mNPS", value = StatisticType.MANAGER_NPS.name),
                    DesignSystem.Option(title = "Nya anställda", value = StatisticType.ENTERED.name),
                    DesignSystem.Option(title = "Anställda som slutat", value = StatisticType.EXITED.name),
                )
            ),
            selectTopList = DesignSystem.SelectInput(
                header = Header.topList,
                label = null,
                selected = state.selectedTopList,
                options = listOf(
                    DesignSystem.Option(title = "Fått Boosts", value = TopListType.BOOSTS_RECEIVED.name),
                    DesignSystem.Option(title = "Gett Boosts", value = TopListType.BOOSTS_CREATED.name),
                    DesignSystem.Option(title = "Lagt inlägg", value = TopListType.MESSAGES.name),
                    DesignSystem.Option(title = "Gillat", value = TopListType.LIKES.name),
                    DesignSystem.Option(title = "Kommenterat", value = TopListType.COMMENTS.name),
                    DesignSystem.Option(title = "Loggat in", value = TopListType.USER_AUTHENTICATIONS.name),
                    DesignSystem.Option(title = "Flest reaktioner", value = TopListType.MOST_REACTIONS.name),
                )
            ),
            uniqueMessages = DesignSystem.Text(text = texts.uniqueMessages, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.LIGHT),
            uniqueLikes = DesignSystem.Text(text = texts.uniqueLikes, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.LIGHT),
            uniqueComments = DesignSystem.Text(text = texts.uniqueComments, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.LIGHT),
            uniqueBoostsCreated = DesignSystem.Text(text = texts.uniqueBoostsCreated, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.LIGHT),
            uniqueBoostsReceived = DesignSystem.Text(text = texts.uniqueBoostsReceived, size = DesignSystem.SizeType.MD, background = DesignSystem.Background.LIGHT),
            reportStatsTitle = DesignSystem.Text(text = texts.reportStatsTitle, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
            topList = DesignSystem.Table(
                titles = listOf(
                    DesignSystem.Text(
                        text = "Topp 10, ${state.selectedTopList.title}",
                        size = DesignSystem.SizeType.LG,
                        style = DesignSystem.StyleType.BOLD
                    ),
                    DesignSystem.Text(""),
                ),
                data = when (state.selectedTopList.value) {
                    TopListType.BOOSTS_CREATED.name -> state.topLists?.boostsCreated
                    TopListType.BOOSTS_RECEIVED.name -> state.topLists?.boostsReceived
                    TopListType.USER_AUTHENTICATIONS.name -> state.topLists?.userAuthentications
                    TopListType.MESSAGES.name -> state.topLists?.messages
                    TopListType.COMMENTS.name -> state.topLists?.comments
                    TopListType.LIKES.name -> state.topLists?.likes
                    TopListType.MOST_REACTIONS.name -> state.topLists?.mostReactions
                    else -> emptyList()
                }?.map {
                    Unit to listOf(
                        DesignSystem.Container(
                            listOf(
                                DesignSystem.Text(text = it.initials, size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, background = DesignSystem.Background.DARK),
                                DesignSystem.Text(text = it.name, size = DesignSystem.SizeType.MD),
                            )
                        ),
                        DesignSystem.Text(text = it.count.toString(), size = DesignSystem.SizeType.MD, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.RIGHT),
                    )
                }
            ),
            topListTitle = DesignSystem.Text(text = texts.topListTitle, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
            noDataIcon = DesignSystem.Text(text = texts.noDataIcon, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
            noDataText = DesignSystem.Text(text = texts.noDataText, size = DesignSystem.SizeType.MD, align = DesignSystem.TextAlign.CENTER),
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val texts = Texts(
            dashboard = "Start",
            perMonth = "Total per månad",
            users = "Anställda",
            authentications = "Totalt antal inloggningar",
            messages = store.standard?.let { "Inlägg i ${it.key.rawValue}" } ?: "Inlägg",
            uniqueMessages = "Lagt inlägg",
            uniqueLikes = "Gillat",
            uniqueComments = "Kommenterat",
            lastMonth = "förra månaden",
            reportStatsTitle = "Användare som under ${currentMonthName()}",
            topListTitle = "Topplista ${currentMonthName()}",
            uniqueBoostsCreated = "Gett Boost",
            uniqueBoostsReceived = "Fått Boost",
            noDataIcon = "\uD83D\uDCEA",
            noDataText = "Ingen data tillgänglig",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun setValues(scene: Scene.Input<ViewModel>, selectedStatistic: DesignSystem.Option? = null, selectedTopList: DesignSystem.Option? = null): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state.copy(
            selectedStatistic = selectedStatistic ?: viewModel.state.selectedStatistic,
            selectedTopList = selectedTopList ?: viewModel.state.selectedTopList,
            topLists = selectedTopList?.let {
                store.findTopList(TopListType.valueOf(selectedTopList.value))
            } ?: viewModel.state.topLists,
        )
        return sceneOf(viewModel.ready(state = state))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val state = State(
            statistics = store.findStatistics(),
            topLists = store.findTopList(TopListType.valueOf(viewModel.state.selectedTopList.value)),
        )
        return sceneOf(viewModel.ready(state = state))
    }
}