package support

import io.ktor.http.*
import screens.items.ActivityValue
import techla.base.*
import techla.form.Field
import techla.form.Form


object Quiz {
    val CHOICES = "CHOICES"
    val TEAM = "TEAM"
    val PAGE_BREAK = "PAGE_BREAK"

    val CITY = "CITY"
    val DEPARTMENT = "DEPARTMENT"
    val POSITION = "POSITION"
    val GOVID = "GOVID"
    val FIRST_NAME = "FIRST_NAME"
    val LAST_NAME = "LAST_NAME"
    val CORRECT_ANSWERS = "SCORE"

    object PageBreak

    fun stringFromStyle(s: Field.Style) =
        when (s) {
            is Field.Style.Choice -> "$CHOICES-"
            is Field.Style.Short -> "$TEAM-"

            is Field.Style.City -> "$CITY-"
            is Field.Style.Department -> "$DEPARTMENT-"
            is Field.Style.Position -> "${POSITION}-"
            is Field.Style.GovId -> "$GOVID-"
            is Field.Style.FirstName -> "$FIRST_NAME-"
            is Field.Style.LastName -> "$LAST_NAME-"
            is Field.Style.CorrectAnswers -> "$CORRECT_ANSWERS-"
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    fun styleFromString(s: String, value: ActivityValue) =
        when {
            s.startsWith("$CHOICES-") -> Field.Style.Choice(value.listValue ?: emptyList())
            s.startsWith("$TEAM-") -> Field.Style.Short

            s.startsWith("$CITY-") -> Field.Style.City
            s.startsWith("$DEPARTMENT-") -> Field.Style.Department
            s.startsWith("$POSITION-") -> Field.Style.Position
            s.startsWith("$GOVID-") -> Field.Style.GovId
            s.startsWith("$FIRST_NAME-") -> Field.Style.FirstName
            s.startsWith("$LAST_NAME-") -> Field.Style.LastName
            s.startsWith("$CORRECT_ANSWERS-") -> Field.Style.CorrectAnswers
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    fun predefinedFromString(s: String, value: ActivityValue) =
        null

    private fun requiredFromString(s: String) =
        when {
            s.startsWith("$CHOICES-") -> true
            s.startsWith("$TEAM-") -> true
            else -> false
        }

    fun userFields(values: List<Pair<DesignSystem.Header, ActivityValue>>): List<Either<Field, PageBreak>> =
        values.mapIndexed { index, (header, value) ->
            if (header.id == PAGE_BREAK)
                rightOf(PageBreak)
            else {
                val label = value.stringValue ?: ""
                val answer = value.answerValue
                val hint = value.hintValue
                val url = value.urlValue?.let { Url(it) }
                val assetId = value.assetValue
                val style = styleFromString(s = header.id, value = value)
                val predefined = predefinedFromString(s = header.id, value = value)
                val required = requiredFromString(s = header.id)
                leftOf(Field(id = Identifier(), key = Key(label), style = style, order = index, page = 1, required = required, hidden = false, autofill = false, label = label, predefined = predefined, min = null, max = null, placeholder = null, hint = hint, answer = answer, url = url, assetId = assetId, attributes = emptyList()))
            }
        }

    fun hiddenFields(start: Int): List<Either<Field, PageBreak>> =
        listOf(
            Field(id = Identifier(), key = Key(GOVID), style = Field.Style.GovId, order = start, page = 1, required = false, hidden = true, autofill = true, label = "Personnummer", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(CITY), style = Field.Style.City, order = start + 1, page = 1, required = false, hidden = true, autofill = true, label = "Stad", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(DEPARTMENT), style = Field.Style.Department, order = start + 2, page = 1, required = false, hidden = true, autofill = true, label = "Avdelning", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(POSITION), style = Field.Style.Position, order = start + 3, page = 1, required = false, hidden = true, autofill = true, label = "Roll", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(FIRST_NAME), style = Field.Style.FirstName, order = start + 4, page = 1, required = false, hidden = true, autofill = true, label = "Förnamn", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(LAST_NAME), style = Field.Style.LastName, order = start + 5, page = 1, required = false, hidden = true, autofill = true, label = "Efternamn", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(CORRECT_ANSWERS), style = Field.Style.CorrectAnswers, order = start + 6, page = 1, required = false, hidden = true, autofill = true, label = "Rätta svar", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
        ).map { leftOf(it) }

    fun fields(values: List<Pair<DesignSystem.Header, ActivityValue>>): List<Field> {
        var page = 1
        var order = 0
        val userFields = userFields(values)
        val hiddenFields = hiddenFields(userFields.size)

        return (userFields + hiddenFields)
            .mapNotNull { field ->
                when (field) {
                    is Either.Left -> {
                        order += 1
                        field.value.copy(page = page, order = order)
                    }

                    is Either.Right -> {
                        page += 1
                        null
                    }
                }
            }
    }
}
