package views

import ROUTES
import StoreContext
import components.*
import emotion.react.css
import kotlinx.browser.localStorage
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.*
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import screens.LoginScreen
import support.*
import kotlin.time.ExperimentalTime
import web.cssom.*


@OptIn(ExperimentalTime::class)
val Login = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: LoginScreen.ViewModel by useState(LoginScreen.ViewModel.None)

    var callCheck by useState(true)
    var count by useState(0)
    var showDemoModal by useState(false)

    var savedCrew by useLocalStorage("crew", "")
    var savedPhone by useLocalStorage("phone", "")

    val navigate = useNavigate()
    val reactScope by useReactScope()

    val executor by useExecutor<LoginScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    fun clearLocalStorage() {
        localStorage.removeItem("govId")
        localStorage.removeItem("crew")
        localStorage.removeItem("moodi.rememberMe")
    }

    fun handleDemoMode(isPro: Boolean) {
        executor.call({ LoginScreen.demo(sceneInputOf(store, viewModel), isPro = isPro) }) {
            navigate(ROUTES.DASHBOARD.INDEX)
        }
    }

    suspend fun onLogin() {
        executor.call({ LoginScreen.validate(sceneInputOf(store, viewModel)) }) { updated ->
            val ready = updated as? LoginScreen.ViewModel.Ready
            if (ready?.status is Status.Valid) {
                executor.call({ LoginScreen.login(sceneInputOf(store, updated)) }) {
                    callCheck = true
                }
            } else if (ready?.status == null) {
                callCheck = true
                count = 0
            }
        }
    }

    suspend fun onVerify() {
        executor.call({ LoginScreen.validate(sceneInputOf(store, viewModel)) }) { updated ->
            val verify = updated as? LoginScreen.ViewModel.Verify
            if (verify?.status is Status.Valid) {
                executor.call({ LoginScreen.verify(sceneInputOf(store, updated)) }) {
                    callCheck = true
                }
            }
        }
    }

    fun handleCancel() {
        executor.call { LoginScreen.cancel(sceneInputOf(store, viewModel)).also { callCheck = false } }
    }

    fun onCrew(crew: String) {
        savedCrew = crew
        executor.call {
            LoginScreen.setValues(sceneInputOf(store, viewModel), crew = crew)
        }
    }

    fun onPhone(phone: String) {
        savedPhone = phone
        executor.call {
            LoginScreen.setValues(sceneInputOf(store, viewModel), phone = phone)
        }
    }

    fun onCode(code: String) {
        executor.call {
            LoginScreen.setValues(sceneInputOf(store, viewModel), code = code)
        }
    }

    suspend fun check() {
        if (callCheck && viewModel !is LoginScreen.ViewModel.QRCode) {
            count += 1
            LoginScreen.check(sceneInputOf(store, viewModel)).also { (updated, actions) ->
                when (updated) {
                    is LoginScreen.ViewModel.Waiting -> {}
                    else -> {
                        viewModel = updated
                        dispatch(actions)
                    }
                }
            }
        }
    }

    suspend fun qr() {
        if (callCheck && viewModel is LoginScreen.ViewModel.QRCode) {
            LoginScreen.checkQrCode(sceneInputOf(store, viewModel)).also { (updated, actions) ->
                when (updated) {
                    is LoginScreen.ViewModel.Waiting -> {}
                    else -> {
                        count += 1
                        viewModel = updated
                        dispatch(actions)
                    }
                }
            }
        }
    }

    useEffect(count) {
        if (callCheck && count > 0 && viewModel !is LoginScreen.ViewModel.QRCode) {
            reactScope.launch {
                delay(3000L)
                check()
            }
        }
        if (callCheck && count > 0 && viewModel is LoginScreen.ViewModel.QRCode) {
            reactScope.launch {
                delay(1000)
                qr()
            }
        }
    }

    useEffect(viewModel) {
        when (viewModel) {
            is LoginScreen.ViewModel.None ->
                executor.call {
                    LoginScreen.start(sceneInputOf(store, viewModel))
                }

            is LoginScreen.ViewModel.Loading -> {

                executor.call {
                    LoginScreen.load(sceneInputOf(store, viewModel), savedCrew, savedPhone)
                }
            }

            is LoginScreen.ViewModel.QRCode -> {
                if (count == 0)
                    executor.call {
                        LoginScreen.qrCode(sceneInputOf(store, viewModel)).also { count += 1 }
                    }
            }

            is LoginScreen.ViewModel.Waiting ->
                if (count == 0) {
                    reactScope.launch { check() }
                }

            is LoginScreen.ViewModel.Finished -> {
                callCheck = false
                count = 0
                navigate(ROUTES.DASHBOARD.INDEX)
            }

            is LoginScreen.ViewModel.Expired -> {
                callCheck = false
                count = 0
            }

            is LoginScreen.ViewModel.Failed -> {
                callCheck = false
                count = 0
            }

            else -> {}
        }
    }


    viewModel.asReady?.let { ready ->
        RNavbar {}
        RBackgroundImage {}
        Container {
            maxWidth = "xs"
            Stack {
                spacing = responsive(4)
                sx {
                    justifyContent = JustifyContent.center
                    height = 75.vh
                }

                Stack {
                    spacing = responsive(2)
                    sx {
                        padding = Padding(0.rem, 1.rem)
                    }

                    RText {
                        design = ready.title
                        onDoubleClick = { showDemoModal = true }
                    }
                    RText { design = ready.body }
                }

                div {
                    RForm {
                        onSubmit = ::onLogin

                        Stack {
                            spacing = responsive(2)
                            RTextInput { design = ready.crew; onChange = ::onCrew }
                            RTextInput { design = ready.phone; onChange = ::onPhone }

                            Stack {
                                spacing = responsive(1)
                                RButton { design = ready.loginBankID }
                                RButton { design = ready.loginSMS }

                                RText { design = ready.version }
                            }
                        }
                    }
                }
            }
        }

        Dialog {
            open = showDemoModal
            onClose = { _, _ -> showDemoModal = false }
            fullWidth = true
            maxWidth = "sm"

            sx {
                ".MuiPaper-root" {
                    backgroundColor = Color(Palette.Grey.dark)
                }
            }
            Stack {
                direction = responsive(StackDirection.row)
                sx {
                    justifyContent = JustifyContent.flexEnd
                    padding = Padding(0.rem, 0.5.rem)
                    hover {
                        opacity = 0.5.unsafeCast<Opacity>()
                    }
                }
                Button {
                    onClick = { showDemoModal = false }
                    sx {
                        color = Color(Palette.Grey.light)
                        fontSize = 1.8.rem
                        fontWeight = FontWeight.bold
                    }
                    +"×️"
                }
            }

            DialogContent {
                sx {
                    padding = Padding(0.rem, 2.rem, 3.rem, 2.rem)
                    textAlign = TextAlign.center
                }

                Stack {
                    spacing = responsive(2)
                    RText { design = ready.demoTitle }
                    RText { design = ready.demoBody }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    spacing = responsive(3)
                    sx {
                        paddingTop = 1.rem
                        justifyContent = JustifyContent.center
                    }

                    RButton {
                        design = ready.demoBase
                        onClick = { handleDemoMode(isPro = false) }
                    }
                    RButton {
                        design = ready.demoPro
                        onClick = { handleDemoMode(isPro = true) }
                    }
                }
            }
        }
    }

    viewModel.asQRCode?.let { verify ->
        RNavbar {}
        RBackgroundImage {}
        Container {
            maxWidth = "xs"
            Stack {
                spacing = responsive(4)
                sx {
                    justifyContent = JustifyContent.center
                    height = 75.vh
                }
                div {
                    if (store.qrCode != null && store.qrCode != undefined) {
                        RBankID {
                            qrCode = store.qrCode ?: ""
                            scanQr = verify.title
                        }
                    } else
                        div {
                            css {
                                height = 85.px
                                width = 85.px
                                marginLeft = Auto.auto
                                marginRight = Auto.auto
                                marginBottom = 2.rem
                            }
                            RLottie {
                                this.autoplay = true
                                this.loop = true
                                this.animationData = Design.animation(DesignSystem.Animation.SPINNER)
                            }
                        }
                    Stack {
                        spacing = responsive(2)
                        Stack {
                            spacing = responsive(1)
                            RForm {
                                onSubmit = { handleCancel() }
                                RButton {
                                    design = verify.cancel
                                }
                            }
                        }

                    }
                }
            }
        }
    }

    viewModel.asVerify?.let { verify ->
        RNavbar {}
        RBackgroundImage {}
        Container {
            maxWidth = "xs"
            Stack {
                spacing = responsive(4)
                sx {
                    justifyContent = JustifyContent.center
                    height = 75.vh
                }

                Stack {
                    spacing = responsive(2)
                    sx {
                        padding = Padding(0.rem, 1.rem)
                    }

                    RText { design = verify.title }
                    RText { design = verify.body }
                }

                div {
                    RForm {
                        onSubmit = ::onVerify

                        Stack {
                            spacing = responsive(2)
                            RTextInput { design = verify.code; onChange = ::onCode }

                            Stack {
                                spacing = responsive(1)
                                RButton { design = verify.verify }
                            }
                        }
                    }
                }
            }
        }
    }

    viewModel.asWaiting?.let { waiting ->
        Container {
            maxWidth = "xs"

            Stack {
                spacing = responsive(4)
                sx {
                    justifyContent = JustifyContent.center
                    height = 75.vh
                }

                RText { design = waiting.bankId }

                div {
                    div {
                        css {
                            height = 85.px
                            width = 85.px
                            marginLeft = Auto.auto
                            marginRight = Auto.auto
                        }
                        RLottie {
                            this.autoplay = true
                            this.loop = true
                            this.animationData = Design.animation(DesignSystem.Animation.SPINNER)
                        }
                    }
                }

                RButton { design = waiting.cancel; onClick = { handleCancel() } }
            }
        }
    }

    if (viewModel is LoginScreen.ViewModel.Loading) {
        RLoader {}
    }

    viewModel.asExpired?.let { expired ->
        RBackgroundImage {}
        Container {
            maxWidth = "xs"
            Stack {
                spacing = responsive(4)
                sx {
                    justifyContent = JustifyContent.center
                    height = 75.vh
                }
                Stack {
                    direction = responsive(StackDirection.column)
                    spacing = responsive(2)
                    sx {
                        justifyContent = JustifyContent.center
                        alignItems = AlignItems.center
                        height = 75.pct
                    }
                    RText { design = expired.expireOut }
                    RText { design = expired.expireTitle }
                    RText { design = expired.expireBody }
                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            alignItems = AlignItems.center
                        }
                        RForm {
                            onSubmit = { handleCancel() }
                            RButton {
                                design = expired.resume
                            }
                        }
                    }
                }
            }
        }
    }

    viewModel.asFailed?.let { failed ->
        RError { message = failed.message }
    }
}