package support

import components.RButton
import components.items.*
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import screens.items.ActivityItem
import screens.items.ActivityValue
import techla.base.Identifier
import web.cssom.*

external interface ActivityItemSearchProps : Props {
    var searchImage: DesignSystem.ImageView?
    var searchTitle: DesignSystem.Text?
    var searchBody: DesignSystem.Text?
    var firstButton: DesignSystem.Button?
    var secondButton: DesignSystem.Button?
    var activityItems: List<ActivityItem.ViewModel>?
    var onChange: ((id: Identifier<ActivityItem>, value: ActivityValue) -> Unit)?
    var onAction: ((id: Identifier<ActivityItem>, action: DesignSystem.Action) -> Unit)?
    var onFirst: (() -> Unit)?
    var onSecond: (() -> Unit)?
}

val RActivityItemSearch = FC<ActivityItemSearchProps> { props ->
    fun onFirst(action: DesignSystem.Action) {
        props.onFirst?.let { it() }
    }
    fun onSecond(action: DesignSystem.Action) {
        props.onSecond?.let { it() }
    }

    fun onChange(id: Identifier<ActivityItem>, value: ActivityValue) {
        props.onChange?.let { it(id, value) }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction?.let { it(id, action) }
    }

    RActivityItemTitle {
        imageView = props.searchImage
        titleText = props.searchTitle
        bodyText = props.searchBody
    }

    props.activityItems?.map { item ->
        when (item) {
            is ActivityItem.ViewModel.Header -> ActivityItemHeader { this.viewModel = item }
            is ActivityItem.ViewModel.BasicTextInput -> ActivityItemBasicTextInput { this.viewModel = item; onChange = ::onChange }
            is ActivityItem.ViewModel.Search -> ActivityItemSearch { this.viewModel = item; onAction = ::onAction }

            else -> {}
        }
    }

    Stack {
        id = "RActivityItemSearch-Footer"
        direction = responsive(StackDirection.row)
        spacing = responsive(1.rem)
        sx {
            paddingTop = 1.rem
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
        }
        RButton { design = props.firstButton; onClick = ::onFirst }
        RButton { design = props.secondButton; onClick = ::onSecond }
    }
}
