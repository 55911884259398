package services

import support.ActionOutcome
import support.Demo
import support.Store
import support.noActions
import techla.base.*
import techla.billing.BillingAPI
import techla.billing.BillingAPIResource
import techla.billing.Buyer

suspend fun Store.listBuyers(): ActionOutcome<List<Buyer>> {
    if (demoMode) return Demo.allBuyers(Key(workspace!!.rawValue)).noActions()
    return billingAPI { api ->
        measureAPI(BillingAPIResource.ListBuyers, api) {
            api.listBuyers().onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editBuyer(id: Identifier<Buyer>, edit: Buyer.Edit): ActionOutcome<Buyer> {
    if (demoMode) return Demo.currentBuyer(Key(workspace!!.rawValue)).noActions()
    return billingAPI { api ->
        measureAPI(BillingAPIResource.EditBuyer(id, edit), api) {
            api.editBuyer(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }

    }
}

suspend fun <T> Store.billingAPI(block: suspend (api: BillingAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = BillingAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) BillingAPI.sandbox else BillingAPI.shared
            api.token = updated.userToken
        }
        block(api)
    }
}