package support

import kotlinx.datetime.TimeZone
import kotlinx.datetime.toLocalDateTime
import techla.base.Date
import techla.base.toKotlinxInstant

@JsModule("@js-joda/timezone")
@JsNonModule
external object JsJodaTimeZoneModule

private val jsJodaTz = JsJodaTimeZoneModule

fun localize(date: Date): String  {
    val zonedDateTime = date
        .toKotlinxInstant()
        .toLocalDateTime(TimeZone.of("Europe/Stockholm"))

    val year = zonedDateTime.year
    val month = "${zonedDateTime.monthNumber}".padStart(2, '0')
    val day = "${zonedDateTime.dayOfMonth}".padStart(2, '0')
    val hour = "${zonedDateTime.hour}".padStart(2, '0')
    val minute = "${zonedDateTime.minute}".padStart(2, '0')

    return "$year-$month-$day $hour:$minute"
}
