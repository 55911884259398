package support

import components.RBooleanInput
import components.RButton
import components.RText
import mui.material.Box
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import techla.base.Identifier
import web.cssom.*

external interface ActivityItemProps : PropsWithChildren {
    var hasBorder: Boolean?
    var viewModel: ActivityItem.ViewModel
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
    var onActivate: ((Boolean) -> Unit)?
}

val RActivityItem = FC<ActivityItemProps> { props ->
    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    fun onActivate(value: Boolean) {
        props.onActivate?.let { it(value) }
    }

    Stack {
        id = buildString {
            append("RActivityItem")
            if (props.viewModel.navigation.hasFixedPosition)
                append("-Fixed")
            append("-${props.viewModel.navigation.index}")
        }
        direction = responsive(StackDirection.row)

        Box {
            id = if (props.hasBorder == true)
                "RActivityItem-Box-Bordered"
            else
                "RActivityItem-Box"
            sx {
                display = Display.flex
                flex = Flex.maxContent
                if (props.hasBorder == true) {
                    backgroundColor = Color(Palette.Grey.light)
                    border = Border(0.5.px, LineStyle.solid, Color(Palette.Grey.main))
                    borderRadius = Shape.borderRadius
                    padding = 1.rem
                }
            }

            Stack {
                id = "RActivityItem-Content"
                sx {
                    flex = Flex.maxContent
                }

                Stack {
                    id = "RActivityItem-TitleBar"
                    direction = responsive(StackDirection.row)

                    sx {
                        justifyContent = JustifyContent.spaceBetween
                        alignItems = AlignItems.flexStart
                    }
                    RText { design = props.viewModel.titleBar.style }
                    Stack {
                        direction = responsive(StackDirection.row)
                        spacing = responsive(1)
                        props.viewModel.titleBar.booleanInput?.let { booleanInput ->
                            RBooleanInput { design = booleanInput; onChange = { onActivate(it) } }
                        }
                        RButton { design = props.viewModel.titleBar.options; onClick = { onAction(props.viewModel.id, it) } }
                    }
                }

                +props.children
            }
        }

        Box {
            id = "RActivityItem-Navigation"
            RButton { design = props.viewModel.navigation.up; onClick = { onAction(props.viewModel.id, it) } }
            RButton { design = props.viewModel.navigation.down; onClick = { onAction(props.viewModel.id, it) } }
        }
    }
}
