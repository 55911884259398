package components.items

import components.RImage
import mui.material.Stack
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.DesignSystem
import support.RActivityItem
import techla.base.Identifier
import web.cssom.*

external interface ActivityItemPinsProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.Pins
    var onChange: (Identifier<ActivityItem>, ActivityValue) -> Unit
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemPins = FC<ActivityItemPinsProps> { props ->
    fun onActivate(value: Boolean) {
        props.onChange(props.viewModel.id, ActivityValue.BooleanValue(value))
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    if (props.viewModel.navigation.isVisible) {
        RActivityItem {
            hasBorder = true
            viewModel = props.viewModel
            onAction = ::onAction
            onActivate = ::onActivate

            if (props.viewModel.image.visible) {
                Stack {
                    id = "ActivityItem-Pins"
                    sx {
                        paddingTop = 1.rem
                    }

                    RImage {
                        sx {
                            maxWidth = 100.pct
                        }
                        design = props.viewModel.image
                    }
                }
            }
        }
    }
}