package support

import techla.base.PageContent

sealed class DesignSystem {
    enum class SizeType { XL2, XL, LG, MD, SM, XS, XS2 }
    enum class StyleType { REGULAR, BOLD }
    enum class TextAlign { LEFT, CENTER, RIGHT }
    enum class Background { DARK, LIGHT }
    enum class PaletteType { PRIMARY, SECONDARY, TRANSPARENT, GREY, WARNING, DANGER, SUCCESS, INFO }
    enum class Image { BANKID, WHISTLEBLOWER, MOODI, SUGGESTION_BOX, PULS, BACKGROUNDIMAGE1, BACKGROUNDIMAGE2, QUIZ, UP, DOWN, EDIT, RECRUITMENT, SKILLS, SURVEY, BOOST, STANDARD_PINS }

    enum class Animation { SPINNER, CONFETTI }
    enum class ToastStyle { SUCCESS, ERROR }
    enum class TextInputType { TEXT, GOV_ID, CREW, EMAIL, NUMBER, PHONE, NOVEL, OTP }
    enum class ButtonType { SUBMIT, RESET, BUTTON }
    enum class BooleanStyle { CHECKBOX, SWITCH }

    enum class Action { NONE, EDIT, DELETE, UP, DOWN, DUPLICATE, ADD, BACK, PREV, NEXT, SUBMIT, ENABLE, DISABLE, CHOICES, TEAM, PAGE_BREAK, TEXT, PHONE, EMAIL, CHOICE, QUESTION, CREATE, SHOW, SEARCH, EXTERNAL, INLINE, SAVE, SCORE_0, SCORE_0_MAX, SCORE_1 }

    data class Header(val id: String) {
        fun append(s: String) = copy(id = id + s)

        companion object
    }

    data class Container(val children: List<DesignSystem>): DesignSystem() {
        companion object
    }

    data class Option(val title: String, val value: String, val action: Action = Action.NONE) {
        companion object
    }

    data class Text(
        val text: String = "",
        val size: SizeType = SizeType.SM,
        val style: StyleType = StyleType.REGULAR,
        val visible: Boolean = true,
        val align: TextAlign = TextAlign.LEFT,
        val background: Background = Background.LIGHT,
    ) : DesignSystem() {
        companion object
    }

    data class Button(
        val type: ButtonType = ButtonType.BUTTON,
        val text: String? = null,
        val image: Image? = null,
        val disabled: Boolean = false,
        val danger: Boolean = false,
        val style: PaletteType = PaletteType.PRIMARY,
        val fullWidth: Boolean = false,
        val visible: Boolean = true,
        val action: Action = Action.NONE,
        val menu: List<Option> = emptyList(),
    ) : DesignSystem() {
        companion object
    }

    data class TextInput(
        val header: Header,
        val label: String? = null,
        val hint: String? = null,
        val disabled: Boolean = false,
        val value: String? = null,
        val placeholder: String? = null,
        val type: TextInputType = DesignSystem.TextInputType.TEXT,
        val insetText: String? = null,
        val visible: Boolean = true,
        val status: Status = Status.Unknown,
    ) : DesignSystem() {
        companion object
    }

    data class MultiLineInput(
        val header: Header,
        val label: String?,
        val disabled: Boolean = false,
        val value: String = "",
        val placeholder: String?,
    ) : DesignSystem() {
        companion object
    }

    data class BooleanInput(
        val header: Header,
        val label: String? = null,
        val disabled: Boolean = false,
        val value: Boolean? = false,
        val visible: Boolean = true,
        val style: BooleanStyle = BooleanStyle.CHECKBOX,
        val status: Status = Status.Unknown,
    ) : DesignSystem() {
        companion object
    }

    data class SelectInput(
        val header: Header,
        val label: String?,
        val disabled: Boolean = false,
        val selected: Option? = null,
        val options: List<Option>,
        val visible: Boolean = true,
        val status: Status = Status.Unknown,
    ) : DesignSystem() {
        companion object
    }

    data class MultipleSelectInput(
        val header: Header,
        val label: String?,
        val disabled: Boolean = false,
        val selected: List<Option>? = null,
        val options: List<Option>,
        val visible: Boolean = true,
        val status: Status = Status.Unknown,
    ) : DesignSystem() {
        companion object
    }

    data class FileInput(
        val header: Header,
        val label: String?,
        val helpText: Text,
        val disabled: Boolean = false,
        val status: Status = Status.Unknown,
    ) : DesignSystem() {
        companion object
    }

    data class Modal(val header: Header? = null, val title: String? = null, val visible: Boolean = true, val firstButton: Button? = null, val secondButton: Button? = null)

    data class Toast(val message: String, val style: ToastStyle = ToastStyle.SUCCESS, val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class Chip(val message: Text, val style: PaletteType = PaletteType.PRIMARY, val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class Table<T>(val header: Header? = null, val data: List<Pair<T, List<DesignSystem>>>?, val titles: List<Text> = emptyList(), val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class ImageView(
        val href: String? = null, val image: Image? = null, val shadow: Boolean = false, val visible: Boolean = true
    ) : DesignSystem() {
        companion object
    }

    data class Link(val text: String, val to: String? = null, val href: String? = null) : DesignSystem() {
        companion object
    }

    data class Pagination(val content: PageContent<*>?, val label: Text = Text(), val visible: Boolean = true) : DesignSystem() {
        companion object
    }

    data class SideBarItem(val href: String, val iconSrc: String?, val text: String, val children: List<SideBarItem>? = null, val pro: Boolean = false) : DesignSystem() {
        companion object
    }

    data class Confirmation(
        val icon: String? = null,
        val animation: Animation? = null,
        val title: String,
        val body: String,
        val next: String,
        val href: String? = null
    ) : DesignSystem() {
        companion object
    }

    data class PalettePreview(val palette: PaletteType) : DesignSystem() {
        companion object
    }
}

typealias ValidationStatus = Pair<DesignSystem.Header, Status>

sealed class Status {
    object Unknown : Status()
    object Valid : Status()
    data class Invalid(val warning: String? = null) : Status()

    val message: String?
        get() = when (this) {
            is Unknown, is Valid -> null
            is Invalid -> this.warning
        }
}

fun List<ValidationStatus>.statusOf(header: DesignSystem.Header): Status =
    firstOrNull { it.first == header }?.second ?: Status.Unknown

fun List<Status>.overallStatus(): Status =
    when {
        any { it is Status.Invalid } -> Status.Invalid()
        all { it is Status.Valid } -> Status.Valid
        else -> Status.Unknown
    }

fun List<ValidationStatus>.overallStatus(): Status =
    map { it.second }.overallStatus()
