package screens

import services.*
import support.*
import techla.base.*
import techla.storage.Asset
import techla.storage.Catalog
import kotlin.time.ExperimentalTime

@ExperimentalTime
object GalleryScreen {
    object Header {
        val asset = DesignSystem.Header(id = "asset")
    }

    data class Texts(
        val title: String,
        val upload: String,
        val name: String,
        val delete: String,
        val uploaded: String,
        val deleted: String,
        val helpText: String,
    )

    data class State(
        val assets: List<Asset>?,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", ""),
            state = State(null),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val upload: DesignSystem.Button,
            val assets: DesignSystem.Table<Asset>,
            val asset: DesignSystem.FileInput,
            val uploaded: DesignSystem.Toast,
            val deleted: DesignSystem.Toast,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel = Ready(
            texts = texts,
            state = state,
            title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD,),
            upload = DesignSystem.Button(
                type = DesignSystem.ButtonType.SUBMIT,
                text = texts.upload
            ),
            assets = DesignSystem.Table(
                titles = listOf(
                    DesignSystem.Text(texts.name)
                ),
                data = state.assets?.map { asset ->
                    asset to listOf(
                        DesignSystem.ImageView(href = asset.thumbnailUrl?.toString()),
                        DesignSystem.Text(asset.name, size = DesignSystem.SizeType.MD),
                        DesignSystem.Text(asset.contentType, size = DesignSystem.SizeType.MD),
                    )
                },
            ),
            deleted = DesignSystem.Toast(message = texts.deleted, style = DesignSystem.ToastStyle.SUCCESS),
            uploaded = DesignSystem.Toast(message = texts.uploaded, style = DesignSystem.ToastStyle.SUCCESS),
            asset = DesignSystem.FileInput(header = Header.asset, label = null, helpText = DesignSystem.Text(text = texts.helpText)),
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            title = "Galleriet",
            name = "Namn",
            upload = "Ladda upp",
            delete = "Ta bort",
            deleted = "Filen borttagen",
            uploaded = "Filen uppladdad",
            helpText = ".png, .jpg, .mov, .mp4 format.",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val gallery = Key<Catalog>("gallery")

        return store.findCatalogs(keys = listOf(gallery)).flatMap { (actions, catalogs) ->
            if (catalogs.isNotEmpty()) {
                successfulOf(actions to catalogs.first())
            } else {
                val create = Catalog.Create(key = gallery, name = "Galleriet")
                store.reduce(actions).createCatalog(create).accumulate(actions)
            }
        }.flatMap { (actions, _) ->
            store.reduce(actions).findAssets(keys = listOf(gallery))
        }.map { (actions, assets) ->
            val state = viewModel.state.copy(assets = assets)
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>, assetId: Identifier<Asset>?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val gallery = Key<Catalog>("gallery")
        assetId ?: return sceneOf(viewModel.failed("No asset selected"))

        return store.deleteAsset(id = assetId).flatMap { (actions, _) ->
            store.reduce(actions).findAssets(keys = listOf(gallery)).accumulate(actions).map { (actions, assets) ->
                val state = viewModel.state.copy(assets = assets)
                sceneOf(viewModel.ready(state = state), actions)
            }
        }.failed { scene.failed(result = it) }
    }

    suspend fun upload(scene: Scene.Input<ViewModel>, asset: Asset.Create?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val gallery = Key<Catalog>("gallery")
        asset ?: return sceneOf(viewModel.failed("No asset"))
        val create = Asset.Create(
            name = asset.name,
            contentType = asset.contentType,
            data = asset.data,
            catalog = gallery
        )

        return store.createAsset(create = create).flatMap { (actions, _) ->
            store.reduce(actions).findAssets(keys = listOf(gallery)).accumulate(actions).map { (actions, assets) ->
                val state = viewModel.state.copy(assets = assets)
                sceneOf(viewModel.ready(state = state), actions)
            }
        }.failed { scene.failed(result = it) }
    }
}