package components

import emotion.react.css
import mui.icons.material.KeyboardArrowDown
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.sx
import react.FC
import react.Props
import react.create
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.useState
import support.Design
import support.DesignSystem
import support.Palette
import web.cssom.AlignItems
import web.cssom.Color
import web.cssom.Display
import web.cssom.Opacity
import web.cssom.important
import web.cssom.pct
import web.cssom.px
import web.cssom.rem
import web.html.ButtonType
import web.html.HTMLButtonElement
import web.html.HTMLElement


external interface RButtonProps : Props {
    var design: DesignSystem.Button?
    var onClick: ((DesignSystem.Action) -> Unit)?
}

val RButton = FC<RButtonProps> { props ->
    var anchorEl: HTMLElement? by useState(null)
    var menuOpen: Boolean by useState(false)

    fun onClick(event: MouseEvent<HTMLButtonElement, *>) {
        if (!props.design?.menu.isNullOrEmpty()) {
            anchorEl = event.currentTarget
            menuOpen = true
        } else if (props.onClick != null && props.design?.action != null) {
            props.onClick!!(props.design!!.action)
        }
    }

    fun onOptionClick(option: DesignSystem.Option) {
        if (props.onClick != null) {
            props.onClick!!(option.action)
        }
        menuOpen = false
    }

    val buttonType = when (props.design?.type) {
        DesignSystem.ButtonType.BUTTON, null -> ButtonType.button
        DesignSystem.ButtonType.RESET -> ButtonType.reset
        DesignSystem.ButtonType.SUBMIT -> ButtonType.submit
    }

    val color = if (props.design?.danger == true)
        ButtonColor.error
    else
        when (props.design?.style) {
            DesignSystem.PaletteType.PRIMARY, null -> ButtonColor.primary
            DesignSystem.PaletteType.SECONDARY -> ButtonColor.secondary
            DesignSystem.PaletteType.TRANSPARENT -> ButtonColor.primary
            DesignSystem.PaletteType.GREY -> ButtonColor.primary
            DesignSystem.PaletteType.WARNING -> ButtonColor.warning
            DesignSystem.PaletteType.SUCCESS -> ButtonColor.success
            DesignSystem.PaletteType.INFO -> ButtonColor.info
            DesignSystem.PaletteType.DANGER -> ButtonColor.error
        }

    if (props.design?.visible == true) {
        val design = props.design!!

        div {
            css {
                display = Display.flex
                alignItems = AlignItems.center
                if (design.fullWidth) {
                    width = 100.pct
                }
            }

            when (design.style) {
                DesignSystem.PaletteType.GREY -> {
                    Button {
                        sx {
                            backgroundColor = important(Color(Palette.transparent))
                            padding = 0.px
                        }
                        this.type = buttonType
                        this.variant = ButtonVariant.text
                        this.onClick = ::onClick
                        this.disabled = design.disabled
                        this.color = color
                        this.fullWidth = design.fullWidth

                        img {
                            css {
                                padding = 0.5.rem
                            }
                            src = support.Design.image(design.image)
                            alt = design.image?.name
                        }
                    }
                }

                DesignSystem.PaletteType.TRANSPARENT -> {
                    Button {
                        sx {
                            backgroundColor = important(Color(Palette.transparent))
                            padding = 0.px
                        }
                        this.type = buttonType
                        this.variant = ButtonVariant.text
                        this.onClick = ::onClick
                        this.disabled = design.disabled
                        this.color = color
                        this.fullWidth = design.fullWidth

                        if (design.menu.isNotEmpty()) {
                            this.endIcon = KeyboardArrowDown.create()
                        }

                        Typography {
                            variant = TypographyVariant.body1
                            sx {
                                this.color = Color.currentcolor
                            }
                            +(design.text ?: "")
                        }
                    }
                }

                else -> {
                    Button {
                        this.type = buttonType
                        this.variant = ButtonVariant.contained
                        this.onClick = ::onClick
                        this.disabled = design.disabled
                        this.color = color
                        this.fullWidth = design.fullWidth
                        if (design.disabled) {
                            sx {
                                this.backgroundColor = important(Color("#AA86FD"))
                                opacity = 0.65.unsafeCast<Opacity>()
                                this.color = important(Color(Palette.Grey.light))
                            }
                        }
                        if (design.menu.isNotEmpty()) {
                            this.endIcon = KeyboardArrowDown.create()
                        }

                        +(design.text ?: "")

                        design.image?.let {
                            img {
                                css {
                                    paddingLeft = 0.5.rem
                                }
                                src = Design.image(it)
                                alt = it.name
                            }
                        }
                    }
                }
            }

            if (design.menu.isNotEmpty()) {
                Menu {
                    this.anchorEl = { anchorEl ?: 1.unsafeCast<HTMLElement>() }
                    this.open = menuOpen
                    this.onClose = { menuOpen = false }

                    design.menu.forEach { option ->
                        MenuItem { this.onClick = { onOptionClick(option) }; +option.title }
                    }
                }
            }
        }
    }
}