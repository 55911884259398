package support

import components.RBackgroundImage
import components.RButton
import components.RImage
import components.RText
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import web.cssom.*

external interface RActivityItemTitleProps : Props {
    var imageView: DesignSystem.ImageView?
    var titleText: DesignSystem.Text?
    var bodyText: DesignSystem.Text?
    var firstButton: DesignSystem.Button?
    var secondButton: DesignSystem.Button?
    var onFirst: (() -> Unit)?
    var onSecond: (() -> Unit)?
}

val RActivityItemTitle = FC<RActivityItemTitleProps> { props ->
    fun onFirst(action: DesignSystem.Action) =
        props.onFirst?.let { it() }
    fun onSecond(action: DesignSystem.Action) =
        props.onSecond?.let { it() }

    RBackgroundImage {}

    Stack {
        id = "RActivityItemTitle-Title"
        direction = responsive(StackDirection.column)
        spacing = responsive(1.rem)
        sx {
            paddingTop = 3.rem
            alignItems = AlignItems.center
            justifyContent = JustifyContent.center
            paddingBottom = 1.rem
        }
        RImage { design = props.imageView; height = 80.0; width = 80.0 }
        RText { design = props.titleText }
        RText { design = props.bodyText }
    }

    if (props.firstButton != null || props.secondButton != null) {
        Stack {
            id = "RActivityItemTitle-Buttons"
            direction = responsive(StackDirection.column)
            sx {
                alignItems = AlignItems.center
                paddingTop = 16.px
            }
            RButton { design = props.firstButton; onClick = { onFirst(it) } }
            RButton { design = props.secondButton; onClick = { onSecond(it) } }
        }
    }
}
