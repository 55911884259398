package support

import techla.base.Key
import techla.content.Toggle

object Directory {
    val key = Key<Toggle>("DIRECTORY")
    val showPhone = Key<Toggle.Feature>("SHOW_PHONE")
    val showEmail = Key<Toggle.Feature>("SHOW_EMAIL")
    val showAIBio = Key<Toggle.Feature>("SHOW_AI_BIO")
}