package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.hr
import react.router.useNavigate
import react.router.useParams
import react.useRequiredContext
import react.useState
import screens.SubmissionScreen
import support.DesignSystem
import support.RScaffold
import support.sceneInputOf
import support.useAsyncEffect
import techla.base.Identifier
import techla.form.Submission
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Submission = FC<Props> { props ->
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(SubmissionScreen.ViewModel.None as SubmissionScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val submissionId = params["id"]?.let { Identifier<Submission>(it) }

    var showModal by useState(false)

    fun onDelete(action: DesignSystem.Action) {
        MainScope().launch {
            SubmissionScreen.delete(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                showModal = false
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is SubmissionScreen.ViewModel.None -> if (coroutineScope.isActive) {
                SubmissionScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is SubmissionScreen.ViewModel.Loading -> {
                if (coroutineScope.isActive) {
                    SubmissionScreen.load(sceneInputOf(store, viewModel), submissionId = submissionId).also { (viewModel, actions) ->
                        setViewModel(viewModel)
                        dispatch(actions)
                    }
                }
            }

            is SubmissionScreen.ViewModel.Ready -> {}
            is SubmissionScreen.ViewModel.Deleted -> {}
            is SubmissionScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is SubmissionScreen.ViewModel.Ready) {
            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(2)
                sx {
                    alignItems = AlignItems.start
                    justifyContent = JustifyContent.spaceBetween
                    marginBottom = 1.5.rem
                }
                RButton {
                    design = viewModel.back
                    onClick = { navigate(-1) }
                }
                RText { design = viewModel.title }
            }

            RForm {
                RFormLayout {
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.timestamp }
                        }
                        RFormInputWrapper {
                            RTextInput { design = viewModel.activity }
                        }
                    }
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.status }
                        }
                    }
                }
            }

            RTable {
                design = viewModel.results

                viewModel.results.data?.map { (_, row) ->
                    TableRow {
                        row.map { cell ->
                            TableCell {
                                variant = TableCellVariant.body
                                when (cell) {
                                    is DesignSystem.Text -> RText { design = cell }
                                    else -> {}
                                }
                            }
                        }
                    }
                }
            }

            hr {
                css {
                    marginTop = 1.5.rem
                    marginBottom = 1.rem
                }
            }

            RForm {
                onSubmit = { showModal = true }
                RButton { design = viewModel.remove }
            }

            RModal {
                design = viewModel.modalRemove
                show = showModal
                hide = { showModal = false }
                info = viewModel.modalBody
                firstOnClick = { showModal = false }
                secondOnClick = ::onDelete
            }
        }

        if (viewModel is SubmissionScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is SubmissionScreen.ViewModel.Deleted) {
            RConfirmation { design = viewModel.removed }
        }

        if (viewModel is SubmissionScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}