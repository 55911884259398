package services

import support.ActionOutcome
import support.Demo
import support.Store
import support.noActions
import techla.base.*
import techla.guard.Profile
import techla.personal.*


suspend fun Store.narrative(profileId: Identifier<Profile>): ActionOutcome<Narrative?> {
    if (demoMode) return Demo.matchNarrativeByProfile(profileId).noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.GetNarrativeWithProfile(profileId), api) {
            api.narrative(profileId).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listNarratives(index: PageIndex): ActionOutcome<PageContent<Narrative>> {
    if (demoMode) return Demo.allNarrativesPage(index).noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.ListNarrativesWithPaging(index), api) {
            api.listNarratives(index).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editNarrative(id: Identifier<Narrative>, edit: Narrative.Edit): ActionOutcome<Narrative> {
    if (demoMode) return Demo.matchNarrative(id).noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.EditNarrative(id, edit), api) {
            api.editNarrative(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createNarrative(create: Narrative.Create): ActionOutcome<Narrative> {
    if (demoMode) return Demo.currentNarrative.noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.CreateNarrative(create), api) {
            api.createNarrative(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.bio(id: Identifier<Profile>): ActionOutcome<Bio?> {
    if (demoMode) return Demo.matchBio(id).noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.Bio(id), api) {
            api.bio(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.organization(): ActionOutcome<Organization?> {
    if (demoMode) return Demo.currentOrganization.noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.MyOrganization, api) {
            api.myOrganization().onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.addEditNarrative(
    profileId: Identifier<Profile>, narrativeId: Identifier<Narrative>?, edit: Narrative.Edit
): ActionOutcome<Narrative> {
    return if (narrativeId == null) {
        val create = Narrative.Create(
            profileId = profileId,
            firstName = edit.firstName.getOrNull(),
            lastName = edit.lastName.getOrNull(),
            email = edit.email.getOrNull(),
            phone = edit.phone.getOrNull(),
            city = edit.city.getOrNull(),
            role = edit.role.getOrNull()
        )
        createNarrative(create)
    } else {
        editNarrative(narrativeId, edit)
    }
}

suspend fun Store.listTraits(profileId: Identifier<Profile>): ActionOutcome<List<Trait>> {
    if (demoMode) return Demo.matchTraitsByProfile(profileId).noActions()
    return personalAPI { api ->
        measureAPI(PersonalAPIResource.ListTraitsWithProfile(profileId), api) {
            api.listTraits(profileId).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun <T> Store.personalAPI(block: suspend (api: PersonalAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = PersonalAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) PersonalAPI.sandbox else PersonalAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}