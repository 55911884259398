package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.hr
import react.router.useNavigate
import react.router.useParams
import react.useRequiredContext
import react.useState
import screens.UserScreen
import support.*
import techla.base.Identifier
import techla.guard.Profile
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val User = FC<CreateOrDetailViewProps> { props ->
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(UserScreen.ViewModel.None as UserScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val profileId = params["id"]?.let { Identifier<Profile>(it) }

    var firstName by useState("")
    var lastName by useState("")
    var email by useState("")
    var govId by useState("")
    var phone by useState("")
    var city by useState("")
    var department by useState("")
    var position by useState("")

    var showUpdatedToast by useState(false)
    var showDeleteModal by useState(false)
    var showReplaceModal by useState(false)

    suspend fun handleSubmit() {
        UserScreen.validate(
            sceneInputOf(store, viewModel), firstName = firstName, lastName = lastName, email = email, govId = govId, phone = phone, city = city, department = department, position = position,
        ).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun handleOnChange(headerId: String, value: Boolean) {
        MainScope().launch {
            UserScreen.setValues(sceneInputOf(store, viewModel), headerId, value).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    fun handleDelete(action: DesignSystem.Action) {
        MainScope().launch {
            UserScreen.delete(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                showDeleteModal = false
            }
        }
    }

    fun handleReplace(action: DesignSystem.Action) {
        MainScope().launch {
            UserScreen.replace(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is UserScreen.ViewModel.None -> if (coroutineScope.isActive) {
                UserScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is UserScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                if (profileId != null) {
                    UserScreen.load(sceneInputOf(store, viewModel), profileId = profileId)
                        .also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                        }
                } else {
                    UserScreen.load(sceneInputOf(store, viewModel))
                        .also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                        }
                }
            }

            is UserScreen.ViewModel.Ready ->
                if (coroutineScope.isActive && viewModel.state.isSaving) {
                    if (props.createView) {
                        UserScreen.create(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                            showReplaceModal = viewModel.state.isReplacing
                        }
                    } else {
                        UserScreen.update(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                            showUpdatedToast = true
                        }
                    }
                }

            is UserScreen.ViewModel.Created -> {}
            is UserScreen.ViewModel.Deleted -> {}
            is UserScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is UserScreen.ViewModel.Ready) {
            RToast {
                open = showUpdatedToast
                onClose = { showUpdatedToast = false }
                design = viewModel.saved
            }
            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(2)
                sx {
                    alignItems = AlignItems.start
                    justifyContent = JustifyContent.spaceBetween
                    marginBottom = 1.5.rem
                }
                RButton {
                    design = viewModel.back
                    onClick = { navigate(-1) }
                }
                RText { design = viewModel.title }
                RChip { design = viewModel.inactive }
            }

            RForm {
                onSubmit = ::handleSubmit

                RFormLayout {
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.firstName; onChange = { firstName = it } }
                        }
                        RFormInputWrapper {
                            RTextInput { design = viewModel.lastName; onChange = { lastName = it } }
                        }
                    }
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.govId; onChange = { govId = it } }
                        }
                        RFormInputWrapper {
                            RTextInput { design = viewModel.email; onChange = { email = it } }
                        }
                    }
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.phone; onChange = { phone = it } }
                        }
                        RFormInputWrapper {
                            RTextInput { design = viewModel.city; onChange = { city = it } }
                        }
                    }
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.department; onChange = { department = it } }
                        }
                        RFormInputWrapper {
                            RTextInput { design = viewModel.position; onChange = { position = it } }
                        }
                    }
                }
                div {
                    css {
                        width = 100.pct
                        marginTop = (-1).rem
                    }
                    RTable {
                        design = viewModel.feeds
                        viewModel.feeds.data?.map { (_, row) ->
                            TableRow {
                                row.map { cell ->
                                    TableCell {
                                        variant = TableCellVariant.body
                                        when (cell) {
                                            is DesignSystem.Link -> RLink { design = cell }
                                            is DesignSystem.Text -> RText { design = cell }
                                            is DesignSystem.BooleanInput -> RBooleanInput { design = cell; onChange = { value -> handleOnChange(cell.header.id, value) } }
                                            else -> {}
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                div {
                    css {
                        marginTop = 2.rem
                    }
                    if (props.createView) {
                        RButton { design = viewModel.create }
                    } else {
                        RButton { design = viewModel.save }
                    }
                }
            }

            hr {
                css {
                    marginTop = 1.5.rem
                    marginBottom = 1.rem
                }
            }

            if (!props.createView) {
                RForm {
                    onSubmit = { showDeleteModal = true }
                    RButton { design = viewModel.remove }
                }
            }
            RModal {
                design = viewModel.modalRemove
                show = showDeleteModal
                hide = { showDeleteModal = false }
                info = viewModel.deleteModalBody
                firstOnClick = { showDeleteModal = false }
                secondOnClick = ::handleDelete
            }
            RModal {
                design = viewModel.modalReplace
                show = showReplaceModal
                hide = { showReplaceModal = false }
                info = viewModel.replaceModalBody
                firstOnClick = { showReplaceModal = false }
                secondOnClick = ::handleReplace
            }
        }

        if (viewModel is UserScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is UserScreen.ViewModel.Created) {
            RConfirmation { design = viewModel.added }
        }

        if (viewModel is UserScreen.ViewModel.Deleted) {
            RConfirmation { design = viewModel.removed }
        }

        if (viewModel is UserScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}