package support

import mui.material.Box
import mui.material.Container
import mui.material.Stack
import mui.material.StackDirection
import mui.system.Breakpoint
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import web.cssom.*

val Display.Companion.none get() = "none".unsafeCast<Display>()

external interface ScaffoldProps : PropsWithChildren {
    var isFullScreen: Boolean?
}

val RScaffold = FC<ScaffoldProps>("RScaffold") { props ->
    Stack {
        id = "RScaffold"
        direction = responsive(StackDirection.row)

        if (props.isFullScreen == false) {
            Box {
                id = "RScaffold-Left"
                sx {
                    display = responsive(
                        Breakpoint.sm to Display.none,
                        Breakpoint.md to Display.flex,
                    )
                    flexGrow = responsive(
                        Breakpoint.md to number(1.0),
                        Breakpoint.lg to number(3.0),
                    )
                }
            }

            Stack {
                id = "RScaffold-Main"
                direction = responsive(StackDirection.column)
                spacing = responsive(1)
                sx {
                    flexGrow = number(3.0)
                    maxWidth = 40.rem
                    alignItems = AlignItems.stretch
                    justifyContent = JustifyContent.flexStart
                    paddingTop = 3.rem
                    paddingBottom = 1.rem
                }

                +props.children
            }

            Box {
                id = "RScaffold-Right"
                sx {
                    display = responsive(
                        Breakpoint.sm to Display.none,
                        Breakpoint.md to Display.flex,
                    )
                    flexGrow = responsive(
                        Breakpoint.md to number(1.0),
                        Breakpoint.lg to number(3.0),
                    )
                }
            }
        } else {
            Container {
                id = "RScaffold-Main"
                maxWidth = false
                sx {
                    paddingTop = 3.rem
                    paddingBottom = 1.rem
                }

                +props.children
            }
        }
    }
}
