package views

import ROUTES
import StoreContext
import components.*
import emotion.react.css
import kotlinx.coroutines.isActive
import mui.material.*
import mui.system.responsive
import react.FC
import react.Props
import react.router.useNavigate
import react.useRequiredContext
import react.useState
import screens.ShortLinksScreen
import support.DesignSystem
import support.RScaffold
import support.sceneInputOf
import support.useAsyncEffect
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val ShortLinks = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(ShortLinksScreen.ViewModel.None as ShortLinksScreen.ViewModel)
    val navigate = useNavigate()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is ShortLinksScreen.ViewModel.None -> if (coroutineScope.isActive) {
                ShortLinksScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is ShortLinksScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                ShortLinksScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is ShortLinksScreen.ViewModel.Ready -> {}
            is ShortLinksScreen.ViewModel.GetPro -> {}
            is ShortLinksScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is ShortLinksScreen.ViewModel.Ready) {
            Stack {
                spacing = responsive(2)
                Stack {
                    direction = responsive(StackDirection.row)
                    css {
                        justifyContent = JustifyContent.spaceBetween
                    }

                    RText { design = viewModel.title }
                    RButton {
                        design = viewModel.create
                        onClick = { navigate(ROUTES.DASHBOARD.SHORTLINKS.ADD) }
                    }
                }

                RTable {
                    design = viewModel.shortLinks
                    viewModel.shortLinks.data?.map { (_, row) ->
                        TableRow {
                            row.map { cell ->
                                TableCell {
                                    variant = TableCellVariant.body
                                    when (cell) {
                                        is DesignSystem.Link -> RLink { design = cell }
                                        is DesignSystem.Text -> RText { design = cell }
                                        else -> {}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is ShortLinksScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is ShortLinksScreen.ViewModel.GetPro) {
            RGetPro {}
        }

        if (viewModel is ShortLinksScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}