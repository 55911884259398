package services

import support.ActionOutcome
import support.Demo
import support.Store
import support.noActions
import techla.base.*
import techla.content.ContentAPI
import techla.content.ContentAPIResource
import techla.content.ShortLink
import techla.content.Toggle

suspend fun Store.listShortLinks(index: PageIndex): ActionOutcome<List<ShortLink>> {
    if (demoMode) return Demo.allShortLinks.noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.ListShortLinks(null), api) {
            api.listShortLinks().onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.getShortLink(id: Identifier<ShortLink>): ActionOutcome<ShortLink> {
    if (demoMode) return Demo.matchShortLink(id).noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.GetShortLink(id), api) {
            api.getShortLink(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editShortLink(id: Identifier<ShortLink>, edit: ShortLink.Edit): ActionOutcome<ShortLink> {
    if (demoMode) return Demo.matchShortLink(id).noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.EditShortLink(id, edit), api) {
            api.editShortLink(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createShortLink(create: ShortLink.Create): ActionOutcome<ShortLink> {
    if (demoMode) return Demo.currentShortLink.noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.CreateShortLink(create), api) {
            api.createShortLink(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteShortLink(id: Identifier<ShortLink>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.DeleteShortLink(id), api) {
            api.deleteShortLink(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.listAllToggles(): ActionOutcome<List<Toggle>> {
    if (demoMode) return Demo.allToggles.noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.ListToggles, api) {
            api.listToggles().onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.getToggle(id: Identifier<Toggle>): ActionOutcome<Toggle> {
    if (demoMode) return Demo.matchToggle(id).noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.GetToggle(id), api) {
            api.getToggle(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.editToggle(id: Identifier<Toggle>, edit: Toggle.Edit): ActionOutcome<Toggle> {
    if (demoMode) return Demo.matchToggle(id).noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.EditToggle(id, edit), api) {
            api.editToggle(id, edit).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.createToggle(create: Toggle.Create): ActionOutcome<Toggle> {
    if (demoMode) return Demo.currentToggle.noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.CreateToggle(create), api) {
            api.createToggle(create).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun Store.deleteToggle(id: Identifier<Toggle>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return contentAPI { api ->
        measureAPI(ContentAPIResource.DeleteToggle(id), api) {
            api.deleteToggle(id).onNotSuccess {
                techla_log("WARN: $it")
            }
        }
    }
}

suspend fun <T> Store.contentAPI(block: suspend (api: ContentAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = ContentAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) ContentAPI.sandbox else ContentAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}
