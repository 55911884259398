package components.items

import components.RTextInput
import mui.material.Stack
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.DesignSystem
import support.RActivityItem
import techla.base.Identifier
import web.cssom.rem

external interface ActivityItemQuestionProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.Question
    var onChange: (Identifier<ActivityItem>, ActivityValue) -> Unit
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemQuestion = FC<ActivityItemQuestionProps> { props ->
    fun onTitle(value: String) {
        (props.viewModel.value as? ActivityValue.QuestionValue)?.let { questionValue ->
            props.onChange(props.viewModel.id, questionValue.copy(title = value))
        }
        (props.viewModel.value as? ActivityValue.None)?.let {
            props.onChange(props.viewModel.id, ActivityValue.QuestionValue(title = value, choices = emptyList(), answer = ""))
        }
    }

    fun onChoice(value: String) {
        val choices = value.split(",").map { it.trim() }
        (props.viewModel.value as? ActivityValue.QuestionValue)?.let { questionValue ->
            props.onChange(props.viewModel.id, questionValue.copy(choices = choices))
        }
        (props.viewModel.value as? ActivityValue.None)?.let {
            props.onChange(props.viewModel.id, ActivityValue.QuestionValue(title = "", choices = choices, answer = ""))
        }
    }

    fun onAnswer(value: String) {
        (props.viewModel.value as? ActivityValue.QuestionValue)?.let { questionValue ->
            props.onChange(props.viewModel.id, questionValue.copy(answer = value))
        }
        (props.viewModel.value as? ActivityValue.None)?.let {
            props.onChange(props.viewModel.id, ActivityValue.QuestionValue(title = "", choices = emptyList(), answer = value))
        }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    if (props.viewModel.navigation.isVisible) {
        RActivityItem {
            hasBorder = true
            viewModel = props.viewModel
            onAction = ::onAction

            Stack {
                id = "ActivityItem-Question"
                spacing = responsive(1.rem)
                sx {
                    paddingTop = 1.rem
                }

                RTextInput { design = props.viewModel.input; onChange = { onTitle(it) } }
                RTextInput { design = props.viewModel.choices; onChange = { onChoice(it) } }
                RTextInput { design = props.viewModel.answer; onChange = { onAnswer(it) } }
            }
        }
    }
}