package components.items

import components.RSelectInput
import components.RTextInput
import mui.material.Stack
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.DesignSystem
import support.RActivityItem
import techla.base.Identifier
import web.cssom.*

external interface ActivityItemAssetProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.Asset
    var onChange: (Identifier<ActivityItem>, ActivityValue) -> Unit
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemAsset = FC<ActivityItemAssetProps> { props ->
    fun onTitle(value: String) {
        (props.viewModel.value as? ActivityValue.AssetValue)?.let { linkValue ->
            props.onChange(props.viewModel.id, linkValue.copy(title = value))
        }
        (props.viewModel.value as? ActivityValue.None)?.let {
            props.onChange(props.viewModel.id, ActivityValue.AssetValue(title = value, hint = "", asset = null))
        }
    }

    fun onHint(value: String) {
        (props.viewModel.value as? ActivityValue.AssetValue)?.let { linkValue ->
            props.onChange(props.viewModel.id, linkValue.copy(hint = value))
        }
        (props.viewModel.value as? ActivityValue.None)?.let {
            props.onChange(props.viewModel.id, ActivityValue.AssetValue(title = "", hint = value, asset = null))
        }
    }

    fun onAsset(value: DesignSystem.Option) {
        (props.viewModel.value as? ActivityValue.AssetValue)?.let { linkValue ->
            props.onChange(props.viewModel.id, linkValue.copy(asset = Identifier(value.value)))
        }
        (props.viewModel.value as? ActivityValue.None)?.let {
            props.onChange(props.viewModel.id, ActivityValue.AssetValue(title = "", hint = "", asset = Identifier(value.value)))
        }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    if (props.viewModel.navigation.isVisible) {
        RActivityItem {
            hasBorder = true
            viewModel = props.viewModel
            onAction = ::onAction

            Stack {
                id = "ActivityItem-Asset"
                spacing = responsive(1.rem)
                sx {
                    paddingTop = 1.rem
                }

                RTextInput { design = props.viewModel.input; onChange = { onTitle(it) } }
                RTextInput { design = props.viewModel.hint; onChange = { onHint(it) } }
                RSelectInput { design = props.viewModel.asset; onChange = { onAsset(it) } }
            }
        }
    }
}