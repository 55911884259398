package services

import support.ActionOutcome
import support.Demo
import support.Store
import support.noActions
import techla.base.*
import techla.guard.*

suspend fun Store.createApplicationAuthentication(workspace: Key<Workspace>): Outcome<ApplicationAuthentication> {
    val api = guardAPI
    api.token = applicationToken
    api.additionalHeaders = listOf("workspace" to workspace.rawValue)
    val create = ApplicationAuthentication.Create(
        applicationKey = deployment.applicationKey,
        applicationSecret = deployment.applicationSecret,
        device = device,
    )
    return measureAPI(GuardAPIResource.CreateApplicationAuthentication(create), api) {
        api.createApplicationAuthentication(create).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.listUserAuthentications(index: PageIndex, statuses: List<UserAuthentication.Status>?): ActionOutcome<PageContent<UserAuthentication>> {
    if (demoMode) return Demo.allUserAuthenticationsPage(index).noActions()
    return guardAPI { api ->
        measureAPI(GuardAPIResource.ListUserAuthenticationsWithPaging(index, statuses), api) {
            api.listUserAuthentications(index, statuses).onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createUserAuthentication(govId: String? = null, phone: String? = null): Outcome<UserAuthentication> {
    val api = guardAPI
    val create = UserAuthentication.Create(
        govId = govId,
        phone = phone,
        device = device,
    )
    return measureAPI(GuardAPIResource.CreateUserAuthentication(create), api) {
        api.createUserAuthentication(create).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.verifyUserAuthentication(code: String?): Outcome<UserAuthentication> {
    val api = guardAPI
    userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId cannot be null"))
    val verify = UserAuthentication.Verify(
        code = code,
    )
    return measureAPI(GuardAPIResource.VerifyUserAuthentication(userAuthenticationId, verify), api) {
        api.verifyUserAuthentication(userAuthenticationId, verify).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.getUserAuthentication(): Outcome<UserAuthentication> {
    val api = guardAPI
    userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId cannot be null"))
    return measureAPI(GuardAPIResource.GetUserAuthentication(userAuthenticationId), api) {
        api.getUserAuthentication(userAuthenticationId).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.deleteUserAuthentication(): Outcome<Unit> {
    val api = guardAPI
    userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId cannot be null"))
    return measureAPI(GuardAPIResource.DeleteUserAuthentication(userAuthenticationId), api) {
        api.deleteUserAuthentication(userAuthenticationId).onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.listWorkspaces(): Outcome<List<Workspace>> {
    val api = guardAPI
    return measureAPI(GuardAPIResource.ListWorkspaces, api) {
        api.listWorkspaces().onNotSuccess { techla_log("WARN: $it") }
    }
}

suspend fun Store.listProfiles(index: PageIndex, sources: List<Profile.Source> = listOf(Profile.Source.Manual, Profile.Source.Import)): ActionOutcome<PageContent<Profile>> {
    if (demoMode) return Demo.allProfilesPage(index).noActions()
    return guardAPI { api ->
        measureAPI(GuardAPIResource.ListProfilesWithPaging(index, sources), api) {
            api.listProfiles(index, sources).onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.getProfile(id: Identifier<Profile>): ActionOutcome<Profile> {
    if (demoMode) return Demo.matchProfile(id).noActions()
    return guardAPI { api ->
        measureAPI(GuardAPIResource.GetProfile(id), api) {
            api.getProfile(id).onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.getProfileOrNull(id: Identifier<Profile>): ActionOutcome<Profile?> {
    if (demoMode) return Demo.matchProfile(id).noActions().map { it }
    val (actions, profile) = getProfile(id).outputOrNull() ?: (emptyList<Store.Action>() to null)
    return successfulOf(actions to profile)
}

suspend fun Store.editProfile(id: Identifier<Profile>, edit: Profile.Edit): ActionOutcome<Profile> {
    if (demoMode) return Demo.matchProfile(id).noActions()
    return guardAPI { api ->
        measureAPI(GuardAPIResource.EditProfile(id, edit), api) {
            api.editProfile(id, edit).onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.createProfile(create: Profile.Create): ActionOutcome<Profile> {
    if (demoMode) return Demo.currentProfile.noActions()
    return guardAPI { api ->
        measureAPI(GuardAPIResource.CreateProfile(create), api) {
            api.createProfile(create).onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.deleteProfile(id: Identifier<Profile>): ActionOutcome<Unit> {
    if (demoMode) return successfulOf(Unit).noActions()
    return guardAPI { api ->
        measureAPI(GuardAPIResource.DeleteProfile(id), api) {
            api.deleteProfile(id).onNotSuccess { techla_log("WARN: $it") }
        }
    }
}

suspend fun Store.refreshUserAuthentication(): Outcome<UserAuthentication> {
    val id =
        userAuthenticationId ?: return failedOf(TechlaError.BadRequest("userAuthenticationId needed to refresh token"))
    val api = guardAPI
    return api.getUserAuthentication(id).fold({
        when (it.status) {
            is UserAuthentication.Status.Complete -> successfulOf(it)
            is UserAuthentication.Status.Expired -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is expired"))
            is UserAuthentication.Status.Cancelled -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is cancelled"))
            is UserAuthentication.Status.Failed -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is failed"))
            is UserAuthentication.Status.Outstanding -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is outstanding"))
            is UserAuthentication.Status.Verified -> failedOf(TechlaError.InternalServerError("Can't refresh token since authentication is verified"))
        }
    }, { failedOf(TechlaError.BadRequest("WARN: $it")) }, { failedOf(it) })
}

suspend fun <T> Store.withUserToken(block: suspend (Store) -> Outcome<T>): ActionOutcome<T> {
    val expired = tokenExpiresAt?.hasPassed() ?: true
    return if (expired) {
        techla_log("STORE: Token expired (${tokenExpiresAt}), refreshing")
        refreshUserAuthentication().flatMap { userAuthentication ->
            val action = Store.Action.TokenRefresh(userAuthentication.tokens)
            val updated = reduce(action)
            block(updated).map { listOf(action) to it }
        }
    } else {
        block(this).noActions()
    }
}

suspend fun <T> Store.guardAPI(block: suspend (api: GuardAPI) -> Outcome<T>): ActionOutcome<T> {
    return withUserToken { updated ->
        val api = GuardAPI(httpClient).also { api ->
            api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
            api.token = updated.adminToken
        }
        block(api)
    }
}

val Store.guardAPI
    get() = GuardAPI(httpClient).also { api ->
        api.host = if (deployment.isSandbox) GuardAPI.sandbox else GuardAPI.shared
        api.token = applicationToken
    }
