package components

import components.chartjs.DataSet
import components.chartjs.chartJs
import components.chartjs.reactChart
import js.objects.jso
import mui.material.Paper
import mui.system.sx
import react.ForwardRef
import react.PropsWithRef
import support.Palette
import web.cssom.*

external interface RChartProps : PropsWithRef<RChartProps> {
    var data: Array<DataSet>
    var labels: Array<String>
}

val RChart = ForwardRef<RChartProps, RChartProps> { props ->
    chartJs.Chart.register(
        chartJs.LinearScale,
        chartJs.CategoryScale,
        chartJs.PointElement,
        chartJs.LineElement,
        chartJs.Title,
        chartJs.Tooltip,
        chartJs.Legend,
        chartJs.Filler,
    )

    Paper {
        sx {
            height = 50.vh
            width = 100.pct
            boxShadow = important("0 .5rem 1rem rgba(0,0,0,.15)".unsafeCast<BoxShadow>())
            padding = 3.rem
        }

        reactChart {
            this.ref = props.ref!!
            type = "line"
            updateMode = "normal"
            data = jso {
                labels = props.labels
                datasets = props.data
            }

            options = jso {
                maintainAspectRatio = false
                responsive = true
                animation = jso {
                    easing = "easeInOutBack"
                }
                this.plugins = jso {
                    legend = jso {
                        display = false
                    }
                    tooltip = jso {
                        displayColors = false
                    }
                }

                elements = jso {
                    line = jso {
                        borderWidth = 0
                        borderColor = "transparent"
                    }
                    point = jso {
                        borderColor = Palette.Warning.light
                        backgroundColor = Palette.Warning.light
                        hoverBackgroundColor = Palette.Warning.light
                        hoverBorderColor = Palette.Warning.light
                        borderWidth = 10
                        hoverRadius = 10
                        hoverBorderWidth = 1
                        radius = 3
                    }
                }
                scales = jso {
                    y = jso {
                        min = 0
                        ticks = jso {
                            font = jso {
                                color = "rgba(0, 0, 0, 0.5)"
                                weight = "bold"
                            }
                            padding = 20
                            maxTicksLimit = 5
                        }
                        grid = jso {
                            drawBorder = false
                            drawTicks = false
                            display = false
                        }
                    }
                    x = jso {
                        grid = jso {
                            drawBorder = false
                        }
                        ticks = jso {
                            font = jso {
                                color = "rgba(0, 0, 0, 0.5)"
                                weight = "bold"
                            }
                            padding = 20
                        }
                    }
                }
            }
        }
    }
}