package views

import components.*
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.PropsWithChildren
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.small
import support.*
import web.cssom.*

external interface ShowcaseProps : Props {
    var design: DesignSystem
    var description: String?
}

val RShowcase = FC<ShowcaseProps> { props ->
    var description: String? = null
    Stack {
        when (val design = props.design) {
            is DesignSystem.Text -> {
                description = "${themeFontFamily(design.size)}, ${themeFontWeight(design.style)}, ${themeFontSize(design.size)} rem, ${themeFontColor(design.size)}"
                RText { this.design = design }
            }

            is DesignSystem.PalettePreview -> {
                description = when (design.palette) {
                    DesignSystem.PaletteType.PRIMARY -> "Primary"
                    DesignSystem.PaletteType.SECONDARY -> "Secondary"
                    DesignSystem.PaletteType.SUCCESS -> "Success"
                    DesignSystem.PaletteType.WARNING -> "Warning"
                    DesignSystem.PaletteType.DANGER -> "Danger"
                    DesignSystem.PaletteType.INFO -> "Info"
                    DesignSystem.PaletteType.GREY -> "Grey"
                    DesignSystem.PaletteType.TRANSPARENT -> "Transparent"
                }
                RPalettePreview { this.design = design }
            }

            is DesignSystem.Button -> {
                description = "${design.style}"
                if (design.image != null) description += ", with image"
                if (design.menu.isNotEmpty()) description += ", with menu"
                if (design.danger) description += ", danger"
                RButton { this.design = design }
            }

            is DesignSystem.TextInput -> {
                description = "${design.type}"
                if (design.label == null) description += ", without label"
                if (design.placeholder != null) description += ", with placeholder"
                if (design.status is Status.Invalid) description += ", invalid"
                if (design.status is Status.Valid) description += ", valid"
                if (design.value?.isNotEmpty() == true) description += ", with value"
                if (design.insetText != null) description += ", with inset text"
                if (design.disabled) description += ", disabled"
                RTextInput { this.design = design }
            }

            is DesignSystem.MultiLineInput -> {
                description = "MULTILINE"
                if (design.label == null) description += ", without label"
                if (design.placeholder != null) description += ", with placeholder"
                if (design.value.isNotEmpty()) description += ", with value"
                if (design.disabled) description += ", disabled"
                RMultiLineInput { this.design = design; onChange = {} }
            }

            is DesignSystem.BooleanInput -> {
                description = "BOOLEAN"
                if (design.label == null) description += ", without label"
                if (design.value == true) description += ", is on"
                if (design.value == null || design.value == false) description += ", is off"
                if (design.status is Status.Invalid) description += ", invalid"
                if (design.status is Status.Valid) description += ", valid"
                if (design.disabled) description += ", disabled"
                RBooleanInput { this.design = design }
            }

            is DesignSystem.SelectInput -> {
                description = "SELECT"
                if (design.label == null) description += ", without label"
                if (design.selected != null) description += ", with selection"
                if (design.status is Status.Invalid) description += ", invalid"
                if (design.status is Status.Valid) description += ", valid"
                if (design.disabled) description += ", disabled"
                RSelectInput { this.design = design; onChange = {} }
            }

            is DesignSystem.MultipleSelectInput -> {
                description = "MULTIPLE SELECT"
                if (design.label == null) description += ", without label"
                if (design.selected != null) description += ", with selection"
                if (design.status is Status.Invalid) description += ", invalid"
                if (design.status is Status.Valid) description += ", valid"
                if (design.disabled) description += ", disabled"
                RMultipleSelectInput { this.design = design; onChange = {} }
            }

            is DesignSystem.FileInput -> {
                description = "FILE"
                if (design.label == null) description += ", without label"
                if (design.status is Status.Invalid) description += ", invalid"
                if (design.status is Status.Valid) description += ", valid"
                if (design.disabled) description += ", disabled"
                RFileInput { this.design = design; onChange = {} }
            }

            is DesignSystem.Toast -> {
                description = "${design.style}"
                RToast { this.design = design }
            }

            is DesignSystem.Chip -> {
                description = "CHIP"
                RChip { this.design = design }
            }

            is DesignSystem.Link -> {
                description = "LINK"
                if (design.href == null) description += ", with external"
                RLink { this.design = design }
            }

            is DesignSystem.SideBarItem -> {
                description = "SIDEBAR ITEM"
                if (design.pro) description += ", is pro"
                RSideBarItem { this.design = design }
            }

            is DesignSystem.Confirmation -> {
                description = "CONFIRMATION"
                RConfirmation { this.design = design }
            }

            is DesignSystem.ImageView -> {
                description = "${design.image}"
                if (design.shadow) description += ", with shadow"
                RImage { this.design = design }
            }

            else -> {}
        }

        description = description?.lowercase()?.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
        small { i { +(description ?: props.description ?: "") } }
    }
}

val RVerticalShowcase = FC<PropsWithChildren> { props ->
    Stack {
        direction = responsive(StackDirection.column)
        spacing = responsive(0.5)
        sx {
            padding = 16.px
            width = 350.px
        }

        +props.children
    }
}

val RHorizontalShowcase = FC<PropsWithChildren> { props ->
    Stack {
        direction = responsive(StackDirection.row)
        spacing = responsive(2)
        sx {
            padding = 8.px
            alignItems = AlignItems.flexEnd
            justifyContent = JustifyContent.flexStart
        }

        +props.children
    }
}

val PreviewView = FC<Props> {
    Stack {
        direction = responsive(StackDirection.column)
        sx {
            padding = 32.px
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Palette" }

            RHorizontalShowcase {
                RVerticalShowcase {
                    RShowcase { design = DesignSystem.PalettePreview.previewPrimary }
                    RShowcase { design = DesignSystem.PalettePreview.previewSecondary }
                }
                RVerticalShowcase {
                    RShowcase { design = DesignSystem.PalettePreview.previewSuccess }
                    RShowcase { design = DesignSystem.PalettePreview.previewDanger }
                }
                RVerticalShowcase {
                    RShowcase { design = DesignSystem.PalettePreview.previewWarning }
                    RShowcase { design = DesignSystem.PalettePreview.previewInfo }
                }
                RVerticalShowcase {
                    RShowcase { design = DesignSystem.PalettePreview.previewGrey }
                }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Typography" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.Text.previewXL2Bold }
                RShowcase { design = DesignSystem.Text.previewXLBold }
                RShowcase { design = DesignSystem.Text.previewLGBold }
                RShowcase { design = DesignSystem.Text.previewMDRegular }
                RShowcase { design = DesignSystem.Text.previewMDBold }
                RShowcase { design = DesignSystem.Text.previewSMRegular }
                RShowcase { design = DesignSystem.Text.previewXSRegular }
                RShowcase { design = DesignSystem.Text.previewXSBold }
                RShowcase { design = DesignSystem.Text.previewXS2Regular }
                RShowcase { design = DesignSystem.Text.previewXS2Bold }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Buttons" }

            RHorizontalShowcase {
                RShowcase { design = DesignSystem.Button.previewPrimary }
                RShowcase { design = DesignSystem.Button.previewPrimaryImage }
                RShowcase { design = DesignSystem.Button.previewPrimaryMenu }
                RShowcase { design = DesignSystem.Button.previewPrimaryDanger }
                RShowcase { design = DesignSystem.Button.previewSecondary }
                RShowcase { design = DesignSystem.Button.previewGrey }
                RShowcase { design = DesignSystem.Button.previewGreyMenu }
                RShowcase { design = DesignSystem.Button.previewTransparent }
                RShowcase { design = DesignSystem.Button.previewTransparentDanger }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Text Inputs" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.TextInput.previewNoLabel }
                RShowcase { design = DesignSystem.TextInput.previewLabel }
                RShowcase { design = DesignSystem.TextInput.previewPlaceholder }
                RShowcase { design = DesignSystem.TextInput.previewInvalid }
                RShowcase { design = DesignSystem.TextInput.previewValid }
                RShowcase { design = DesignSystem.TextInput.previewValue }
                RShowcase { design = DesignSystem.TextInput.previewInsetText }
                RShowcase { design = DesignSystem.TextInput.previewDisabled }
                RShowcase { design = DesignSystem.TextInput.previewNovel }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Multiline Text Inputs" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.MultiLineInput.preview }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Boolean Inputs" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.BooleanInput.previewNoLabel }
                RShowcase { design = DesignSystem.BooleanInput.previewOn }
                RShowcase { design = DesignSystem.BooleanInput.previewOff }
                RShowcase { design = DesignSystem.BooleanInput.previewDisabled }
                RShowcase { design = DesignSystem.BooleanInput.previewInvalid }
                RShowcase { design = DesignSystem.BooleanInput.previewValid }
                RShowcase { design = DesignSystem.BooleanInput.switchOn }
                RShowcase { design = DesignSystem.BooleanInput.switchOff }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Select Inputs" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.SelectInput.previewNoLabel }
                RShowcase { design = DesignSystem.SelectInput.previewOn }
                RShowcase { design = DesignSystem.SelectInput.previewOff }
                RShowcase { design = DesignSystem.SelectInput.previewDisabled }
                RShowcase { design = DesignSystem.SelectInput.previewInvalid }
                RShowcase { design = DesignSystem.SelectInput.previewValid }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Multiple Select Inputs" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.MultipleSelectInput.previewNoLabel }
                RShowcase { design = DesignSystem.MultipleSelectInput.previewOn }
                RShowcase { design = DesignSystem.MultipleSelectInput.previewOff }
                RShowcase { design = DesignSystem.MultipleSelectInput.previewDisabled }
                RShowcase { design = DesignSystem.MultipleSelectInput.previewInvalid }
                RShowcase { design = DesignSystem.MultipleSelectInput.previewValid }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"File Inputs" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.FileInput.previewNoLabel }
                RShowcase { design = DesignSystem.FileInput.previewOn }
                RShowcase { design = DesignSystem.FileInput.previewOff }
                RShowcase { design = DesignSystem.FileInput.previewDisabled }
                RShowcase { design = DesignSystem.FileInput.previewInvalid }
                RShowcase { design = DesignSystem.FileInput.previewValid }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Links" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.Link.preview }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Chips" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.Chip.preview }
                RShowcase { design = DesignSystem.Chip.previewWarning }
                RShowcase { design = DesignSystem.Chip.previewSuccess }
                RShowcase { design = DesignSystem.Chip.previewInfo }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Sidebar Items" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.SideBarItem.preview }
                RShowcase { design = DesignSystem.SideBarItem.previewPro }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Confirmations" }

            RVerticalShowcase {
                RShowcase { design = DesignSystem.Confirmation.preview }
            }
        }

        Stack {
            direction = responsive(StackDirection.column)
            h1 { +"Images" }

            RVerticalShowcase {
                DesignSystem.ImageView.previews.forEach {
                    RShowcase { design = it }
                }
            }
        }
    }
}
