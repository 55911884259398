package support

import io.ktor.client.*
import techla.base.Date
import techla.base.Device
import techla.base.Identifier
import techla.base.Key
import techla.billing.Buyer
import techla.conversation.Feed
import techla.guard.*

typealias ApplicationContext = Any?

data class Deployment(
    val applicationKey: String,
    val applicationSecret: String,
    val primary: Key<Workspace>,
    val redirect: String,
    val isSandbox: Boolean,
    val version: String,
    val build: Int,
    val workspaceName: (crew: String) -> String
)

data class Store(
    val applicationContext: ApplicationContext,
    val deployment: Deployment,
    val device: Device,

    //val updateStoredUserAuthenticationId: (Identifier<UserAuthentication>) -> Unit,
    //val removeStoredUserAuthenticationId: () -> Unit,

    val applicationToken: String? = null,
    val userAuthenticationId: Identifier<UserAuthentication>? = null,
    val tokens: List<Token> = emptyList(),
    val profileId: Identifier<Profile>? = null,
    val me: Me? = null,

    val workspace: Key<Workspace>? = null,
    val workspaces: List<Workspace>? = null,
    val demoMode: Boolean = false,
    val buyer: Buyer? = null,
    val standard: Feed? = null,
) {
    sealed class Action {
        data class ApplicationAuthenticationCompleted(val applicationToken: String, val workspace: Key<Workspace>, val workspaces: List<Workspace>) : Action()

        data class UserAuthenticationStarted(val tokens: List<Token>, val userAuthenticationId: Identifier<UserAuthentication>) : Action()
        data class UserAuthenticationCompleted(val tokens: List<Token>, val profileId: Identifier<Profile>?, val demoMode: Boolean) : Action()
        data class UserAuthenticationCancelled(val userAuthenticationId: Identifier<UserAuthentication>) : Action()
        data class TokenRefresh(val tokens: List<Token>) : Action()
        data class LoadBuyer(val buyer: Buyer) : Action()
        data class LoadStandard(val feed: Feed) : Action()
        data object Logout : Action()
    }

    val userToken: String?
        get() = tokens.filterIsInstance<Token.User>().firstOrNull()?.token

    val tokenExpiresAt: Date?
        get() = tokens.filterIsInstance<Token.User>().firstOrNull()?.expiresAt

    val adminToken: String?
        get() = tokens.filterIsInstance<Token.Admin>().firstOrNull()?.token

    val qrCode: String?
        get() = tokens.filterIsInstance<Token.QR>().firstOrNull()?.code

    val autoStartToken: String?
        get() = tokens.filterIsInstance<Token.AutoStart>().firstOrNull()?.token

    val isSignedIn: Boolean
        get() = userToken != null

    val version: String
        get() =
            if (deployment.isSandbox)
                "${deployment.version.replace("-SNAPSHOT", "")}(${deployment.build})"
            else
                deployment.version.replace("-SNAPSHOT", "")

    val isPro: Boolean
        get() = buyer?.tier is Buyer.Tier.Pro || buyer?.tier is Buyer.Tier.Premium

    val currentWorkspace get() = workspaces?.firstOrNull { it.key == workspace }

  /*  fun updateUserAuthenticationId(userAuthenticationId: Identifier<UserAuthentication>?): Identifier<UserAuthentication>? {
        if (userAuthenticationId != null)
            updateStoredUserAuthenticationId(userAuthenticationId)
        else
            removeStoredUserAuthenticationId()
        //storeUpdates.tryEmit(userAuthenticationId != null)
        return userAuthenticationId
    }

   */

    fun reduce(action: Action) = when (action) {
        is Action.ApplicationAuthenticationCompleted -> copy(
            applicationToken = action.applicationToken,
            workspace = action.workspace,
            workspaces = action.workspaces,
        )

        is Action.UserAuthenticationStarted -> copy(
            tokens = action.tokens,
            userAuthenticationId = action.userAuthenticationId
        )

        is Action.UserAuthenticationCompleted -> copy(
            tokens = action.tokens,
            profileId = action.profileId,
            demoMode = action.demoMode,
        )

        is Action.UserAuthenticationCancelled ->
            copy(userAuthenticationId = null, tokens = emptyList())

        is Action.TokenRefresh -> copy(tokens = action.tokens)
        is Action.LoadBuyer -> copy(buyer = action.buyer)
        is Action.LoadStandard -> copy(standard = action.feed)
        is Action.Logout -> copy(
            applicationToken = null,
            buyer = null,
            demoMode = false,
            me = null,
            profileId = null,
            tokens = emptyList(),
            userAuthenticationId = null,
            workspace = null,
            standard = null,
        )
    }

    fun reduce(actions: List<Action>) = actions.fold(this) { store, action -> store.reduce(action) }

    val httpClient = HttpClient() {
        expectSuccess = false
    }
}
