package support

import js.objects.jso
import mui.material.PaletteMode
import mui.material.styles.TypographyVariant
import mui.material.styles.createTheme
import support.DesignSystem.Image.BANKID
import support.DesignSystem.SizeType.XL2
import support.DesignSystem.SizeType.XL
import support.DesignSystem.SizeType.LG
import support.DesignSystem.SizeType.MD
import support.DesignSystem.SizeType.SM
import support.DesignSystem.SizeType.XS
import support.DesignSystem.SizeType.XS2
import support.DesignSystem.StyleType.BOLD
import support.DesignSystem.StyleType.REGULAR
import techla.base.TechlaError
import web.cssom.*

object Palette {
    object Primary {
        const val light = "#763DFB"
        const val main = "#540CFA"
        const val dark = "#430AC8"
    }
    object Secondary {
        const val light = "#FFFFFF"
        const val main = "#FFFFFF"
        const val dark = "#CCCCCC"
    }
    object Success {
        const val light = "#79EB6D"
        const val main = "#58E649"
        const val dark = "#46B83A"
    }
    object Warning {
        const val light = "#FFBF51"
        const val main = "#FFAF26"
        const val dark = "#CC8C1E"
    }
    object Danger {
        const val light = "#FF689A"
        const val main = "#FF4281"
        const val dark = "#CC3567"
    }
    object Info {
        const val light = "#4C7BFF"
        const val main = "#11CDEF"
        const val dark = "#1948CC"
    }
    object Grey {
        const val light = "#FFFFFF"
        const val main = "#F0F0F0"
        const val dark = "#000000"
    }
    const val transparent = "transparent"
}

object Shape {
    val borderRadius = 4.px
}

object Support {}

fun Support.typographyVariant(size: DesignSystem.SizeType, style: DesignSystem.StyleType) =
    when {
        size == XL2 && style == BOLD -> TypographyVariant.h1
        size == XL && style == BOLD -> TypographyVariant.h2
        size == LG && style == REGULAR -> TypographyVariant.subtitle1
        size == LG && style == BOLD -> TypographyVariant.subtitle2
        size == MD && style == REGULAR -> TypographyVariant.body1
        size == MD && style == BOLD -> TypographyVariant.body2
        size == SM && style == REGULAR -> TypographyVariant.caption
        size == XS && style == REGULAR -> TypographyVariant.h3
        size == XS && style == BOLD -> TypographyVariant.h4
        size == XS2 && style == REGULAR -> TypographyVariant.h5
        size == XS2 && style == BOLD -> TypographyVariant.h6
        else -> throw TechlaError.BadRequest("Combination not supported ($size:$style)")
    }

fun themeFontFamily(size: DesignSystem.SizeType) =
    "Poppins, sans-serif"

fun themeFontWeight(style: DesignSystem.StyleType) =
    when (style) {
        BOLD -> 700
        REGULAR -> 400
    }

fun themeFontSize(size: DesignSystem.SizeType) =
    when (size) {
        XL2 -> 2.25
        XL -> 1.5
        LG -> 1.125
        MD -> 1.0
        SM -> 0.875
        XS -> 0.75
        XS2 -> 0.625
    }

fun themeFontColor(size: DesignSystem.SizeType) =
    when (size) {
        XL2, XL, LG, MD, XS, XS2 -> Palette.Grey.dark
        SM -> Palette.Grey.dark
    }

val rocksTheme = createTheme(jso {
    palette = jso {
        mode = PaletteMode.light
        primary = jso {
            main = Palette.Primary.main
            light = Palette.Primary.light
            dark = Palette.Primary.dark
        }
        secondary = jso {
            main = Palette.Secondary.main
            light = Palette.Secondary.light
            dark = Palette.Secondary.dark
        }
        error = jso {
            main = Palette.Danger.main
            light = Palette.Danger.light
            dark = Palette.Danger.dark
        }
        warning = jso {
            main = Palette.Warning.main
            light = Palette.Warning.light
            dark = Palette.Warning.dark
        }
        success = jso {
            main = Palette.Success.main
            light = Palette.Success.light
            dark = Palette.Success.dark
        }
        info = jso {
            main = Palette.Info.main
            light = Palette.Info.light
            dark = Palette.Info.dark
        }
    }
    typography = jso {
        useNextVariants = false
        fontFamily = themeFontFamily(SM)
        button = jso {
            textTransform = none
            fontWeight = 700
        }
        h1 = jso { // XL2
            fontSize = themeFontSize(XL2).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XL2)
        }
        h2 = jso {// XL
            fontSize = themeFontSize(XL).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XL)
        }
        subtitle1 = jso {// LG
            fontSize = themeFontSize(LG).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(LG)
        }
        subtitle2 = jso {// LG
            fontSize = themeFontSize(LG).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(LG)
        }
        body1 = jso {// SM
            fontSize = themeFontSize(MD).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(MD)
        }
        body2 = jso {// SM
            fontSize = themeFontSize(MD).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(MD)
        }
        caption = jso {// LG
            fontSize = themeFontSize(SM).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(SM)
        }
        h3 = jso {// XS
            fontSize = themeFontSize(XS).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(XS)
        }
        h4 = jso {// XS
            fontSize = themeFontSize(XS).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XS)
        }
        h5 = jso { // XS
            fontSize = themeFontSize(XS2).rem
            fontWeight = themeFontWeight(REGULAR)
            color = themeFontColor(XS2)
        }
        h6 = jso { // XS
            fontSize = themeFontSize(XS2).rem
            fontWeight = themeFontWeight(BOLD)
            color = themeFontColor(XS2)
        }
    }
    components = jso {
        MuiCheckboxBase = jso {
            root = jso {
                // We need to do an explicit import of the image i.e. `import support.DesignSystem.Image.BANKID`
                // for this to work
                icon = Design.image(BANKID)
                checkedIcon = Design.image(BANKID)
            }
        }
        MuiButtonBase = jso {
            defaultProps = jso {
                disableRipple = true
                fontSize = themeFontSize(MD).rem
                fontWeight = themeFontWeight(BOLD)
            }
        }
        MuiInputBase = jso {
            styleOverrides = jso {
                root = jso {
                    backgroundColor = Palette.Grey.main
                    notchedOutline = none
                    color = "#495057"
                    border = none
                    fontSize = themeFontSize(MD).rem
                }
            }
        }
    }
})