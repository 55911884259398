package components

import mui.material.Grid
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import web.cssom.*

val RFormLayout = FC<PropsWithChildren> { props ->
    Grid {
        container = true
        spacing = responsive(2)
        sx {
            marginBottom = 2.rem
        }

        +props.children
    }
}

val RFormRow = FC<PropsWithChildren> { props ->
    Grid {
        item = true
        container = true
        spacing = responsive(2)

        +props.children
    }
}

val RFormInputWrapper = FC<PropsWithChildren> { props ->
    Grid {
        item = true
        sx { width = 50.pct }

        +props.children
    }
}
