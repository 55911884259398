import components.RLayout
import components.RTheme
import js.objects.jso
import kotlinx.browser.window
import react.*
import react.router.dom.createBrowserRouter
import react.router.RouterProvider
import screens.items.ActivityItem
import support.Mode
import support.Store
import support.create
import support.deployment
import techla.base.Device
import techla.base.Identifier
import techla.content.ShortLink
import techla.conversation.Feed
import techla.form.Submission
import techla.guard.Profile
import views.*
import kotlin.time.ExperimentalTime


val storeReducer: Reducer<Store, List<Store.Action>> = { store, actions ->
    store.reduce(actions)
}

var StoreContext = createRequiredContext<Pair<Store, Dispatch<List<Store.Action>>>>()

object ROUTES {
    const val LOGIN = "/login"

    object DASHBOARD {
        const val INDEX = "/dashboard"
        const val ACCOUNT = "/account"
        const val EMPLOYEE_SURVEY = "/employee-survey"

        object FEEDS {
            const val INDEX = "/feeds"
            const val SHOW = "/feeds/:id"
            fun show(id: Identifier<Feed>) = SHOW.replace(":id", id.rawValue)
            const val ADD = "/feeds/add"
        }

        const val GALLERY = "/gallery"
        const val PULSE = "/pulse"

        object QUIZ {
            const val INDEX = "/quiz"
            const val SHOW = "/quiz/:id"
            fun show(id: Identifier<ActivityItem>) = SHOW.replace(":id", id.rawValue)
            const val ADD = "/quiz/add"
        }

        object RECRUITMENT {
            const val INDEX = "/recruitment"
        }

        object BOOST {
            const val INDEX = "/boost"
        }

        object SHORTLINKS {
            const val INDEX = "/short-links"
            const val SHOW = "/short-links/:id"
            fun show(id: Identifier<ShortLink>) = SHOW.replace(":id", id.rawValue)
            const val ADD = "/short-links/add"
        }

        const val SKILLS = "/skills"

        object SUBMISSIONS {
            const val INDEX = "/submissions"
            const val SHOW = "/submissions/:id"
            fun show(id: Identifier<Submission>) = SHOW.replace(":id", id.rawValue)
        }

        const val SUGGESTION_BOX = "/suggestion-box"

        object USERS {
            const val INDEX = "/users"
            const val SHOW = "/users/:id"
            fun show(id: Identifier<Profile>) = SHOW.replace(":id", id.rawValue)
            const val ADD = "/users/add"
        }

        const val WHISTLE_BLOWER = "/whistle-blower"
    }
}

@ExperimentalTime
val App = FC<Props> {
    val (store, storeDispatch) = useReducer(
        storeReducer, initialState = Store(
            deployment = deployment,
            device = Device.Web(window.navigator.userAgent),
            applicationContext = null,
            // We don't store the application token in local storage since we want an application auth every time the app is loaded for statistics
            applicationToken = null,
        )
    )
    StrictMode {
        StoreContext.Provider(store to storeDispatch) {
            RTheme {

                val appRouter = createBrowserRouter(
                    arrayOf(
                        jso {
                            path = "/"
                            element = RLayout.create()
                            children = arrayOf(
                                jso { path = ROUTES.DASHBOARD.INDEX; element = Dashboard.create() },
                                jso { path = ROUTES.DASHBOARD.ACCOUNT; element = Account.create() },
                                jso { path = ROUTES.DASHBOARD.EMPLOYEE_SURVEY; element = EmployeeSurvey.create() },
                                jso { path = ROUTES.DASHBOARD.FEEDS.INDEX; element = Feeds.create() },
                                jso { path = ROUTES.DASHBOARD.FEEDS.SHOW; element = views.Feed.create(createView = false) },
                                jso { path = ROUTES.DASHBOARD.FEEDS.ADD; element = views.Feed.create(createView = true) },
                                jso { path = ROUTES.DASHBOARD.GALLERY; element = Gallery.create() },
                                jso { path = ROUTES.DASHBOARD.PULSE; element = Pulse.create() },
                                jso { path = ROUTES.DASHBOARD.QUIZ.INDEX; element = QuizView.create(mode = Mode.Search) },
                                jso { path = ROUTES.DASHBOARD.QUIZ.SHOW; element = QuizView.create(mode = Mode.Edit) },
                                jso { path = ROUTES.DASHBOARD.QUIZ.ADD; element = QuizView.create(mode = Mode.Create) },
                                jso { path = ROUTES.DASHBOARD.RECRUITMENT.INDEX; element = RecruitmentView.create() },
                                jso { path = ROUTES.DASHBOARD.BOOST.INDEX; element = BoostView.create() },
                                jso { path = ROUTES.DASHBOARD.SHORTLINKS.INDEX; element = ShortLinks.create() },
                                jso { path = ROUTES.DASHBOARD.SHORTLINKS.SHOW; element = views.ShortLink.create(createView = false) },
                                jso { path = ROUTES.DASHBOARD.SHORTLINKS.ADD; element = views.ShortLink.create(createView = true) },
                                jso { path = ROUTES.DASHBOARD.SKILLS; element = SkillsView.create() },
                                jso { path = ROUTES.DASHBOARD.SUBMISSIONS.INDEX; element = Submissions.create() },
                                jso { path = ROUTES.DASHBOARD.SUBMISSIONS.SHOW; element = views.Submission.create() },
                                jso { path = ROUTES.DASHBOARD.SUGGESTION_BOX; element = SuggestionBox.create() },
                                jso { path = ROUTES.DASHBOARD.USERS.INDEX; element = Users.create() },
                                jso { path = ROUTES.DASHBOARD.USERS.ADD; element = User.create(createView = true) },
                                jso { path = ROUTES.DASHBOARD.USERS.SHOW; element = User.create(createView = false) },
                                jso { path = ROUTES.DASHBOARD.WHISTLE_BLOWER; element = WhistleBlower.create() },
                            )
                        },
                        jso {
                            path = ROUTES.LOGIN
                            element = Login.create()
                        },
                        jso {
                            path = "/preview"
                            element = PreviewView.create()
                        }
                    )
                )
                RouterProvider { router = appRouter }
            }
        }
    }
}