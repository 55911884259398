package support

import techla.base.*

typealias ActionOutcome<T> = Outcome<Pair<List<Store.Action>, T>>

fun <T> ActionOutcome<T>.accumulate(action: Store.Action) =
    map { tupleOf(it.first + action, it.second) }

fun <T> ActionOutcome<T>.accumulate(actions: List<Store.Action>) =
    map { tupleOf(it.first + actions, it.second) }

fun <T1, T2> ActionOutcome<T1>.accumulate(actions: List<Store.Action>, other: T2) =
    map { tupleOf(it.first + actions, other, it.second) }

fun <T> Outcome<T>.noActions(): ActionOutcome<T> =
    map { emptyList<Store.Action>() to it }

fun <T> List<ActionOutcome<T>>.all(): ActionOutcome<List<T>> =
    all(predicate = { true })
        .fold(
            onSuccess = { all ->
                val actions = all.flatMap { it.first }
                val values = all.map { it.second }
                successfulOf(values)
                    .noActions()
                    .accumulate(actions)
            },
            onInvalid = { invalidOf(it) },
            onFailure = { failedOf(it) }
        )

sealed class Scene {
    data class Input<ViewModel>(val store: Store, val viewModel: ViewModel) : Scene()
    data class Output<out ViewModel>(val viewModel: ViewModel, val actions: List<Store.Action>) : Scene()
}

fun <ViewModel> sceneInputOf(store: Store, viewModel: ViewModel) =
    Scene.Input(store, viewModel)

fun <ViewModel> sceneOf(viewModel: ViewModel, action: Store.Action) =
    Scene.Output(viewModel, listOf(action))

fun <ViewModel> sceneOf(viewModel: ViewModel, actions: List<Store.Action> = emptyList()) =
    Scene.Output(viewModel, actions)

inline fun <ViewModel> Outcome<Scene.Output<ViewModel>>.failed(
    onNotSuccess: (Either<List<Warning>, Throwable>) -> Scene.Output<ViewModel>
): Scene.Output<ViewModel> {
    return when (this) {
        is Outcome.Successful -> output
        is Outcome.Invalid -> onNotSuccess(Either.Left(warnings))
        is Outcome.Failed -> onNotSuccess(Either.Right(exception))
    }
}
