package components.items

import components.RButton
import components.RText
import components.RChip
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import support.DesignSystem
import techla.base.Identifier
import support.RActivityItem
import web.cssom.*

external interface ActivityItemSearchProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.Search
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemSearch = FC<ActivityItemSearchProps> { props ->
    fun onClick(action: DesignSystem.Action) {
        props.onAction(props.viewModel.id, action)
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    RActivityItem {
        viewModel = props.viewModel
        hasBorder = true
        onAction = ::onAction

        Stack {
            id = "ActivityItemSearch"
            direction = responsive(StackDirection.row)
            sx {
                justifyContent = JustifyContent.spaceBetween
                alignItems = AlignItems.flexStart
            }

            Stack {
                direction = responsive(StackDirection.column)
                RText { design = props.viewModel.title }
                RText { design = props.viewModel.info }
            }

            Stack {
                id = "ActivityItemSearch-Controls"
                direction = responsive(StackDirection.row)
                // spacing = responsive(1.rem)
                RChip { design = props.viewModel.status }
                RButton { design = props.viewModel.more; onClick = ::onClick }
            }
        }
    }
}