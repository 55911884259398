package support

import techla.base.Key

val deployment = Deployment(
    applicationKey = "9C9519D1-4399-4505-B7F4-EA3A4680B780",
    applicationSecret = "AADB40B6-23CE-4591-AEED-D13D2CB874F8",
    primary = Key("mo"),
    redirect = "",
    isSandbox = false,
    version = "2.1.9",
    build = 45,
    workspaceName = { crew -> "moodi-${crew.lowercase()}"}
)