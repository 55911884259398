package screens

import screens.items.ActivityTexts
import services.*
import support.*

import techla.base.*
import techla.form.Field
import techla.form.Form
import kotlin.time.ExperimentalTime

@ExperimentalTime
object SuggestionBoxScreen {
    object Header {
        val title = DesignSystem.Header(id = "title")
    }

    data class Texts(
        val title: String,
        val body: String,
        val back: String,
        val enable: String,
        val disable: String,
        val disabledTitle: String,
        val disabledBody: String,
        val done: String,
        val enabledTitle: String,
        val enabledBody: String,
        val description: String,
        val hint: String,
        val next: String,
        val placeholder: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    data class State(
        val formId: Identifier<Form>? = null,
        val enabled: Boolean = false,
        val title: String = "",
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {

        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State()
        )

        data class Loading(
            override var texts: Texts,
            override var state: State
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            var image: DesignSystem.ImageView,
            var title: DesignSystem.Text,
            var body: DesignSystem.Text,
            var enable: DesignSystem.Button,
            var disable: DesignSystem.Button,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String
        ) : ViewModel(texts, state)

        data class Create(
            override var texts: Texts,
            override var state: State,
            var back: DesignSystem.Button,
            var next: DesignSystem.Button,
            var text: DesignSystem.TextInput,
        ) : ViewModel(texts, state)

        data class Success(
            override var texts: Texts,
            override var state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel =
            Ready(
                texts = texts,
                state = state,
                image = DesignSystem.ImageView(image = DesignSystem.Image.SUGGESTION_BOX),
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                body = DesignSystem.Text(text = texts.body, align = DesignSystem.TextAlign.CENTER),
                disable = DesignSystem.Button(text = texts.disable, visible = state.enabled),
                enable = DesignSystem.Button(text = texts.enable, visible = !state.enabled),
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun create(state: State? = null, status: List<ValidationStatus> = emptyList()): ViewModel =
            (state ?: this.state).run {
                Create(
                    texts = texts,
                    state = this,
                    back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.SECONDARY),
                    next = DesignSystem.Button(text = texts.next, type = DesignSystem.ButtonType.SUBMIT),
                    text = DesignSystem.TextInput(
                        header = Header.title,
                        label = texts.description,
                        hint = texts.hint,
                        value = title,
                        type = DesignSystem.TextInputType.NOVEL,
                        placeholder = texts.placeholder,
                        status = status.statusOf(Header.title),
                    )
                )
            }

        fun success(state: State): ViewModel = Success(
            texts = texts,
            state = state,
            confirmation = DesignSystem.Confirmation(
                icon = "",
                title = if (state.enabled) texts.enabledTitle else texts.disabledTitle,
                body = if (state.enabled) texts.enabledBody else texts.disabledBody,
                next = texts.done,
            )
        )

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        fun asLoading() = this as? Loading
        fun asReady() = this as? Ready
        fun asCreate() = this as? Create
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            title = "Förslagslåda",
            back = "Tillbaka",
            next = "Nästa",
            body = "Ge anställda möjlighet att komma med förslag och ideér. Aktivera Förslagslådan nedan, denna dyker sedan upp i appen som ett menyval under Hubben. Svaren hamnar i menyvalet aktivitetssvar där kan du sedan välja att se svar från just Förslagslådan",
            enable = "Jag vill ha en låda",
            disable = "Inaktivera lådan",

            disabledTitle = "Allt är nu klart \uD83C\uDF8A",
            disabledBody = "Förslagslådan är nu inaktiv",
            enabledTitle = "Allt är nu klart \uD83C\uDF8A",
            enabledBody = "Förslagslådan är nu igång och kommer att synas för anställda i appen under Hubben",
            done = "Fortsätt",

            description = "Beskrivning av förslagslådan",
            hint = "Text som hamnar på överst på Förslagslådan i appen. Exempel på text kan vara: ”Här kan du komma med förslag och ideér som kan förbättra. Bästa förslagen lyfts fram i dialogen och belönas.”",
            placeholder = "Fyll på här tack :)",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state

        if (!store.isPro) return sceneOf(viewModel.getPro())
        return store.listForms().map { (actions, forms) ->
            val form = forms.firstOrNull { it.kind is Form.Kind.SuggestionBox }
            state = state.copy(formId = form?.id, enabled = form?.status is Form.Status.Active)
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(
            title = ""
        )
        return sceneOf(viewModel.create(state = state))
    }

    suspend fun enable(scene: Scene.Input<ViewModel>, value: String): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val state = viewModel.state.copy(
            title = value
        )

        val status: MutableList<ValidationStatus> = mutableListOf()
        Validator.text(
            text = value,
            isEmpty = { status.add(Header.title to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.title to Status.Valid) },
            formatted = { }
        )
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.create(state, status))
        }

        val form = Form.Create(
            key = Key.random(length = 10),
            name = "Förslagslåda",
            info = value,
            kind = Form.Kind.SuggestionBox,
            feedback = Form.Feedback.None,
            status = Form.Status.Active
        )

        return if (state.formId == null) {
            store.createForm(create = form)
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = SUGGESTION_BOX_FIELD, style = Field.Style.Long, order = 0, page = 1, required = true, hidden = false, label = "Förslag", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = Key("GOV_ID"), style = Field.Style.GovId, order = 0, page = 1, required = true, hidden = false, label = "Personnummer", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = Key("FIRST_NAME"), style = Field.Style.FirstName, order = 0, page = 1, required = true, hidden = false, label = "Förnamn", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = Key("LAST_NAME"), style = Field.Style.LastName, order = 0, page = 1, required = true, hidden = false, label = "Efternamn", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = Key("CITY"), style = Field.Style.City, order = 0, page = 1, required = true, hidden = false, label = "Stad", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .map { (actions, form) ->

                    val newState = state.copy(enabled = form.status is Form.Status.Active)
                    sceneOf(viewModel.success(state = newState), actions)

                }
                .failed { scene.failed(result = it) }
        } else {
            store.editForm(id = state.formId, edit = Form.Edit(status = modifiedOf(Form.Status.Active), info = modifiedOf(value))).map { (actions, _) ->
                val newState = state.copy(enabled = form.status is Form.Status.Active)
                sceneOf(viewModel.success(state = newState), actions)
            }
                .failed { scene.failed(result = it) }
        }
    }

    suspend fun disable(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val id = viewModel.state.formId ?: return sceneOf(viewModel.failed("Missing formId"))
        val edit = Form.Edit(status = modifiedOf(Form.Status.Inactive))

        return store.editForm(id = id, edit = edit).map { (actions, form) ->
            val newState = viewModel.state.copy(enabled = form.status is Form.Status.Active)
            sceneOf(viewModel.success(state = newState), actions)
        }
            .failed { scene.failed(result = it) }
    }
}