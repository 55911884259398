package support

@JsModule("./img/ico_bankid.svg")
@JsNonModule
external val bankID: dynamic

@JsModule("./img/graphic_whistleblower_box.svg")
@JsNonModule
external val whistleblower: dynamic

@JsModule("./img/moodi_logo.svg")
@JsNonModule
external val moodi: dynamic

@JsModule("./img/graphic_suggestion_box.svg")
@JsNonModule
external val suggestion_box:dynamic

@JsModule("./img/graphic_pulse.svg")
@JsNonModule
external val puls:dynamic

@JsModule("./img/org_shape2.svg")
@JsNonModule
external val backgroundImage1:dynamic

@JsModule("./img/org_shape3.svg")
@JsNonModule
external val backgroundImage2:dynamic

@JsModule("./img/graphic_quiz_box.svg")
@JsNonModule
external val quiz:dynamic

@JsModule("./img/graphic_survey_box.svg")
@JsNonModule
external val survey:dynamic

@JsModule("./img/ico_arrow_down.svg")
@JsNonModule
external val down:dynamic

@JsModule("./img/ico_arrow_up.svg")
@JsNonModule
external val up:dynamic

@JsModule("./img/ico_edit.svg")
@JsNonModule
external val edit:dynamic

@JsModule("./img/graphic_recruitment_box.svg")
@JsNonModule
external val recruitment:dynamic

@JsModule("./img/graphic_boost.svg")
@JsNonModule
external val boost:dynamic

@JsModule("./img/img-boost_moodi_standard.png")
@JsNonModule
external val standardPins:dynamic

@JsModule("./img/graphic_skills_box.svg")
@JsNonModule
external val skills:dynamic

@JsModule("./lottie/spinner.json")
@JsNonModule
external val spinner: dynamic

@JsModule("./lottie/confetti.json")
@JsNonModule
external val confetti: dynamic


object Design {
    fun image(image: DesignSystem.Image?): String? {
        return when (image) {
            DesignSystem.Image.BANKID -> bankID.default
            DesignSystem.Image.WHISTLEBLOWER -> whistleblower.default
            DesignSystem.Image.MOODI -> moodi.default
            DesignSystem.Image.SUGGESTION_BOX -> suggestion_box.default
            DesignSystem.Image.BOOST -> boost.default
            DesignSystem.Image.PULS -> puls.default
            DesignSystem.Image.BACKGROUNDIMAGE1 -> backgroundImage1.default
            DesignSystem.Image.BACKGROUNDIMAGE2 -> backgroundImage2.default
            DesignSystem.Image.QUIZ -> quiz.default
            DesignSystem.Image.SURVEY -> survey.default
            DesignSystem.Image.UP -> up.default
            DesignSystem.Image.DOWN -> down.default
            DesignSystem.Image.EDIT -> edit.default
            DesignSystem.Image.RECRUITMENT -> recruitment.default
            DesignSystem.Image.SKILLS -> skills.default
            DesignSystem.Image.STANDARD_PINS -> standardPins.default

            else -> null
        }
    }

    fun animation(animation: DesignSystem.Animation?): dynamic {
        return when (animation) {
            DesignSystem.Animation.SPINNER -> spinner
            DesignSystem.Animation.CONFETTI -> confetti
            else -> null
        }
    }
}