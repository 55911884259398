package components.items

import components.RSelectInput
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.DesignSystem
import support.RActivityItem
import techla.base.Identifier

external interface ActivityItemBasicSelectInputProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.BasicSelectInput
    var onChange: (Identifier<ActivityItem>, ActivityValue) -> Unit
}

val ActivityItemBasicSelectInput = FC<ActivityItemBasicSelectInputProps> { props ->
    fun onChange(value: DesignSystem.Option) {
        props.onChange(props.viewModel.id, ActivityValue.OptionValue(value))
    }

    if (props.viewModel.navigation.isVisible) {
        RActivityItem {
            viewModel = props.viewModel
            RSelectInput { design = props.viewModel.input; onChange = ::onChange }
        }
    }
}