package components

import js.objects.jso
import mui.material.*
import react.FC
import react.Props
import support.DesignSystem

external interface RToastProps : Props {
    var design: DesignSystem.Toast
    var open: Boolean
    var onClose: () -> Unit
}

val RToast = FC<RToastProps> { props ->
    Snackbar {
        open = props.open
        autoHideDuration = 5000
        onClose = { _, _ -> props.onClose() }
        anchorOrigin = jso {
            vertical = SnackbarOriginVertical.top
            horizontal = SnackbarOriginHorizontal.right
        }

        Alert {
            onClose = { props.onClose() }
            color = when (props.design.style) {
                DesignSystem.ToastStyle.SUCCESS -> AlertColor.success
                DesignSystem.ToastStyle.ERROR -> AlertColor.error
            }

            + props.design.message
        }
    }
}