package views

import StoreContext
import components.*
import emotion.react.css
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.isActive
import kotlinx.coroutines.launch
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.dom.html.ReactHTML.hr
import react.router.useNavigate
import react.router.useParams
import react.useRequiredContext
import react.useState
import screens.FeedScreen
import support.*
import techla.base.Identifier
import techla.conversation.Feed
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Feed = FC<CreateOrDetailViewProps> { props ->
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(FeedScreen.ViewModel.None as FeedScreen.ViewModel)
    val navigate = useNavigate()
    val params = useParams()
    val feedId = params["id"]?.let { Identifier<Feed>(it) }

    var name by useState("")

    var showUpdatedToast by useState(false)
    var showModal by useState(false)

    suspend fun onSubmit() {
        FeedScreen.validate(sceneInputOf(store, viewModel), name = name).also { (viewModel, actions) ->
            setViewModel(viewModel)
            dispatch(actions)
        }
    }

    fun onDelete(action: DesignSystem.Action) {
        MainScope().launch {
            FeedScreen.delete(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                setViewModel(viewModel)
                dispatch(actions)
                showModal = false
            }
        }
    }

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is FeedScreen.ViewModel.None -> if (coroutineScope.isActive) {
                FeedScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is FeedScreen.ViewModel.Loading -> {
                if (coroutineScope.isActive) {
                    FeedScreen.load(sceneInputOf(store, viewModel), feedId = feedId)
                        .also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                        }
                }
            }

            is FeedScreen.ViewModel.Ready -> {
                if (coroutineScope.isActive && viewModel.state.isSaving) {
                    if (props.createView) {
                        FeedScreen.create(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                        }
                    } else {
                        FeedScreen.save(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                            setViewModel(viewModel)
                            dispatch(actions)
                            showUpdatedToast = true
                        }
                    }
                }
            }
            is FeedScreen.ViewModel.Created -> {}
            is FeedScreen.ViewModel.Deleted -> {}
            is FeedScreen.ViewModel.GetPro -> {}
            is FeedScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is FeedScreen.ViewModel.Ready) {
            RToast {
                open = showUpdatedToast
                onClose = { showUpdatedToast = false }
                design = viewModel.saved
            }
            Stack {
                direction = responsive(StackDirection.column)
                spacing = responsive(2)
                sx {
                    alignItems = AlignItems.start
                    justifyContent = JustifyContent.spaceBetween
                    marginBottom = 1.5.rem
                }
                RButton {
                    design = viewModel.back
                    onClick = { navigate(-1) }
                }
                RText { design = viewModel.title }
            }

            RForm {
                onSubmit = ::onSubmit

                RFormLayout {
                    RFormRow {
                        RFormInputWrapper {
                            RTextInput { design = viewModel.name; onChange = { value -> name = value } }
                        }
                    }
                }

                if (props.createView) {
                    RButton { design = viewModel.create }
                } else {
                    RButton { design = viewModel.save }
                }
            }

            hr {
                css {
                    marginTop = 1.5.rem
                    marginBottom = 1.rem
                }
            }

            if (!props.createView) {
                RForm {
                    onSubmit = { showModal = true }
                    RButton { design = viewModel.remove }
                }
            }
            RModal {
                design = viewModel.modalRemove
                show = showModal
                hide = { showModal = false }
                info = viewModel.modalBody
                firstOnClick = { showModal = false }
                secondOnClick = ::onDelete
            }
        }

        if (viewModel is FeedScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is FeedScreen.ViewModel.Created) {
            RConfirmation { design = viewModel.added }
        }

        if (viewModel is FeedScreen.ViewModel.Deleted) {
            RConfirmation { design = viewModel.removed }
        }

        if (viewModel is FeedScreen.ViewModel.GetPro) {
            RGetPro {}
        }

        if (viewModel is FeedScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}