package screens

import ROUTES
import services.createAsset
import services.editBuyer
import services.listBuyers
import support.*
import techla.base.*
import techla.billing.Buyer
import techla.storage.Asset

val Buyer.Tier.displayName: String
    get() = when (this) {
        Buyer.Tier.Standard -> "Bas"
        Buyer.Tier.Pro -> "Pro"
        Buyer.Tier.Premium -> "Premium"
        else -> ""
    }

object AccountScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
        val govCode = DesignSystem.Header(id = "govCode")
        val street = DesignSystem.Header(id = "street")
        val postalCode = DesignSystem.Header(id = "postalCode")
        val city = DesignSystem.Header(id = "city")
        val logo = DesignSystem.Header(id = "logo")
        val tier = DesignSystem.Header(id = "tier")
    }

    data class Texts(
        val title: String,

        val name: String,
        val govCode: String,
        val street: String,
        val postalCode: String,
        val city: String,
        val logo: String,
        val tier: String,

        val saveButton: String,
        val uploadButton: String,
        val upgradeButton: String,
        val closeAccountButton: String,

        val savedToast: String,

        val upgradedTitle: String,
        val upgradedBody: String,
        val nextButton: String,

        val closeAccountSubject: String,
        val closeAccountBody: String,

        val closeAccountTitle: String,
        val closeAccountInfo: String,
        val yes: String,
        val no: String,

        val upgradeTitle: String,
        val upgradeBody: String,
        val yesUpgrade: String,
        val cancel: String,

        val fieldRequired: String,
        val govCodeIllegalCharacters: String,
        val govCodeWrongLength: String,
        val postalCodeIllegalCharacters: String,
        val postalCodeWrongLength: String,
        val helpText: String,
    )

    data class State(
        val buyer: Buyer?,
        val name: String,
        val govCode: String,
        val street: String,
        val postalCode: String,
        val city: String,
        val isSaving: Boolean,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, "", "", "", "", "", false),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val name: DesignSystem.TextInput,
            val govCode: DesignSystem.TextInput,
            val street: DesignSystem.TextInput,
            val postalCode: DesignSystem.TextInput,
            val city: DesignSystem.TextInput,
            val save: DesignSystem.Button,
            val saved: DesignSystem.Toast,
            val logo: DesignSystem.FileInput,
            val upload: DesignSystem.Button,
            val tier: DesignSystem.TextInput,
            val upgrade: DesignSystem.Button,
            val closeAccount: DesignSystem.Button,
            val upgradeModel: DesignSystem.Modal,
            val closeAccountModal: DesignSystem.Modal,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class Upgraded(
            override var texts: Texts,
            override var state: State,
            val upgrade: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State, status: List<ValidationStatus> = emptyList()): ViewModel {
            val company = state.buyer?.identity as? Buyer.Identity.Company
            val address = state.buyer?.address as? Address.Swedish
            return Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD,),
                name = DesignSystem.TextInput(
                    header = Header.name,
                    label = texts.name,
                    placeholder = null,
                    type = DesignSystem.TextInputType.TEXT,
                    value = company?.companyName ?: "",
                    status = status.statusOf(Header.name),
                ),
                govCode = DesignSystem.TextInput(
                    header = Header.govCode,
                    label = texts.govCode,
                    placeholder = null,
                    type = DesignSystem.TextInputType.TEXT,
                    value = company?.govCode ?: "",
                    status = status.statusOf(Header.govCode),
                ),
                street = DesignSystem.TextInput(
                    header = Header.street,
                    label = texts.street,
                    placeholder = null,
                    type = DesignSystem.TextInputType.TEXT,
                    value = address?.street ?: "",
                    status = status.statusOf(Header.street),
                ),
                postalCode = DesignSystem.TextInput(
                    header = Header.postalCode,
                    label = texts.postalCode,
                    placeholder = null,
                    type = DesignSystem.TextInputType.TEXT,
                    value = address?.postalCode?.toString() ?: "",
                    status = status.statusOf(Header.postalCode),
                ),
                city = DesignSystem.TextInput(
                    header = Header.city,
                    label = texts.city,
                    placeholder = null,
                    type = DesignSystem.TextInputType.TEXT,
                    value = address?.city ?: "",
                    status = status.statusOf(Header.city),
                ),
                save = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.saveButton,
                    style = DesignSystem.PaletteType.PRIMARY,
                ),
                saved = DesignSystem.Toast(
                    message = texts.savedToast,
                    style = DesignSystem.ToastStyle.SUCCESS,
                ),
                logo = DesignSystem.FileInput(
                    header = Header.logo,
                    label = null,
                    helpText = DesignSystem.Text(text = texts.helpText),
                ),
                upload = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.uploadButton,
                    style = DesignSystem.PaletteType.PRIMARY,
                ),
                upgrade = DesignSystem.Button(
                    type = DesignSystem.ButtonType.SUBMIT,
                    text = texts.upgradeButton,
                    style = DesignSystem.PaletteType.PRIMARY,
                    visible = state.buyer?.tier is Buyer.Tier.Standard
                ),
                tier = DesignSystem.TextInput(
                    header = Header.tier,
                    label = texts.tier,
                    type = DesignSystem.TextInputType.TEXT,
                    value = state.buyer?.tier?.displayName ?: "",
                    disabled = true
                ),
                closeAccount = DesignSystem.Button(
                    type = DesignSystem.ButtonType.BUTTON,
                    text = texts.closeAccountButton,
                    danger = true,
                    style = DesignSystem.PaletteType.TRANSPARENT,
                ),
                upgradeModel = DesignSystem.Modal(
                    title = texts.upgradeTitle,
                    firstButton = DesignSystem.Button(
                        type = DesignSystem.ButtonType.BUTTON,
                        text = texts.cancel,
                        style = DesignSystem.PaletteType.SECONDARY,
                    ),
                    secondButton = DesignSystem.Button(
                        type = DesignSystem.ButtonType.BUTTON,
                        text = texts.yesUpgrade,
                        style = DesignSystem.PaletteType.PRIMARY,
                    ),
                ),
                closeAccountModal = DesignSystem.Modal(
                    title = texts.closeAccountTitle,
                    firstButton = DesignSystem.Button(
                        type = DesignSystem.ButtonType.BUTTON,
                        text = texts.no,
                        style = DesignSystem.PaletteType.SECONDARY,
                    ),
                    secondButton = DesignSystem.Button(
                        type = DesignSystem.ButtonType.BUTTON,
                        text = texts.yes,
                        style = DesignSystem.PaletteType.PRIMARY,
                    ),
                )
            )
        }

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun upgraded(): ViewModel = Upgraded(
            texts = texts,
            state = state,
            upgrade = DesignSystem.Confirmation(
                icon = null,
                animation = DesignSystem.Animation.CONFETTI,
                title = texts.upgradedTitle,
                body = texts.upgradedBody,
                next = texts.nextButton,
                href = ROUTES.DASHBOARD.INDEX
            )
        )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val texts = Texts(
            title = "Abonnemang",
            name = "Företagsnamn",
            govCode = "Organisationsnummer",
            street = "Gatuadress",
            postalCode = "Postnummer",
            city = "Ort",
            saveButton = "Spara ändringar",
            savedToast = "Abonnemang uppdaterat",
            logo = "Logo/symbol",
            uploadButton = "Ladda upp",
            tier = "Plan",
            upgradeButton = "Uppgradera till Pro",
            upgradedTitle = "Grattis!!",
            upgradedBody = "Du har uppgraderat ditt Moodi konto till Pro. Pro funktioner är nu tillagda på ditt konto. Debitering för Pro börjar nästkommande månad.",
            nextButton = "Fortsätt",
            closeAccountSubject = "Avsluta Moodi",
            closeAccountBody = "Hej Moodi, Vi på ${store.buyer?.identity?.name} vill avsluta vårt abonnemang.",
            closeAccountButton = "\uD83D\uDE25 Avsluta abonnemang",
            upgradeTitle = "Bas till Pro?",
            upgradeBody = "Vill du uppgradera från Bas till Pro?",
            cancel = "Oj, inte än",
            yesUpgrade = "Japp!",
            yes = "Ja",
            no = "Nej",
            closeAccountTitle = "Vill du avsluta abonnemanget?",
            closeAccountInfo = "Genom att klicka på \"Ja\" sätts kontot först i avslutningsläge, abonnemanget avslutas och debitering kommer upphöra först efter tre månader med start nästkommande månad enligt avtal. Under denna tid fungerar Moodi fullt ut. Vid avslut kommer alla bilder, inlägg m.m helt att raderas och kan inte återskapas. Vill du veta mer hittar du det i våra avtalsvillkor. Har du några frågor kontakta oss på hey@moodi.rocks",
            fieldRequired = "\uD83D\uDC6E Du måste ange ett värde här",
            govCodeIllegalCharacters = "\uD83D\uDC6E Organisationsnummer får bara innehålla siffror eller bindestreck\n",
            govCodeWrongLength = "\uD83D\uDC6E Ange organisationsnummer med 10 siffror",
            postalCodeIllegalCharacters = "\uD83D\uDC6E Postnummer ska bara vara siffror",
            postalCodeWrongLength = "\uD83D\uDC6E Postnummer ska vara 5 siffror\n",
            helpText = ".png eller .jpg format. Minst 512x512 pixlar, men inte mer än 1024x1024 pixlar.",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return store.listBuyers().map { (actions, buyers) ->
            val state = viewModel.state.copy(buyer = buyers.firstOrNull())
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    fun validate(scene: Scene.Input<ViewModel>, name: String, govCode: String, street: String, postalCode: String, city: String): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status = mutableListOf<ValidationStatus>()
        var state = viewModel.state.copy(name = name, govCode = govCode, street = street, postalCode = postalCode, city = city)

        Validator.text(
            text = name,
            isEmpty = { status.add(Header.name to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.name to Status.Valid) },
            formatted = { state = state.copy(name = it) },
        )
        Validator.govCode(
            govCode = govCode,
            isWrongLength = { status.add(Header.govCode to Status.Invalid(warning = viewModel.texts.govCodeWrongLength)) },
            illegalCharacters = { status.add(Header.govCode to Status.Invalid(warning = viewModel.texts.govCodeIllegalCharacters)) },
            isEmpty = { status.add(Header.govCode to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.govCode to Status.Valid) },
            formatted = { state = state.copy(govCode = it) },
        )
        Validator.text(
            text = street,
            isEmpty = { status.add(Header.street to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.street to Status.Valid) },
            formatted = { state = state.copy(street = it) },
        )
        Validator.postalCode(
            postalCode = postalCode,
            isEmpty = { status.add(Header.postalCode to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.postalCode to Status.Valid) },
            formatted = { state = state.copy(postalCode = it) },
            isWrongLength = { status.add(Header.postalCode to Status.Invalid(warning = viewModel.texts.postalCodeWrongLength)) },
            illegalCharacters = { status.add(Header.postalCode to Status.Invalid(warning = viewModel.texts.postalCodeIllegalCharacters)) },
        )
        Validator.text(
            text = city,
            isEmpty = { status.add(Header.city to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.city to Status.Valid) },
            formatted = { state = state.copy(city = it) },
        )

        if (status.overallStatus() !is Status.Valid)
            return sceneOf(viewModel.ready(state = viewModel.state, status = status))

        return sceneOf(viewModel.ready(state = state.copy(isSaving = true)))
    }

    suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state
        val buyer = state.buyer ?: return sceneOf(viewModel.failed("No buyer"))

        val identity = Buyer.Identity.Company(
            companyName = state.name, govCode = state.govCode, taxCode = null
        )
        val address = Address.Swedish(
            street = state.street, postalCode = state.postalCode.toInt(), city = state.city
        )
        val edit = Buyer.Edit(
            identity = modifiedOf(identity),
            address = modifiedOf(address),
        )

        return store.editBuyer(buyer.id, edit).map { (actions, buyer) ->
            val action = Store.Action.LoadBuyer(buyer = buyer)
            sceneOf(viewModel.ready(state = viewModel.state.copy(isSaving = false)), actions + action)
        }
            .failed { scene.failed(result = it) }
    }

    suspend fun upload(scene: Scene.Input<ViewModel>, asset: Asset.Create?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val buyer = viewModel.state.buyer ?: return sceneOf(viewModel.failed("No buyer"))
        asset ?: return sceneOf(viewModel.failed("No asset"))

        return store.createAsset(asset).flatMap { (actions, asset) ->
            val edit = Buyer.Edit(
                logo = modifiedOf(Buyer.Logo.Asset(
                    assetId = asset.id,
                    href = asset.publicUrl
                ))
            )
            store.reduce(actions).editBuyer(buyer.id, edit).accumulate(actions).map { (actions, _) ->
                val action = Store.Action.LoadBuyer(buyer = buyer)
                sceneOf(viewModel.ready(state = viewModel.state, emptyList()), actions + action)
            }
        }.failed { scene.failed(result = it) }
    }

    suspend fun upgrade(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val buyer = viewModel.state.buyer ?: return sceneOf(viewModel.failed("No buyer"))

        val edit = Buyer.Edit(
            tier = modifiedOf(Buyer.Tier.Pro)
        )

        return store.editBuyer(buyer.id, edit).map { (actions, buyer) ->
            val action = Store.Action.LoadBuyer(buyer = buyer)
            sceneOf(viewModel.upgraded(), actions + action)
        }.failed { scene.failed(result = it) }
    }
}