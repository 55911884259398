package screens

import services.*
import support.*
import techla.base.*
import techla.content.Toggle
import techla.guard.Profile
import views.fullName
import kotlin.time.ExperimentalTime

@ExperimentalTime
object UsersScreen {
    object Header {
        val directoryOn = DesignSystem.Header(id = "directory_on")
        val directoryName = DesignSystem.Header(id = "directory_name")
        val directoryPhone = DesignSystem.Header(id = "directory_phone")
        val directoryEmail = DesignSystem.Header(id = "directory_email")
        val directoryAIBio = DesignSystem.Header(id = "directory_ai_bio")
        val search = DesignSystem.Header(id = "search")
    }

    data class Texts(
        val title: String,
        val new: String,
        val directoryOn: String,
        val directoryName: String,
        val directoryNameRequired: String,
        val directoryPhone: String,
        val directoryEmail: String,
        val directoryAIBio: String,
        val save: String,
        val search: String,
        val hash: String,
        val email: String,

        val next: String,
        val savedIcon: String,
        val savedTitle: String,
        val savedBody: String,
    )

    data class State(
        val profiles: PageContent<Profile>? = null,
        val directoryId: Identifier<Toggle>? = null,
        val directoryOn: Boolean = false,
        val directoryName: String = "",
        val directoryPhone: Boolean = false,
        val directoryEmail: Boolean = false,
        val directoryAIBio: Boolean = false,
        val search: String? = null,
        val isSaving: Boolean = false,
        val page: Int = 1,
        val rowsPerPage: Int = 25,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val new: DesignSystem.Button,
            val directoryOn: DesignSystem.BooleanInput,
            val directoryName: DesignSystem.TextInput,
            val directoryPhone: DesignSystem.BooleanInput,
            val directoryEmail: DesignSystem.BooleanInput,
            val directoryAIBio: DesignSystem.BooleanInput,
            val save: DesignSystem.Button,
            val search: DesignSystem.TextInput,
            val searchButton: DesignSystem.Button,
            val profiles: DesignSystem.Table<Profile>,
            val pagination: DesignSystem.Pagination,
        ) : ViewModel(texts, state)

        data class Saved(
            override var texts: Texts,
            override var state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State, status: List<ValidationStatus> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
                new = DesignSystem.Button(text = texts.new),
                directoryOn = DesignSystem.BooleanInput(header = Header.directoryOn, label = texts.directoryOn, value = state.directoryOn, style = DesignSystem.BooleanStyle.SWITCH),
                directoryName = DesignSystem.TextInput(header = Header.directoryName, label = texts.directoryName, value = state.directoryName, disabled = !state.directoryOn, status = status.statusOf(Header.directoryName)),
                directoryPhone = DesignSystem.BooleanInput(header = Header.directoryPhone, label = texts.directoryPhone, value = state.directoryPhone, disabled = !state.directoryOn, style = DesignSystem.BooleanStyle.SWITCH),
                directoryEmail = DesignSystem.BooleanInput(header = Header.directoryEmail, label = texts.directoryEmail, value = state.directoryEmail, disabled = !state.directoryOn, style = DesignSystem.BooleanStyle.SWITCH),
                directoryAIBio = DesignSystem.BooleanInput(header = Header.directoryAIBio, label = texts.directoryAIBio, value = state.directoryAIBio, disabled = !state.directoryOn, style = DesignSystem.BooleanStyle.SWITCH),
                save = DesignSystem.Button(text = texts.save),
                search = DesignSystem.TextInput(header = Header.search, value = state.search),
                searchButton = DesignSystem.Button(text = texts.search),
                profiles = DesignSystem.Table(
                    titles = listOf(
                        DesignSystem.Text(text = texts.hash),
                        DesignSystem.Text(text = texts.email),
                    ),
                    data = state.profiles?.contents?.map { profile ->
                        profile to listOf(
                            DesignSystem.Link(text = profile.fullName, to = ROUTES.DASHBOARD.USERS.show(profile.index.id)),
                            DesignSystem.Text(text = profile.email ?: "", size = DesignSystem.SizeType.MD),
                        )
                    },
                    visible = !state.profiles?.contents.isNullOrEmpty()
                ),
                pagination = DesignSystem.Pagination(state.profiles, visible = !state.profiles?.contents.isNullOrEmpty())
            )

        fun saved(): ViewModel =
            Saved(
                texts = texts,
                state = state,
                confirmation = DesignSystem.Confirmation(
                    icon = texts.savedIcon,
                    title = texts.savedTitle,
                    body = texts.savedBody,
                    next = texts.next,
                )
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        val asLoading get() = this as? Loading
        val asReady get() = this as? Ready
        val asSaved get() = this as? Saved
        val asFailed get() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val texts = Texts(
            title = "Anställda",
            new = "Ny anställd",
            directoryOn = "Synas som eget menyval i appen?",
            directoryName = "Vilket namn ska menyvalet ha",
            directoryNameRequired = "\uD83D\uDC6E Namn måste anges",
            directoryPhone = "Visa telefonnummer på en anställds profilsida?",
            directoryEmail = "Visa e-post på en anställds profilsida?",
            directoryAIBio = "Visa AI generator på en anställds profilsida?",
            save = "Spara",
            email = "Email",
            search = "Sök",
            hash = "#",

            next = "Fortsätt",
            savedIcon = "\uD83E\uDD70",
            savedTitle = "Inställningarna sparade",
            savedBody = "Nu är det bara att tuta och köra!",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        return successfulOf(true).noActions()
            .flatMap { (actions, _) ->
                store.listAllToggles()
                    .accumulate(actions)
            }
            .map { (actions, toggles) ->
                val directory = toggles.firstOrNull { it.key == Directory.key }
                val showPhone = directory?.features?.filterIsInstance<Toggle.Feature.BooleanValue>()?.firstOrNull { it.key == Directory.showPhone }
                val showEmail = directory?.features?.filterIsInstance<Toggle.Feature.BooleanValue>()?.firstOrNull { it.key == Directory.showEmail }
                val showAIBio = directory?.features?.filterIsInstance<Toggle.Feature.BooleanValue>()?.firstOrNull { it.key == Directory.showAIBio }
                val state = viewModel.state.copy(
                    page = 1,
                    search = null,
                    directoryId = directory?.id,
                    directoryOn = directory?.status is Toggle.Status.Active,
                    directoryName = directory?.name ?: "",
                    directoryPhone = showPhone?.value ?: false,
                    directoryEmail = showEmail?.value ?: false,
                    directoryAIBio = showAIBio?.value ?: false,
                )
                showPage(store.reduce(actions), viewModel, state)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun search(scene: Scene.Input<ViewModel>, search: String?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state.copy(
            page = 1,
            search = search
        )
        return showPage(store, viewModel, state)
    }

    suspend fun setPage(scene: Scene.Input<ViewModel>, page: Int): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state.copy(
            page = page,
        )
        return showPage(store, viewModel, state)
    }

    suspend fun setRowsPerPage(scene: Scene.Input<ViewModel>, rowsPerPage: Int): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state.copy(
            rowsPerPage = rowsPerPage,
        )
        return showPage(store, viewModel, state)
    }

    private suspend fun showPage(store: Store, viewModel: ViewModel, state: State): Scene.Output<ViewModel> {
        val index = PageIndex(page = state.page, size = state.rowsPerPage, search = state.search)

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.listProfiles(index)
                    .accumulate(actions)
            }
            .map { (actions, profiles) ->
                val updated = state.copy(
                    profiles = profiles,
                )
                sceneOf(viewModel.ready(state = updated), actions)
            }
            .failed { sceneOf(viewModel.failed(message = it.message)) }
    }

    fun setValues(scene: Scene.Input<ViewModel>, directoryOn: Boolean? = null, directoryName: String? = null, directoryPhone: Boolean? = null, directoryEmail: Boolean? = null, directoryAIBio: Boolean? = null): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val state = viewModel.state.copy(
            directoryOn = directoryOn ?: viewModel.state.directoryOn,
            directoryName = directoryName ?: viewModel.state.directoryName,
            directoryPhone = directoryPhone ?: viewModel.state.directoryPhone,
            directoryEmail = directoryEmail ?: viewModel.state.directoryEmail,
            directoryAIBio = directoryAIBio ?: viewModel.state.directoryAIBio,
        )

        return sceneOf(viewModel.ready(state = state))
    }

    fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status = mutableListOf<ValidationStatus>()
        var state = viewModel.state

        Validator.text(
            text = state.directoryName,
            isEmpty = { status.add(Header.directoryName to Status.Invalid(warning = viewModel.texts.directoryNameRequired)) },
            passed = { status.add(Header.directoryName to Status.Valid) },
            formatted = { state = state.copy(directoryName = it) },
        )

        if (status.overallStatus() !is Status.Valid)
            return sceneOf(viewModel.ready(state = viewModel.state, status = status))

        return sceneOf(viewModel.ready(state = state.copy(isSaving = true)))
    }

    suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val status = if (state.directoryOn) Toggle.Status.Active else Toggle.Status.Inactive
        val features = listOf(
            Toggle.Feature.BooleanValue(state.directoryPhone, Directory.showPhone),
            Toggle.Feature.BooleanValue(state.directoryEmail, Directory.showEmail),
            Toggle.Feature.BooleanValue(state.directoryAIBio, Directory.showAIBio),
        )

        val create = Toggle.Create(
            key = Directory.key,
            status = status,
            name = state.directoryName,
            features = features,
        )

        val edit = Toggle.Edit(
            status = modifiedOf(status),
            name = modifiedOf(state.directoryName),
            features = modifiedOf(features),
        )

        return successfulOf(true).noActions()
            .flatMap { (actions, _) ->
                if (state.directoryId != null) {
                    store.editToggle(state.directoryId, edit)
                        .accumulate(actions)
                } else {
                    store.createToggle(create)
                        .accumulate(actions)
                }
            }
            .map { (actions, _) ->
                sceneOf(viewModel.saved(), actions)
            }
            .failed { scene.failed(result = it) }
    }
}