package screens

import screens.items.*
import services.*
import support.*
import techla.base.*
import techla.conversation.Feed
import techla.conversation.Message
import techla.form.Field
import techla.form.Form
import techla.storage.Asset
import kotlin.time.ExperimentalTime

@ExperimentalTime
object SkillsScreen {
    object Header {
        val feed = DesignSystem.Header(id = "feed")
        val name = DesignSystem.Header(id = "name")
        val image = DesignSystem.Header(id = "image")
        val pageBreak = DesignSystem.Header(id = Skills.PAGE_BREAK)

        fun external(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Skills.stringFromStyle(Field.Style.External)}${id.rawValue}")
        fun inline(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Skills.stringFromStyle(Field.Style.Inline)}${id.rawValue}")
        fun question(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Skills.stringFromStyle(Field.Style.Choice(emptyList()))}${id.rawValue}")
    }

    data class Texts(
        val skills: String,
        val external: String,
        val inline: String,
        val question: String,
        val pageBreak: String,
        val name: String,
        val image: String,
        val successTitle: String,
        val successBody: String,
        val done: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    data class State(
        val feed: Feed? = null,
        val assets: List<Asset> = emptyList(),
        val form: Form? = null,
        val message: Message? = null,
    )

    data class SearchParams(
        val skillItems: List<ActivityItem.ViewModel>,
        val courseItems: List<ActivityItem.ViewModel>,
    )

    data class ReadyParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    enum class Success { DELETE, ENABLE, DISABLE, DUPLICATE, SUCCESS }

    sealed class ViewModel(open val texts: Texts, open val state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        data class Waiting(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        data class Search(
            override val texts: Texts,
            override val state: State,
            val params: SearchParams,
            val image: DesignSystem.ImageView,
            val skillTitle: DesignSystem.Text,
            val courseTitle: DesignSystem.Text,
            val skillItems: List<ActivityItem.ViewModel>,
            val courseItems: List<ActivityItem.ViewModel>,
            val save: DesignSystem.Button,
            val toggle: DesignSystem.Button,
            val create: DesignSystem.Button,
        ) : ViewModel(texts, state)

        data class Ready(
            override val texts: Texts,
            override val state: State,
            val params: ReadyParams,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Success(
            override val texts: Texts,
            override val state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(
            override val texts: Texts,
            override val state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class GetPro(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun search(state: State? = null, params: SearchParams): ViewModel =
            tupleOf(texts, state ?: this.state).let { (texts, state) ->
                val isCreated = state.feed != null
                val isActive = isCreated && state.feed?.status is Feed.Status.Active
                val toggle = if (isActive) "Inaktivera" else "Aktivera"
                Search(
                    texts = texts,
                    state = state,
                    params = params,
                    skillTitle = DesignSystem.Text(text = "Skills", size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                    courseTitle = DesignSystem.Text(text = "Kurser", size = DesignSystem.SizeType.LG, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER, visible = isActive),
                    image = DesignSystem.ImageView(image = DesignSystem.Image.SKILLS),
                    save = DesignSystem.Button(text = "Spara", action = DesignSystem.Action.SAVE, visible = isActive),
                    toggle = DesignSystem.Button(text = toggle, action = DesignSystem.Action.CREATE),
                    skillItems = params.skillItems,
                    create = DesignSystem.Button(text = "Skapa en kurs", action = DesignSystem.Action.ADD, visible = isCreated),
                    courseItems = params.courseItems,
                )
            }

        fun ready(state: State? = null, params: ReadyParams): ViewModel =
            tupleOf(texts, state ?: this.state).let { (texts, state) ->
                Ready(
                    texts = texts,
                    state = state,
                    params = params,
                    items = params.items,
                )
            }

        fun success(success: SkillsScreen.Success): ViewModel =
            Success(
                texts = texts,
                state = state,
                confirmation = DesignSystem.Confirmation(
                    icon = "",
                    title = texts.successTitle,
                    body = texts.successBody,
                    next = texts.done,
                )
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)


        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        val status
            get() = when (this) {
                is None -> "NONE"
                is Search -> "SEARCH"
                is Waiting -> "WAITING"
                is Loading -> "LOADING"
                is Ready -> "READY"
                is Failed -> "FAILED"
                is Success -> "SUCCESS"
                is GetPro -> "GET PRO"
            }

        fun asLoading() = this as? Loading
        fun asWaiting() = this as? Waiting
        fun asSearch() = this as? Search
        fun asReady() = this as? Ready
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message = message))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    private fun options(text: Texts) = listOf(
        DesignSystem.Option(title = text.external, value = "EXTERNAL", action = DesignSystem.Action.EXTERNAL),
        DesignSystem.Option(title = text.inline, value = "INLINE", action = DesignSystem.Action.INLINE),
        DesignSystem.Option(title = text.question, value = "QUESTION", action = DesignSystem.Action.QUESTION),
        DesignSystem.Option(title = text.pageBreak, value = "PAGE_BREAK", action = DesignSystem.Action.PAGE_BREAK),
    )

    private fun titles(text: Texts) = listOf(
        "Beskrivning",
        "Innehåll",
    )

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            skills = "Skills",
            external = "\uD83D\uDD17 Länkat material",
            inline = "\uD83D\uDCCE Bifogat material",
            question = "⁉️ Fråga och svar",
            pageBreak = "\uD83D\uDCC3 Sidbrytning",
            name = "Namn på kursen",
            image = "Bild",

            successTitle = "Allt är nu klart \uD83C\uDF8A",
            successBody = "Allt är nu klart!",
            done = "Fortsätt",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isPro) return sceneOf(viewModel.getPro())
        val page = PageIndex(page = 1, size = 200)
        // Use paging version to avoid business rules about archived feeds
        return store.listFeeds(styles = listOf(Feed.Style.Skills), index = page)
            .flatMap { (actions, feeds) ->
                val feed = feeds.contents.firstOrNull { it.key == Skills.FEED }
                if (feed == null)
                    successfulOf(tupleOf(actions, feed, emptyList()))
                else
                // Use paging version to avoid business rules about removed messages
                    store.reduce(actions).listMessages(feed = leftOf(feed.id), index = page)
                        .accumulate(actions)
                        .map { tupleOf(it.first, feed, it.second.contents) }
            }
            .flatMap { (actions, feed, messages) ->
                store.reduce(actions).findAssets(keys = listOf(Skills.GALLERY))
                    .accumulate(actions)
                    .map { tupleOf(it.first, feed, messages, it.second) }
            }
            .map { (actions, feed, messages, assets) ->
                val state = viewModel.state.copy(
                    feed = feed,
                    assets = assets,
                )
                val params = SearchParams(
                    skillItems = buildSkillSearch(feed), courseItems = buildCourseSearch(messages)
                        .decorate(mode = Mode.Search)
                )
                sceneOf(viewModel.search(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun saveSkills(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Search

        val feed = viewModel.state.feed
        val activityValues = viewModel.params.skillItems.activityValues()
        val name = activityValues.firstOrNull { it.first == Header.feed }?.second?.stringValue

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).createOrEditFeed(feed, name, null)
                    .accumulate(actions)
            }
            .map { (actions, feed) ->
                val state = viewModel.state.copy(
                    feed = feed,
                )
                val params = viewModel.params.copy(skillItems = buildSkillSearch(feed))
                sceneOf(viewModel.search(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun toggleSkills(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Search

        val feed = viewModel.state.feed
        val activityValues = viewModel.params.skillItems.activityValues()
        val name = activityValues.firstOrNull { it.first == Header.feed }?.second?.stringValue

        val status = when (viewModel.state.feed?.status) {
            is Feed.Status.Active -> Feed.Status.Archived
            is Feed.Status.Archived -> Feed.Status.Active
            else -> Feed.Status.Archived
        }

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).createOrEditFeed(feed, name, status)
                    .accumulate(actions)
            }
            .map { (actions, feed) ->
                val state = viewModel.state.copy(
                    feed = feed,
                )
                val params = viewModel.params.copy(skillItems = buildSkillSearch(feed))
                sceneOf(viewModel.search(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun show(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.getMessage(Identifier(id.rawValue))
            .flatMap { (actions, message) ->
                val formKey = message.forms.firstOrNull()?.form
                if (formKey == null)
                    successfulOf(actions, message, null)
                else
                    store.reduce(actions).findForms(keys = listOf(formKey))
                        .accumulate(actions)
                        .map { tupleOf(it.first, message, it.second.firstOrNull()) }
            }
            .map { (actions, message, form) ->
                val state = viewModel.state.copy(
                    message = message,
                    form = form,
                )
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(message, form, state.assets, viewModel.texts)
                        .decorate(mode = Mode.Edit, titles = titles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(Unit).noActions()
            .map { (actions, _) ->
                val state = viewModel.state.copy(
                    message = null,
                    form = null,
                )
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(null, null, state.assets, viewModel.texts)
                        .decorate(mode = Mode.Create, titles = titles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun toggle(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, enabled: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val flags = if (enabled) Message.Flags(removed = false) else Message.Flags(removed = true)
        val success = if (enabled) Success.ENABLE else Success.DISABLE
        return store.flagMessage(Identifier(id.rawValue), flags = flags)
            .map { (actions, _) ->
                sceneOf(viewModel.success(success), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.deleteMessage(id = Identifier(id.rawValue))
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.DELETE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun action(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, action: DesignSystem.Action): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        techla_log("SKILLS: action='$action'")

        return when (viewModel) {
            is ViewModel.Search ->
                when (action) {
                    DesignSystem.Action.EDIT -> show(scene, id)
                    DesignSystem.Action.ENABLE -> toggle(scene, id, enabled = true)
                    DesignSystem.Action.DISABLE -> toggle(scene, id, enabled = false)
                    DesignSystem.Action.DELETE -> delete(scene, id)
                    // DesignSystem.Action.DUPLICATE -> sceneOf(viewModel.waiting(viewModel.state.copy(formId = Identifier(id.rawValue))))
                    DesignSystem.Action.UP -> move(scene, id, isUp = true)
                    DesignSystem.Action.DOWN -> move(scene, id, isUp = false)
                    else -> sceneOf(viewModel)
                }

            is ViewModel.Ready ->
                when (action) {
                    DesignSystem.Action.SEARCH -> load(scene)
                    DesignSystem.Action.DELETE -> sceneOf(viewModel.replace(viewModel.params.items.delete(id)))
                    DesignSystem.Action.UP -> sceneOf(viewModel.replace(viewModel.params.items.up(id)))
                    DesignSystem.Action.DOWN -> sceneOf(viewModel.replace(viewModel.params.items.down(id)))
                    DesignSystem.Action.PREV -> sceneOf(viewModel.replace(viewModel.params.items.prev()))
                    DesignSystem.Action.NEXT -> validate(scene)
                    DesignSystem.Action.EXTERNAL -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.link(header = Header.external(), label = viewModel.texts.external))))
                    DesignSystem.Action.INLINE -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.asset(header = Header.inline(), label = viewModel.texts.inline, assets = viewModel.state.assets))))
                    DesignSystem.Action.QUESTION -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.question(header = Header.question(), label = viewModel.texts.question))))
                    DesignSystem.Action.PAGE_BREAK -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.pageBreak(header = Header.pageBreak, label = viewModel.texts.pageBreak))))
                    DesignSystem.Action.CREATE -> save(scene)
                    DesignSystem.Action.EDIT -> save(scene)
                    else -> sceneOf(viewModel)
                }

            else -> sceneOf(viewModel)
        }
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, value: ActivityValue): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        techla_log("SET VALUE: $value")
        return when (viewModel) {
            is ViewModel.Search -> sceneOf(viewModel.replace(skillItems = viewModel.params.skillItems.setValue(id = id, value = value)))
            is ViewModel.Ready -> sceneOf(viewModel.replace(viewModel.params.items.setValue(id = id, value = value)))
            else -> scene.failed("Unexpected view model in set value")
        }
    }

    private fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, viewModel.params.page)
        return if (status.overallStatus() !is Status.Valid) {
            sceneOf(viewModel.status(status))
        } else
            sceneOf(viewModel.replace(viewModel.params.items.next()))
    }

    private suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, null)
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.status(status))
        }

        val form = viewModel.state.form
        val feed = viewModel.state.feed!!
        val message = viewModel.state.message

        val activityValues = viewModel.params.items.activityValues()
        val name = activityValues.firstOrNull { it.first == Header.name }?.second?.stringValue
        val image = activityValues.firstOrNull { it.first == Header.image }?.second?.assetValue
        val userActivities = activityValues.filter { it.first != Header.feed && it.first != Header.name && it.first != Header.image }

        val position = 1

        techla_log("SKILLS: name is '$name'")
        techla_log("SKILLS: image is '$image'")
        techla_log("SKILLS: userActivities is '$userActivities'")

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).createOrEditForm(form, name)
                    .accumulate(actions)
            }
            .flatMap { (actions, form) ->
                store.reduce(actions).createOrEditMessage(feed, form, message, position, name ?: "", image)
                    .accumulate(actions)
                    .map { tupleOf(it.first, form, it.second) }
            }
            .flatMap { (actions, form, _) ->
                form.fields
                    .zipAll(Skills.fields(userActivities), null, null)
                    .map { (existing, modified) ->
                        when {
                            existing != null && modified != null -> store.reduce(actions).editField(existing.id, modified.toEdit()).map { tupleOf(it.first, Unit) }
                            existing == null && modified != null -> store.reduce(actions).createField(modified.toCreate(form.id)).map { tupleOf(it.first, Unit) }
                            existing != null && modified == null -> store.reduce(actions).deleteField(existing.id)
                            else -> successfulOf(Unit).noActions()
                        }.accumulate(actions)
                    }
                    .all()
                    .map { tupleOf(it.first, form, it.second) }
            }
            .map { (actions, _, _) ->
                sceneOf(viewModel.success(Success.SUCCESS), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun move(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, isUp: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Search

        val courseItems = if (isUp)
            viewModel.params.courseItems.up(id)
        else
            viewModel.params.courseItems.down(id)

        val messageId = Identifier<Message>(id.rawValue)
        var previous: Pair<Identifier<Message>, Int>? = null
        var next: Pair<Identifier<Message>, Int>? = null
        var found = false
        courseItems.forEachIndexed { index, activity ->
            when {
                !found && activity.id != id -> previous = Identifier<Message>(activity.id.rawValue) to index
                activity.id == id -> found = true
                found && next == null -> next = Identifier<Message>(activity.id.rawValue) to index
            }
        }

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (isUp && next != null) {
                    store.reduce(actions).swapMessageOrder(
                        id1 = messageId,
                        id2 = next!!.first,
                        order1 = next!!.second - 1,
                        order2 = next!!.second,
                    )
                        .map { it.first }
                } else if (!isUp && previous != null) {
                    store.reduce(actions).swapMessageOrder(
                        id1 = messageId,
                        id2 = previous!!.first,
                        order1 = previous!!.second + 1,
                        order2 = previous!!.second,
                    )
                        .map { it.first }
                } else {
                    successfulOf(actions)
                }
            }
            .map { actions ->
                sceneOf(viewModel.replace(courseItems = courseItems), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun Store.createOrEditFeed(feed: Feed?, name: String?, status: Feed.Status?): ActionOutcome<Feed> =
        successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (feed?.id == null) {
                    val create = Feed.Create(
                        key = Skills.FEED,
                        name = name ?: "",
                        style = Feed.Style.Skills,
                        status = status ?: Feed.Status.Archived,
                    )
                    reduce(actions).createFeed(create = create)
                        .accumulate(actions)
                } else {
                    val edit = Feed.Edit(
                        name = name?.let { modifiedOf(it) } ?: Modification.Unmodified,
                        status = status?.let { modifiedOf(it) } ?: Modification.Unmodified,
                    )
                    reduce(actions).editFeed(feed.id, edit = edit)
                        .accumulate(actions)
                }
            }

    private suspend fun Store.createOrEditForm(form: Form?, name: String?): ActionOutcome<Form> =
        successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (form?.id == null) {
                    val create = Form.Create(
                        key = Key.random(length = 10),
                        name = name ?: "",
                        kind = Form.Kind.Course,
                        feedback = Form.Feedback.None,
                        status = Form.Status.Inactive,
                        policy = Form.Policy.Append,
                    )
                    reduce(actions).createForm(create = create)
                        .accumulate(actions)
                } else {
                    val edit = Form.Edit(
                        name = name?.let { modifiedOf(it) } ?: Modification.Unmodified,
                    )
                    reduce(actions).editForm(form.id, edit = edit)
                        .accumulate(actions)
                }
            }

    private suspend fun Store.createOrEditMessage(feed: Feed, form: Form, message: Message?, position: Int, name: String, image: Identifier<Asset>?): ActionOutcome<Message> =
        successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (message?.id == null) {
                    val create = Message.Create(
                        feed = feed.key,
                        kind = Message.Kind.Post,
                        display = Message.Display.Fixed(position),
                        attachments = listOfNotNull(
                            Message.Attachment.InlineTitle(name, Skills.TITLE),
                            Message.Attachment.Form(form = form.key, Skills.FORM),
                            image?.let { Message.Attachment.AssetImage(assetId = it, Skills.IMAGE) },
                        )
                    )
                    reduce(actions).createMessage(create = create)
                        .accumulate(actions)
                        .flatMap { (actions, message) ->
                            reduce(actions).flagMessage(message.id, flags = Message.Flags(removed = true))
                                .accumulate(actions)
                        }
                } else {
                    val edit = Message.Edit(
                        attachments = modifiedOf(
                            listOfNotNull(
                                Message.Attachment.InlineTitle(name, Skills.TITLE),
                                Message.Attachment.Form(form = form.key, Skills.FORM),
                                image?.let { Message.Attachment.AssetImage(assetId = it, Skills.IMAGE) },
                            )
                        ),
                    )
                    reduce(actions).editMessage(message.id, edit = edit)
                        .accumulate(actions)
                }
            }

    private fun buildSkillSearch(feed: Feed?): List<ActivityItem.ViewModel> {
        return listOf(
            ActivityItem.basicFeed(Header.feed, label = "Namn", value = ActivityValue.StringValue(feed?.name ?: ""))
        )
    }

    private fun buildCourseSearch(messages: List<Message>): List<ActivityItem.ViewModel> {
        return messages.map { item ->
            ActivityItem.search(id = Identifier(item.id.rawValue), label = item.title ?: "", info = "", active = item.flags.removed != true, hasFixedPosition = false)
        }
    }

    private fun buildItems(message: Message?, form: Form?, assets: List<Asset>, texts: Texts): List<ActivityItem.ViewModel> {
        val page1 = listOf(
            ActivityItem.basicName(header = Header.name, label = texts.name, displayPage = 1, value = ActivityValue.StringValue(message?.title ?: "")),
            ActivityItem.basicAsset(header = Header.image, label = texts.image, assets = assets, displayPage = 1, value = ActivityValue.AssetValue("", "", message?.assetImages?.firstOrNull()?.assetId)),
        )
        val page2 = form?.fields
            ?.groupBy { it.page }
            ?.map { entry ->
                entry.value
                    .sortedBy { it.order }
                    .mapNotNull { field ->
                        when (val style = field.style) {
                            is Field.Style.External -> ActivityItem.link(header = Header.external(field.id), label = texts.external, value = ActivityValue.LinkValue(title = field.label ?: "", hint = field.hint ?: "", url = field.url?.toString() ?: ""), displayPage = 2)
                            is Field.Style.Inline -> ActivityItem.asset(header = Header.inline(field.id), label = texts.inline, assets = assets, value = ActivityValue.AssetValue(title = field.label ?: "", hint = field.hint ?: "", asset = field.assetId), displayPage = 2)
                            is Field.Style.Choice -> ActivityItem.question(header = Header.question(field.id), label = texts.question, value = ActivityValue.QuestionValue(title = field.label ?: "", choices = style.choices, answer = field.answer ?: ""), displayPage = 2)
                            else -> null
                        }
                    }
            }
            ?.filter { it.isNotEmpty() }
            ?.join(ActivityItem.pageBreak(header = Header.pageBreak, label = texts.pageBreak))
            ?: emptyList()

        return page1 + page2
    }
}

@ExperimentalTime
private fun SkillsScreen.ViewModel.Search.replace(
    skillItems: List<ActivityItem.ViewModel> = params.skillItems,
    courseItems: List<ActivityItem.ViewModel> = params.courseItems,
): SkillsScreen.ViewModel {
    return search(params = params.copy(skillItems = skillItems, courseItems = courseItems))
}

@ExperimentalTime
private fun SkillsScreen.ViewModel.Ready.replace(items: List<ActivityItem.ViewModel>): SkillsScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.resetStatus() }))
}

@ExperimentalTime
private fun SkillsScreen.ViewModel.Ready.status(status: List<ValidationStatus>): SkillsScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.setStatus(status) }))
}
