package components

import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.PropsWithChildren
import react.PropsWithClassName
import react.dom.events.FormEvent
import react.dom.html.ReactHTML.form
import web.html.HTMLFormElement

external interface RFormProps : PropsWithChildren, PropsWithClassName {
    var onSubmit: (suspend () -> Unit)?
}

val RForm = FC<RFormProps> { props ->
    fun onSubmit(event: FormEvent<HTMLFormElement>) {
        event.preventDefault()
        MainScope().launch {
            props.onSubmit?.let { it() }
        }
    }

    form {
        this.onSubmit = ::onSubmit
        className = props.className
        + props.children

    }
}