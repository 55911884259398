package screens

import services.listFeeds
import support.*
import techla.base.Either
import techla.base.PageIndex
import techla.base.Warning
import techla.base.map
import techla.conversation.Feed

object FeedsScreen {
    object Header {}

    data class Texts(
        val title: String,
        val new: String,
        val name: String,
        val standard: String,
    )

    data class State(
        val feeds: List<Feed>?,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", ""),
            state = State(null),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val new: DesignSystem.Button,
            val feeds: DesignSystem.Table<Feed>
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel = Ready(
            texts = texts,
            state = state,
            title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD,),
            new = DesignSystem.Button(text = texts.new),
            feeds = DesignSystem.Table(
                titles = listOf(
                    DesignSystem.Text(text = texts.name)
                ),
                data = state.feeds?.map { feed ->
                    feed to listOf(
                        if (feed.style is Feed.Style.Standard)
                            DesignSystem.Text(text = "${feed.key.rawValue} (${texts.standard})", size = DesignSystem.SizeType.MD)
                        else
                            DesignSystem.Link(text = feed.key.rawValue, to = ROUTES.DASHBOARD.FEEDS.show(feed.id))
                    )
                }
            )
        )

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            title = "Kanaler",
            name = "Namn",
            new = "Ny kanal",
            standard = "Inbyggd kanal som inte går att ändra",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        if (!store.isPro) return sceneOf(viewModel.getPro())

        val index = PageIndex(page = 1, size = 50)

        return store.listFeeds(index = index, styles = listOf(Feed.Style.Standard, Feed.Style.Normal, Feed.Style.External)).map { (action, pageContent) ->
            val state = viewModel.state.copy(feeds = pageContent.contents.sortedByDescending { it.style is Feed.Style.Standard })

            sceneOf(viewModel.ready(state = state), action)
        }.failed { scene.failed(result = it) }
    }
}