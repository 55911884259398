package views

import StoreContext
import components.*
import emotion.react.css
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.*
import screens.PulseScreen
import support.*
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Pulse = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: PulseScreen.ViewModel by useState(PulseScreen.ViewModel.None)
    val executor by useExecutor<PulseScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    var name by useState("")
    val (selectedFeed, setSelectedFeed) = useState<DesignSystem.Option>()
    val (selectedDay, setSelectedDay) = useState<DesignSystem.Option>()
    val (selectedDays, setSelectedDays) = useState<DesignSystem.Option>()
    val (selectedHour, setSelectedHour) = useState<DesignSystem.Option>()

    useEffect(viewModel) {
        if (viewModel is PulseScreen.ViewModel.None)
            executor.call { PulseScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is PulseScreen.ViewModel.Loading)
            executor.call { PulseScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onDisable() {
        executor.call { PulseScreen.disable(sceneInputOf(store, viewModel)) }
    }

    fun onEnable() {
        executor.call { PulseScreen.enable(sceneInputOf(store, viewModel), info = name, feed = selectedFeed, weekday = selectedDay, days = selectedDays, hour = selectedHour) }
    }

    fun onCreate() {
        executor.call { PulseScreen.create(sceneInputOf(store, viewModel)) }
    }

    fun onBack(action: DesignSystem.Action) {
        executor.call { PulseScreen.start(sceneInputOf(store, viewModel)) }
    }

    RScaffold {
        isFullScreen = false

        viewModel.asReady()?.run {
            RActivityItemTitle {
                imageView = image
                titleText = title
                bodyText = body
                firstButton = enable
                secondButton = disable
                onFirst = ::onCreate
                onSecond = ::onDisable
            }
        }

        viewModel.asCreate()?.run {
            Stack {
                spacing = responsive(6)
                sx {
                    width = 100.pct
                }

                RForm {
                    onSubmit = { onEnable() }
                    Stack {
                        spacing = responsive(4)
                        RTextInput { design = this@run.name; onChange = { value -> name = value } }
                        Stack {
                            spacing = responsive(4)
                            css {
                                flexDirection = FlexDirection.row
                                justifyContent = JustifyContent.spaceBetween
                            }
                            RSelectInput { design = day; overrideValue = selectedDay; onChange = { setSelectedDay(it) } }
                            RSelectInput { design = hour; overrideValue = selectedHour; onChange = { setSelectedHour(it) } }
                        }
                        RSelectInput { design = days; overrideValue = selectedDays; onChange = { setSelectedDays(it) } }
                        RSelectInput { design = feed; overrideValue = selectedFeed; onChange = { setSelectedFeed(it) } }
                        Stack {
                            direction = responsive(StackDirection.row)
                            sx {
                                justifyContent = JustifyContent.spaceBetween
                                paddingTop = 16.px
                            }
                            RButton { design = back; onClick = ::onBack }
                            RButton { design = next; }
                        }
                    }
                }
            }
        }

        viewModel.asLoading()?.run {
            RLoader {}
        }

        viewModel.asSuccess()?.run {
            RBackgroundImage {}
            RConfirmation { design = confirmation; onClick = ::onBack }
        }

        viewModel.asGetPro()?.run {
            RGetPro {}
        }

        viewModel.asFailed()?.run {
            RError { message = message }
        }
    }
}