package views

import StoreContext
import components.*
import react.*
import screens.QuizScreen
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.*
import techla.base.Identifier
import techla.base.techla_log
import kotlin.time.ExperimentalTime

@ExperimentalTime
val QuizView = FC<ModeProps> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    var viewModel: QuizScreen.ViewModel by useState(QuizScreen.ViewModel.None)
    val executor by useExecutor<QuizScreen.ViewModel> { (updated, actions) ->
        viewModel = updated
        dispatch(actions)
    }

    useEffect(viewModel) {
        techla_log("QUIZ: status='${viewModel.status}'")
        if (viewModel is QuizScreen.ViewModel.None)
            executor.call { QuizScreen.start(sceneInputOf(store, viewModel)) }
        if (viewModel is QuizScreen.ViewModel.Loading)
            executor.call { QuizScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        executor.call { QuizScreen.action(sceneInputOf(store, viewModel), id, action) }
    }

    fun onResume(action: DesignSystem.Action) {
        executor.call { QuizScreen.load(sceneInputOf(store, viewModel)) }
    }

    fun onChange(id: Identifier<ActivityItem>, value: ActivityValue) {
        executor.call { QuizScreen.setValue(sceneInputOf(store, viewModel), id, value) }
    }

    fun onCreate() {
        executor.call { QuizScreen.create(sceneInputOf(store, viewModel)) }
    }

    RScaffold {
        isFullScreen = false
        viewModel.asSearch()?.run {
            RActivityItemSearch {
                searchImage = image
                searchTitle = title
                activityItems = items
                firstButton = create
                onFirst = ::onCreate
                onAction = ::onAction
            }
        }

        viewModel.asReady()?.run {
            RActivityItemList {
                activityItems = items
                onChange = ::onChange
                onAction = ::onAction
            }
        }

        viewModel.asLoading()?.run {
            RLoader {}
        }

        viewModel.asSuccess()?.run {
            RConfirmation { design = confirmation; onClick = ::onResume }
        }

        viewModel.asGetPro()?.run {
            RGetPro {}
        }

        viewModel.asFailed()?.run {
            RError { message = message }
        }
    }
}
