package screens

import kotlinx.coroutines.async
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.coroutineScope
import screens.items.*
import services.*
import support.*
import techla.base.*
import techla.content.Toggle
import techla.loyalty.Pin

object BoostScreen {
    object Header {
        fun theme(theme: String) = DesignSystem.Header(id = theme)
    }

    private val standardPins = listOf(
        Pin.Create(key = Key("BOSS"), title = "Boss", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Inactive),
        Pin.Create(key = Key("CREATIVE"), title = "Kreatör", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("FRIEND"), title = "Kompis", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("GENIUS"), title = "Geni", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("GURU"), title = "Guru", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("HACKER"), title = "Tekniker", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("HERO"), title = "Hjälte", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("JOKER"), title = "Skojare", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("LOVE_CARE"), title = "Godhjärtad", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("MENTOR"), title = "Mentor", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("MULTITASKER"), title = "Multitasker", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("PARTY"), title = "Party", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("POSITIVE"), title = "Positiv", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("PROBLEMSOLVER"), title = "Problemlösare", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("STAR"), title = "Stjärna", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("TEAMPLAYER"), title = "Lagspelare", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("WILDCARD"), title = "Wildcard", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
        Pin.Create(key = Key("WORKHORSE"), title = "Arbetshäst", theme = "standard", category = Pin.Category.Boost, status = Pin.Status.Active),
    )

    enum class Success { DELETE, ENABLE, DISABLE, DUPLICATE, SUCCESS }

    data class Texts(
        val back: String,
        val next: String,
        val content: String,
        val delete: String,
        val cancel: String,
        val successTitle: String,
        val successBody: String,
        val done: String,
        val deletedTitle: String,
        val deletedBody: String,
        val deleteModalTitle: String,
        val deleteModalBody: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    class State(
    )

    data class SearchParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    data class ReadyParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    sealed class ViewModel(open val texts: Texts, open val state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        data class Waiting(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        data class Search(
            override val texts: Texts,
            override val state: State,
            val params: SearchParams,
            val title: DesignSystem.Text,
            val body: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val create: DesignSystem.Button,
            val items: List<ActivityItem.ViewModel>,
            val deleteModal: DesignSystem.Modal
        ) : ViewModel(texts, state)

        data class Ready(
            override val texts: Texts,
            override val state: State,
            val params: ReadyParams,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Success(
            override val texts: Texts,
            override val state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(
            override val texts: Texts,
            override val state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class GetPro(
            override val texts: Texts,
            override val state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts? = null): ViewModel =
            Loading(texts = texts ?: this.texts, state = state)

        fun waiting(state: State): ViewModel =
            ViewModel.Waiting(texts = texts, state = state)

        fun search(state: State, params: SearchParams): ViewModel =
            Search(
                texts = texts,
                state = state,
                params = params,
                title = DesignSystem.Text(text = "Boost", size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                body = DesignSystem.Text(
                    text = "Boost är till för att anställda ska kunna peppa och motivera varandra i appen. Nedan finns olika medaljpaket som man kan aktivera i appen. Vill ni ha ett eget medaljpaket med egna medaljer, kontakta oss.",
                    size = DesignSystem.SizeType.SM,
                    style = DesignSystem.StyleType.REGULAR,
                    align = DesignSystem.TextAlign.CENTER,
                    visible = params.items.isEmpty()
                ),
                image = DesignSystem.ImageView(image = DesignSystem.Image.BOOST),
                create = DesignSystem.Button(
                    text = "Aktivera",
                    action = DesignSystem.Action.ADD,
                    visible = params.items.isEmpty()
                ),
                items = params.items,
                deleteModal = DesignSystem.Modal(
                    title = texts.deleteModalTitle,
                    firstButton = DesignSystem.Button(text = texts.cancel),
                    secondButton = DesignSystem.Button(text = texts.delete),
                )
            )

        fun ready(state: State? = null, params: ReadyParams): ViewModel =
            tupleOf(texts, state ?: this.state).let { (texts, state) ->
                Ready(
                    texts = texts,
                    state = state,
                    params = params,
                    items = params.items,
                )
            }

        fun success(success: BoostScreen.Success): ViewModel = Success(
            texts = texts,
            state = state,
            confirmation = DesignSystem.Confirmation(
                icon = "",
                title = when (success) {
                    BoostScreen.Success.SUCCESS, BoostScreen.Success.ENABLE -> texts.successTitle
                    BoostScreen.Success.DELETE, BoostScreen.Success.DISABLE -> texts.deletedTitle
                    else -> "Boost uppdaterad \uD83C\uDF8A"
                },
                body = when (success) {
                    BoostScreen.Success.SUCCESS, BoostScreen.Success.ENABLE -> texts.successBody
                    BoostScreen.Success.DELETE, BoostScreen.Success.DISABLE -> texts.deletedBody
                    else -> ""
                },
                next = texts.done,
            )
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        val status
            get() = when (this) {
                is None -> "NONE"
                is Search -> "SEARCH"
                is Waiting -> "WAITING"
                is Loading -> "LOADING"
                is Ready -> "READY"
                is Failed -> "FAILED"
                is Success -> "SUCCESS"
                is GetPro -> "GET PRO"
            }

        fun asLoading() = this as? Loading
        fun asWaiting() = this as? Waiting
        fun asSearch() = this as? Search
        fun asReady() = this as? Ready
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    private fun titles(text: Texts) = listOf(
        "Tillgängliga medaljpaket",
    )

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            back = "Tillbaka",
            next = "Nästa",
            content = "Innehåll",
            delete = "Radera",
            cancel = "Avbryt",

            successTitle = "Boost aktiverad \uD83C\uDF8A",
            successBody = "Boost är nu igång och kommer att synas för anställda i appen.",
            deletedTitle = "Boost avaktiverad ⏹\uFE0F",
            deletedBody = "Boost är nu avaktivarad och kommer inte att synas för anställda i appen.",
            deleteModalTitle = "Är du säker?",
            deleteModalBody = "Genom att radera Boosten kommer funktionen att försvinna ifrån appen, men du kan alltid skapa en ny Boost här igen. Boosts som gjorts kommer fortsätta synas i anställdas profiler.",
            done = "Fortsätt",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isPro) return sceneOf(viewModel.getPro())
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).listAllToggles()
                    .accumulate(actions)
            }
            .flatMap { (actions, toggles) ->
                store.reduce(actions).listPins(categories = listOf(Pin.Category.Boost))
                    .accumulate(actions)
                    .map { tupleOf(it.first, it.second, toggles) }
            }
            .map { (actions, pins, toggles) ->
                val state = viewModel.state
                val params = SearchParams(page = 1, items = buildSearch(toggles, pins))
                sceneOf(viewModel.search(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun show(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).getToggle(Identifier(id.rawValue))
                    .accumulate(actions)
            }
            .flatMap { (actions, toggle) ->
                store.reduce(actions).listPins(categories = listOf(Pin.Category.Boost))
                    .accumulate(actions)
                    .map { tupleOf(it.first, it.second, toggle) }
            }
            .map { (actions, pins, toggle) ->
                val themes = pins.groupBy { it.theme }
                val state = viewModel.state
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(themes, viewModel.texts)
                        .decorate(mode = Mode.Edit, titles = titles(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).listPins(categories = listOf(Pin.Category.Boost))
                    .accumulate(actions)
                    .flatMap { (actions, pins) ->
                        coroutineScope {
                            val pinsToCreate = standardPins.filterNot { create -> pins.any { create.key == it.key } }

                            pinsToCreate.map { create ->
                                async { store.reduce(actions).createPin(create).accumulate(actions) }
                            }.awaitAll().all().flatMap {  (actions, createdPins) ->
                                successfulOf(actions to (pins + createdPins))
                            }
                        }
                    }
            }
            .map { (actions, pins) ->
                val themes = pins.groupBy { it.theme }
                val state = viewModel.state
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(themes, viewModel.texts)
                        .decorate(mode = Mode.Create, titles = titles(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun toggle(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, enabled: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status = if (enabled) Toggle.Status.Active else Toggle.Status.Inactive
        val success = if (enabled) Success.ENABLE else Success.DISABLE
        // TechContent 2.14.23 had a problem that you could not just edit status. It has been fixed in later releases, but we edit the key also as a workaround
        val edit = Toggle.Edit(status = modifiedOf(status), key = modifiedOf(Boost.key))
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).editToggle(Identifier(id.rawValue), edit)
                    .accumulate(actions)
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(success), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).deleteToggle(id = Identifier(id.rawValue))
                    .accumulate(actions)
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.DELETE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun action(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, action: DesignSystem.Action): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        techla_log("BOOST: action='$action'")

        return when (viewModel) {
            is ViewModel.Search ->
                when (action) {
                    DesignSystem.Action.EDIT -> show(scene, id)
                    DesignSystem.Action.ENABLE -> toggle(scene, id, enabled = true)
                    DesignSystem.Action.DISABLE -> toggle(scene, id, enabled = false)
                    DesignSystem.Action.DELETE -> delete(scene, id)
                    // DesignSystem.Action.DUPLICATE -> sceneOf(viewModel.waiting(viewModel.state.copy(formId = Identifier(id.rawValue))))
                    else -> sceneOf(viewModel)
                }

            is ViewModel.Ready ->
                when (action) {
                    DesignSystem.Action.SEARCH -> load(scene)
                    DesignSystem.Action.DELETE -> sceneOf(viewModel.replace(viewModel.params.items.delete(id)))
                    DesignSystem.Action.UP -> sceneOf(viewModel.replace(viewModel.params.items.up(id)))
                    DesignSystem.Action.DOWN -> sceneOf(viewModel.replace(viewModel.params.items.down(id)))
                    DesignSystem.Action.PREV -> sceneOf(viewModel.replace(viewModel.params.items.prev()))
                    DesignSystem.Action.NEXT -> save(scene)
                    DesignSystem.Action.CREATE -> save(scene)
                    DesignSystem.Action.EDIT -> save(scene)
                    else -> sceneOf(viewModel)
                }

            else -> sceneOf(viewModel)
        }
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, value: ActivityValue): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready
        return sceneOf(viewModel.replace(viewModel.params.items.setValue(id = id, value = value)))
    }

    private suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, null)
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.status(status))
        }

        val activityValues = viewModel.params.items.activityValues()

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).listPins(categories = listOf(Pin.Category.Boost))
                    .accumulate(actions)
            }
            .flatMap { (actions, pins) ->
                coroutineScope {
                    pins.map { pin ->
                        val isActive = activityValues.firstOrNull { it.first == Header.theme(pin.theme) }?.second?.booleanValue ?: false
                        val status = if (isActive) Pin.Status.Active else Pin.Status.Inactive
                        async { store.reduce(actions).editPin(pin.id, Pin.Edit(status = modifiedOf(status))) }
                    }.awaitAll().all()
                }
            }
            .flatMap { (actions, pins) ->
                store.reduce(actions).listAllToggles()
                    .accumulate(actions).flatMap { (actions, toggles) ->
                        val hasBoostAlready = toggles.any { it.key == Boost.key }
                        val noActivePins = pins.none { it.status is Pin.Status.Active }
                        if (hasBoostAlready || noActivePins) {
                            successfulOf(Unit).noActions()
                        } else {
                            store.reduce(actions).createToggle(Toggle.Create(key = Boost.key, name = Boost.name, status = Toggle.Status.Active, features = emptyList()))
                                .accumulate(actions)
                        }
                    }
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.SUCCESS), actions)
            }.failed { scene.failed(result = it) }
    }

    private fun buildSearch(toggles: List<Toggle>, pins: List<Pin>): List<ActivityItem.ViewModel> =
        toggles
            .filter { it.key == Boost.key }
            .map { item ->
                ActivityItem.search(
                    id = Identifier(item.id.rawValue),
                    label = item.name,
                    info = when (val numThemes = pins.filter{ it.status is Pin.Status.Active }.map { it.theme }.distinct().size) {
                        0 -> "Inga aktiva medaljpaket"
                        1 -> "1 aktivt medaljpaket"
                        else -> "$numThemes aktiva medaljpaket"
                    },
                    active = item.status is Toggle.Status.Active
                )
            }

    private fun buildItems(themes: Map<String, List<Pin>>, texts: Texts): List<ActivityItem.ViewModel> {
        val page1 = themes.keys
            .sortedBy {
                // standard should always be first, otherwise sort alphabetically
                if (it == "standard") "0" else it
            }
            .map { theme ->
                val isActive = themes[theme]?.firstOrNull()?.status == Pin.Status.Active
                ActivityItem.pins(
                    header = Header.theme(theme),
                    label = if (theme == "standard") "Moodi Standard" else theme.replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() },
                    value = ActivityValue.BooleanValue(value = isActive),
                    image = if (theme == "standard") DesignSystem.ImageView(image = DesignSystem.Image.STANDARD_PINS) else DesignSystem.ImageView(visible = false),
                )
            }
        return page1
    }
}

private fun BoostScreen.ViewModel.Ready.replace(items: List<ActivityItem.ViewModel>): BoostScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.resetStatus() }))
}

private fun BoostScreen.ViewModel.Ready.status(status: List<ValidationStatus>): BoostScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.setStatus(status) }))
}
