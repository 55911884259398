package components

import emotion.react.css
import mui.material.Container
import mui.material.Stack
import mui.material.StackDirection
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.router.useNavigate
import support.Design
import support.DesignSystem
import web.cssom.*


external interface RConfirmationProps : Props {
    var design: DesignSystem.Confirmation
    var onClick: (DesignSystem.Action) -> Unit
}

val RConfirmation = FC<RConfirmationProps> { props ->
    val navigate = useNavigate()

    fun onClick(action: DesignSystem.Action) {
        if (props.design.href != null)
            navigate(props.design.href!!)
        else
            props.onClick(action)
    }

    Container {
        maxWidth = "xs"
        Stack {
            direction = responsive(StackDirection.column)
            spacing = responsive(2)
            sx {
                justifyContent = JustifyContent.center
                alignItems = AlignItems.center
                minHeight = 40.vh
                padding = Padding(0.px, 50.px)
            }

            props.design.icon?.let {
                RText {
                    design = DesignSystem.Text(
                        text = it,
                        size = DesignSystem.SizeType.LG,
                        style = DesignSystem.StyleType.BOLD,
                        align = DesignSystem.TextAlign.CENTER
                    )
                }
            }

            props.design.animation?.let {
                div {
                    css {
                        height = 580.px
                        width = 435.px
                        position = Position.absolute
                        zIndex = (-1).unsafeCast<ZIndex>()
                    }
                    RLottie {
                        this.autoplay = true
                        this.loop = true
                        this.animationData = Design.animation(it)
                    }
                }
            }

            Stack {
                spacing = responsive(2)
                sx {
                    if (props.design.animation != null) paddingTop = 300.px
                    height = 100.pct
                }

                RText {
                    design = DesignSystem.Text(
                        text = props.design.title,
                        size = DesignSystem.SizeType.XL,
                        style = DesignSystem.StyleType.BOLD,
                        align = DesignSystem.TextAlign.CENTER
                    )
                }

                RText {
                    design = DesignSystem.Text(
                        text = props.design.body,
                        size = DesignSystem.SizeType.SM,
                        align = DesignSystem.TextAlign.CENTER
                    )
                }

                RButton {
                    design = DesignSystem.Button(
                        text = props.design.next,
                        type = DesignSystem.ButtonType.BUTTON,
                        style = DesignSystem.PaletteType.PRIMARY,
                        fullWidth = true
                    )
                    onClick = ::onClick
                }
            }
        }
    }
}