package screens

import ROUTES
import services.*
import support.*
import support.overallStatus
import support.statusOf
import techla.base.*
import techla.conversation.Cross
import techla.conversation.Feed
import techla.conversation.Mix
import techla.guard.Access
import techla.guard.Profile
import techla.guard.Role
import techla.loyalty.Member
import techla.personal.Narrative
import kotlin.time.ExperimentalTime

@ExperimentalTime
object UserScreen {
    object Header {
        val firstName = DesignSystem.Header(id = "firstName")
        val lastName = DesignSystem.Header(id = "lastName")
        val govId = DesignSystem.Header(id = "govId")
        val email = DesignSystem.Header(id = "email")
        val phone = DesignSystem.Header(id = "phone")
        val department = DesignSystem.Header(id = "department")
        val city = DesignSystem.Header(id = "city")
        val position = DesignSystem.Header(id = "position")
        val replace = DesignSystem.Header(id = "replace")

        fun read(id: String) = DesignSystem.Header(id = "read-$id")
        fun write(id: String) = DesignSystem.Header(id = "write-$id")
        fun delete(id: String) = DesignSystem.Header(id = "delete-$id")
    }

    data class Texts(
        val employee: String,
        val firstName: String,
        val lastName: String,
        val govId: String,
        val email: String,
        val phone: String,
        val department: String,
        val city: String,
        val position: String,
        val save: String,
        val updateToast: String,
        val deleteYes: String,
        val replace: String,
        val replaceToast: String,
        val cancel: String,
        val firstNameRequired: String,
        val lastNameRequired: String,
        val govIdRequired: String,
        val govIdLength: String,
        val govIdFormat: String,
        val emailRequired: String,
        val emailFormat: String,
        val phoneFormat: String,
        val back: String,
        val inactive: String,
        val createEmployee: String,
        val create: String,
        val remove: String,

        val next: String,
        val createdIcon: String,
        val createdTitle: String,
        val createdBody: String,

        val removedIcon: String,
        val removedTitle: String,
        val removedBody: String,

        val deleteModalTitle: String,
        val deleteModalBody: String,
        val deleteNo: String,
        val saved: String,

        val replaceModalTitle: String,
        val replaceModalBody: String,
        val replaceNo: String,
        val replaceYes: String,
    )

    data class State(
        val profile: Profile?,
        val narrative: Narrative?,
        val employee: String,
        val firstName: String?,
        val lastName: String?,
        val govId: String?,
        val email: String?,
        val phone: String?,
        val department: String?,
        val city: String?,
        val position: String?,
        val feeds: List<Feed> = emptyList(),
        val infos: List<FeedInfo> = emptyList(),
        val memberId: Identifier<Member>? = null,
        val mixId: Identifier<Mix>? = null,
        val contributorId: Identifier<Cross>? = null,
        val moderatorId: Identifier<Cross>? = null,
        val isSaving: Boolean = false,
        val isReplacing: Boolean = false,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, null, "", "", "", "", "", "", "", "", "")
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val back: DesignSystem.Button,
            val inactive: DesignSystem.Chip,
            val firstName: DesignSystem.TextInput,
            val lastName: DesignSystem.TextInput,
            val govId: DesignSystem.TextInput,
            val email: DesignSystem.TextInput,
            val phone: DesignSystem.TextInput,
            val city: DesignSystem.TextInput,
            val department: DesignSystem.TextInput,
            val position: DesignSystem.TextInput,
            val create: DesignSystem.Button,
            val save: DesignSystem.Button,
            val remove: DesignSystem.Button,
            val saved: DesignSystem.Toast,
            val modalRemove: DesignSystem.Modal,
            val deleteModalBody: String,
            val modalReplace: DesignSystem.Modal,
            val replaceModalBody: String,
            val feeds: DesignSystem.Table<FeedInfo>,
        ) : ViewModel(texts, state)

        data class Created(
            override var texts: Texts,
            override var state: State,
            val added: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Deleted(
            override var texts: Texts,
            override var state: State,
            val removed: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(override var texts: Texts, override var state: State, val message: String) :
            ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel = Loading(texts = texts, state = state)

        fun created(): ViewModel = Created(
            texts = texts, state = state, added = DesignSystem.Confirmation(
                icon = texts.createdIcon,
                title = texts.createdTitle,
                body = texts.createdBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.USERS.INDEX
            )
        )

        fun deleted(): ViewModel = Deleted(
            texts = texts, state = state, removed = DesignSystem.Confirmation(
                icon = texts.removedIcon,
                title = texts.removedTitle,
                body = texts.removedBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.USERS.INDEX
            )
        )

        fun ready(texts: Texts, state: State, status: List<ValidationStatus> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.employee, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD),
                back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.TRANSPARENT),
                inactive = DesignSystem.Chip(message = DesignSystem.Text(texts.inactive), style = DesignSystem.PaletteType.WARNING, visible = state.profile?.inactivatedAt != null),
                firstName = DesignSystem.TextInput(
                    header = Header.firstName,
                    label = texts.firstName,
                    value = state.firstName ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.firstName)
                ),
                lastName = DesignSystem.TextInput(
                    header = Header.lastName,
                    label = texts.lastName,
                    value = state.lastName ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.lastName)
                ),
                govId = DesignSystem.TextInput(
                    header = Header.govId,
                    label = texts.govId,
                    value = state.govId ?: "",
                    type = DesignSystem.TextInputType.GOV_ID,
                    status = status.statusOf(Header.govId)
                ),
                email = DesignSystem.TextInput(
                    header = Header.email,
                    label = texts.email,
                    value = state.email ?: "",
                    type = DesignSystem.TextInputType.EMAIL,
                    status = status.statusOf(Header.email)
                ),
                phone = DesignSystem.TextInput(
                    header = Header.phone,
                    label = texts.phone,
                    value = state.phone ?: "",
                    type = DesignSystem.TextInputType.PHONE,
                    status = status.statusOf(Header.phone)
                ),
                city = DesignSystem.TextInput(
                    header = Header.city,
                    label = texts.city,
                    value = state.city ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.city)
                ),
                department = DesignSystem.TextInput(
                    header = Header.department,
                    label = texts.department,
                    value = state.department ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.department)
                ),
                position = DesignSystem.TextInput(
                    header = Header.position,
                    label = texts.position,
                    value = state.position ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    status = status.statusOf(Header.position)
                ),
                feeds = DesignSystem.Table(
                    titles = listOf(
                        DesignSystem.Text(text = "Feeds"),
                        DesignSystem.Text(text = "Titta"),
                        DesignSystem.Text(text = "Posta"),
                        DesignSystem.Text(text = "Radera"),
                    ),
                    data = state.infos.map { feedInfo ->
                        feedInfo to listOf(
                            DesignSystem.Text(text = feedInfo.key),
                            DesignSystem.BooleanInput(header = Header.read(feedInfo.id), value = feedInfo.read, disabled = feedInfo.standard, label = ""),
                            DesignSystem.BooleanInput(header = Header.write(feedInfo.id), value = feedInfo.write, disabled = feedInfo.standard, label = ""),
                            DesignSystem.BooleanInput(header = Header.delete(feedInfo.id), value = feedInfo.delete, label = ""),
                        )
                    }
                ),
                create = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, style = DesignSystem.PaletteType.PRIMARY, text = texts.create),
                save = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, style = DesignSystem.PaletteType.PRIMARY, text = texts.save),
                remove = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, danger = true, style = DesignSystem.PaletteType.PRIMARY, text = texts.remove),
                saved = DesignSystem.Toast(message = texts.saved, style = DesignSystem.ToastStyle.SUCCESS),
                deleteModalBody = texts.deleteModalBody,
                replaceModalBody = texts.replaceModalBody,
                modalRemove = DesignSystem.Modal(title = texts.deleteModalTitle, firstButton = DesignSystem.Button(text = texts.deleteNo, style = DesignSystem.PaletteType.SECONDARY), secondButton = DesignSystem.Button(text = texts.deleteYes)),
                modalReplace = DesignSystem.Modal(title = texts.replaceModalTitle, firstButton = DesignSystem.Button(text = texts.replaceNo, style = DesignSystem.PaletteType.SECONDARY), secondButton = DesignSystem.Button(text = texts.replaceYes)),
            )

        fun failed(message: String): ViewModel = Failed(texts = texts, state = state, message = message)


        fun failed(result: Either<List<Warning>, Throwable>) = failed(message = result.message)
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(result))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            employee = "Anställd",
            createEmployee = "Skapa anställd",
            firstName = "Förnamn",
            lastName = "Efternamn",
            govId = "Personnummer",
            email = "E-post",
            phone = "Telefonnummer",
            department = "Avdelning",
            city = "Ort",
            position = "Roll",
            firstNameRequired = "\uD83D\uDC6E Förnamn måste anges",
            lastNameRequired = "\uD83D\uDC6E Efternamn måste anges",
            govIdRequired = "\uD83D\uDC6E Personnummer måste anges",
            govIdLength = "\uD83D\uDC6E Ange personnummer med 10 eller 12 siffror",
            govIdFormat = "\uD83D\uDC6E Personnummer ska bara innehålla siffror eller bindestreck",
            emailRequired = "\uD83D\uDC6E E-post måste anges",
            emailFormat = "\uD83D\uDC6E E-post ska innehålla @",
            phoneFormat = "Telefonnummer ska bara innehålla siffror, bindestreck, plus och blanksteg",
            inactive = "Inaktiv",
            back = "Tillbaka",

            save = "Spara ändringar",
            updateToast = "Anställd har uppdaterats",

            create = "Skapa anställd",
            remove = "Ta bort anställd",
            deleteModalTitle = "Är du säker?",
            deleteModalBody = "Vill du ta bort den anställda?",
            deleteNo = "Nej",
            deleteYes = "Japp!",

            replace = "Ersätt!",
            replaceToast = "Anställd har ersatts",
            cancel = "Avbryt",

            next = "Fortsätt",
            createdIcon = "\uD83E\uDD70",
            createdTitle = "Anställd upplagd",
            createdBody = "Nu är det bara att tuta och köra!",

            removedIcon = "\uD83D\uDC94",
            removedTitle = "Anställd borttagen",
            removedBody = "Man över bord! Nu är ni en mindre i ditt crew.",

            saved = "Anställd har uppdaterats",

            replaceModalTitle = "Hoppsan!",
            replaceModalBody = "Vi såg att det redan finns en upplagd med detta personnummer. Är du säker på att du vill ersätta den anställda?",
            replaceNo = "Avbryt",
            replaceYes = "Ersätt!",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, profileId: Identifier<Profile>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state

        val styles = listOf(Feed.Style.Standard, Feed.Style.Normal, Feed.Style.External)

        return store.getProfile(profileId).zip(
            store.narrative(profileId),
            store.member(profileId),
            store.listFeeds(styles),
            store.listMixes(profileId),
            store.listCrosses(profileId),
            store.listTraits(profileId),
        )
            .map { (profile, narrative, member, feeds, mixes, crosses, traits) ->
                val actions = profile.first + narrative.first + member.first + feeds.first + mixes.first + crosses.first + traits.first

                val mix = mixes.second.firstOrNull()
                val contributor = crosses.second.firstOrNull { it.role == Cross.Role.Contributor }
                val moderator = crosses.second.firstOrNull { it.role == Cross.Role.Moderator }
                val infos = store.createFeedInfoList(feeds.second, mix?.feeds, crosses.second) ?: emptyList()

                state = state.copy(
                    narrative = narrative.second,
                    profile = profile.second,
                    feeds = feeds.second,
                    infos = infos,
                    memberId = member.second?.id,
                    mixId = mix?.id,
                    contributorId = contributor?.id,
                    moderatorId = moderator?.id,
                    firstName = narrative.second?.firstName ?: profile.second.firstName,
                    lastName = narrative.second?.lastName ?: profile.second.lastName,
                    govId = profile.second.govId,
                    email = narrative.second?.email ?: profile.second.email,
                    phone = narrative.second?.phone ?: profile.second.phone,
                    city = narrative.second?.city,
                    department = narrative.second?.department,
                    position = narrative.second?.position,
                )
                sceneOf(viewModel.ready(texts = viewModel.texts, state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun setValues(scene: Scene.Input<ViewModel>, headerId: String, value: Boolean): Scene.Output<ViewModel> {
        val (_, viewModel) = scene

        val state = viewModel.state.copy(
            infos = viewModel.state.infos.map { feed ->
                FeedInfo(
                    id = feed.id,
                    key = feed.key,
                    read = if (headerId == Header.read(feed.id).id) value else feed.read,
                    write = if (headerId == Header.write(feed.id).id) value else feed.write,
                    delete = if (headerId == Header.delete(feed.id).id) value else feed.delete,
                    standard = feed.standard,
                )
            }
        )

        return sceneOf(viewModel.ready(texts = viewModel.texts, state = state))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state
        val styles = listOf(Feed.Style.Standard, Feed.Style.Normal, Feed.Style.External)
        return store.listFeeds(styles)
            .map { (actions, feeds) ->
                val infos = store.createFeedInfoList(feeds)
                state = state.copy(feeds = feeds, infos = infos ?: emptyList())
                sceneOf(viewModel.ready(texts = viewModel.texts, state = state), actions)

            }
            .failed { scene.failed(result = it) }
    }

    fun validate(scene: Scene.Input<ViewModel>, firstName: String, lastName: String, govId: String, email: String, phone: String, city: String, department: String, position: String): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status = mutableListOf<ValidationStatus>()
        var state = viewModel.state.copy(phone = phone, email = email, city = city, department = department, position = position)

        Validator.text(
            text = firstName,
            isEmpty = { status.add(Header.firstName to Status.Invalid(warning = viewModel.texts.firstNameRequired)) },
            passed = { status.add(Header.firstName to Status.Valid) },
            formatted = { state = state.copy(firstName = it) },
        )
        Validator.text(
            text = lastName,
            isEmpty = { status.add(Header.lastName to Status.Invalid(warning = viewModel.texts.lastNameRequired)) },
            passed = { status.add(Header.lastName to Status.Valid) },
            formatted = { state = state.copy(lastName = it) },
        )
        Validator.govId(
            govId = govId,
            illegalCharacters = { status.add(Header.govId to Status.Invalid(warning = viewModel.texts.govIdFormat)) },
            isWrongLength = { status.add(Header.govId to Status.Invalid(warning = viewModel.texts.govIdLength)) },
            isEmpty = { status.add(Header.govId to Status.Invalid(warning = viewModel.texts.govIdRequired)) },
            passed = { status.add(Header.govId to Status.Valid) },
            formatted = { state = state.copy(govId = it) },
        )
        Validator.email(
            email = email,
            isWrongFormat = { status.add(Header.email to Status.Invalid(warning = viewModel.texts.emailFormat)) },
            isEmpty = { },
            passed = { status.add(Header.email to Status.Valid) },
            formatted = { state = state.copy(email = it) },
        )

        if (status.overallStatus() !is Status.Valid)
            return sceneOf(viewModel.ready(texts = viewModel.texts, state = state, status = status))

        return sceneOf(viewModel.ready(texts = viewModel.texts, state = state.copy(isSaving = true)))
    }


    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val profile = Profile.Create(
            firstName = state.firstName?.trim(),
            lastName = state.lastName?.trim(),
            govId = state.govId?.trim(),
            email = state.email?.trim(),
            phone = state.phone?.trim(),
            access = store.currentWorkspace?.services?.map { Access(it, Role.User) }
        )

        val narrativeEdit = Narrative.Edit(
            firstName = modifiedOf(state.firstName?.trim()),
            lastName = modifiedOf(state.lastName?.trim()),
            email = modifiedOf(state.email?.trim()),
            phone = modifiedOf(state.phone?.trim()),
            city = modifiedOf(state.city?.trim()),
            department = modifiedOf(state.department?.trim()),
            position = modifiedOf(state.position?.trim())
        )

        val memberEdit = Member.Edit(
            balance = modifiedOf(0),
            notifications = modifiedOf(null)
        )

        val mixEdit = Mix.Edit(
            feeds = modifiedOf(state.infos.readableFeeds(state.feeds).map { it.key }),
        )

        val contributorEdit = Cross.Edit(
            role = modifiedOf(Cross.Role.Contributor),
            feeds = modifiedOf(state.infos.writeableFeeds(state.feeds).map { it.key }),
        )

        val moderatorEdit = Cross.Edit(
            role = modifiedOf(Cross.Role.Moderator),
            feeds = modifiedOf(state.infos.deletableFeeds(state.feeds).map { it.key }),
        )

        val index = PageIndex(page = 1, size = 5, search = profile.govId)
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.reduce(actions).listProfiles(index = index)
                    .accumulate(actions)
            }
            .map { (actions, page) ->
                val profiles = page.contents
                if (profiles.isNotEmpty()) {
                    if (profiles.size > 1) sceneOf(viewModel.failed(message = "More than one profile with the same govId exists."))
                    else {
                        val state = state.copy(isReplacing = true, isSaving = false, profile = profiles.first())
                        sceneOf(viewModel.ready(texts = viewModel.texts, state = state))
                    }
                } else {
                    store.reduce(actions).createProfile(create = profile).accumulate(actions).flatMap { (actions, profile) ->
                        val profileId = profile.index.id
                        successfulOf(actions to profile).zip(
                            store.narrative(profileId).flatMap { (actions, narrative) ->
                                store.reduce(actions).addEditNarrative(profileId, narrative?.id, narrativeEdit).accumulate(actions)
                            },
                            store.member(profileId).flatMap { (actions, member) ->
                                store.reduce(actions).addEditMember(profileId, member?.id, memberEdit).accumulate(actions)
                            },
                            store.listMixes(profileId).flatMap { (actions, mixes) ->
                                val existingMix = mixes.firstOrNull { it.destination.profileId == profileId }
                                if (existingMix == null)
                                    store.reduce(actions).addEditDeleteMix(profileId, state.mixId, mixEdit).accumulate(actions)
                                else successfulOf(actions to Unit)
                            },
                            store.addEditDeleteCross(profileId, state.contributorId, contributorEdit).accumulate(actions),
                            store.addEditDeleteCross(profileId, state.moderatorId, moderatorEdit).accumulate(actions),
                        )
                    }.fold(
                        { (profile, narrative, member, mix, contributor, moderator) ->
                            val actions = profile.first + narrative.first + member.first + mix.first + contributor.first + moderator.first
                            sceneOf(viewModel.created(), actions)
                        },
                        { sceneOf(viewModel.failed(it.message)) }
                    )
                }
            }.failed { scene.failed(result = it) }
    }

    suspend fun replace(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val profileId = viewModel.state.profile?.index?.id ?: return sceneOf(viewModel.failed(message = "Missing profile"))

        store.narrative(profileId).zip(
            store.listMixes(profileId),
            store.listCrosses(profileId),
        ).map { (narrative, mixes, crosses) ->
            viewModel.state = viewModel.state.copy(
                narrative = narrative.second,
                mixId = mixes.second.firstOrNull()?.id,
                contributorId = crosses.second.firstOrNull { it.role == Cross.Role.Contributor }?.id,
                moderatorId = crosses.second.firstOrNull { it.role == Cross.Role.Moderator }?.id,
            )
        }

        return update(sceneInputOf(store, viewModel))
    }

    suspend fun update(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state

        val profileId = viewModel.state.profile?.index?.id ?: return sceneOf(viewModel.ready(texts = viewModel.texts, state = viewModel.state))

        val profile = Profile.Edit(
            firstName = modifiedOf(state.firstName),
            lastName = modifiedOf(state.lastName),
            govId = modifiedOf(state.govId),
            email = modifiedOf(state.email),
            phone = modifiedOf(state.phone),
        )

        val narrative = Narrative.Edit(
            firstName = modifiedOf(state.firstName),
            lastName = modifiedOf(state.lastName),
            email = modifiedOf(state.email),
            phone = modifiedOf(state.phone),
            city = modifiedOf(state.city?.trim()),
            department = modifiedOf(state.department?.trim()),
            position = modifiedOf(state.position?.trim()),
        )

        val member = Member.Edit()

        val mix = Mix.Edit(
            feeds = modifiedOf(state.infos.readableFeeds(state.feeds).map { it.key })
        )

        val contributor = Cross.Edit(
            role = modifiedOf(Cross.Role.Contributor),
            feeds = modifiedOf(state.infos.writeableFeeds(state.feeds).map { it.key })
        )

        val moderator = Cross.Edit(
            role = modifiedOf(Cross.Role.Moderator),
            feeds = modifiedOf(state.infos.deletableFeeds(state.feeds).map { it.key })
        )

        return store.editProfile(id = profileId, edit = profile).zip(
            store.addEditNarrative(profileId = profileId, narrativeId = state.narrative?.id, edit = narrative),
            store.addEditMember(profileId = profileId, memberId = state.memberId, edit = member),
            store.addEditDeleteMix(profileId = profileId, mixId = state.mixId, edit = mix),
            store.addEditDeleteCross(profileId = profileId, crossId = state.contributorId, edit = contributor),
            store.addEditDeleteCross(profileId = profileId, crossId = state.moderatorId, edit = moderator),
        ).fold(
            { (profile, narrative, mix, contributor, moderator) ->
                val actions = profile.first + narrative.first + mix.first + contributor.first + moderator.first
                state = state.copy(narrative = narrative.second, profile = profile.second, isSaving = false)

                if (state.isReplacing) sceneOf(viewModel.created())
                else sceneOf(viewModel.ready(texts = viewModel.texts, state = state), actions)
            },
            { sceneOf(viewModel.failed(message = it.message)) }
        )
    }

    suspend fun delete(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val profileId = viewModel.state.profile?.index?.id ?: return sceneOf(
            viewModel.ready(texts = viewModel.texts, state = viewModel.state)
        )
        return store.deleteProfile(profileId).map { (actions, _) -> sceneOf(viewModel.deleted(), actions) }
            .failed { scene.failed(result = it) }
    }
}