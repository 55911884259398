const data = {
  "v": "5.5.9",
  "fr": 30,
  "ip": 0,
  "op": 50,
  "w": 512,
  "h": 512,
  "nm": "spinner",
  "ddd": 0,
  "assets": [],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 4,
      "nm": "Circle 1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": -1.001,
              "s": [
                -90
              ]
            },
            {
              "t": 49.999975561499,
              "s": [
                270
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            256,
            256,
            0
          ],
          "ix": 2
        },
        "a": {
          "a": 0,
          "k": [
            256,
            256,
            0
          ],
          "ix": 1
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6
        }
      },
      "ao": 0,
      "shapes": [
        {
          "ty": "gr",
          "it": [
            {
              "ind": 0,
              "ty": "sh",
              "ix": 1,
              "ks": {
                "a": 0,
                "k": {
                  "i": [
                    [
                      0,
                      -110.457
                    ],
                    [
                      110.457,
                      0
                    ],
                    [
                      0,
                      110.457
                    ],
                    [
                      -110.457,
                      0
                    ]
                  ],
                  "o": [
                    [
                      0,
                      110.457
                    ],
                    [
                      -110.457,
                      0
                    ],
                    [
                      0,
                      -110.457
                    ],
                    [
                      110.457,
                      0
                    ]
                  ],
                  "v": [
                    [
                      200,
                      0
                    ],
                    [
                      0,
                      200
                    ],
                    [
                      -200,
                      0
                    ],
                    [
                      0,
                      -200
                    ]
                  ],
                  "c": true
                },
                "ix": 2
              },
              "nm": "Path 1",
              "mn": "ADBE Vector Shape - Group",
              "hd": false
            },
            {
              "ty": "gs",
              "o": {
                "a": 0,
                "k": 100,
                "ix": 9
              },
              "w": {
                "a": 0,
                "k": 48,
                "ix": 10
              },
              "g": {
                "p": 3,
                "k": {
                  "a": 0,
                  "k": [
                    0,
                    0.137,
                    0.278,
                    0.871,
                    0.5,
                    0.137,
                    0.453,
                    0.871,
                    1,
                    0.137,
                    0.627,
                    0.871
                  ],
                  "ix": 8
                }
              },
              "s": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 4
              },
              "e": {
                "a": 0,
                "k": [
                  100,
                  0
                ],
                "ix": 5
              },
              "t": 1,
              "lc": 1,
              "lj": 1,
              "ml": 10,
              "ml2": {
                "a": 0,
                "k": 10,
                "ix": 13
              },
              "bm": 0,
              "nm": "Gradient Stroke 1",
              "mn": "ADBE Vector Graphic - G-Stroke",
              "hd": false
            },
            {
              "ty": "tr",
              "p": {
                "a": 0,
                "k": [
                  256,
                  256
                ],
                "ix": 2
              },
              "a": {
                "a": 0,
                "k": [
                  0,
                  0
                ],
                "ix": 1
              },
              "s": {
                "a": 0,
                "k": [
                  100,
                  100
                ],
                "ix": 3
              },
              "r": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "o": {
                "a": 0,
                "k": 100,
                "ix": 7
              },
              "sk": {
                "a": 0,
                "k": 0,
                "ix": 4
              },
              "sa": {
                "a": 0,
                "k": 0,
                "ix": 5
              },
              "nm": "Transform"
            }
          ],
          "nm": "Group 1",
          "np": 2,
          "cix": 2,
          "bm": 0,
          "ix": 1,
          "mn": "ADBE Vector Group",
          "hd": false
        },
        {
          "ty": "tm",
          "s": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": 26,
                "s": [
                  0
                ]
              },
              {
                "t": 49.999975561499,
                "s": [
                  100
                ]
              }
            ],
            "ix": 1
          },
          "e": {
            "a": 1,
            "k": [
              {
                "i": {
                  "x": [
                    0.667
                  ],
                  "y": [
                    1
                  ]
                },
                "o": {
                  "x": [
                    0.333
                  ],
                  "y": [
                    0
                  ]
                },
                "t": -1.001,
                "s": [
                  0
                ]
              },
              {
                "t": 49.999975561499,
                "s": [
                  100
                ]
              }
            ],
            "ix": 2
          },
          "o": {
            "a": 0,
            "k": 0,
            "ix": 3
          },
          "m": 1,
          "ix": 2,
          "nm": "Trim Paths 1",
          "mn": "ADBE Vector Filter - Trim",
          "hd": false
        }
      ],
      "ip": 0,
      "op": 50,
      "st": -1.001001001001,
      "bm": 0
    }
  ],
  "markers": []
}
module.exports = data;