package components.items

import mui.material.Step
import mui.material.StepLabel
import mui.material.Stepper
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import web.cssom.*

external interface ActivityItemHeaderProps: PropsWithChildren{
    var viewModel: ActivityItem.ViewModel.Header
}

val ActivityItemHeader = FC<ActivityItemHeaderProps>{ props ->
    if (props.viewModel.navigation.isVisible) {
        Stepper {
            sx {
                paddingTop = 1.rem
                paddingBottom = 1.rem
            }
            val page = props.viewModel.navigation.currentPage
            id = "ActivityItem-Header-$page"
            activeStep = page - 1
            nonLinear = true
            props.viewModel.titles.forEachIndexed { index, title ->
                Step {
                    id = "ActivityItem-Header-Page-${index + 1}"
                    key = "${index + 1}"
                    StepLabel {
                        +title
                    }
                }
            }
        }
    }
}