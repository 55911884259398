package components.items

import components.RTextInput
import mui.material.Stack
import mui.system.responsive
import mui.system.sx
import react.FC
import react.PropsWithChildren
import screens.items.ActivityItem
import screens.items.ActivityValue
import support.DesignSystem
import support.RActivityItem
import techla.base.Identifier
import web.cssom.*

external interface ActivityItemTextInputProps : PropsWithChildren {
    var viewModel: ActivityItem.ViewModel.TextInput
    var onChange: (Identifier<ActivityItem>, ActivityValue) -> Unit
    var onAction: (Identifier<ActivityItem>, DesignSystem.Action) -> Unit
}

val ActivityItemTextInput = FC<ActivityItemTextInputProps> { props ->
    fun onTitle(value: String) {
        props.onChange(props.viewModel.id, ActivityValue.StringValue(value))
    }

    fun onAction(id: Identifier<ActivityItem>, action: DesignSystem.Action) {
        props.onAction(id, action)
    }

    if (props.viewModel.navigation.isVisible) {
        RActivityItem {
            hasBorder = true
            viewModel = props.viewModel
            onAction = ::onAction

            Stack {
                id = "ActivityItem-TextInput"
                spacing = responsive(1.rem)
                sx {
                    paddingTop = 1.rem
                }

                RTextInput { design = props.viewModel.input; onChange = { onTitle(it) } }
            }
        }
    }
}