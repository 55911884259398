package views

import ROUTES
import StoreContext
import components.*
import kotlinx.coroutines.isActive
import mui.material.*
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.router.useNavigate
import react.useRequiredContext
import react.useState
import screens.FeedsScreen
import support.DesignSystem
import support.RScaffold
import support.sceneInputOf
import support.useAsyncEffect
import kotlin.time.ExperimentalTime
import web.cssom.*


@ExperimentalTime
val Feeds = FC<Props> {
    val (store, dispatch) = useRequiredContext(StoreContext)
    val (viewModel, setViewModel) = useState(FeedsScreen.ViewModel.None as FeedsScreen.ViewModel)
    val navigate = useNavigate()

    useAsyncEffect(viewModel) { coroutineScope ->
        when (viewModel) {
            is FeedsScreen.ViewModel.None -> if (coroutineScope.isActive) {
                FeedsScreen.start(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is FeedsScreen.ViewModel.Loading -> if (coroutineScope.isActive) {
                FeedsScreen.load(sceneInputOf(store, viewModel)).also { (viewModel, actions) ->
                    setViewModel(viewModel)
                    dispatch(actions)
                }
            }

            is FeedsScreen.ViewModel.Ready -> {}
            is FeedsScreen.ViewModel.GetPro -> {}
            is FeedsScreen.ViewModel.Failed -> {}
        }
    }

    RScaffold {
        if (viewModel is FeedsScreen.ViewModel.Ready) {
            Stack {
                spacing = responsive(2)
                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        justifyContent = JustifyContent.spaceBetween
                    }
                    RText { design = viewModel.title }
                    RButton {
                        design = viewModel.new
                        onClick = { navigate(ROUTES.DASHBOARD.FEEDS.ADD) }
                    }
                }

                RTable {
                    design = viewModel.feeds

                    viewModel.feeds.data?.map { (_, row) ->
                        TableRow {
                            row.map { cell ->
                                TableCell {
                                    variant = TableCellVariant.body
                                    when (cell) {
                                        is DesignSystem.Link -> RLink { design = cell }
                                        is DesignSystem.Text -> RText { design = cell }
                                        else -> {}
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        if (viewModel is FeedsScreen.ViewModel.Loading) {
            RLoader {}
        }

        if (viewModel is FeedsScreen.ViewModel.GetPro) {
            RGetPro {}
        }

        if (viewModel is FeedsScreen.ViewModel.Failed) {
            RError { message = viewModel.message }
        }
    }
}
