package support

import io.ktor.http.*
import screens.items.ActivityValue
import techla.base.Identifier
import techla.base.Key
import techla.base.TechlaError
import techla.conversation.Feed
import techla.conversation.Message
import techla.form.Field
import techla.storage.Catalog

object Skills {
    val FEED = Key<Feed>("#skills")
    val GALLERY = Key<Catalog>("gallery")

    val TITLE = Key<Message.Attachment>("TITLE")
    val FORM = Key<Message.Attachment>("FORM")
    val IMAGE = Key<Message.Attachment>("IMAGE")

    val EXTERNAL = "EXTERNAL"
    val INLINE = "INLINE"
    val CHOICES = "CHOICES"
    val PAGE_BREAK = "PAGE_BREAK"

    val CITY = "CITY"
    val DEPARTMENT = "DEPARTMENT"
    val POSITION = "POSITION"
    val GOVID = "GOVID"
    val FIRST_NAME = "FIRST_NAME"
    val LAST_NAME = "LAST_NAME"

    object PageBreak

    fun stringFromStyle(s: Field.Style) =
        when (s) {
            is Field.Style.External -> "$EXTERNAL-"
            is Field.Style.Inline -> "$INLINE-"
            is Field.Style.Choice -> "$CHOICES-"

            is Field.Style.City -> "$CITY-"
            is Field.Style.Department -> "$DEPARTMENT-"
            is Field.Style.Position -> "$POSITION-"
            is Field.Style.GovId -> "$GOVID-"
            is Field.Style.FirstName -> "$FIRST_NAME-"
            is Field.Style.LastName -> "$LAST_NAME-"
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    private fun styleFromString(s: String, value: ActivityValue) =
        when {
            s.startsWith("$EXTERNAL-") -> Field.Style.External
            s.startsWith("$INLINE-") -> Field.Style.Inline
            s.startsWith("$CHOICES-") -> Field.Style.Choice(value.listValue ?: emptyList())

            s.startsWith("$CITY-") -> Field.Style.City
            s.startsWith("$DEPARTMENT-") -> Field.Style.Department
            s.startsWith("$POSITION-") -> Field.Style.Position
            s.startsWith("$GOVID-") -> Field.Style.GovId
            s.startsWith("$FIRST_NAME-") -> Field.Style.FirstName
            s.startsWith("$LAST_NAME-") -> Field.Style.LastName
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    private fun requiredFromString(s: String) =
        when {
            s.startsWith("$CHOICES-") -> true
            else -> false
        }

    fun userFields(values: List<Pair<DesignSystem.Header, ActivityValue>>) =
        values.mapIndexed { index, (header, value) ->
            val label = value.stringValue ?: ""
            val answer = value.answerValue
            val hint = value.hintValue
            val url = value.urlValue?.let { Url(it) }
            val assetId = value.assetValue
            val style = styleFromString(s = header.id, value = value)
            val required = requiredFromString(s = header.id)
            Field(id = Identifier(), key = Key(label), style = style, order = index, page = 1, required = required, hidden = false, autofill = false, label = label, predefined = null, min = null, max = null, placeholder = null, hint = hint, answer = answer, url = url, assetId = assetId, attributes = emptyList())
        }

    fun hiddenFields(start: Int) =
        listOf(
            Field(id = Identifier(), key = Key(CITY), style = Field.Style.City, order = start, page = 1, required = false, hidden = true, autofill = true, label = "Stad", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(DEPARTMENT), style = Field.Style.Department, order = start + 1, page = 1, required = false, hidden = true, autofill = true, label = "Avdelning", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(POSITION), style = Field.Style.Position, order = start + 2, page = 1, required = false, hidden = true, autofill = true, label = "Roll", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(GOVID), style = Field.Style.GovId, order = start, page = 3, required = false, hidden = true, autofill = true, label = "Personnummer", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(FIRST_NAME), style = Field.Style.FirstName, order = start + 4, page = 1, required = false, hidden = true, autofill = true, label = "Förnamn", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(LAST_NAME), style = Field.Style.LastName, order = start + 5, page = 1, required = false, hidden = true, autofill = true, label = "Efternamn", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
        )

    fun fields(values: List<Pair<DesignSystem.Header, ActivityValue>>): List<Field> {
        val userFields = userFields(values)
        val hiddenFields = hiddenFields(userFields.size)
        return userFields + hiddenFields
    }
}