package components

import emotion.react.css
import mui.system.Stack
import mui.system.StackDirection
import mui.system.responsive
import react.FC
import react.PropsWithClassName
import react.dom.html.ReactHTML.div
import support.DesignSystem
import support.DesignSystem.PaletteType.*
import support.Palette
import web.cssom.*

external interface RPalettePreviewProps : PropsWithClassName {
    var design: DesignSystem.PalettePreview?
}

val RPalettePreview = FC<RPalettePreviewProps> { props ->
    when (props.design?.palette) {
        PRIMARY, null -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Primary.light; text = "Primary light" }
                RColorBox { color = Palette.Primary.main; text = "Primary main" }
                RColorBox { color = Palette.Primary.dark; text = "Primary dark" }
            }
        }
        SECONDARY -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Secondary.light; text = "Secondary light" }
                RColorBox { color = Palette.Secondary.main; text = "Secondary main" }
                RColorBox { color = Palette.Secondary.dark; text = "Secondary dark" }
            }
        }
        GREY -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Grey.light; text = "Grey light" }
                RColorBox { color = Palette.Grey.main; text = "Grey main" }
                RColorBox { color = Palette.Grey.dark; text = "Grey dark" }
            }
        }
        WARNING -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Warning.light; text = "Warning light" }
                RColorBox { color = Palette.Warning.main; text = "Warning main" }
                RColorBox { color = Palette.Warning.dark; text = "Warning dark" }
            }
        }
        DANGER -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Danger.light; text = "Danger light" }
                RColorBox { color = Palette.Danger.main; text = "Danger main" }
                RColorBox { color = Palette.Danger.dark; text = "Danger dark" }
            }
        }
        SUCCESS -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Success.light; text = "Success light" }
                RColorBox { color = Palette.Success.main; text = "Success main" }
                RColorBox { color = Palette.Success.dark; text = "Success dark" }
            }
        }
        INFO -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.Info.light; text = "Info light" }
                RColorBox { color = Palette.Info.main; text = "Info main" }
                RColorBox { color = Palette.Info.dark; text = "Info dark" }
            }
        }
        TRANSPARENT -> {
            Stack {
                spacing = responsive(1)
                RColorBox { color = Palette.transparent; text = "Transparent" }
            }
        }
    }
}

external interface RColorBoxProps : PropsWithClassName {
    var color: String
    var text: String
}

val RColorBox = FC<RColorBoxProps> { props ->
    Stack {
        spacing = responsive(1)
        direction = responsive(StackDirection.row)

        div {
            css {
                width = 100.px
                height = 100.px
                backgroundColor = Color(props.color)
            }
        }

        RText {
            this.design = DesignSystem.Text(text = "${props.text}, ${props.color}")
        }
    }
}