package components

import ROUTES
import emotion.react.css
import mui.material.*
import mui.material.styles.TypographyVariant
import mui.system.responsive
import mui.system.sx
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import react.router.useNavigate
import support.DesignSystem
import web.cssom.*

val RGetPro = FC<Props> { props ->
    val navigate = useNavigate()

    div {
        css {
            overflowX = Overflow.hidden
        }
        img {
            src = "img/org_shape_xl.svg"
            css {
                zIndex = (-1).unsafeCast<ZIndex>()
                position = Position.absolute
                bottom = 0.px
                right = 0.px
            }
        }

        Container {
            maxWidth = "sm"

            Stack {
                sx {
                    minHeight = 50.vh
                    width = 100.pct
                    alignItems = AlignItems.center
                    justifyContent = JustifyContent.center
                }

                div {
                    Typography {
                        variant = TypographyVariant.h4
                        align = TypographyAlign.center
                        +"Tjena kompis,"
                    }
                    Typography {
                        variant = TypographyVariant.h4
                        align = TypographyAlign.center
                        +"nyfiken på Pro \uD83D\uDC51?"
                    }

                    Stack {
                        direction = responsive(StackDirection.row)
                        sx {
                            alignItems = AlignItems.center
                            justifyContent = JustifyContent.center
                            marginTop = 1.rem
                        }
                        Typography {
                            variant = TypographyVariant.body1

                            +"Aktivera Pro under "
                        }

                        RButton {
                            design = DesignSystem.Button(text = "Abonnemang", style = DesignSystem.PaletteType.TRANSPARENT)
                            onClick = { navigate(ROUTES.DASHBOARD.ACCOUNT) }
                        }

                        Typography {
                            variant = TypographyVariant.body1
                            align = TypographyAlign.center
                            +" så"
                        }
                    }

                    Typography {
                        variant = TypographyVariant.body1
                        align = TypographyAlign.center
                        +"får du tillgång till följande i Moodi:"
                    }
                }

                Stack {
                    direction = responsive(StackDirection.row)
                    sx {
                        marginTop = 2.rem
                    }

                    Stack {
                        sx {
                            height = 100.pct
                            justifyContent = JustifyContent.start
                        }

                        span {
                            css { fontSize = 40.px; marginTop = 2.rem }
                            +"✨"
                        }
                        span {
                            css { fontSize = 28.px }
                            +"✨"
                        }
                    }

                    Card {
                        sx {
                            margin = Margin(0.rem, 1.5.rem)
                            boxShadow = important(BoxShadow(0.rem, 0.5.rem, 1.rem, rgb(0,0,0,0.15)))
                            borderRadius = important(6.px)
                        }
                        Stack {
                            spacing = responsive(2)
                            sx {
                                paddingBottom = 1.rem
                            }

                            Stack {
                                spacing = responsive(1)
                                sx {
                                    alignItems = AlignItems.center
                                    justifyContent = JustifyContent.center
                                    background = "linear-gradient(135deg, #9C09DB 0%, #1C0FD7 100%)".unsafeCast<Background>();
                                    padding = Padding(2.rem, 3.rem)
                                }

                                RText {
                                    design = DesignSystem.Text(
                                        text = "Pro \uD83D\uDC51",
                                        size = DesignSystem.SizeType.XL,
                                        style = DesignSystem.StyleType.BOLD,
                                        align = DesignSystem.TextAlign.CENTER,
                                        background = DesignSystem.Background.DARK,
                                    )
                                }

                                RText {
                                    design = DesignSystem.Text(
                                        text = "39 kr Anställd / månad",
                                        size = DesignSystem.SizeType.SM,
                                        align = DesignSystem.TextAlign.CENTER,
                                        background = DesignSystem.Background.DARK,
                                    )
                                }
                            }

                            Stack {
                                sx {
                                    alignItems = AlignItems.center
                                    justifyContent = JustifyContent.center
                                }

                                ul {
                                    css {
                                        display = Display.flex
                                        flexDirection = FlexDirection.column
                                        alignItems = AlignItems.center
                                        justifyContent = JustifyContent.center
                                        listStyle = None.none
                                        marginLeft = 0.px
                                        paddingLeft = 0.px
                                        lineHeight = 28.px
                                    }

                                    li  { +"Moodi app med din logga" }
                                    li  { +"Kommentera" }
                                    li  { +"Adminverktyg" }
                                    li  { +"Flera interaktiva flöden" }
                                    li  { +"Egna undersökningar" }
                                    li  { +"Genvägar" }
                                    li  { +"En bättre företagskultur" }
                                    li  { +"...och andra mooduler" }
                                }
                            }
                        }
                    }

                    Stack {
                        sx {
                            height = 100.pct
                            justifyContent = JustifyContent.end
                        }
                        span {
                            css { fontSize = 46.px; marginBottom = 2.rem }
                            +"✨"
                        }
                    }
                }

            }
        }
    }
}
