package support

import io.ktor.http.*
import kotlinx.datetime.DateTimeUnit
import kotlinx.datetime.minus
import techla.ai.Prompt
import techla.base.*
import techla.billing.Buyer
import techla.content.ShortLink
import techla.content.Toggle
import techla.control.Event
import techla.conversation.*
import techla.form.Field
import techla.form.Form
import techla.form.Submission
import techla.guard.*
import techla.loyalty.Member
import techla.loyalty.Pin
import techla.notification.Notification
import techla.notification.Registration
import techla.personal.Bio
import techla.personal.Narrative
import techla.personal.Organization
import techla.personal.Trait
import techla.storage.Asset

fun <T> List<T>.safeSubList(fromIndex: Int, toIndex: Int): List<T> {
    val from = fromIndex.coerceAtLeast(0).coerceAtMost(this.size)
    val to = toIndex.coerceAtMost(this.size)
    return subList(from, to)
}

fun <T> List<T>.shuffle(): List<T> {
    val arr = this.toTypedArray()
    arr.shuffle()
    return arr.toList()
}

object Demo {
    const val CREW = "demo"
    const val WORKSPACE_STANDARD = "moodi-$CREW"
    const val WORKSPACE_PRO = "moodi-$CREW-pro"
    const val GOVID_ANDERS_NILSSON = "201701012393"

    const val UUID_ANDERS_NILSSON = "48D06410-1C9A-43A9-858E-F18C9DCBABE5"
    const val UUID_CAMILLA_HJERTS = "1DB780B0-0D05-4110-BC89-04790C86FECC"
    const val UUID_LAURA_SIEMENS = "73B3175E-C939-4D28-B80E-2372D707EE84"
    const val UUID_LISA_JANSSON = "38B04B5F-9CFD-427F-ACBF-8DB5B0F88D8D"
    const val UUID_ALI_AMER = "4729F604-8951-4559-BD64-63BD184CBE15"
    const val UUID_BOSSE_BULLDOG = "929DC513-04DF-442E-AFD7-BF709AE9D815"
    const val UUID_JHONNY_KARLSSON = "768D1E11-B578-40F3-81DD-D8B04DBC9F56"

    const val DEMO_USER_AUTHENTICATION_ID = "33ED4478-5420-469C-AD68-2EB269A17DC7"

    val demoApplicationAuthentication
        get() = successfulOf(
            ApplicationAuthentication(
                index = Index(UUID_ANDERS_NILSSON),
                application = null,
                claims = "",
                device = Device.Unknown,
                createdAt = Date.now(),
                tokens = emptyList(),
            )
        )

    val demoOutstandingUserAuthentication
        get() = successfulOf(
            UserAuthentication(
                index = Index(DEMO_USER_AUTHENTICATION_ID),
                provider = UserAuthentication.Provider.None,
                display = "Demo Authentication",
                status = UserAuthentication.Status.Outstanding,
                reason = null,
                profileId = null,
                device = Device.Unknown,
                createdAt = Date.now(),
                govId = GOVID_ANDERS_NILSSON,
                tokens = emptyList(),
                authenticationUrl = null,

                claims = "",
                autoStartToken = "auto-start-token",
                token = null,
                tokenExpiresAt = Date.distantFuture,
                externalToken = null,
            )
        )

    val demoCompletedUserAuthentication: Outcome<UserAuthentication>
        get() = successfulOf(
            UserAuthentication(
                index = Index(DEMO_USER_AUTHENTICATION_ID),
                provider = UserAuthentication.Provider.None,
                display = "Demo Authentication",
                status = UserAuthentication.Status.Complete,
                reason = null,
                profileId = Identifier(UUID_ANDERS_NILSSON),
                device = Device.Unknown,
                createdAt = Date.now(),
                govId = GOVID_ANDERS_NILSSON,
                tokens = listOf(
                    Token.User("demo", Date.distantFuture, Group.STANDARD),
                ),
                authenticationUrl = null,

                claims = "",
                autoStartToken = "",
                token = "demo",
                tokenExpiresAt = Date.distantFuture,
                externalToken = null,
            )
        )

    val currentMe: Outcome<Me>
        get() = currentProfile.map {
            Me(
                profileId = it.index.id,
                govId = it.govId,
                firstName = it.firstName,
                lastName = it.lastName,
                email = it.email,
                phone = it.phone,
                access = it.access,
                workspaces = it.workspaces,
            )
        }

    fun allProfilesPage(index: PageIndex): Outcome<PageContent<Profile>> =
        allProfiles.map { PageContent.paginate(index, it) }

    fun allUserAuthenticationsPage(index: PageIndex): Outcome<PageContent<UserAuthentication>> =
        allUserAuthentications.map { PageContent.paginate(index, it) }

    fun matchProfiles(id: Identifier<Profile>): Outcome<List<Profile>> = allProfiles.flatMap { all ->
        val match = all.filter { it.index.id == id }
        successfulOf(match)
    }

    fun matchProfile(id: Identifier<Profile>): Outcome<Profile> = allProfiles.flatMap { all ->
        val match = all.firstOrNull { it.index.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    val currentProfile: Outcome<Profile>
        get() = allProfiles.map { it.first() }

    val modifyProfiles: Outcome<Profile>
        get() = invalidOf(
            listOf(
                Warning("Demo user can't create/edit profiles", message = "Demo user can't create/edit profiles")
            )
        )
    val allNarratives: Outcome<List<Narrative>>
        get() = allProfiles.map { all ->
            all.map { it.toNarrative() }
        }

    fun allNarrativesPage(index: PageIndex): Outcome<PageContent<Narrative>> =
        allNarratives.map { PageContent.paginate(index, it) }

    fun matchBio(id: Identifier<Profile>): Outcome<Bio?> = matchProfile(id).map { it.toBio() }

    val currentNarrative: Outcome<Narrative>
        get() = currentProfile.map { it.toNarrative() }

    val currentOrganization: Outcome<Organization?>
        get() = successfulOf(
            Organization(
                cities = listOf("Helsingborg", "Malmö", "Landskrona", "Ängelholm"),
                departments = listOf("Kök", "Kafé"),
                positions = emptyList(),
            )
        )

    fun matchNarrativeByProfile(id: Identifier<Profile>): Outcome<Narrative?> =
        matchProfile(id).map { it.toNarrative() }

    fun matchNarrative(id: Identifier<Narrative>): Outcome<Narrative> = allNarratives.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun matchTraitsByProfile(id: Identifier<Profile>): Outcome<List<Trait>> =
        matchProfiles(id).map { all ->
            all.mapNotNull { it.toTrait() }
        }

    val allMembers: Outcome<List<Member>>
        get() = allProfiles.map { all ->
            all.map { it.toMember() }
        }

    val currentMember: Outcome<Member>
        get() = currentProfile.map { it.toMember() }

    fun matchMemberByProfile(id: Identifier<Profile>): Outcome<Member?> = matchProfile(id).map { it.toMember() }

    fun matchMember(id: Identifier<Member>): Outcome<Member> = allMembers.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    val allPins: Outcome<List<Pin>>
        get() = successfulOf(
            listOf(
                Pin(
                    id = Identifier("DE16D79C-C9BB-45D4-A5F4-8F62AAF5D815"),
                    key = Key("Test"),
                    status = Pin.Status.Active,
                    title = "Test",
                    category = Pin.Category.Boost,
                    theme = "Moodi Standard",
                ),
                Pin(
                    id = Identifier("BEC133E3-5E20-4CE0-BD92-3AE0056C8932"),
                    key = Key("Test2"),
                    status = Pin.Status.Inactive,
                    title = "Test 2",
                    category = Pin.Category.Boost,
                    theme = "Frakta snabbt",
                ),
            )
        )

    fun matchPin(id: Identifier<Pin>): Outcome<Pin> = allPins.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun createPin(create: Pin.Create): Outcome<Pin> = allPins.flatMap { all ->
        successfulOf(all.first())
    }

    val allProfiles: Outcome<List<Profile>>
        get() = successfulOf(rawProfiles)

    val allUserAuthentications: Outcome<List<UserAuthentication>>
        get() = successfulOf(rawUserAuthentications)

    fun currentBuyer(workspace: Key<Workspace>): Outcome<Buyer> = successfulOf(
        Buyer(
            id = Identifier("67872FEB-8189-47BB-9869-54BA1DEF1CB7"),
            identity = Buyer.Identity.Company("1234567890", "Fraktasnabbt (Demo)", null),
            address = Address.Swedish(street = "Fraktavägen 3", postalCode = 12345, city = "Helsingborg"),
            logo = Buyer.Logo.Url(href = Url("https://noboto.io/dev/fraktasnabbt_icon_512x512.png")),
            tier = if (workspace.rawValue == WORKSPACE_STANDARD) Buyer.Tier.Standard else Buyer.Tier.Pro,
        )
    )

    fun allBuyers(workspace: Key<Workspace>): Outcome<List<Buyer>> = currentBuyer(workspace).map { listOf(it) }

    fun currentPrompt(): Outcome<Prompt> = successfulOf(
        Prompt(
            id = Identifier("5D70F462-19A6-44FB-B1D5-C2B45B7176FD"),
            key = Key("DEMO"),
            name = "Demo",
            order = 1,
            input = Prompt.Input.Unknown,
            modelMatches = emptyList(),
        )
    )

    fun allPrompts(): Outcome<List<Prompt>> = currentPrompt().map { listOf(it) }

    val allShortLinks: Outcome<List<ShortLink>>
        get() = successfulOf(
            listOf(
                ShortLink(
                    id = Identifier("408B6641-A354-47F2-AA95-201D26915658"),
                    name = "Moodi Rocks 🤘",
                    code = "XYZ",
                    access = ShortLink.Access.Shortener(Url("https://moodi.rocks")),
                    isActive = true,
                    expires = null,
                    short = Url("https://moodi.rocks"),
                    createdAt = Date.now(),
                    editedAt = Date.now(),
                )
            )
        )

    val currentShortLink: Outcome<ShortLink> = allShortLinks.map { it.first() }

    fun allShortLinksPage(index: PageIndex): Outcome<PageContent<ShortLink>> =
        allShortLinks.map { PageContent.paginate(index, it) }

    fun matchShortLink(id: Identifier<ShortLink>): Outcome<ShortLink> = allShortLinks.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    val allToggles: Outcome<List<Toggle>>
        get() = successfulOf(
            listOf(
                Toggle(
                    id = Identifier("039FBCAE-F497-453B-AF66-2A498193A97B"),
                    key = Directory.key,
                    status = Toggle.Status.Active,
                    name = "Rekrytering",
                    features = listOf(
                        Toggle.Feature.BooleanValue(true, Key("SHOW_EMAIL"))
                    )
                ),
                Toggle(
                    id = Identifier("BF5E1810-CDEF-41CC-A364-FA4A8D48FCD0"),
                    key = Boost.key,
                    status = Toggle.Status.Active,
                    name = "Boost",
                    features = emptyList()
                ),
            )
        )

    val currentToggle: Outcome<Toggle> = allToggles.map { it.first() }

    fun allTogglesPage(index: PageIndex): Outcome<PageContent<Toggle>> =
        allToggles.map { PageContent.paginate(index, it) }

    fun matchToggle(id: Identifier<Toggle>): Outcome<Toggle> = allToggles.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun allFeeds(keys: List<Key<Feed>>?): Outcome<List<Feed>> = successfulOf(
        listOf(
            Feed(
                id = Identifier("9D68EC5F-528A-4BF5-AD64-F013EE4FD49D"),
                key = Key("#alla"),
                name = "alla",
                style = Feed.Style.Standard,
                visibility = Feed.Visibility.Private,
                status = Feed.Status.Active,
                links = emptyList(),
            ),
            Feed(
                id = Identifier("4AEA8A88-BC95-4CD4-9C92-864612FAC922"),
                key = Key("#bryggan"),
                name = "bryggan",
                style = Feed.Style.Normal,
                visibility = Feed.Visibility.Private,
                status = Feed.Status.Active,
                links = emptyList(),
            ),
            Feed(
                id = Identifier("4AEA8A88-BC95-4CD4-9C92-864612FAC922"),
                key = Key("#maskinrummet"),
                name = "maskinrummet",
                style = Feed.Style.Normal,
                visibility = Feed.Visibility.Private,
                status = Feed.Status.Active,
                links = emptyList(),
            ),
        )
            .filter { keys?.contains(it.key) ?: true }
    )

    val currentFeed: Outcome<Feed>
        get() = allFeeds(null).map { it.first() }

    fun allFeedsPage(index: PageIndex): Outcome<PageContent<Feed>> =
        allFeeds(null).map { PageContent.paginate(index, it) }

    fun matchFeed(id: Identifier<Feed>): Outcome<Feed> =
        allFeeds(null).flatMap { all ->
            val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
            successfulOf(match)
        }

    val allMessages: Outcome<List<Message>>
        get() {
            val hrefs1 = listOf("https://noboto.io/dev/fraktasnabbt_post1.jpg")
            val comments1 = listOf(
                UUID_ANDERS_NILSSON to "Woho, jag älskar semlor!",
                UUID_CAMILLA_HJERTS to "Tack. Jag hoppar över denna gången, mitt kostschema säger nej.",
                UUID_LISA_JANSSON to "Mitt kostschema säger JA, jag tar två :) \uD83D\uDC83 \uD83D\uDD7A"
            )
            val hrefs2 =
                listOf("https://noboto.io/dev/fraktasnabbt_post2.jpg", "https://noboto.io/dev/fraktasnabbt_post1.jpg")
            val comments2 = listOf(
                UUID_LAURA_SIEMENS to "Du är gryyym Ali!",
                UUID_ALI_AMER to "Tack \uD83D\uDE09",
                UUID_JHONNY_KARLSSON to "Go, go, go!"
            )
            val hrefs3 = listOf(
                "https://noboto.io/dev/fraktasnabbt_post3.jpg",
                "https://noboto.io/dev/fraktasnabbt_post2.jpg",
                "https://noboto.io/dev/fraktasnabbt_post1.jpg"
            )
            val comments3 = listOf(
                UUID_JHONNY_KARLSSON to "Vad är tennis?",
                UUID_CAMILLA_HJERTS to "Tönt Jhonny.. alla vet väl vad Tennis är?",
                UUID_LISA_JANSSON to "I'm set! \uD83E\uDD73"
            )
            val hrefs4 = listOf(
                "https://noboto.io/dev/fraktasnabbt_post4.jpg",
                "https://noboto.io/dev/fraktasnabbt_post3.jpg",
                "https://noboto.io/dev/fraktasnabbt_post2.jpg",
                "https://noboto.io/dev/fraktasnabbt_post1.jpg"
            )
            val comments4 = listOf(
                UUID_LAURA_SIEMENS to "Ping @ali du hade väl en polare som var intresserad?",
                UUID_ALI_AMER to "Jag hör med henne! \uD83D\uDC83",
                UUID_CAMILLA_HJERTS to "Har en snubbe jag med Anders!"
            )
            val hrefs5 = listOf(
                "https://noboto.io/dev/fraktasnabbt_post5.jpg",
                "https://noboto.io/dev/fraktasnabbt_post4.jpg",
                "https://noboto.io/dev/fraktasnabbt_post3.jpg",
                "https://noboto.io/dev/fraktasnabbt_post2.jpg",
                "https://noboto.io/dev/fraktasnabbt_post1.jpg"
            )
            val comments5 = listOf(UUID_ANDERS_NILSSON to "Snart helg! \uD83D\uDC4F")
            val hrefs6 = listOf(
                "https://noboto.io/dev/fraktasnabbt_post6.jpg",
                "https://noboto.io/dev/fraktasnabbt_post5.jpg",
                "https://noboto.io/dev/fraktasnabbt_post4.jpg",
                "https://noboto.io/dev/fraktasnabbt_post3.jpg",
                "https://noboto.io/dev/fraktasnabbt_post2.jpg",
                "https://noboto.io/dev/fraktasnabbt_post1.jpg"
            )
            val comments6 = listOf(
                UUID_LAURA_SIEMENS to "Shit vad bra!",
                UUID_ALI_AMER to "Heja Heja!!",
                UUID_JHONNY_KARLSSON to "\uD83D\uDC4F\uD83D\uDC4F\uD83D\uDC4F",
                UUID_CAMILLA_HJERTS to "Låter inte fel med lite skumpa \uD83D\uDE09",
                UUID_LISA_JANSSON to "Bra jobbat alla \uD83D\uDC83 \uD83D\uDD7A",
                UUID_BOSSE_BULLDOG to "Voff Voff \uD83E\uDD73"
            )
            return successfulOf(
                listOf(
                    blog(
                        "E33D7208-5DAD-4F2B-BCD7-959CD6489F0B",
                        UUID_LAURA_SIEMENS,
                        "Idag kör vi fika! \uD83E\uDD50",
                        "Idag firar vi vår framgång. Hjärtligt välkommen in till HK kl 14 \uD83E\uDD73",
                        hrefs = hrefs1,
                        comments = comments1
                    ),
                    blog(
                        "9EB96943-366B-492C-A465-5ED93BF1360F",
                        UUID_ALI_AMER,
                        "Leverans till nöjd kund \uD83D\uDE0E",
                        "Idag var det leverans i Allingsås!",
                        hrefs = hrefs2,
                        comments = comments2
                    ),
                    blog(
                        "F4146B4D-A368-4693-B0BD-52C614DB5472",
                        UUID_LAURA_SIEMENS,
                        "Tennis med familjen",
                        "I sommar bjuder vi dig och din familj på Tennis \uD83C\uDFBE\uD83C\uDFBE\uD83C\uDFBE kontakta mig för mer info",
                        hrefs = hrefs3,
                        comments = comments3
                    ),
                    blog(
                        "90AEE069-7612-4994-AAFD-8E47CE0BBB69",
                        UUID_ANDERS_NILSSON,
                        "Vi växer",
                        "Vi växer och söker just nu efter 10 st kollegor, alla tips på rekryteringar belönas",
                        hrefs = hrefs4,
                        comments = comments4
                    ),
                    blog(
                        "D731AF42-A702-4355-A897-BAA03A469CEA",
                        UUID_JHONNY_KARLSSON,
                        "Många leveranser idag :)",
                        "Härligt väder och packad lastbil...",
                        hrefs = hrefs5,
                        comments = comments5
                    ),
                    blog(
                        "470D27BD-30DA-4E93-B18B-6500253CA9D7",
                        UUID_ANDERS_NILSSON,
                        "All time high",
                        "I Juni slog vi All Time High - Bra jobbat Alla!!",
                        hrefs = hrefs6,
                        comments = comments6
                    ),
                    blog(
                        "D0F60E15-B763-4A01-8496-3CED96CCF8A6",
                        UUID_LISA_JANSSON,
                        "Det nalkas semester",
                        "Dags att fylla i semester",
                        listOf("https://noboto.io/dev/fraktasnabbt_post7.jpg"),
                        comments = emptyList()
                    ),
                    blog(
                        "2C585A9E-9AC2-499C-A550-224E2A656F0D",
                        UUID_CAMILLA_HJERTS,
                        "Bowling på fredag?",
                        "Vi är ett gäng som tänkte bowla på fredag, sugen på att följa med? Anmälan till mig \uD83D\uDC83",
                        listOf("https://noboto.io/dev/fraktasnabbt_post8.jpg"),
                        comments = listOf(
                            UUID_LAURA_SIEMENS to "Du kan räkna med mig \uD83C\uDF78\uD83D\uDC8B",
                            UUID_JHONNY_KARLSSON to "Striiiikee \uD83C\uDFC6"
                        )
                    ),
                    blog(
                        "42406667-8FA5-4AC6-9829-28B18A6FF2B2",
                        UUID_BOSSE_BULLDOG,
                        "Dagens Bosse",
                        "Morgonstund har guld i mund",
                        listOf("https://noboto.io/dev/fraktasnabbt_post9.jpg"),
                        comments = listOf(
                            UUID_ANDERS_NILSSON to "Inte hänga läpp väl \uD83E\uDD41",
                            UUID_CAMILLA_HJERTS to "Bosse, du gör inte många knop du \uD83D\uDE09"
                        )
                    ),
                )
            )
        }

    fun allMessagesPage(index: PageIndex): Outcome<PageContent<Message>> =
        allMessages.map { PageContent.paginate(index, it) }


    private var rawAssets = listOf(
        Asset(
            id = Identifier("2AB6A74E-5E36-403B-9880-D4E43EA47F1B"),
            group = Group.STANDARD,
            name = "Förslagslådan",
            contentType = "image/jpg",
            status = Asset.Status.Ready,
            publicUrl = null,
            limitedUrl = null,
            thumbnailUrl = null,
            uploadUrl = null,
            playbackId = null,
            catalogId = null,
            originalAssetId = null,
            transforms = null,
            metadata = null
        ),
    )

    val allAssets: Outcome<List<Asset>>
        get() = successfulOf(rawAssets)

    fun matchAsset(id: Identifier<Asset>): Outcome<Asset> = allAssets.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun createAsset(create: Asset.Create): Outcome<Asset> = Asset(
        id = Identifier("D1FC91FB-BF12-4D74-BB92-DAAE15898B96"),
        group = Group.STANDARD,
        name = create.name,
        contentType = "image/jpg",
        status = Asset.Status.Processing,
        publicUrl = null,
        limitedUrl = null,
        thumbnailUrl = null,
        uploadUrl = null,
        playbackId = null,
        catalogId = null,
        originalAssetId = null,
        transforms = null,
        metadata = null
    ).run {
        rawAssets = rawAssets + this
        successfulOf(this)
    }

    fun personal(startIndex: Int, endIndex: Int): Outcome<List<Message>> =
        allMessages.map { it.safeSubList(startIndex, endIndex) }

    fun matchMessage(id: Identifier<Message>): Outcome<Message> = allMessages.flatMap { all ->
        val m = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(m)
    }
    /*
        val allReactions: Outcome<List<Reaction>>
            get() = successfulOf(
                comments.map {
            Reaction(
                id = Identifier(it.first),
                messageId = Identifier(id),
                kind = Reaction.Kind.Comment(it.second),
                reactor = Identifier(it.first),
                timestamp = Date.now()
            )
        }*/

    val allCrosses: Outcome<List<Cross>>
        get() = allProfiles
            .zip(allFeeds(null))
            .map { (profiles, feeds) ->
                profiles.map {
                    Cross(
                        id = Identifier(it.index.id.rawValue),
                        profileId = it.index.id,
                        role = Cross.Role.Contributor,
                        privacy = Cross.Privacy.None,
                        feeds = feeds
                    )
                }
            }

    val currentCross: Outcome<Cross>
        get() = allCrosses.map { it.first() }

    fun matchCross(id: Identifier<Cross>): Outcome<Cross> =
        allCrosses.flatMap { all ->
            val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
            successfulOf(match)
        }

    fun matchCrosses(id: Identifier<Profile> = Identifier(UUID_ANDERS_NILSSON)): Outcome<List<Cross>> =
        allCrosses.map { mixes -> mixes.filter { it.profileId == id } }

    val feedUpdate: Outcome<Feed.Update>
        get() = successfulOf(Feed.Update(1, emptyList()))

    private val allMixes: Outcome<List<Mix>>
        get() = allProfiles
            .zip(allFeeds(null))
            .map { (profiles, feeds) ->
                profiles.map {
                    Mix(
                        id = Identifier(it.index.id.rawValue),
                        destination = Mix.Destination.TargetProfile(it.index.id),
                        feeds = feeds
                    )
                }
            }

    val currentMix: Outcome<Mix>
        get() = allMixes.map { it.first() }

    fun matchMix(id: Identifier<Mix>): Outcome<Mix> = allMixes.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun matchMixes(id: Identifier<Profile>): Outcome<List<Mix>> =
        allMixes.map { mixes -> mixes.filter { it.destination.profileId == id } }

    private var rawEmitters = emptyList<Emitter>()

    val allEmitters: Outcome<List<Emitter>>
        get() = successfulOf(rawEmitters)

    fun createEmitter(create: Emitter.Create): Outcome<Emitter> {
        rawEmitters = rawEmitters + Emitter(
            id = Identifier("7C6C8D59-32F6-4C9E-A6D4-AB5287D07544"),
            key = create.key,
            schedule = create.schedule,
            feed = create.feed,
            kind = create.kind,
            status = Message.Status.Published,
            display = Message.Display.Pinned,
        )
        return allEmitters.map { it.first() }
    }

    fun deleteEmitter(id: Identifier<Emitter>) {
        rawEmitters = rawEmitters.filter { it.id != id }
    }

    fun exampleStatistics(intervals: List<Event.Interval>): Outcome<List<Event.Statistic>> {
        val today = Date.now().dateTime
        val currentYear = today.year
        val currentMonthNumber = today.monthNumber
        val previousMonthNumber = today.date.minus(1, DateTimeUnit.MONTH).monthNumber
        val statistics = mutableListOf<Event.Statistic>()

        val monthlyIntervals = intervals.filterIsInstance<Event.Interval.Monthly>()

        val totalUsers = 487
        val totalUserAuthentications = 5031
        val totalMessages = 1121
        val totalBoosts = 79

        val monthlyUsers = listOf(0, 439, 443, 406, 450, 451, 456, 458, 461, 468, 471, 519, 487)
        val monthlyUserAuthentications = listOf(0, 403, 410, 414, 421, 424, 399, 330, 405, 421, 423, 436, 362)
        val monthlyMessages = listOf(0, 31, 33, 36, 41, 39, 44, 29, 33, 39, 42, 43, 31)
        val monthlyBoosts = listOf(0, 14, 16, 12, 9, 10, 13, 4, 5, 12, 13, 16, 8)
        val monthlyEmployeeNPS = listOf(0, 47, 52, 62, 70, 71, 71, 68, 70, 46, 77, 82, 80)
        val monthlyManagerNPS = listOf(0, 48, 58, 73, 70, 71, 69, 68, 49, 46, 77, 82, 80)

        val userAuthenticationsCurrentMonth = 436
        val userAuthenticationsPreviousMonth = 423
        val messagesCurrentMonth = 38
        val messagesPreviousMonth = 34
        val likesCurrentMonth = 196
        val likesPreviousMonth = 173
        val commentsCurrentMonth = 19
        val commentsPreviousMonth = 24
        val boostsCreatedCurrentMonth = 17
        val boostsCreatedPreviousMonth = 14
        val boostsReceivedCurrentMonth = 11
        val boostsReceivedPreviousMonth = 9

        /* Total */
        statistics.addAll(
            listOf(
                Event.Statistic(count = totalUsers, action = Event.Action.Created, category = Event.Category.UserAuthentication, interval = Event.Interval.Total, uniqueOnly = true, subcategory = null, profileId = null, secondary = null),
                Event.Statistic(count = totalUserAuthentications, action = Event.Action.Created, category = Event.Category.UserAuthentication, interval = Event.Interval.Total, uniqueOnly = false, subcategory = null, profileId = null, secondary = null),
                Event.Statistic(count = totalMessages, action = Event.Action.Created, category = Event.Category.Message, interval = Event.Interval.Total, uniqueOnly = false, subcategory = null, profileId = null, secondary = null),
                Event.Statistic(count = totalBoosts, action = Event.Action.Created, category = Event.Category.Boost, interval = Event.Interval.Total, uniqueOnly = false, subcategory = null, profileId = null, secondary = null),
            )
        )

        /* Monthly */
        statistics.addAll(elements =
        monthlyIntervals.map { interval ->
            val count = monthlyUsers[interval.monthNumber]
            Event.Statistic(count = count, action = Event.Action.Created, category = Event.Category.UserAuthentication, interval = interval, uniqueOnly = true, subcategory = null, profileId = null, secondary = null)
        } +
                monthlyIntervals.map { interval ->
                    val count = monthlyUserAuthentications[interval.monthNumber]
                    Event.Statistic(count = count, action = Event.Action.Created, category = Event.Category.UserAuthentication, interval = interval, uniqueOnly = false, subcategory = null, profileId = null, secondary = null)
                } +
                monthlyIntervals.map { interval ->
                    val count = monthlyMessages[interval.monthNumber]
                    Event.Statistic(count = count, action = Event.Action.Created, category = Event.Category.Message, interval = interval, uniqueOnly = false, subcategory = null, profileId = null, secondary = null)
                } +
                monthlyIntervals.map { interval ->
                    val count = monthlyBoosts[interval.monthNumber]
                    Event.Statistic(count = count, action = Event.Action.Created, category = Event.Category.Boost, interval = interval, uniqueOnly = false, subcategory = null, profileId = null, secondary = null)
                } +
                monthlyIntervals.map { interval ->
                    val count = monthlyEmployeeNPS[interval.monthNumber]
                    Event.Statistic(count = count, action = Event.Action.Created, category = Event.Category.EmployeeNPS, interval = interval, uniqueOnly = false, subcategory = null, profileId = null, secondary = null)
                } +
                monthlyIntervals.map { interval ->
                    val count = monthlyManagerNPS[interval.monthNumber]
                    Event.Statistic(count = count, action = Event.Action.Created, category = Event.Category.ManagerNPS, interval = interval, uniqueOnly = false, subcategory = null, profileId = null, secondary = null)
                }
        )

        /* Current and Previous Month */
        statistics.addAll(
            listOf(
                /* User authentications */
                //Event.Statistic(count = userAuthenticationsCurrentMonth, action = Event.Action.Created, category = Event.Category.UserAuthentication, interval = Event.Interval.Monthly(currentYear, currentMonthNumber), uniqueOnly = true, subcategory = null, profileId = null),
                //Event.Statistic(count = userAuthenticationsPreviousMonth, action = Event.Action.Created, category = Event.Category.UserAuthentication, interval = Event.Interval.Monthly(currentYear, previousMonthNumber), uniqueOnly = true, subcategory = null, profileId = null),

                /* Messages */
                Event.Statistic(count = messagesCurrentMonth, action = Event.Action.Created, category = Event.Category.Message, interval = Event.Interval.Monthly(currentYear, currentMonthNumber), uniqueOnly = true, subcategory = null, profileId = null, secondary = null),
                Event.Statistic(count = messagesPreviousMonth, action = Event.Action.Created, category = Event.Category.Message, interval = Event.Interval.Monthly(currentYear, previousMonthNumber), uniqueOnly = true, subcategory = null, profileId = null, secondary = null),

                /* Likes */
                Event.Statistic(count = likesCurrentMonth, action = Event.Action.Created, category = Event.Category.Reaction, interval = Event.Interval.Monthly(currentYear, currentMonthNumber), uniqueOnly = true, subcategory = Event.Subcategory.Like, profileId = null, secondary = null),
                Event.Statistic(count = likesPreviousMonth, action = Event.Action.Created, category = Event.Category.Reaction, interval = Event.Interval.Monthly(currentYear, previousMonthNumber), uniqueOnly = true, subcategory = Event.Subcategory.Like, profileId = null, secondary = null),

                /* Comments */
                Event.Statistic(count = commentsCurrentMonth, action = Event.Action.Created, category = Event.Category.Reaction, interval = Event.Interval.Monthly(currentYear, currentMonthNumber), uniqueOnly = true, subcategory = Event.Subcategory.Comment, profileId = null, secondary = null),
                Event.Statistic(count = commentsPreviousMonth, action = Event.Action.Created, category = Event.Category.Reaction, interval = Event.Interval.Monthly(currentYear, previousMonthNumber), uniqueOnly = true, subcategory = Event.Subcategory.Comment, profileId = null, secondary = null),

                /* Boosts created */
                Event.Statistic(count = boostsCreatedCurrentMonth, action = Event.Action.Created, category = Event.Category.Boost, interval = Event.Interval.Monthly(currentYear, currentMonthNumber), uniqueOnly = true, subcategory = null, profileId = null, secondary = null),
                Event.Statistic(count = boostsCreatedPreviousMonth, action = Event.Action.Created, category = Event.Category.Boost, interval = Event.Interval.Monthly(currentYear, previousMonthNumber), uniqueOnly = true, subcategory = null, profileId = null, secondary = null),

                /* Boosts received */
                Event.Statistic(count = boostsReceivedCurrentMonth, action = Event.Action.Received, category = Event.Category.Boost, interval = Event.Interval.Monthly(currentYear, currentMonthNumber), uniqueOnly = true, subcategory = null, profileId = null, secondary = null),
                Event.Statistic(count = boostsReceivedPreviousMonth, action = Event.Action.Received, category = Event.Category.Boost, interval = Event.Interval.Monthly(currentYear, previousMonthNumber), uniqueOnly = true, subcategory = null, profileId = null, secondary = null),
            )
        )

        return successfulOf(statistics.toList())
    }

    fun exampleTopLists(intervals: List<Event.Interval>): Outcome<List<Event.Statistic>> {
        val receivedBoosts = listOf(6, 4, 4, 2, 1)
        val createdBoosts = listOf(5, 4, 3, 3, 1, 1)
        val createdMessages = listOf(9, 8, 8, 6, 3, 3, 1)
        val createdComments = listOf(5, 5, 4, 3, 2, 2, 2)
        val createdLikes = listOf(37, 34, 23, 20, 20, 18, 11)
        val createdUserAuthentications = listOf(59, 38, 34, 27, 25, 22, 20)

        val profiles = allProfiles.outputOrNull() ?: emptyList()

        return successfulOf(intervals.flatMap { interval ->
            receivedBoosts.zip(profiles).map { (value, profile) ->
                Event.Statistic(
                    count = value,
                    action = Event.Action.Received,
                    category = Event.Category.Boost,
                    interval = interval,
                    uniqueOnly = true,
                    subcategory = null,
                    profileId = profile.index.id,
                    secondary = null
                )
            } +
                    createdBoosts.zip(profiles.shuffle()).map { (value, profile) ->
                        Event.Statistic(
                            count = value,
                            action = Event.Action.Created,
                            category = Event.Category.Boost,
                            interval = interval,
                            uniqueOnly = true,
                            subcategory = null,
                            profileId = profile.index.id,
                            secondary = null
                        )
                    } +
                    createdMessages.zip(profiles.shuffle()).map { (value, profile) ->
                        Event.Statistic(
                            count = value,
                            action = Event.Action.Created,
                            category = Event.Category.Message,
                            interval = interval,
                            uniqueOnly = true,
                            subcategory = null,
                            profileId = profile.index.id,
                            secondary = null
                        )
                    } +
                    createdComments.zip(profiles.shuffle()).map { (value, profile) ->
                        Event.Statistic(
                            count = value,
                            action = Event.Action.Created,
                            category = Event.Category.Reaction,
                            interval = interval,
                            uniqueOnly = true,
                            subcategory = Event.Subcategory.Comment,
                            profileId = profile.index.id,
                            secondary = null
                        )
                    } +
                    createdLikes.zip(profiles.shuffle()).map { (value, profile) ->
                        Event.Statistic(
                            count = value,
                            action = Event.Action.Created,
                            category = Event.Category.Reaction,
                            interval = interval,
                            uniqueOnly = true,
                            subcategory = Event.Subcategory.Like,
                            profileId = profile.index.id,
                            secondary = null
                        )
                    } +
                    createdUserAuthentications.zip(profiles.shuffle()).map { (value, profile) ->
                        Event.Statistic(
                            count = value,
                            action = Event.Action.Created,
                            category = Event.Category.UserAuthentication,
                            interval = interval,
                            uniqueOnly = true,
                            subcategory = null,
                            profileId = profile.index.id,
                            secondary = null
                        )
                    }
        })
    }

    fun currentRegistration(workspace: Key<Workspace>): Outcome<Registration> = currentProfile.map {
        Registration(
            id = Identifier(""),
            profileId = it.index.id,
            device = Device.Other,
            token = "",
            createdAt = Date.distantPast,
        )
    }

    fun createNotification(workspace: Key<Workspace>): Outcome<Notification> = successfulOf(
        Notification(
            id = Identifier(""),
            title = "",
            body = "",
            profileIds = emptyList(),
            metadata = emptyList(),
        )
    )

    private var rawForms = listOf(
        Form(
            id = Identifier("2AB6A74E-5E36-403B-9880-D4E43EA47F1B"),
            key = Key.random(length = 10),
            name = "Förslagslådan",
            info = "Här kan du komma med förslag och ideér som kan förbättra. Bästa förslagen lyfts fram i dialogen och belönas.",
            kind = Form.Kind.SuggestionBox,
            feedback = Form.Feedback.None,
            status = Form.Status.Active,
            fields = listOf(
                Field(
                    id = Identifier("8F576644-E14C-4715-9F61-70EECEC5C664"),
                    key = SUGGESTION_BOX_FIELD,
                    style = Field.Style.Long,
                    order = 1,
                    page = 1,
                    required = true,
                    hidden = false,
                    label = "Förslag",
                    answer = null,
                    autofill = false,
                    predefined = null,
                    min = null,
                    max = null,
                    placeholder = null,
                    hint = null,
                    url = null,
                    assetId = null,
                    attributes = emptyList(),
                ),
            ),
            policy = Form.Policy.Append,
            links = emptyList(),
            sources = emptyList(),
        ),
        Form(
            id = Identifier("7954144B-C6A2-448E-9E00-85728440B006"),
            key = EmployeeSurvey.FORM,
            name = "Medarbetarundersökning",
            info = "Dags igen för årets Medarbetarundersökning! Undersökningen är anonym och följs upp på avdelning, stad och roll",
            kind = Form.Kind.EmployeeSurvey,
            feedback = Form.Feedback.None,
            status = Form.Status.Active,
            fields = listOf(
                Field(
                    id = Identifier("8F576644-E14C-4715-9F61-70EECEC5C664"),
                    key = Key("Hur trivs du på jobbet?"),
                    style = Field.Style.Score(0, 10),
                    order = 1,
                    page = 1,
                    required = true,
                    hidden = false,
                    label = "Hur trivs du på jobbet?",
                    predefined = "5",
                    min = null,
                    max = null,
                    answer = null,
                    autofill = false,
                    placeholder = null,
                    hint = null,
                    url = null,
                    assetId = null,
                    attributes = emptyList(),
                ),
            ),
            policy = Form.Policy.Replace,
            links = emptyList(),
            sources = emptyList(),
        ),
        Form(
            id = Identifier("393B10AC-C5A9-4330-9841-3A50A262287D"),
            key = Key.random(length = 10),
            name = "Tipsy",
            info = "Tips om vår nästa anställda kommer premieras med...",
            kind = Form.Kind.Recruitment,
            feedback = Form.Feedback.None,
            status = Form.Status.Active,
            fields = listOf(
                Field(
                    id = Identifier("D4E0ABD4-3602-4A08-90FC-7F833512E881"),
                    key = Key("Namn på den du tipsar om"),
                    style = Field.Style.Short,
                    order = 1,
                    page = 1,
                    required = true,
                    hidden = false,
                    label = "Namn på den du tipsar om",
                    predefined = null,
                    min = null,
                    max = null,
                    answer = null,
                    autofill = false,
                    placeholder = null,
                    hint = null,
                    url = null,
                    assetId = null,
                    attributes = emptyList(),
                ),
            ),
            policy = Form.Policy.Append,
            links = emptyList(),
            sources = emptyList(),
        ),
    )

    val allForms: Outcome<List<Form>>
        get() = successfulOf(rawForms)

    val currentForm: Outcome<Form> = allForms.map { it.first() }

    fun allFormsPage(index: PageIndex): Outcome<PageContent<Form>> = allForms.map { PageContent.paginate(index, it) }

    fun matchForm(id: Identifier<Form>): Outcome<Form> = allForms.flatMap { all ->
        val match = all.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun createForm(create: Form.Create): Outcome<Form> = Form(
        id = Identifier("D1FC91FB-BF12-4D74-BB92-DAAE15898B96"),
        key = create.key,
        name = create.name,
        info = create.info,
        kind = create.kind,
        status = create.status,
        feedback = create.feedback,
        policy = create.policy,
        fields = emptyList(),
        links = emptyList(),
        sources = emptyList(),
    ).run {
        rawForms = rawForms + this
        successfulOf(this)
    }

    fun matchField(id: Identifier<Field>): Outcome<Field> = allForms.flatMap { all ->
        val match =
            all.flatMap { it.fields }.firstOrNull { it.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    fun createField(create: Field.Create): Outcome<Field> = Field(
        id = Identifier("D41309AE-1B7C-4818-80AB-47D4F6B37569"),
        key = create.key,
        style = create.style,
        order = create.order,
        page = create.page,
        required = create.required,
        hidden = create.hidden,
        label = create.label,
        predefined = create.predefined,
        min = null,
        max = null,
        placeholder = create.placeholder,
        hint = create.hint,
        answer = create.answer,
        autofill = create.autofill,
        url = create.url,
        assetId = create.assetId,
        attributes = emptyList(),
    ).run {
        rawForms = rawForms.map { if (it.id == create.formId) it.copy(fields = it.fields + this) else it }
        successfulOf(this)
    }

    val allSubmissions: Outcome<List<Submission>>
        get() = successfulOf(
            listOf(
                Submission(
                    index = Index("7043B6D9-0A08-447F-BEF0-490A8EDA67E1"),
                    group = Group.STANDARD,
                    state = Submission.State.Closed,
                    timestamp = Date.now(),
                    form = rawForms.first(),
                    entries = listOf(
                        Submission.Entry(
                            fieldKey = Key("F3"),
                            text = "Jag vill ha en glassmaskin!",
                        ),
                    ),
                    profileId = null,
                )
            )
        )

    val currentSubmission: Outcome<Submission> = allSubmissions.map { it.first() }

    fun allSubmissionsPage(index: PageIndex): Outcome<PageContent<Submission>> =
        allSubmissions.map { PageContent.paginate(index, it) }

    fun matchSubmission(id: Identifier<Submission>): Outcome<Submission> = allSubmissions.flatMap { all ->
        val match = all.firstOrNull { it.index.id == id } ?: return@flatMap failedOf(TechlaError.NotFound(""))
        successfulOf(match)
    }

    private fun Profile.toBio(): Bio = Bio(
        profileId = index.id,
        firstName = firstName,
        lastName = lastName,
        city = "Helsingborg",
        department = "",
        position = "",
        lastLoginAt = Date.distantPast,
        email = "",
        phone = "",
        birthday = null,
        birthmonth = null,
        biography = null,
        dietIncludes = emptyList(),
        dietExcludes = emptyList(),
        interests = emptyList(),
        avatar = null,
    )

    private fun Profile.toMember(): Member = Member(
        id = Identifier(index.rawValue),
        profileId = index.id,
        balance = 110,
    )

    private fun Profile.toNarrative(): Narrative = Narrative(
        id = Identifier(index.rawValue),
        profileId = index.id,
        firstName = firstName,
        lastName = lastName,
        alias = "",
        email = "",
        phone = "",
        city = "Helsingborg",
        department = "",
        position = "",
        role = Narrative.Role.Manager,
        symbol = null,
        birthday = null,
        birthmonth = null,
        biography = null,
        manager = null,
        memberSince = Date.distantPast,
        lastLoginAt = Date.distantPast,
        preferences = emptyList(),
        avatar = null,
    )

    private fun Profile.toTrait(): Trait? = Trait(
        id = Identifier(index.rawValue),
        profileId = index.id,
        key = Key("SICK"),
        category = Trait.Category.Sick,
    )

    private fun blog(
        id: String,
        author: String,
        title: String,
        text: String,
        hrefs: List<String>,
        comments: List<Pair<String, String>>
    ) = Message(
        id = Identifier(id),
        key = Key(""),
        kind = Message.Kind.Blog,
        status = Message.Status.Published,
        display = Message.Display.Normal,
        author = Identifier(author),
        timestamp = Date.now(),
        flags = Message.Flags(),
        origin = Key("#blogg"),
        links = emptyList(),
        likes = 0,
        liked = false,
        comments = comments.count(),
        commented = false,
        digest = "XX",
        attachments = listOfNotNull(
            Message.Attachment.InlineTitle(title = title, key = Key("TITLE")),
            Message.Attachment.InlineText(text = text, key = Key("TEXT")),
            hrefs.firstOrNull()?.let { Message.Attachment.ExternalLink(url = Url(it), title = null, key = Key("LINK")) },
        ) + hrefs.mapIndexed { index, s ->
            Message.Attachment.PublicImage(url = Url(s), key = Key("${index + 1}"))
        },
    )

    private fun <T> Collection<T>.repeat(times: Int = 1): List<T> {
        val list = this
        val result = mutableListOf<T>()
        for (i in 1..times) {
            result.addAll(list)
        }
        return result
    }

    private val rawProfiles: List<Profile>
        get() = listOf(
            Profile(
                index = Index(UUID_ANDERS_NILSSON),
                govId = GOVID_ANDERS_NILSSON,
                firstName = "Anders",
                lastName = "Nilsson",
                email = "anders@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
            Profile(
                index = Index(UUID_CAMILLA_HJERTS),
                govId = "20070204-2391",
                firstName = "Camilla",
                lastName = "Hjerts",
                email = "camilla@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
            Profile(
                index = Index(UUID_LAURA_SIEMENS),
                govId = "19010728-9811",
                firstName = "Laura",
                lastName = "Siemens",
                email = "laura@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
            Profile(
                index = Index(UUID_LISA_JANSSON),
                govId = "19910212-2380",
                firstName = "Lisa",
                lastName = "Jansson",
                email = "lisa@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
            Profile(
                index = Index(UUID_ALI_AMER),
                govId = "19910211-2381",
                firstName = "Ali",
                lastName = "Amer",
                email = "ali@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
            Profile(
                index = Index(UUID_BOSSE_BULLDOG),
                govId = "11111111-1111",
                firstName = "Bosse",
                lastName = "Bulldog",
                email = "bosse@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
            Profile(
                index = Index(UUID_JHONNY_KARLSSON),
                govId = "19010323-9802",
                firstName = "Jhonny",
                lastName = "Karlsson",
                email = "jhonny@frakta.snabbt",
                phone = "+46123456789",
                source = Profile.Source.Manual,
                access = emptyList(),
                workspaces = emptyList(),
                inactivatedAt = null,
                createdAt = Date.distantPast,
                editedAt = Date.distantPast,
            ),
        )


    private val rawUserAuthentications: List<UserAuthentication>
        get() = listOf(
            UserAuthentication(
                index = Index(UUID_ANDERS_NILSSON),
                provider = UserAuthentication.Provider.None,
                display = "",
                status = UserAuthentication.Status.Complete,
                reason = null,
                profileId = rawProfiles.first().index.id,
                device = Device.Other,
                createdAt = Date.now(),
                govId = rawProfiles.first().govId,
                tokens = emptyList(),
                authenticationUrl = null,
                claims = "",
            ),
        )
}
