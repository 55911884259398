package components

import mui.material.*
import react.FC
import react.Props
import react.create
import support.DesignSystem

external interface RChipProps : Props {
    var design: DesignSystem.Chip
}

val RChip = FC<RChipProps> { props ->
    if (props.design.visible) {
        Chip {
            id = "Chip-${props.design.style}"
            label = RText.create { design = props.design.message }
            color = when (props.design.style) {
                DesignSystem.PaletteType.PRIMARY -> ChipColor.primary
                DesignSystem.PaletteType.SECONDARY -> ChipColor.secondary
                DesignSystem.PaletteType.TRANSPARENT -> ChipColor.primary
                DesignSystem.PaletteType.GREY -> ChipColor.primary
                DesignSystem.PaletteType.WARNING -> ChipColor.warning
                DesignSystem.PaletteType.SUCCESS -> ChipColor.success
                DesignSystem.PaletteType.INFO -> ChipColor.info
                DesignSystem.PaletteType.DANGER -> ChipColor.error
            }
        }
    }
}