package screens

import kotlinx.datetime.DayOfWeek
import screens.items.ActivityTexts
import services.*
import support.*
import techla.base.*
import techla.conversation.Emitter
import techla.conversation.Feed
import techla.conversation.Message
import techla.form.Field
import techla.form.Form
import kotlin.time.ExperimentalTime

@ExperimentalTime
object PulseScreen {
    val PULSE_EMITTER = Key<Emitter>("PULSE")
    val PULSE_FORM = Key<Form>("PULSE")
    val PULSE_SCORE_FIELD = Key<Field>("PULSE")
    val PULSE_WEEK_FIELD = Key<Field>("WEEK")
    val PULSE_YEAR_FIELD = Key<Field>("YEAR")
    val PULSE_MESSAGE_FIELD = Key<Field>("MESSAGE")
    val PULSE_ATTACHMENT = Key<Message.Attachment>("PULSE")

    object Header {
        val info = DesignSystem.Header(id = "info")
        val day = DesignSystem.Header(id = "day")
        val hour = DesignSystem.Header(id = "hour")
        val days = DesignSystem.Header(id = "days")
        val feed = DesignSystem.Header(id = "feed")
    }

    val DayOfWeek.swedishDayOfWeek
        get() =
            when (this) {
                DayOfWeek.MONDAY -> "Måndag"
                DayOfWeek.TUESDAY -> "Tisdag"
                DayOfWeek.WEDNESDAY -> "Onsdag"
                DayOfWeek.THURSDAY -> "Torsdag"
                DayOfWeek.FRIDAY -> "Fredag"
                DayOfWeek.SATURDAY -> null
                DayOfWeek.SUNDAY -> null
            }

    val Int.swedishHour
        get() =
            when (this) {
                6 -> "06:00"
                7 -> "07:00"
                8 -> "08:00"
                9 -> "09:00"
                10 -> "10:00"
                11 -> "11:00"
                12 -> "12:00"
                13 -> "13:00"
                14 -> "14:00"
                15 -> "15:00"
                16 -> "16:00"
                17 -> "17:00"
                18 -> "18:00"
                19 -> "19:00"
                20 -> "20:00"
                21 -> "21:00"
                else -> null
            }

    val Int.swedishDays
        get() =
            when (this) {
                1 -> "1 dag"
                2 -> "2 dagar"
                3 -> "3 dagar"
                4 -> "4 dagar"
                5 -> "5 dagar"
                6 -> "6 dagar"
                else -> null
            }


    data class Texts(
        val title: String,
        val body: String,
        val back: String,
        val enable: String,
        val disable: String,
        val disabledTitle: String,
        val disabledBody: String,
        val done: String,
        val enabledTitle: String,
        val enabledBody: String,
        val description: String,
        val next: String,
        val nameLabel: String,
        val dayLabel: String,
        val hourLabel: String,
        val daysLabel: String,
        val feedLabel: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    data class State(
        val formId: Identifier<Form>? = null,
        val emitterId: Identifier<Emitter>? = null,
        val enabled: Boolean = false,
        val feeds: List<Feed> = emptyList(),
        val day: List<DesignSystem.Option> = emptyList(),
        val days: List<DesignSystem.Option> = emptyList(),
        val hour: List<DesignSystem.Option> = emptyList(),
        val info: String = "",
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {

        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State()
        )

        data class Loading(
            override var texts: Texts,
            override var state: State
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            var image: DesignSystem.ImageView,
            var title: DesignSystem.Text,
            var body: DesignSystem.Text,
            var enable: DesignSystem.Button,
            var disable: DesignSystem.Button,
        ) : ViewModel(texts, state)


        data class Create(
            override var texts: Texts,
            override var state: State,
            var title: DesignSystem.Text,
            var back: DesignSystem.Button,
            var next: DesignSystem.Button,
            var name: DesignSystem.TextInput,
            var day: DesignSystem.SelectInput,
            var days: DesignSystem.SelectInput,
            var hour: DesignSystem.SelectInput,
            var feed: DesignSystem.SelectInput,
        ) : ViewModel(texts, state)

        data class Success(
            override var texts: Texts,
            override var state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State): ViewModel =
            Ready(
                texts = texts,
                state = state,
                image = DesignSystem.ImageView(image = DesignSystem.Image.PULS),
                title = DesignSystem.Text(text = texts.title, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
                body = DesignSystem.Text(text = texts.body, align = DesignSystem.TextAlign.CENTER),
                disable = DesignSystem.Button(text = texts.disable, visible = state.enabled),
                enable = DesignSystem.Button(text = texts.enable, visible = !state.enabled),
            )

        fun create(state: State? = null, status: List<ValidationStatus> = emptyList()): ViewModel =
            (state ?: this.state).run {
                Create(
                    texts = texts,
                    state = this,
                    title = DesignSystem.Text(text = texts.description, size = DesignSystem.SizeType.XS),
                    back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.SECONDARY),
                    next = DesignSystem.Button(text = texts.next, type = DesignSystem.ButtonType.SUBMIT),
                    name = DesignSystem.TextInput(
                        header = Header.info,
                        label = texts.nameLabel,
                        value = info,
                        type = DesignSystem.TextInputType.TEXT,
                        status = status.statusOf(Header.info),
                    ),
                    day = DesignSystem.SelectInput(
                        header = Header.day,
                        label = texts.dayLabel,
                        selected = day.first(),
                        options = day,
                        status = status.statusOf(Header.day),
                    ),
                    days = DesignSystem.SelectInput(
                        header = Header.days,
                        label = texts.daysLabel,
                        selected = days.first(),
                        options = days,
                        status = status.statusOf(Header.days),
                    ),
                    hour = DesignSystem.SelectInput(
                        header = Header.hour,
                        label = texts.hourLabel,
                        selected = hour.first(),
                        options = hour,
                        status = status.statusOf(Header.hour),
                    ),
                    feed = DesignSystem.SelectInput(
                        header = Header.feed,
                        label = texts.feedLabel,
                        selected = DesignSystem.Option(title = feeds.firstOrNull()?.name ?: "", value = feeds.firstOrNull()?.id?.rawValue ?: ""),
                        options = feeds.map { DesignSystem.Option(title = it.name, value = it.id.rawValue) },
                        status = status.statusOf(Header.feed),
                    ),
                )
            }

        fun success(state: State): ViewModel =
            Success(
                texts = texts,
                state = state,
                confirmation = DesignSystem.Confirmation(
                    icon = "",
                    title = if (state.enabled) texts.enabledTitle else texts.disabledTitle,
                    body = if (state.enabled) texts.enabledBody else texts.disabledBody,
                    next = texts.done,
                )
            )

        fun getPro(): ViewModel =
            GetPro(
                texts = texts,
                state = state,
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun asLoading() = this as? Loading
        fun asReady() = this as? Ready
        fun asCreate() = this as? Create
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            title = "Puls",
            back = "Tillbaka",
            next = "Nästa",
            body = "Hur mår dina medarbetare? Kontrollera deras puls! Ställ en snabb fråga och få direkt feedback.",
            enable = "Starta pulsmätning",
            disable = "Pausa pulsmätning",

            disabledTitle = "Allt är nu klart \uD83C\uDF8A",
            disabledBody = "Puls är nu pausat",
            enabledTitle = "Allt är nu klart \uD83C\uDF8A",
            enabledBody = "Puls är nu igång",
            done = "Fortsätt",

            description = "Beskrivning av förslagslådan",
            nameLabel = "Titel på din pulsundersökning",
            dayLabel = "Dag puls ska dyka upp",
            hourLabel = "och tid",
            daysLabel = "Antal dagar synlig i flödet",
            feedLabel = "Kanal som inlägget ska postas i",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        if (!store.isPro) return sceneOf(viewModel.getPro())

        return store.listEmitters()
            .flatMap { (actions, emitters) ->
                store.listForms()
                    .accumulate(actions)
                    .map { tupleOf(it.first, emitters, it.second) }
            }
            .flatMap { (actions, emitters, forms) ->
                store.listFeeds(index = PageIndex(page = 1, size = 50), styles = listOf(Feed.Style.Standard, Feed.Style.Normal, Feed.Style.External))
                    .accumulate(actions)
                    .map { tupleOf(it.first, emitters, forms, it.second) }
            }
            .map { (actions, emitters, forms, pageContent) ->
                val form = forms.firstOrNull { it.kind is Form.Kind.Pulse }
                val emitter = emitters.firstOrNull { it.key == PULSE_EMITTER }
                val state = viewModel.state.copy(
                    formId = form?.id,
                    emitterId = emitter?.id,
                    enabled = form?.status is Form.Status.Active,
                    feeds = pageContent.contents.sortedByDescending { it.style is Feed.Style.Standard }
                )
                sceneOf(viewModel.ready(state = state), actions)
            }
            .failed { scene.failed(result = it) }
    }

    fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val state = viewModel.state.copy(
            info = "",
            day = DayOfWeek.values().mapNotNull { dayOfWeek -> dayOfWeek.swedishDayOfWeek?.let { DesignSystem.Option(title = it, value = dayOfWeek.name) } },
            days = (1..6).mapNotNull { days -> days.swedishDays?.let { DesignSystem.Option(title = it, value = days.toString()) } },
            hour = (6..21).mapNotNull { hour -> hour.swedishHour?.let { DesignSystem.Option(title = it, value = hour.toString()) } },
        )
        return sceneOf(viewModel.create(state = state))
    }

    suspend fun enable(scene: Scene.Input<ViewModel>, info: String, weekday: DesignSystem.Option?, hour: DesignSystem.Option?, days: DesignSystem.Option?, feed: DesignSystem.Option?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        val state = viewModel.state.copy(
            info = info
        )

        val selectedFeed = if (feed != null) state.feeds.find { feed.value == it.id.rawValue }?.key else state.feeds.find { it.style == Feed.Style.Standard }?.key
        val selectedWeekday = if (weekday != null) DayOfWeek.valueOf(weekday.value) else DayOfWeek.valueOf(state.day.first().value)
        val selectedHour = hour?.value?.toInt() ?: state.hour.first().value.toInt()
        val selectedDays = days?.value?.toInt() ?: state.days.first().value.toInt()

        val status: MutableList<ValidationStatus> = mutableListOf()
        Validator.text(
            text = info,
            isEmpty = { status.add(Header.info to Status.Invalid(warning = viewModel.texts.fieldRequired)) },
            passed = { status.add(Header.info to Status.Valid) },
            formatted = { }
        )
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.create(state, status))
        }

        val form = Form.Create(
            key = PULSE_FORM,
            name = "Puls",
            info = info,
            kind = Form.Kind.Pulse,
            feedback = Form.Feedback.None,
            status = Form.Status.Active
        )

        val emitter = Emitter.Create(
            key = PULSE_EMITTER,
            schedule = Schedule.Weekly(selectedWeekday, selectedHour),
            feed = selectedFeed!!,
            kind = Message.Kind.Blog,
            status = Message.Status.PublishedWithTimeLimit(selectedDays.times(24)),
            display = Message.Display.Pinned,
            attachments = listOf(
                Message.Attachment.InlineForm(PULSE_FORM, PULSE_ATTACHMENT),
                Message.Attachment.InlineTitle(info, Key("0")),
                Message.Attachment.InlineText("Du behöver uppdatera din app till den senaste versionen för att kunna se det här inlägget", Key("1")),
            )
        )

        return if (state.formId == null) {
            store.createForm(create = form)
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = PULSE_SCORE_FIELD, style = Field.Style.Score(1, 5), order = 0, page = 1, required = true, hidden = false, label = "Puls", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = PULSE_WEEK_FIELD, style = Field.Style.WeekOfYear, order = 0, page = 1, required = true, hidden = false, label = "Vecka", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = PULSE_YEAR_FIELD, style = Field.Style.Year, order = 0, page = 1, required = true, hidden = false, label = "År", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = PULSE_MESSAGE_FIELD, style = Field.Style.MessageId, order = 0, page = 1, required = true, hidden = false, label = "", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.reduce(actions).createField(Field.Create(formId = form.id, key = Key("CITY"), style = Field.Style.City, order = 0, page = 1, required = true, hidden = false, label = "Stad", predefined = null, placeholder = null, hint = null))
                        .accumulate(actions)
                        .map { tupleOf(it.first, form) }
                }
                .flatMap { (actions, form) ->
                    store.createEmitter(emitter)
                        .accumulate(actions)
                        .map { tupleOf(it.first, form, it.second) }
                }
                .map { (actions, form, emitter) ->

                    val newState = state.copy(enabled = form.status is Form.Status.Active, emitterId = emitter.id)
                    sceneOf(viewModel.success(state = newState), actions)
                }.failed { scene.failed(result = it) }
        } else {
            store.editForm(id = state.formId, edit = Form.Edit(status = modifiedOf(Form.Status.Active), info = modifiedOf(info)))
                .flatMap { (actions, _) ->
                    store.createEmitter(emitter)
                        .accumulate(actions)
                        .map { tupleOf(it.first, it.second) }
                }
                .map { (actions, emitter) ->

                    val newState = state.copy(enabled = form.status is Form.Status.Active, emitterId = emitter.id)
                    sceneOf(viewModel.success(state = newState), actions)
                }.failed { scene.failed(result = it) }
        }
    }

    suspend fun disable(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val id = viewModel.state.formId ?: return sceneOf(viewModel.failed("Missing formId"))
        val emitterId = viewModel.state.emitterId ?: return sceneOf(viewModel.failed("Missing emitterId"))
        val edit = Form.Edit(status = modifiedOf(Form.Status.Inactive))

        return store.deleteEmitter(emitterId)
            .flatMap { (actions, _) ->
                store.editForm(id = id, edit = edit)
                    .accumulate(actions)
                    .map { tupleOf(it.first, it.second) }
            }
            .map { (actions, form) ->
                val newState = viewModel.state.copy(enabled = form.status is Form.Status.Active)
                sceneOf(viewModel.success(state = newState), actions)
            }.failed { scene.failed(result = it) }
    }
}