package support

import io.ktor.http.*
import screens.items.ActivityValue
import techla.base.*
import techla.form.Field
import techla.form.Form
import techla.guard.Group

object EmployeeSurvey {
    val FORM = Key<Form>("Medarbetarundersökning")
    val PAGE_BREAK = "PAGE_BREAK"

    val SCORE_0 = "SCORE-0"
    val SCORE_0_MAX = "SCORE-MAX-0"
    val SCORE_1 = "SCORE-1"
    val LONG = "LONG"

    val CITY = "CITY"
    val DEPARTMENT = "DEPARTMENT"
    val POSITION = "POSITION"

    object PageBreak

    fun stringFromStyle(s: Field.Style, predefined: String) =
        when (s) {
            is Field.Style.Score ->
                when {
                    predefined == "10" && s.min == 0 -> "${SCORE_0_MAX}-"
                    s.min == 0 -> "${SCORE_0}-"
                    else -> "${SCORE_1}-"
                }
            is Field.Style.Long -> "${LONG}-"

            is Field.Style.City -> "$CITY-"
            is Field.Style.Department -> "$DEPARTMENT-"
            is Field.Style.Position -> "${POSITION}-"
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    fun styleFromString(s: String, value: ActivityValue) =
        when {
            s.startsWith("${SCORE_0_MAX}-") -> Field.Style.Score(0, 10)
            s.startsWith("${SCORE_0}-") -> Field.Style.Score(0, 10)
            s.startsWith("${SCORE_1}-") -> Field.Style.Score(1, 10)
            s.startsWith("${LONG}-") -> Field.Style.Long

            s.startsWith("$CITY-") -> Field.Style.City
            s.startsWith("$DEPARTMENT-") -> Field.Style.Department
            s.startsWith("$POSITION-") -> Field.Style.Position
            else -> throw TechlaError.BadRequest("Unknown specifier: $s")
        }

    private fun predefinedFromString(s: String, value: ActivityValue) =
        when {
            s.startsWith("${SCORE_0_MAX}-") -> "10"
            s.startsWith("${SCORE_0}-") -> "5"
            s.startsWith("${SCORE_1}-") -> "5"
            s.startsWith("${LONG}-") -> ""
            s.startsWith("$CITY-") -> null
            s.startsWith("$DEPARTMENT-") -> null
            s.startsWith("$POSITION-") -> null
            else -> throw TechlaError.BadRequest("Unknown style string: $s")
        }

    private fun requiredFromString(s: String) =
        when {
            s.startsWith("${SCORE_0_MAX}-") -> true
            s.startsWith("${SCORE_0}-") -> true
            s.startsWith("${SCORE_1}-") -> true
            else -> false
        }

    fun userFields(values: List<Pair<DesignSystem.Header, ActivityValue>>): List<Either<Field, PageBreak>> =
        values.mapIndexed { index, (header, value) ->
            if (header.id == PAGE_BREAK)
                rightOf(PageBreak)
            else {
                val label = value.stringValue ?: ""
                val answer = value.answerValue
                val hint = value.hintValue
                val url = value.urlValue?.let { Url(it) }
                val assetId = value.assetValue
                val style = styleFromString(s = header.id, value = value)
                val predefined = predefinedFromString(s = header.id, value = value)
                val required = requiredFromString(s = header.id)
                leftOf(Field(id = Identifier(), key = Key(label), style = style, order = index, page = 1, required = required, hidden = false, autofill = false, label = label, predefined = predefined, min = null, max = null, placeholder = null, hint = hint, answer = answer, url = url, assetId = assetId, attributes = emptyList()))
            }
        }

    fun hiddenFields(start: Int): List<Either<Field, PageBreak>> =
        listOf(
            Field(id = Identifier(), key = Key(CITY), style = Field.Style.City, order = start, page = 1, required = false, hidden = true, autofill = true, label = "Stad", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(DEPARTMENT), style = Field.Style.Department, order = start + 1, page = 1, required = false, hidden = true, autofill = true, label = "Avdelning", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
            Field(id = Identifier(), key = Key(POSITION), style = Field.Style.Position, order = start + 2, page = 1, required = false, hidden = true, autofill = true, label = "Roll", predefined = null, min = null, max = null, placeholder = null, hint = null, answer = null, url = null, assetId = null, attributes = emptyList()),
        ).map { leftOf(it) }

    fun fields(values: List<Pair<DesignSystem.Header, ActivityValue>>): List<Field> {
        var page = 1
        var order = 0
        val userFields = userFields(values)
        val hiddenFields = hiddenFields(userFields.size)

        return (userFields + hiddenFields)
            .mapNotNull { field ->
                when (field) {
                    is Either.Left -> {
                        order += 1
                        field.value.copy(page = page, order = order)
                    }

                    is Either.Right -> {
                        page += 1
                        null
                    }
                }
            }
    }
}