package components

import mui.system.PropsWithSx
import react.FC
import react.PropsWithStyle
import react.dom.html.ReactHTML.img
import support.Design
import support.DesignSystem

external interface RImageProps : PropsWithSx {
    var design: DesignSystem.ImageView?
    var height: Double?
    var width: Double?
}

val RImage = FC<RImageProps> { props ->
    val src = when {
        props.design?.href != null -> props.design!!.href
        props.design?.image != null -> Design.image(props.design!!.image)
        else -> null
    }
    if (src != null) {
        img {
            this.src = src
            height = props.height
            width = props.width
            alt = props.design?.image?.name ?: ""
            style = props.sx
        }
    }
}

