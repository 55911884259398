package screens

import ROUTES
import services.*
import support.*
import support.overallStatus
import support.statusOf
import techla.base.*
import techla.conversation.Feed

object FeedScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
    }

    data class Texts(
        val feed: String,
        val name: String,
        val save: String,
        val yes: String,
        val nameRequired: String,
        val back: String,
        val createFeed: String,
        val create: String,
        val remove: String,

        val next: String,
        val createdIcon: String,
        val createdTitle: String,
        val createdBody: String,

        val removedIcon: String,
        val removedTitle: String,
        val removedBody: String,

        val modalTitle: String,
        val modalBody: String,
        val no: String,
        val saved: String,
        val hash: String,
    )

    data class State(
        val feed: Feed?,
        val name: String,
        val isSaving: Boolean,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(null, "", false)
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val title: DesignSystem.Text,
            val back: DesignSystem.Button,
            val name: DesignSystem.TextInput,
            val create: DesignSystem.Button,
            val save: DesignSystem.Button,
            val remove: DesignSystem.Button,
            val saved: DesignSystem.Toast,
            val modalRemove: DesignSystem.Modal,
            val modalBody: String,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class Created(
            override var texts: Texts,
            override var state: State,
            val added: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Deleted(
            override var texts: Texts,
            override var state: State,
            val removed: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun ready(state: State, status: List<ValidationStatus> = emptyList()): ViewModel =
            Ready(
                texts = texts,
                state = state,
                title = DesignSystem.Text(text = texts.feed, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD,),
                back = DesignSystem.Button(text = texts.back, style = DesignSystem.PaletteType.TRANSPARENT),
                name = DesignSystem.TextInput(
                    header = Header.name,
                    label = texts.name,
                    value = state.feed?.name ?: "",
                    type = DesignSystem.TextInputType.TEXT,
                    insetText = texts.hash,
                    status = status.statusOf(Header.name)
                ),
                create = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, style = DesignSystem.PaletteType.PRIMARY, text = texts.create),
                save = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, style = DesignSystem.PaletteType.PRIMARY, text = texts.save),
                remove = DesignSystem.Button(type = DesignSystem.ButtonType.SUBMIT, danger = true, style = DesignSystem.PaletteType.PRIMARY, text = texts.remove),
                saved = DesignSystem.Toast(message = texts.saved, style = DesignSystem.ToastStyle.SUCCESS),
                modalBody = texts.modalBody,
                modalRemove = DesignSystem.Modal(title = texts.modalTitle, firstButton = DesignSystem.Button(text = texts.no, style = DesignSystem.PaletteType.SECONDARY), secondButton = DesignSystem.Button(text = texts.yes)),
            )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun created(): ViewModel = Created(
            texts = texts,
            state = state,
            added = DesignSystem.Confirmation(
                icon = texts.createdIcon,
                title = texts.createdTitle,
                body = texts.createdBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.FEEDS.INDEX
            )
        )

        fun deleted(): ViewModel = Deleted(
            texts = texts,
            state = state,
            removed = DesignSystem.Confirmation(
                icon = texts.removedIcon,
                title = texts.removedTitle,
                body = texts.removedBody,
                next = texts.next,
                href = ROUTES.DASHBOARD.FEEDS.INDEX
            )
        )

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )
    }

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            feed = "Kanal",
            createFeed = "Skapa kanal",
            name = "Namn",
            nameRequired = "\uD83D\uDC6E Namn måste anges",
            back = "Tillbaka",


            save = "Spara ändringar",
            saved = "Kanal har uppdaterats",

            create = "Skapa kanal",
            remove = "Ta bort kanal",
            modalTitle = "Är du säker?",
            modalBody = "Vill du ta bort kanalen?",
            no = "Nej",
            yes = "Japp!",

            next = "Fortsätt",

            createdIcon = "\uD83E\uDD70",
            createdTitle = "Kanal upplagd",
            createdBody = "Super. Dags att börja göra inlägg!",

            removedIcon = "\uD83D\uDC94",
            removedTitle = "Kanal borttagen",
            removedBody = "Puts väck!",
            hash = "#",
        )

        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>, feedId: Identifier<Feed>?): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state

        if (!store.isPro) return sceneOf(viewModel.getPro())

        if (feedId == null) {
            state = viewModel.state.copy(feed = null, name = "")
            return sceneOf(viewModel.ready(state = state))
        }

        return store.getFeed(feedId).map { (actions, feed) ->
            state = state.copy(feed = feed)
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    fun validate(scene: Scene.Input<ViewModel>, name: String): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val status = mutableListOf<ValidationStatus>()
        var state = viewModel.state.copy(name = name)

        Validator.text(
            text = name,
            isEmpty = { status.add(Header.name to Status.Invalid(warning = viewModel.texts.nameRequired)) },
            passed = { status.add(Header.name to Status.Valid) },
            formatted = { state = state.copy(name = it.lowercase()) },
        )


        if (status.overallStatus() !is Status.Valid)
            return sceneOf(viewModel.ready(state = viewModel.state, status = status))

        return sceneOf(viewModel.ready(state = state.copy(isSaving = true)))
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val state = viewModel.state

        val create = Feed.Create(
            key = Key("#${state.name}"),
            name = state.name,
            style = Feed.Style.Normal
        )

        return store.createFeed(create).map { (actions, _) ->
            sceneOf(viewModel.created(), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        var state = viewModel.state

        val feedId = viewModel.state.feed?.id ?: return sceneOf(viewModel.ready(state = viewModel.state))

        val edit = Feed.Edit(
            key = modifiedOf(Key("#${state.name}")),
            name = modifiedOf(state.name),
        )

        return store.editFeed(id = feedId, edit = edit).map { (actions, feed) ->
            state = state.copy(feed = feed, isSaving = false)
            sceneOf(viewModel.ready(state = state), actions)
        }.failed { scene.failed(result = it) }
    }

    suspend fun delete(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val feedId = viewModel.state.feed?.id ?: return sceneOf(viewModel.ready(state = viewModel.state))

        return store.deleteFeed(feedId).map { (actions, _) ->
            sceneOf(viewModel.deleted(), actions)
        }.failed { scene.failed(result = it) }
    }
}