package screens

import screens.items.*
import services.*
import support.*
import techla.base.*
import techla.form.Field
import techla.form.Form
import kotlin.time.ExperimentalTime

@ExperimentalTime
object QuizScreen {
    object Header {
        val name = DesignSystem.Header(id = "name")
        val title = DesignSystem.Header(id = "title")
        val pageBreak = DesignSystem.Header(id = Quiz.PAGE_BREAK)

        fun question(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Quiz.stringFromStyle(Field.Style.Choice(emptyList()))}${id.rawValue}")
        fun team(id: Identifier<Field> = Identifier()) = DesignSystem.Header(id = "${Quiz.stringFromStyle(Field.Style.Short)}${id.rawValue}")
    }

    enum class Success { DELETE, ENABLE, DISABLE, DUPLICATE, SUCCESS }

    data class Texts(
        val back: String,
        val name: String,
        val nameRequired: String,
        val team: String,
        val pageBreak: String,
        val question: String,
        val next: String,
        val description: String,
        val content: String,
        val add: String,
        val delete: String,
        val successTitle: String,
        val successBody: String,
        val deleteBody: String,
        val disableBody: String,
        val enableBody: String,
        val duplicateBody: String,
        val copy: String,
        val done: String,
        val create: String,
        val quiz: String,
        override val fieldRequired: String,
    ) : ActivityTexts

    data class State(
        val mode: Mode = Mode.Search,
        val formId: Identifier<Form>? = null,
    )

    data class SearchParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    data class ReadyParams(
        val items: List<ActivityItem.ViewModel>,
        val page: Int,
    )

    sealed class ViewModel(open var texts: Texts, open var state: State) {
        object None : ViewModel(
            texts = Texts("", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", "", ""),
            state = State(),
        )

        data class Loading(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Search(
            override var texts: Texts,
            override var state: State,
            val params: SearchParams,
            val title: DesignSystem.Text,
            val image: DesignSystem.ImageView,
            val create: DesignSystem.Button,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Ready(
            override var texts: Texts,
            override var state: State,
            val params: ReadyParams,
            val items: List<ActivityItem.ViewModel>
        ) : ViewModel(texts, state)

        data class Waiting(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        data class Success(
            override var texts: Texts,
            override var state: State,
            val confirmation: DesignSystem.Confirmation,
        ) : ViewModel(texts, state)

        data class Failed(
            override var texts: Texts,
            override var state: State,
            val message: String,
        ) : ViewModel(texts, state)

        data class GetPro(
            override var texts: Texts,
            override var state: State,
        ) : ViewModel(texts, state)

        fun loading(texts: Texts): ViewModel =
            Loading(texts = texts, state = state)

        fun search(state: State, params: SearchParams): ViewModel = Search(
            texts = texts,
            state = state,
            params = params,
            title = DesignSystem.Text(text = texts.quiz, size = DesignSystem.SizeType.XL, style = DesignSystem.StyleType.BOLD, align = DesignSystem.TextAlign.CENTER),
            image = DesignSystem.ImageView(image = DesignSystem.Image.QUIZ),
            create = DesignSystem.Button(text = texts.create, action = DesignSystem.Action.ADD),
            items = params.items
        )

        fun ready(state: State? = null, params: ReadyParams): ViewModel = Ready(
            texts = texts,
            state = (state ?: this.state),
            params = params,
            items = params.items
        )

        fun waiting(state: State): ViewModel =
            Waiting(texts = texts, state = state)

        fun success(success: QuizScreen.Success): ViewModel = Success(
            texts = texts,
            state = state,
            confirmation = DesignSystem.Confirmation(
                icon = "",
                title = texts.successTitle,
                body = when (success) {
                    QuizScreen.Success.DELETE -> texts.deleteBody
                    QuizScreen.Success.DISABLE -> texts.disableBody
                    QuizScreen.Success.ENABLE -> texts.enableBody
                    QuizScreen.Success.DUPLICATE -> texts.duplicateBody
                    QuizScreen.Success.SUCCESS -> texts.successBody
                },
                next = texts.done,
            )
        )

        fun failed(message: String): ViewModel =
            Failed(texts = texts, state = state, message = message)

        fun getPro(): ViewModel = GetPro(
            texts = texts,
            state = state,
        )

        val status
            get() = when (this) {
                is None -> "NONE"
                is Search -> "SEARCH"
                is Waiting -> "WAITING"
                is Loading -> "LOADING"
                is Ready -> "READY"
                is Failed -> "FAILED"
                is Success -> "SUCCESS"
                is GetPro -> "GET PRO"
            }

        fun asLoading() = this as? Loading
        fun asWaiting() = this as? Waiting
        fun asSearch() = this as? Search
        fun asReady() = this as? Ready
        fun asSuccess() = this as? Success
        fun asGetPro() = this as? GetPro
        fun asFailed() = this as? Failed
    }

    private fun Scene.Input<ViewModel>.failed(message: String) =
        sceneOf(viewModel.failed(message = message))

    private fun Scene.Input<ViewModel>.failed(result: Either<List<Warning>, Throwable>) =
        sceneOf(viewModel.failed(message = result.message))

    private fun options(text: Texts) = listOf(
        DesignSystem.Option(title = text.question, value = "QUESTION", action = DesignSystem.Action.QUESTION),
        DesignSystem.Option(title = text.team, value = "TEAM", action = DesignSystem.Action.TEAM),
        DesignSystem.Option(title = text.pageBreak, value = "PAGE_BREAK", action = DesignSystem.Action.PAGE_BREAK),
    )

    private fun titles(text: Texts) = listOf(
        "Beskrivning",
        "Innehåll",
    )

    fun start(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        val texts = Texts(
            back = "Tillbaka",
            name = "Namn på Quiz",
            nameRequired = "\uD83D\uDC6E Namn måste anges",
            team = "\uD83D\uDC6B Lag",
            question = "⁉️ Fråga och svar",
            pageBreak = "\uD83D\uDCC3 Sidbrytning",
            next = "Nästa",
            description = "Beskrivning",
            content = "Innehåll",
            add = "Lägg till",
            delete = "Radera",

            successTitle = "Allt är nu klart \uD83C\uDF8A",
            successBody = "Allt är nu klart!",
            done = "Fortsätt",
            deleteBody = "Puts väck. Men du kan alltid skapa en ny såklart!",
            disableBody = "Sådärja. Nu syns den inte längre i apparna, men resultatet hittar du under Resultat",
            enableBody = "Perfekt! Nu syns den i appen",
            duplicateBody = "Sådärja. Nu finns en kopia på quizen",
            copy = "kopia - ",
            create = "Skapa ett Quiz",
            quiz = "Quiz",

            fieldRequired = "\uD83D\uDC6E Fältet får inte vara tomt",
        )
        return sceneOf(viewModel.loading(texts = texts))
    }

    suspend fun load(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        if (!store.isPro) return sceneOf(viewModel.getPro())
        return store.listQuizzes()
            .map { (actions, quizzes) ->
                val state = viewModel.state
                val params = SearchParams(page = 1, items = buildSearch(quizzes))
                sceneOf(viewModel.search(state = state, params = params), actions)
            }.failed { scene.failed(result = it) }
    }

    private suspend fun show(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.getForm(Identifier(id.rawValue))
            .map { (actions, form) ->
                val state = viewModel.state.copy(formId = form.id)
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(form, viewModel.texts)
                        .decorate(mode = Mode.Edit, titles = titles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun create(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        return successfulOf(Unit).noActions()
            .map { (actions, _) ->
                val state = viewModel.state.copy(formId = null)
                val params = ReadyParams(
                    page = 1,
                    items = buildItems(null, viewModel.texts)
                        .decorate(mode = Mode.Create, titles = titles(viewModel.texts), options = options(viewModel.texts))
                )
                sceneOf(viewModel.ready(state = state, params = params), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun toggle(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, enabled: Boolean): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        val status = if (enabled) Form.Status.Active else Form.Status.Inactive
        val success = if (enabled) Success.ENABLE else Success.DISABLE
        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                store.listQuizzes()
                    .accumulate(actions)
            }
            .flatMap { (actions, quizzes) ->
                store.editForm(Identifier(id.rawValue), Form.Edit(status = modifiedOf(status)))
                    .accumulate(actions)
                    .map {
                        tupleOf(it.first, quizzes, it.second)
                    }
            }
            .flatMap { (actions, quizzes, form) ->
                // Finds any previously active forms and inactivate them
                val previous = quizzes.firstOrNull { it.status.isActive }
                if (form.status.isActive && previous != null)
                    store.editForm(previous.id, Form.Edit(status = modifiedOf(Form.Status.Inactive)))
                        .accumulate(actions)
                else
                    successfulOf(tupleOf(actions, form))
            }
            .map { (actions, _) ->
                sceneOf(viewModel.success(success), actions)
            }
            .failed { scene.failed(result = it) }
    }

    private suspend fun delete(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        return store.deleteForm(id = Identifier(id.rawValue))
            .map { (actions, _) ->
                sceneOf(viewModel.success(Success.DELETE), actions)
            }
            .failed { scene.failed(result = it) }
    }

    suspend fun action(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, action: DesignSystem.Action): Scene.Output<ViewModel> {
        val (store, viewModel) = scene

        techla_log("QUIZ: action='$action'")

        return when (viewModel) {
            is ViewModel.Search ->
                when (action) {
                    DesignSystem.Action.EDIT -> show(scene, id)
                    DesignSystem.Action.ENABLE -> toggle(scene, id, enabled = true)
                    DesignSystem.Action.DISABLE -> toggle(scene, id, enabled = false)
                    DesignSystem.Action.DELETE -> delete(scene, id)
                    // DesignSystem.Action.DUPLICATE -> sceneOf(viewModel.waiting(viewModel.state.copy(formId = Identifier(id.rawValue))))
                    else -> sceneOf(viewModel)
                }

            is ViewModel.Ready ->
                when (action) {
                    DesignSystem.Action.SEARCH -> load(scene)
                    DesignSystem.Action.DELETE -> sceneOf(viewModel.replace(viewModel.params.items.delete(id)))
                    DesignSystem.Action.UP -> sceneOf(viewModel.replace(viewModel.params.items.up(id)))
                    DesignSystem.Action.DOWN -> sceneOf(viewModel.replace(viewModel.params.items.down(id)))
                    DesignSystem.Action.PREV -> sceneOf(viewModel.replace(viewModel.params.items.prev()))
                    DesignSystem.Action.NEXT -> validate(scene)
                    DesignSystem.Action.QUESTION -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.question(header = Header.question(), label = viewModel.texts.question))))
                    DesignSystem.Action.TEAM -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.title(header = Header.team(), label = viewModel.texts.team))))
                    DesignSystem.Action.PAGE_BREAK -> sceneOf(viewModel.replace(viewModel.params.items.add(ActivityItem.pageBreak(header = Header.pageBreak, label = viewModel.texts.pageBreak))))
                    DesignSystem.Action.CREATE -> save(scene)
                    DesignSystem.Action.EDIT -> save(scene)
                    else -> sceneOf(viewModel)
                }

            else -> sceneOf(viewModel)
        }
    }

    suspend fun setValue(scene: Scene.Input<ViewModel>, id: Identifier<ActivityItem>, value: ActivityValue): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready
        return sceneOf(viewModel.replace(viewModel.params.items.setValue(id = id, value = value)))
    }

    private fun validate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (_, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, viewModel.params.page)
        return if (status.overallStatus() !is Status.Valid) {
            sceneOf(viewModel.status(status))
        } else
            sceneOf(viewModel.replace(viewModel.params.items.next()))
    }

    private suspend fun save(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
        val (store, viewModel) = scene
        viewModel as ViewModel.Ready

        val status = viewModel.params.items.validate(viewModel.texts, null)
        if (status.overallStatus() !is Status.Valid) {
            return sceneOf(viewModel.status(status))
        }

        val formId = viewModel.state.formId
        val activityValues = viewModel.params.items.activityValues()
        val name = activityValues.firstOrNull { it.first == Header.name }?.second?.stringValue
        val userActivities = activityValues.filter { it.first != Header.name }

        val create = Form.Create(
            key = Key.random(length = 10),
            name = name ?: "",
            kind = Form.Kind.Quiz,
            feedback = Form.Feedback.None,
            status = Form.Status.Inactive,
            policy = Form.Policy.Once,
        )
        val edit = Form.Edit(
            name = name?.let { modifiedOf(it) } ?: Modification.Unmodified,
        )

        return successfulOf(Unit).noActions()
            .flatMap { (actions, _) ->
                if (formId == null)
                    store.reduce(actions).createForm(create = create)
                        .accumulate(actions)
                else
                    store.reduce(actions).editForm(formId, edit = edit)
                        .accumulate(actions)
            }
            .flatMap { (actions, form) ->
                form.fields
                    .zipAll(Quiz.fields(userActivities), null, null)
                    .map { (existing, modified) ->
                        when {
                            existing != null && modified != null -> store.reduce(actions).editField(existing.id, modified.toEdit()).map { tupleOf(it.first, Unit) }
                            existing == null && modified != null -> store.reduce(actions).createField(modified.toCreate(form.id)).map { tupleOf(it.first, Unit) }
                            existing != null && modified == null -> store.reduce(actions).deleteField(existing.id)
                            else -> successfulOf(Unit).noActions()
                        }.accumulate(actions)
                    }
                    .all()
                    .map { tupleOf(it.first, form, it.second) }
            }
            .map { (actions, _, _) ->
                sceneOf(viewModel.success(Success.SUCCESS), actions)
            }.failed { scene.failed(result = it) }
    }
    /*
        suspend fun duplicate(scene: Scene.Input<ViewModel>): Scene.Output<ViewModel> {
            val (store, viewModel) = scene
            if (viewModel.state.formId == null) return sceneOf(viewModel.failed("No FormId"))

            return store.getForm(viewModel.state.formId!!)
                .flatMap { (actions, form) ->

                    val create = Form.Create(
                        key = Key("QUIZ"),
                        name = "${viewModel.texts.copy}${form.name}",
                        kind = Form.Kind.Quiz,
                        feedback = Form.Feedback.None,
                        status = Form.Status.Inactive,
                        policy = Form.Policy.Once
                    )
                    store.reduce(actions).createForm(create = create)
                        .flatMap { (actions, copiedForm) ->
                            val fieldList = createFields(items = form.fields, form = copiedForm)

                            fieldList.map {
                                store.reduce(actions).createField(it)
                                    .accumulate(actions)
                            }
                                .all()
                                .map { tupleOf(it.first, copiedForm, it.second) }
                        }
                }
                .map { (actions, _) ->
                    sceneOf(viewModel.success(Success.DUPLICATE), actions)
                }.failed { scene.failed(result = it) }
        }*/

    private fun buildSearch(forms: List<Form>): List<ActivityItem.ViewModel> =
        forms.map { item ->
            ActivityItem.search(id = Identifier(item.id.rawValue), label = item.name, info = "", active = item.status is Form.Status.Active)
        }

    private fun buildItems(form: Form?, texts: Texts): List<ActivityItem.ViewModel> {
        val page1 = listOf(
            ActivityItem.basicName(header = Header.name, value = ActivityValue.StringValue(form?.name ?: ""), label = texts.name, displayPage = 1),
            // ActivityItem.info(header = Header.info, value = ActivityValue.StringValue(form?.info ?: ""), label = texts.info, displayPage = 1),
        )
        val page2 = form?.fields
            ?.groupBy { it.page }
            ?.map { entry ->
                entry.value
                    .sortedBy { it.order }
                    .mapNotNull { field ->
                        when (val style = field.style) {
                            is Field.Style.Choice -> ActivityItem.question(header = Header.question(field.id), label = texts.question, value = ActivityValue.QuestionValue(title = field.label ?: "", choices = style.choices, answer = field.answer ?: ""), displayPage = 2)
                            is Field.Style.Short -> ActivityItem.title(header = Header.team(field.id), label = texts.team, value = ActivityValue.StringValue(value = field.label ?: ""), displayPage = 2)
                            else -> null
                        }
                    }
            }
            ?.filter { it.isNotEmpty() }
            ?.join(ActivityItem.pageBreak(header = Header.pageBreak, label = texts.pageBreak))
            ?: emptyList()

        return page1 + page2
    }
}

@ExperimentalTime
private fun QuizScreen.ViewModel.Ready.replace(items: List<ActivityItem.ViewModel>): QuizScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.resetStatus() }))
}

@ExperimentalTime
private fun QuizScreen.ViewModel.Ready.status(status: List<ValidationStatus>): QuizScreen.ViewModel {
    return ready(params = params.copy(items = items.map { it.setStatus(status) }))
}
